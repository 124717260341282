import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, ICurriInfo, ILibrary, ILibraryFile, IMeta, IMetaMap, IScript, IText } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from 'xlsx';

interface IMetaBulkRegister {
    brand: IBrand;
    menuName: string;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaBulkRegister(props: IMetaBulkRegister) {

    const [fileContent, setFileContent] = useState(null as any);
    const [fileUploading, setFileUploading] = useState(false);

    let prevView = false;
    
    const currMenu = util.getCurrentMenu();

    useEffect(() => {
        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.OnClose();
        //     return;
        // }    
    }, [])

    function OnClose(): void {
        props.OnClose();
    }

    async function onExcelProccessing(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            const sheetName = dataParse[0] as string[];
            logger.log("dataParse", file, sheetName[0]);  

            // const arrRows = [];
            // for (let i = 4; i < dataParse.length; i++) {
            //     const arrItem = dataParse[i] as string[];
            //     arrRows.push(arrItem);
            // }                
            // logger.log("arrRows", arrRows);  
        
            let totalCnt = 1;

            // let metaName = ["curriBook"];
            let metaName = ["curriBook", "curriUnit1", "curriUnit2", "curriUnit3", "curriUnit4", "curriUnit5", "curriUnit6", "curriUnit7"];

            for (let mi = 0; mi < metaName.length; mi++) {

                const names = [
                    "curriBook", "curriUnit1", "curriUnit2", "curriUnit3", "curriUnit4", "curriUnit5", "curriUnit6",
                    "curriYear", 
                    "curriSchool", 
                    "curriSubject", 
                    "curriGrade", 
                    "curriSemester",
                    "unitGroup",
                ];
                const listAll = await util.getMetaNamesList(names);
                logger.log("listAll", listAll);  
    
                const mName = metaName[mi];
                
                for (let i = 4; i < dataParse.length; i++) {

                    const item = dataParse[i] as string[];
                    // logger.log("dataParse", item);  
    
                    let depth = 1;
                    if(item[1] === "curriBook") {
                        depth = 1;
                    } else if(item[1] === "curriUnit1") {
                        depth = 2;
                    } else if(item[1] === "curriUnit2") {
                        depth = 3;
                    } else if(item[1] === "curriUnit3") {
                        depth = 4;
                    } else if(item[1] === "curriUnit4") {
                        depth = 5;
                    } else if(item[1] === "curriUnit5") {
                        depth = 6;
                    } else if(item[1] === "curriUnit6") {
                        depth = 7;
                    } else if(item[1] === "curriUnit7") {
                        depth = 8;
                    }
    
                    if(item[1] === mName) {
    
                        const curriYear = listAll.filter((meta, _) => meta.name === "curriYear");
                        const curriSchool = listAll.filter((meta, _) => meta.name === "curriSchool");
                        const curriSubject = listAll.filter((meta, _) => meta.name === "curriSubject");
                        const curriGrade = listAll.filter((meta, _) => meta.name === "curriGrade");
                        const curriSemester = listAll.filter((meta, _) => meta.name === "curriSemester");
                        const unitGroup = listAll.filter((meta, _) => meta.name === "unitGroup");
            
                        // logger.log(item[1], item);  
    
                        const listMetaMap : IMeta[] = [];
                        if(item[6] && item[6].length > 0) {
                            const obj = curriYear.find((_item) => _item.val === item[6]);
                            if(obj) {
                                listMetaMap.push(obj);
                            }
                        }
                        if(item[7] && item[7].length > 0) {
                            const obj = curriSchool.find((_item) => _item.val === item[7]);
                            if(obj) {
                                listMetaMap.push(obj);
                            }
                        }
                        if(item[8] && item[8].length > 0) {
                            const obj = unitGroup.find((_item) => _item.val === item[8]);
                            if(obj) {
                                listMetaMap.push(obj);
                            }
                        }
                        if(item[9] && item[9].length > 0) {
                            const obj = curriSubject.find((_item) => _item.val === item[9]);
                            if(obj) {
                                listMetaMap.push(obj);
                            }
                        }
                        if(item[10] && item[10].length > 0) {
                            const obj = curriGrade.find((_item) => _item.val === item[10]);
                            if(obj) {
                                listMetaMap.push(obj);
                            }
                        }
                        if(item[11] && item[11].length > 0) {
                            const obj = curriSemester.find((_item) => _item.val === item[11]);
                            if(obj) {
                                listMetaMap.push(obj);
                            }
                        }
        
                        const { v4: uuidv4 } = require('uuid');            
                        const uuid = uuidv4();
        
                        const code = String(item[5]);
                        let pcode = item[1] === "curriBook" ? 0 : -1;
                        let parentCode = "";
                        if(Number(item[15]) > 0) {
                            pcode = Number(item[15]);
                        } else {
                            const arrCode = code.split("-");
                            arrCode.map((item, idx) => {
                                if(idx < arrCode.length-1) {
                                    if(parentCode === "") {
                                        parentCode = item
                                    } else {
                                        parentCode = parentCode + "-" + item;
                                    }                                
                                }
                            });
                            const parentMeta = listAll.find((item) => item.code === parentCode);
                            if(parentMeta) {
                                pcode = parentMeta.id!;
                            }
                        }
    
                        const meta = {
                            brand_id: 6,
                            id: undefined,        
                            code: code,
                            name: item[1],
                            val: item[3],
                            depth: depth,
                            parent_id: pcode,
                            is_active: item[19] === "1" ? true : false,
                            creator: item[16],
                            // creator_id: _id,
                            // creator_name: uname,
                            description: item[4],
                
                            updater: undefined,
                            updater_id: undefined,
                            updater_name: undefined,
                
                            max_depth: item[14] ? Number(item[14]) : undefined,
                            
                            uuid: parentCode,
                        }
                
                        const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": listMetaMap}
                        // logger.log(totalCnt, mName, "insertMetaMetaMap", metamap);
        
                        const result = await util.insertMetaMetaMap(metamap);
                        logger.log(totalCnt, "insertMetaMetaMap", metamap, result);
    
                        totalCnt = totalCnt + 1;
    
                    }
                }
            }

            setFileUploading(false);                
        }

    }

    async function onExcelProccessing1(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            const sheetName = dataParse[0] as string[];
            logger.log("dataParse", file, sheetName[0]);  
        
            let totalCnt = 1;

            const names = [
                "curriSubject", 
            ];
            const listAll = await util.getMetaNamesList(names);
            logger.log("listAll", listAll);  

            for (let i = 4; i < dataParse.length; i++) {

                const item = dataParse[i] as string[];
                // logger.log("dataParse", item);  

                let depth = 1;

                // const curriSubject = listAll.filter((meta, _) => meta.name === "curriSubject");
    
                // logger.log(item[1], item);  

                const listMetaMap : IMeta[] = [];
                // if(item[9] && item[9].length > 0) {
                //     const obj = curriSubject.find((_item) => _item.val === item[9]);
                //     if(obj) {
                //         listMetaMap.push(obj);
                //     }
                // }

                const { v4: uuidv4 } = require('uuid');            
                const uuid = uuidv4();

                const code = item[5];
                if(code === undefined) {
                    continue;
                }

                const meta = {
                    brand_id: 4,
                    id: undefined,        
                    code: code,
                    // name: item[1].trim(),
                    name: "teachingSubject",                    
                    val: item[3],
                    depth: depth,
                    is_active: true,
                    creator: "cbstest1",
                    // creator_id: _id,
                    // creator_name: uname,
                    description: item[4],
                                    
                    uuid: uuid,
                }
        
                const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": listMetaMap}
                // logger.log(totalCnt, "insertMetaMetaMap", metamap);

                const result = await util.insertMetaMetaMap(metamap);
                logger.log(totalCnt, "insertMetaMetaMap", metamap, result);

                totalCnt = totalCnt + 1;

            }
        
            setFileUploading(false);                
        }

    }

    async function onExcelProccessing2(e: ProgressEvent<FileReader>, file: File) {

        if(e.target) {

            setFileUploading(true);

            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});

            const sheetName = dataParse[0] as string[];
            logger.log("dataParse", file, sheetName[0]);  

            // const arrRows = [];
            // for (let i = 4; i < dataParse.length; i++) {
            //     const arrItem = dataParse[i] as string[];
            //     arrRows.push(arrItem);
            // }                
            // logger.log("arrRows", arrRows);  
        
            let totalCnt = 1;

            const achievementStandard = await util.getMetaList("4", "achievementStandard");
            const studyMap2 = await util.getMetaList("3", "studyMap2");

            for (let i = 1; i < dataParse.length; i++) {

                const item = dataParse[i] as string[];
                // logger.log("dataParse", item);  
    
                // logger.log(item[1], item);  

                const { v4: uuidv4 } = require('uuid');            
                const uuid = uuidv4();

                const key = String(item[0]);
                const code = String(item[1]);
                const val = String(item[2]);
                const std = String(item[3]).replaceAll(" ", "#^|");

                if(key && Number(key) > 0) {
                    continue;
                }

                let standard = std.replaceAll("\n", "#^|");
            
                let _depth = 4;
                let _code = "map032-" + code;
                let _parent_id = 0;
        
                let parentCode = "";
                const arrCode = _code.split("-");
                arrCode.map((item, idx) => {
                    if(idx < arrCode.length-1) {
                        if(parentCode === "") {
                            parentCode = item
                        } else {
                            parentCode = parentCode + "-" + item;
                        }                                
                    }
                });
    
                const parent = studyMap2.find((meta) => meta.code === parentCode);
                if(parent) {
                    _parent_id = Number(parent.id);
                }

                let meta_mapping_meta : IMeta[] = [];

                const arrStd = standard.split("#^|");
                arrStd.map((item, idx) => {
                    const stdMap = achievementStandard.find((meta) => meta.val1 === item);
                    if(stdMap) {
                        meta_mapping_meta.push(stdMap);
                    }
                });

                if(meta_mapping_meta.length === 0) {
                    logger.log(totalCnt, "error meta_mapping_meta", item);
                }

                // standardList.map((_, idx) => {
    
                //     const meta_standard_view_ = document.getElementById("meta_standard_view_"+idx) as HTMLInputElement;
                //     const meta_standard_ = (document.getElementById('meta_standard_'+idx) as HTMLSelectElement);
                //     if(meta_standard_ && meta_standard_view_.style.display !== "none" && meta_standard_.value.trim().length > 0) {
                //         const val = meta_standard_.value.trim();
                //         if(standard === "") {
                //             standard = val;
                //         } else {
                //             standard = standard + deli + val;
                //         }
                //         const meta = achievementStandardList[meta_standard_.selectedIndex-1];
                //         if(meta) {
                //             meta_mapping_meta.push(meta);
                //         }
                //     }
                // });
        
                const meta = {
    
                    id: undefined,
        
                    code: _code,
                    name: "studyMap_1",
                    val: val,
                    depth: _depth,
                    parent_id: _parent_id,
                    is_active: true,
                    creator: "cbstest1",
                    brand_id: 3,
                            
                    uuid: uuid,
    
                    name1: "표준체계 ID",
                    name2: "선수학습 ID",
                    name3: "후속학습 ID",
                    name4: "평가영역 type",
                    name5: "평가영역 value",
                    name6: "소재",
                    name7: "의사소통기능​",
                    name8: "언어형식​",
    
                    val1: standard,
                    val2: undefined,
                    val3: undefined,
                    val4: undefined,
                    val5: undefined,
                    val6: undefined,
                    val7: undefined,
                    val8: undefined,
            
                }
    
                const filteredMap : IMeta[] = [];
                meta_mapping_meta.map((item) => {
                    if(filteredMap.length === 0) {
                        filteredMap.push(item);
                    } else {
                        if(filteredMap.findIndex((_item) => _item.id === item.id) === -1) {
                            filteredMap.push(item);
                        }        
                    }
                });
        
                const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": filteredMap}
                logger.log(totalCnt, "insertMetaMetaMap", metamap);

                // const result = await util.insertMetaMetaMap(metamap);
                // logger.log(totalCnt, "insertMetaMetaMap", metamap, result);

                totalCnt = totalCnt + 1;
            }

            setFileUploading(false);                
        }

    }

    async function onFileUploadForSave() {

        if(!fileContent) {
            props.showModal("", "엑셀 파일을 등록해주세요.");
            return;
        }

        logger.log("onFileUploadForSave", fileContent.file);      

        // var reader = new FileReader();
        // reader.onload = (e) => onExcelProccessing2(e, fileContent.file);
        // reader.readAsBinaryString(fileContent.file)
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _url = e.target.files![0];
        const _content : ILibraryFile = {
            type: _url.type,
            file: _url,
            url: _url.name,
            thumbnail: _url.name,
            isDelete: false,
            isUploaded: false
        }
        logger.log("handleChange", _content);
    
        setFileContent(_content);
    }
    
    return (
        <div>

            <div>

                <h4>일괄 등록({props.menuName})</h4>

                {/* <!-- table --> */}
				<table>
					<caption className="sr-only">이미지 관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row"><label htmlFor="file-input" className="mr-0">파일 찾기</label></th>
							<td className="text-left">
								<label htmlFor="file-input" className="sr-only">파일찾기</label>
								{/* <input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file"/> */}
								<input className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary" aria-describedby="file_input_help" id="file-input" type="file" onChange={(e) => handleChange(e)}/>
							</td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
						<button type="button" className="btn-sky" onClick={onFileUploadForSave}>저장</button>
					</div>
				</div>

            </div>

            {fileUploading && <>
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>
            </>}

        </div>
    );
}

export default MetaBulkRegister;