import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IBrand, IMenu, IMeta, ITextBook, isDev } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import Tooltip from "@mui/material/Tooltip";
import TeachingEdit from "./VisangBookEdit";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import VisangBookEdit from "./VisangBookEdit";

interface IVisangBookList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
}
  
function VisangBookList(props: IVisangBookList) {
    
    const [list, setList] = useState([] as any);
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [isEditing, setIsEditing] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null as any);
    const [selectedEditItem, setSelectedEditItem] = useState(null as any);

    const [dataCategoryList, setDataCategoryList] = useState(null as any);
    const [dataBrandList, setDataBrandList] = useState(null as any);
    const [dataYearList, setDataYearList] = useState(null as any);
    const [visangbookCategoryList, setVisangbookCategoryList] = useState(null as any);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    const preview_skin = ["basic04"];

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getVisangBookMetaInfo();

            const _pageNum = localStorage.getItem("VisangBook_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            // const div = document.getElementsByClassName("SG-datepicker__input-container");
            // if(div) {
                
            //     const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
            //     if(!new_TB_sdate) {
            //         let node = document.createElement("input");
            //         node.id = "new_TB_sdate";
            //         node.type = "text";
            //         node.placeholder = "시작일";
            //         node.value = ""
            //         node.addEventListener("click", (e) => {
            //             e.stopPropagation();
            //         });
            //         node.addEventListener("keydown", (e) => {
            //             e.stopPropagation();                            
            //         })
        
            //         div[0].appendChild(node)
    
            //     }

            //     const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
            //     if(TB_sdate) {
            //         TB_sdate.style.display = "none";
            //     }

            //     const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
            //     if(!new_TB_edate) {
            //         let node_e = document.createElement("input");
            //         node_e.id = "new_TB_edate";
            //         node_e.type = "text";
            //         node_e.placeholder = "종료일";
            //         node_e.value = ""
            //         node_e.addEventListener("click", (e) => {
            //             e.stopPropagation();
            //         });
            //         node_e.addEventListener("keydown", (e) => {
            //             e.stopPropagation();
            //         })
        
            //         div[1].appendChild(node_e)
            //     }

            //     const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
            //     if(TB_edate) {
            //         TB_edate.style.display = "none";
            //     }
            // }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            if(isEditing) {
                setIsEditing(false);
            }
        }
    }, [props.menu])

    async function getVisangBookMetaInfo() {

        const names = [
            "dataCategory",
            "dataYear",
            "dataBrand",
            "visangbookCategory",
        ];
        const listAll = await util.getMetaNamesList(names);
        listAll.filter((meta) => meta.name === "dataCategory");

        const dataCategory = listAll.filter((meta) => meta.name === "dataCategory");
        setDataCategoryList(dataCategory);    
        const dataYear = listAll.filter((meta) => meta.name === "dataYear");
        setDataYearList(dataYear);    
        const dataBrand = listAll.filter((meta) => meta.name === "dataBrand");
        setDataBrandList(dataBrand);    
        const visangbookCategory = listAll.filter((meta) => meta.name === "visangbookCategory");
        setVisangbookCategoryList(visangbookCategory);    
    }

    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);

        // const brand = util.getCurrentBrand();
        // if(!brand) {
        //     return;
        // }
        // setCurrPage(1);

        // const data = {
        //     brand_id: brand.id ? brand.id : null,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("VisangBook_numOfPage") ? Number(localStorage.getItem("VisangBook_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("VisangBook_numOfPage") ? Number(localStorage.getItem("VisangBook_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);

        if(data) {
            // data.brand_id = undefined;
            data.detail = true;
        }
        let list = await util.getTextbookSearch(data);
        if(!list) {
            list = [];
        }

        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            item.isValid = item.pdf_url && item.pdf_url.length > 10 ? true : false;    
        }        

        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const TB_keyword = document.getElementById("TB_keyword") as HTMLInputElement;
        const TB_keyword_type = document.getElementById("TB_keyword_type") as HTMLInputElement;
        
        const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
        const TB_curriSchool_val = curriInfo.curriSchool[TB_curriSchool.selectedIndex-1];
        const TB_curriSubject = document.getElementById("TB_curriSubject") as HTMLSelectElement;
        const TB_curriSubject_val = curriInfo.curriSubject[TB_curriSubject.selectedIndex-1];
        const TB_curriGrade = document.getElementById("TB_curriGrade") as HTMLSelectElement;
        const TB_curriGrade_val = curriInfo.curriGrade[TB_curriGrade.selectedIndex-1];
        const TB_curriSemester = document.getElementById("TB_curriSemester") as HTMLSelectElement;
        const TB_curriSemester_val = curriInfo.curriSemester[TB_curriSemester.selectedIndex-1];

        const TB_dataYear = document.getElementById("TB_dataYear") as HTMLSelectElement;
        const TB_dataYear_val = dataYearList && (dataYearList as IMeta[]).length > 1 ? dataYearList[TB_dataYear.selectedIndex-1] : undefined;
        
        const TB_dataCategory = document.getElementById("TB_dataCategory") as HTMLSelectElement;
        const TB_dataCategory_val = dataCategoryList && (dataCategoryList as IMeta[]).length > 1 ? dataCategoryList[TB_dataCategory.selectedIndex-1] : undefined;

        const TB_dataBrand = document.getElementById("TB_dataBrand") as HTMLSelectElement;
        const TB_dataBrand_val = dataBrandList && (dataBrandList as IMeta[]).length > 1 ? dataBrandList[TB_dataBrand.selectedIndex-1] : undefined;

        const TB_visangbookCategory = document.getElementById("TB_visangbookCategory") as HTMLSelectElement;
        const TB_visangbookCategory_val = visangbookCategoryList && (visangbookCategoryList as IMeta[]).length > 1 ? visangbookCategoryList[TB_visangbookCategory.selectedIndex-1] : undefined;

        const metaMap : IMeta[] = [];
        if(TB_curriSchool_val) {
            metaMap.push(TB_curriSchool_val);
        }        
        if(TB_curriSubject_val) {
            metaMap.push(TB_curriSubject_val);
        }        
        if(TB_curriGrade_val) {
            metaMap.push(TB_curriGrade_val);
        }        
        if(TB_curriSemester_val) {
            metaMap.push(TB_curriSemester_val);
        }        
        if(TB_dataYear_val) {
            metaMap.push(TB_dataYear_val);
        }
        if(TB_dataCategory_val) {
            metaMap.push(TB_dataCategory_val);
        }
        if(TB_dataBrand_val) {
            metaMap.push(TB_dataBrand_val);
        }        
        if(TB_visangbookCategory_val) {
            metaMap.push(TB_visangbookCategory_val);
        }        

        let search = "";
        if(TB_keyword) {
            search = TB_keyword.value;
        }
        let searchField = "";
        if(TB_keyword_type) {
            searchField = TB_keyword_type.value;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            search: search,
            searchField: searchField,

            type: "visangbook",

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("VisangBook_numOfPage") ? Number(localStorage.getItem("VisangBook_numOfPage")) : 10,

            metaMap: metaMap,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("VisangBook_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onEditTextBook(textbook: ITextBook | null = null) {
        setSelectedEditItem(textbook);
        setIsEditing(true);
    }

    async function onCopy() {

        props.showLoading(true);

        const TB_chk = document.getElementsByName("TB_chk");

        let checkedCnt = 0;
        for (let i = 0; i < TB_chk.length; i++) {
            const element = TB_chk[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                if(checkedCnt > 1) {
                    props.showModal("", "복사할 i-book 을 하나만 선택해 주세요!");
                    props.showLoading(false);
                    return;        
                }
            }
        }

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const TB_chk_ = document.getElementById("TB_chk_"+i) as HTMLInputElement;
            if(item && TB_chk_ && TB_chk_.checked) {
                logger.log("onCopy", item);

                const data = {
                    id: item.id,
                }
                const result = await util.copyTextbook(item.id!);
                if(!result) {
                    await util.onCheckLoginApi();
                    props.showModal("", "오류가 있습니다. 관리자에게 문의해 주시기 바랍니다.");
                } else {
                    logger.log("onCopy", result);

                    for(;;) {
                        const info = await util.getTextBookInfo(result.id!, false);
                        if(info) {

                            const version = (info as ITextBook).version;
                            logger.log("onCopy check version", version);

                            if(version && version > 0) {
                                break;
                            }
                            await util.timeout(1000); //for 1 sec delay
                        }
                    }

                    props.showModal("", "복사가 완료 되었습니다.");
                }
                
                _onSearch(searchData);

                break;
            }            
        }

        props.showLoading(false);

    }

    async function onDelete() {

        const TB_chk = document.getElementsByName("TB_chk");

        let checkedCnt = 0;
        for (let i = 0; i < TB_chk.length; i++) {
            const element = TB_chk[i] as HTMLInputElement;
            if(element.checked) {
                checkedCnt++;
                if(checkedCnt > 1) {
                    props.showModal("", "삭제할 i-book 을 하나만 선택해 주세요!");
                    return;        
                }
            }
        }

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const radio = document.getElementById("TB_chk_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {

                const me = util.getUserInfo("uid");
                logger.log("onDelete", item, me);

                if(me !== item.creator) {
                    props.showModal("", "본인이 등록한 교수자료만 삭제할 수 있습니다.");
                    return;        
                }

                if(window.confirm("정말 삭제 하시겠습니까?") === true) {
                    const data = {
                        id: item.id,
                    }
                    const result = await util.deleteTextbook(item.id!);
                    logger.log("onDelete", result);
                    
                    _onSearch(searchData);
    
                    props.showLoading(false);
        
                    break;    
                }
            }            
        }
    }

    async function onUse(use: boolean) {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        props.showLoading(true);

        let cnt = 0;
        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const radio = document.getElementById("TB_chk_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {

                const _id = util.getUserInfo("id");
                const uid = util.getUserInfo("uid");
                const uname = util.getUserInfo("name");
        
                const data = {

                    brand_id: brand.id,

                    id: item.id,
        
                    updater: uid,
                    updater_id: _id,
                    updater_name: uname,
        
                    is_active: use,
                };
                const result = await util.textbookForSave(data, "update");
                cnt++;
            }            
        }

        props.showLoading(false);

        if(cnt > 0) {
            const _searchData = searchData;
            _onSearch(_searchData);                
        }
    }

    // async function onDownload() {

    //     const TB_chk = document.getElementsByName("TB_chk");

    //     const checkedList = [[] as any, [] as any];

    //     for (let i = 0; i < TB_chk.length; i++) {
    //         const element = TB_chk[i] as HTMLInputElement;
    //         if(element.checked) {
    //             const item = list[i] as ITextBook;
    //             logger.log("onDownload", item);

    //             const textbook = await util.getTextBookInfo(item.id!);
    //             logger.log("getTextBookInfo", textbook);
    //             if(textbook) {

    //                 const _textbook = (textbook as ITextBook);

    //                 if(_textbook) {

    //                     const metaMap = (textbook as ITextBook).metaMap;
    //                     const _curriYear = metaMap.find((meta, _) => meta.name === "curriYear");
    //                     const _curriSchool = metaMap.find((meta, _) => meta.name === "curriSchool");
    //                     const _curriGrade = metaMap.find((meta, _) => meta.name === "curriGrade");
    //                     const _curriSubject = metaMap.find((meta, _) => meta.name === "curriSubject");
    //                     const _curriBook = metaMap.find((meta, _) => meta.name === "curriBook");
    //                     const _visangbookCategory = metaMap.find((meta, _) => meta.name === "visangbookCategory");
    //                     const _teachingUsage = metaMap.find((meta, _) => meta.name === "teachingUsage");
    //                     const _teachingSubject = metaMap.find((meta, _) => meta.name === "teachingSubject");
                
    //                     const column = [
    //                         "브랜드",
    //                         "KEY",
    //                         "타이틀",
    //                         "교육과정",
    //                         "학교급",
    //                         "학년",
    //                         "교과",
    //                         "과목",
    //                         "교과서",
    //                         "교수자료 유형",
    //                         "교수자료 용도",
    //                         "작성자",
    //                         "등록일",
    //                         "사용설정",
    //                     ];
            
    //                     if(checkedList.length === 2) {
    //                         checkedList.push(column);
    //                     }
                        
    //                     const excelData = [];
    //                     for (let i = 0; i < column.length; i++) {
    //                         excelData[i] = "";
    //                     }
        
    //                     excelData[0] = util.getCurrentBrand()?.id;
    //                     excelData[1] = _textbook.id;
    //                     excelData[2] = _textbook.name;
    //                     excelData[3] = _curriYear?.id;
    //                     excelData[4] = _curriSchool?.id;
    //                     excelData[5] = _curriGrade?.id;
    //                     excelData[6] = _curriSubject?.id;
    //                     excelData[7] = _teachingSubject?.id;
    //                     excelData[8] = _curriBook?.id;
    //                     excelData[9] = _visangbookCategory?.id;
    //                     excelData[10] = _teachingUsage?.id;
    //                     excelData[11] = _textbook.creator;
    //                     excelData[12] = _textbook.regdate;
    //                     excelData[13] = _textbook.is_active ? "1" : "0";
    
    //                     checkedList.push(excelData);                       
    
    //                 }
    //             }
    //         }
    //     }

    //     logger.log("checkedList", checkedList);        
    //     exportToExcel(checkedList);            

    // }

    async function onDownload() {

        const TB_chk = document.getElementsByName("TB_chk");

        const checkedList: string[][] = [];

        const column = [
            "제목",
            "링크",
            "뷰어타입",
        ];
    
        checkedList.push(column);

        let cnt = 0;
        for (let i = 0; i < TB_chk.length; i++) {
            const element = TB_chk[i] as HTMLInputElement;
            if(element.checked) {
                const item = list[i] as ITextBook;
                logger.log("onDownload", item);
                
                for (let k = 0; k < preview_skin.length; k++) {
                    const excelData: string[] = [];
                    for (let j = 0; j < column.length; j++) {
                        excelData[j] = "";
                    }
    
                    const url = "https://t-cms.vsaidt.com"+"/CBS_iBook/"+item.id+"/contents/index.html?skin="+preview_skin[k];
    
                    excelData[0] = item.name;
                    excelData[1] = url;
                    excelData[2] = preview_skin[k];
                    checkedList.push(excelData);      
        
                    cnt++;
                }
            }
        }

        if(cnt > 0) {
            logger.log("checkedList", checkedList);        
            exportToExcel(checkedList);                
        }
    }

    const exportToExcel = (data: string[][]) => {
        const worksheet = XLSX.utils.aoa_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        const today = new Date();
        saveAs(blob, "downloaded_data_" + today.toISOString() + ".xlsx");
    };

    function onChangePreview(e: ChangeEvent<HTMLSelectElement>, item: ITextBook) {

        const select = e.target;

        if(select) {
            const url = "https://t-cms.vsaidt.com"+"/CBS_iBook/"+item.id+"/contents/index.html?skin=" + select.value;                
            window.open(url, "_blank", "width=1024,height=768");        

            select.selectedIndex = 0;
        }
    }

    return (
        <div>

            {!isEditing && <>
            <div className="top-title">
                <h3>교재 관리</h3>
            </div>

            <div className="" id="subject" role="tabpanel" aria-labelledby="subject-tab"> 
                
                <div className="mb-3 text-right">
                    <button type="button" className="btn-sky" onClick={() => onEditTextBook()}>i-book 생성</button>
                </div>

                {/* <!-- 검색영역 --> */}
                <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    <table className="">
                        <tbody>
                            <tr>
                                <th className="w-36">유형</th>
                                <td className="text-left">
                                    <div>
                                        <label htmlFor="search-4" className="ml-4">유형 분류1</label>
                                        <fieldset className="ml-4">
                                            <select id="TB_curriSchool" name="search-4">
                                                <option value={""}>전체(학교)</option>
                                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>

                                        <fieldset className="ml-4">
                                            <select id="TB_curriGrade" name="search-4" >
                                                <option value={""}>전체(학년)</option>
                                                {curriInfo && curriInfo?.curriGrade && (curriInfo?.curriGrade as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>
                                        <fieldset className="ml-4">
                                            <select id="TB_curriSemester" name="search-4" >
                                                <option value={""}>전체(학기)</option>
                                                {curriInfo && curriInfo?.curriSemester && (curriInfo?.curriSemester as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>
                                        <fieldset className="ml-4">
                                            <select id="TB_curriSubject" name="search-4">
                                                <option value={""}>전체(과목)</option>
                                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>

                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="search-4" className="ml-4">유형 분류2</label>
                                        <fieldset className="ml-4">
                                            <select id="TB_dataYear" name="search-4" >
                                                <option value={""}>전체(자료년도)</option>
                                                {dataYearList && (dataYearList as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>

                                        <fieldset className="ml-4">
                                            <select id="TB_visangbookCategory" name="search-4" >
                                                <option value={""}>전체(도서분류)</option>
                                                {visangbookCategoryList && (visangbookCategoryList as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>
                                        <fieldset className="ml-4">
                                            <select id="TB_dataBrand" name="search-4">
                                                <option value={""}>전체(브랜드)</option>
                                                {dataBrandList && (dataBrandList as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>
                                        <fieldset className="ml-4">
                                            <select id="TB_dataCategory" name="search-4">
                                                <option value={""}>전체(자료유형)</option>
                                                {dataCategoryList && (dataCategoryList as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                );
                                            })}
                                            </select>
                                        </fieldset>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <div className="mt-5 text-right">
                        <fieldset>
                            <label htmlFor="keyword_type">검색</label>
                            <select className="mr-2" id="TB_keyword_type" name="keyword_type">
                                {/* <option value={"keyword"}>검색어</option> */}
                                <option value={"name"}>제목</option>
                                <option value={"creator"}>등록자</option>
                                <option value={"id"}>Key</option>
                                <option value={"hashTags"}>해시태그</option>
                            </select>
                            <input type="text" name="keyword" id="TB_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>      

                </div>
                {/* <!-- .//검색영역 --> */}

                <div className="mt-6 mb-3">
                    <form action="" className="flex justify-between">
                        <fieldset>
                            <span>총 <strong className="text-red">{listCount}</strong>건</span>
                            <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                            <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={100}>100</option>
                            </select>
                        </fieldset>
                    </form>                    
                </div>

                <div className="mt-3 mb-5 text-right">
                    <div className="w-avail" style={{display:"ruby"}}>
                        <button type="button" className="btn-white mr-2" onClick={onDownload}>링크 다운로드</button>
                        <button type="button" className="btn-white mr-2" onClick={onCopy}>복사</button>
                        <button type="button" className="btn-white mr-2" onClick={onDelete}>삭제</button>
                        <button type="button" className="text-sky-500 btn-white mr-2" onClick={() => onUse(true)}><span className="text-sky-500">사용</span></button>
                        <button type="button" className="text-red-600 btn-white" onClick={() => onUse(false)}> <span className="text-red-600">미사용</span></button>

                    </div>
                    {/* <button type="button" className="btn-white mr-2" onClick={() => {}}>링크 생성</button> */}
                </div>


				{/* <!-- table --> */}
                {viewList &&
				<table>
					<caption className="sr-only">웹교과서 목록</caption>
					<thead>
						<tr>
							<th scope="col" className="pl-4 pr-3">
                                <input id="all_check" type="checkbox" className="h-4 w-4 rounded" onClick={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    if(input) {
                                        (list as ITextBook[]).map((_, idx) => {
                                            const TB_check = document.getElementById("TB_chk_"+idx) as HTMLInputElement;
                                            TB_check.checked = input.checked;
                                        })
                                    }
                                }}                                
                                />
                            </th>
							<th scope="col" className="px-3">번호</th>
							<th scope="col" className="px-3">Key</th>
							<th scope="col" className="px-3">학교</th>
							<th scope="col" className="px-3">과목</th>
							<th scope="col" className="px-3">제목</th>
							<th scope="col" className="px-3">브랜드</th>
							<th scope="col" className="px-3">자료년도</th>
							<th scope="col" className="px-3">등록자</th>
							<th scope="col" className="px-3">등록일</th>
							<th scope="col" className="px-3">설정</th>
							<th scope="col" className="px-3">편집</th>
							<th scope="col" className="px-3">미리보기</th>
							<th scope="col" className="px-3">사용</th>
						</tr>
					</thead>
					<tbody>
                        {viewList && (!list || (list && (list as ITextBook[]).length === 0)) &&
                        <tr>    
                            <td colSpan={13}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as ITextBook[]).map((item, idx) => {

                        const totalCnt = item.full_count ? item.full_count : 0;
                        const _numOfPage = localStorage.getItem("VisangBook_numOfPage") ? Number(localStorage.getItem("VisangBook_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        const curriSchool = curriInfo?.curriSchool.find((school) => school.id === item.curriSchool);
                        const curriSubject = curriInfo?.curriSubject.find((subject) => subject.id === item.curriSubject);

                        const name = item.name && item.name.length > 15 ? item.name.substring(0, item.name.length/2) + "<br/>" + item.name.substring(item.name.length/2) : item.name;

                        let dataBrandVal = "";
                        const dataBrand = item.metaMap.find((meta) => meta.name === "dataBrand");
                        if(dataBrand) {
                            dataBrandVal = dataBrand.val;
                        }
                        let dataYearVal = "";
                        const dataYear = item.metaMap.find((meta) => meta.name === "dataYear");
                        if(dataYear) {
                            dataYearVal = dataYear.val;
                        }

                        return (
                            <tr className="rt-tr action" key={idx}>
                                <td>
                                    <input id={"TB_chk_"+idx} type="checkbox" name="TB_chk" className="h-4 w-4 rounded" onClick={(e) => {
                                        const text_ck = document.getElementsByName("TB_chk");
                                        let cntChecked = 0;
                                        for (let i = 0; i < text_ck.length; i++) {
                                            const element = text_ck[i] as HTMLInputElement;
                                            if(element.checked) {
                                                cntChecked = cntChecked + 1;
                                            }
                                        }
                                        const all_check = document.getElementById("all_check") as HTMLInputElement;
                                        if(cntChecked === text_ck.length) {                                                
                                            all_check.checked = true;                                                
                                        } else {
                                            all_check.checked = false;                                                
                                        }
                                    }}/></td>
                                <td>{_num}</td>
                                <td>{item.id}</td>
                                <td>{curriSchool?.val}</td>
                                <td>{curriSubject?.val}</td>
                                <td style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: name }}></td>
                                <td style={{fontSize: "0.8em"}}>{dataBrandVal}</td>
                                <td style={{fontSize: "0.8em"}}>{dataYearVal}</td>
                                <td>{item.creator}</td>
                                <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                <td>
                                    <button type="button" className="btn-white-s" onClick={() => {
                                        onEditTextBook(item);
                                    }}>수정</button>
                                </td>
                                <td>
                                    <button type="button" className="btn-white-s" onClick={() => {

                                        // alert("새창으로 띄울 경로가 필요합니다.");

                                        const brand = util.getCurrentBrand();
                                        if(!brand || !brand.id) {
                                            return;
                                        }
                                
                                        let url = "/ebookeditor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                        +"&textbook_id="+item.id
                                        +"&web_textbook_id="+(item.web_textbook_id ? item.web_textbook_id : "")
                                        +"&type=teaching"
                                        +"&token="+util.getUserInfo("token");
                                                
                                        // const url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                        // +"&set_id="+item.id+"&token="+util.getUserInfo("token");

                                        window.open(url, "_blank", "noopener, noreferrer");    
                            
                                    }}>수정</button>
                                </td>
                                <td>
                                    <select id="TB_preview" name="preview" onChange={(e) => onChangePreview(e, item)} disabled={!item.isValid} style={{backgroundColor:item.isValid ? "#ffffff" : "#dddddd"}} >
                                        <option value={""}>미리보기</option>
                                        {preview_skin && preview_skin.map((skin, idx) => {
                                        return(
                                            <option key={idx} value={skin}>{skin}</option>              
                                        )})}
                                    </select>
                                        
                                    {/* <button type="button" className="btn-white-s" onClick={() => {

                                        if(item.type === "teaching") {
                                            const url = EDITOR_HOST+"/CBS_iBook/"+item.id+"/contents/index.html?page=1&uniq=Uc1mzhvg0";
                                            // https://t-cms.vsaidt.com/CBS_iBook/306/contents/index.html?page=1&uniq=Uc1mzhvg0
                                            window.open(url, "_blank", "noopener, noreferrer");        
                                        } else if(item.type === "ebook") {
                                            const url = EDITOR_HOST+"/CBS_eBook/"+item.id+"/contents/index.html?page=1&uniq=Uc1mzhvg0";
                                            window.open(url, "_blank", "noopener, noreferrer");        
                                        }

                                    }}>A</button>
                                    <button type="button" className="btn-white-s ml-2" onClick={() => {
                                    }}>B</button> */}
                                </td>
                                <td>
                                    {item.is_active ? "사용" : "미사용"}
                                    {/* <button type="button" className="btn-white-s" onClick={() => {
                                    }}>링크생성</button> */}
                                </td>
                            </tr>
                        )})}
					</tbody>
				</table>}
				{/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div>
            </div></>}

            {isEditing && 
            <VisangBookEdit brand={props.brand} menu={props.menu} item={selectedEditItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                const _searchData = searchData;
                if(_searchData) {
                    _onSearch(_searchData);                    
                }
                setIsEditing(false);
            }} />}

        </div>

    );  
}
export default VisangBookList;
