import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IBrand, IMenu, ISet, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from '@gsebdev/react-simple-datepicker';
import PopupConfirm from "./PopupConfirm";

interface IContentsMyEdit {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function ContentsMyEdit(props: IContentsMyEdit) {
    
    const [list, setList] = useState([] as any);
    const [viewType, setViewType] = useState("");  //s:small, b:big
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");
    
    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            setViewType("s");

            setViewList(true);

            const _pageNum = localStorage.getItem("Contents_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
            viewlist1.checked = true;

            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                
                const new_CE_sdate = document.getElementById("new_CE_sdate") as HTMLInputElement;
                if(!new_CE_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_CE_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[0].appendChild(node)
    
                }

                const CE_sdate = document.getElementById("CE_sdate") as HTMLInputElement;
                if(CE_sdate) {
                    CE_sdate.style.display = "none";
                }

                const new_CE_edate = document.getElementById("new_CE_edate") as HTMLInputElement;
                if(!new_CE_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_CE_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[1].appendChild(node_e)
                }

                const CE_edate = document.getElementById("CE_edate") as HTMLInputElement;
                if(CE_edate) {
                    CE_edate.style.display = "none";
                }
            }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    const refreshList = () => {

        setCurrPage(1);
        setList([]);
        setListCount(0);
        setSearchData(null);

        // const brand = util.getCurrentBrand();
        // if(!brand || !brand.id) {
        //     return;
        // }
        // setCurrPage(1);
        // const data = {
        //     detail: true,
        //     myuid: util.getUserInfo("uid"),
        //     brand_id: brand.id,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10,
        // };

        // setSearchData(data);
        // _onSearch(data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        data.is_active = true;

        props.showLoading(true);

        setViewList(false);
        // const list = await util.getArticleList(data);
        const list = await util.getSetSearch(data);
        // const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        setList(list);
        logger.log("_onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch(): void {
        
        const CE_name = document.getElementById("CE_name") as HTMLInputElement;
        const CE_sdate = document.getElementById("new_CE_sdate") as HTMLInputElement;
        const CE_edate = document.getElementById("new_CE_edate") as HTMLInputElement;

        const is_publicOpen = document.getElementById("is_publicOpen") as HTMLSelectElement;
        
    
        let sdate = "";
        let edate = "";
        const arrSD = CE_sdate.value.split("-");
        const arrED = CE_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = CE_sdate.value;
            edate = CE_edate.value 
        }  

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const CL_keyword = document.getElementById("CL_keyword") as HTMLInputElement;        
        const CL_keyword_type = document.getElementById("CL_keyword_type") as HTMLInputElement;

        let search = "";
        if(CL_keyword) {
            search = CL_keyword.value;
        }
        let searchField = "";
        if(CL_keyword_type) {
            searchField = CL_keyword_type.value;
        }

        const data = {
            detail: true,
            myuid: util.getUserInfo("uid"),
            brand_id: brand.id ? brand.id : null,

            search: search,
            searchField: searchField,

            reg_sdate: sdate,  
            reg_edate: edate,
            is_publicOpen: is_publicOpen.value === "" ? undefined : is_publicOpen.value === "1" ? true : false,
            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10,
        };

        // logger.log("onSearch", data);

        setSearchData(data);

        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }
        }
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
        // logger.log("onChangePage", page, searchData, _searchData);
    }
        
    function onClickSDateIcon() : void {
        const new_CE_sdate = document.getElementById("new_CE_sdate") as HTMLInputElement;
        if(new_CE_sdate) {
            // new_CE_sdate.value = "";
            const CE_sdate = document.getElementById("CE_sdate") as HTMLInputElement;
            CE_sdate.value = "";
            CE_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const CE_edate = document.getElementById("CE_edate") as HTMLInputElement;
                    CE_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_CE_edate = document.getElementById("new_CE_edate") as HTMLInputElement;
        if(new_CE_edate) {
            // new_CE_edate.value = "";
            const CE_edate = document.getElementById("CE_edate") as HTMLInputElement;
            CE_edate.value = "";
            CE_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const CE_sdate = document.getElementById("CE_sdate") as HTMLInputElement;
                    CE_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "CE_sdate") {
            const new_CE_sdate = document.getElementById("new_CE_sdate") as HTMLInputElement;
            if(new_CE_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CE_sdate.value = val;
            }
        } else if(e.target.id === "CE_edate") {
            const new_CE_edate = document.getElementById("new_CE_edate") as HTMLInputElement;
            if(new_CE_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_CE_edate.value = val;
            }
        }
    }

    function onChangeViewType() {
        const viewlist1 = document.getElementById("rd-viewlist1") as HTMLInputElement;
        if(viewlist1.checked) {
            setViewType("s");    
        } else {
            setViewType("b");
        }        
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Contents_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        logger.log("numOfPage", numOfPage);
        _onSearch(_searchData);            
    }

    function onPreView(item: ISet): void {
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        let url = "";
        if(item) {
            url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&set_id="+item.id+"&token="+util.getUserInfo("token");
        } else {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&create=y"+"&token="+util.getUserInfo("token");
        }
        window.open(url, "_blank", "noopener, noreferrer");
    }

    function onDelete(item: ISet) {
        setViewConfirmDelete(true);
        setDeleteTitle("삭제");
        setDeleteDesc("'" + item.name + "' 작업(수업자료)을 삭제하시겠습니까?");
        setSelectedItem(item);
    }

    async function onDeleteOK() {

        onCloseDelete();

        props.showLoading(true);

        const result = await util.deleteSet(selectedItem);
        logger.log("onDelete", result);

        // props.showLoading(false);

        _onSearch(searchData);        

        props.showModal("", "삭제 되었습니다.");
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }

    function onEditPage(item: ISet | null): void {
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        let url = "";
        if(item) {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&set_id="+item.id+"&token="+util.getUserInfo("token");
        } else {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&create=y"+"&token="+util.getUserInfo("token");
        }
        window.open(url, "_blank", "noopener, noreferrer");
    }

    async function onCopy() {

        props.showLoading(true);

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ISet;
            const radio = document.getElementById("selected_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {
                logger.log("onCopy", item);

                const data = {
                    id: item.id,
                    loginUserId: util.getUserInfo("uid"),
                }
                const result = await util.copySet(data);
                logger.log("onCopy", result);
                
                if(result) {
                    props.showModal("", "복사 되었습니다.");
                } else {
                    props.showModal("", "복사 실패하였습니다.");
                }
    
                break;
            }            
        }

        const nodes = document.getElementsByName("radio_contents");
        if(nodes) {
            nodes.forEach((node) => {
                (node as HTMLInputElement).checked = false;
            });    
        }

        const _searchData = searchData;
        setCurrPage(1);
        _searchData.limit_page = 1;
        _onSearch(_searchData);

        props.showLoading(false);

    }

    return (
        <>
            <div className="top-title">
                <h3>{common.leftmenu_2[1][currMenu.menu2]}</h3>
            </div>

            {/* <!-- 검색영역 --> */}
            <div className="px-8 py-8 border border-gray-300 rounded-xl">
                <div className="text-left">

                    <fieldset>
                        <label htmlFor="date" className="ml-8">등록기간</label>
                    </fieldset>

                    <fieldset>
                        <DatePicker id='CE_sdate' name='CE_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                    </fieldset>

                    <fieldset>
                        <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickSDateIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>                                            
                        </button> 
                    </fieldset>

                    <fieldset>
                        <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                    </fieldset>

                    <fieldset>
                        <DatePicker id='CE_edate' name='CE_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                    </fieldset>

                    <fieldset>
                        <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>                                            
                        </button> 
                    </fieldset>


                    <fieldset>
                        <label htmlFor="section-1" className="ml-8">AIDT 수업자료 공개 여부</label>
                        <select id="is_publicOpen" name="section-1">
                            <option value={""}>전체</option>
                            <option value={"1"}>공개</option>
                            <option value={"0"}>비공개</option>
                        </select>
                        {/* <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                            <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                        </button> */}
                    </fieldset>
                    
                </div>
                
                <div className="mt-3 text-right">
                    <fieldset>
                        <label htmlFor="search-6">검색</label>
                        <select id="CL_keyword_type" name="search-6">
                            <option value={"name"}>작업 명(수업자료 명)</option>
                            <option value={"id"}>KEY</option>
                            {/* <option value={"creator"}>등록자</option> */}
                        </select>
                        <input type="text" name="search" id="CL_keyword" className="w-[300px] ml-3" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                        <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                            <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                        </button>
                    </fieldset>
                </div>                        

            </div>
            {/* <!-- .//검색영역 --> */}

            <div className="mt-6 mb-3">
                <form action="" className="flex justify-between">
                    <fieldset>
                        <span>총 <strong className="text-red">{listCount}</strong>건</span>
                        <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                        <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={100}>100</option>
                        </select>
                    </fieldset>
                    <fieldset aria-labelledby="radiogruop" className="mt-2 items-center">
                        <input type="radio" id="rd-viewlist1" name="rd-viewlist" className="items-center" onChange={onChangeViewType} />
                        <label htmlFor="rd-viewlist1" className="items-center ml-1">간단히</label>
                        <input type="radio" id="rd-viewlist2" name="rd-viewlist" className="items-center" onChange={onChangeViewType}/>
                        <label htmlFor="rd-viewlist2" className="items-center ml-1">큰 아이콘</label>
                    </fieldset>
                </form>
            </div>


            {/* <!-- table --> */}
            {viewList && viewType === "s" &&
            <table>
                <caption className="sr-only">관리 테이블</caption>
                <thead>
                    <tr>
                        <th scope="col" className="pl-4 pr-3">선택</th>
                        <th scope="col" className="px-3">번호</th>
                        <th scope="col" className="px-3">Key</th>
                        <th scope="col" className="px-3">작업 명(수업자료 명)</th>
                        <th scope="col" className="px-3">콘텐츠 공개(공개/전체)</th>
                        <th scope="col" className="px-3">AIDT 수업자료 공개 여부</th>
                        <th scope="col" className="px-3">등록일</th>
                        <th scope="col" className="px-3">수정일</th>
                        <th scope="col" className="px-3">미리보기</th>
                        <th scope="col" className="px-3 pr-4">편집</th>
                        {/* <th scope="col" className="pl-3 pr-4">삭제</th> */}
                    </tr>
                </thead>
                <tbody>

                {viewList && list &&
                        <tr style={{display: (list as ISet[]).length === 0 ? "" : "none"}}>
                            <td colSpan={11}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                {viewList && list && (list as ISet[]).map((item, idx) => {

                    const totalCnt = item.full_count;
                    const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;
                    let _num = totalCnt - ((currPage-1)*_numOfPage+idx);
                    let open = item.is_publicOpen ? "공개" : "비공개";
                    let updatedate = item.updatedate;
                    if(updatedate && updatedate.length > 10) {
                        updatedate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                    } else {
                        updatedate = "-";
                    }

                    const totalArticleCount = item.articles ? item.articles.length === 0 ? 0 : item.articles[0].full_count : 0;
                    const openCnt = item.articles ? item.articles.length === 0 ? 0 : item.articles[0].open_count : 0;
                    
                    return (
                        <tr key={idx}>
                            <td><input type="radio" id={"selected_"+idx} name="radio_contents" /></td>
                            <td>{_num}</td>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td><strong className="text-sky">{openCnt}</strong>/<span>{totalArticleCount}</span></td>
                            <td className="text-sky">{open}</td>
                            <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                            <td>{updatedate}</td>
                            <td><button type="button" className="btn-white-s" onClick={() => onPreView(item)}>미리보기</button></td>
                            <td><button type="button" className="btn-white-s" onClick={() => onEditPage(item)}>편집</button></td>
                            {/* <td><button type="button" className="btn-white-s" onClick={() => onDelete(item)}>삭제</button></td> */}
                        </tr>
                        )
                    })}

                </tbody>
            </table>}
            {/* <!-- .//table -->	 */}
            
            {viewList && viewType === "b" &&
            <ul>

                {viewList && list && (list as ISet[]).map((item, idx) => {

                const totalCnt = item.full_count;
                const _numOfPage = localStorage.getItem("Contents_numOfPage") ? Number(localStorage.getItem("Contents_numOfPage")) : 10;
                let _num = totalCnt - ((currPage-1)*_numOfPage+idx);
                let open = item.is_publicOpen ? "공개" : "비공개";
                let updatedate = item.updatedate;
                if(updatedate && updatedate.length > 10) {
                    updatedate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                } else {
                    updatedate = "-";
                }

                let totalArticleCount = 0;
                let openCnt = 0;
                let thumb = "http://placehold.it/150x150.png/aaaaaa/ffffff";

                if(item.articles) {
                    totalArticleCount = item.articles.length === 0 ? 0 : item.articles[0].full_count;
                    openCnt = item.articles.length === 0 ? 0 : item.articles[0].open_count;
                    thumb = item.thumbnail ? item.thumbnail : item.articles.length === 0 ? "http://placehold.it/150x150.png/aaaaaa/ffffff" : S3Server+item.articles[0].thumbnail;
                }

                return (
                    <li className="flex items-start mt-5" key={idx}>
                        <input className="mt-2" type="radio" id={"selected_"+idx} name="radio_contents" />
                        <div role="label" aria-labelledby="edit-contents1" className="w-full flex">
                            <span className="inline-block mx-3 text-lg">{_num}</span>
                            <div className="flex relative p-4 border border-slate-300 flex-grow justify-between items-center">
                                <div className="flex items-center">
                                    <div className="overflow-hidden">
                                        <img src={thumb} style={{width:"150px", cursor:"pointer"}} alt="썸네일" onClick={() => onPreView(item)} />
                                    </div>
                                    <div className="ml-4">
                                        <p>콘텐츠 공개 (<span><strong className="text-sky">{openCnt}</strong>/<span>{totalArticleCount}</span></span>)</p>
                                        <p className="mt-3">수업자료 <span className="text-sky">{open}</span></p>
                                    </div>
                                </div>
                                <div className="mr-40 w-96">
                                    <div>
                                        <strong className="font-normal">등록일 :</strong>
                                        <span>{item.regdate.substring(0, 10).replaceAll("-", ".")}</span>
                                    </div>
                                    <div>
                                        <strong className="font-normal">수정일 :</strong>
                                        <span>{updatedate}</span>
                                    </div>
                                    <div>
                                        <strong className="font-normal">작업명(수업자료 명) :</strong>
                                        <span>{item.name}</span>
                                    </div>
                                </div>
                                <div className="absolute right-4 top-4">
                                    <button type="button" aria-label="수정" onClick={() => onEditPage(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                    </button>
                                    <button type="button" aria-label="삭제" onClick={() => onDelete(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                )
                })}


            </ul>}				

            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-sky" onClick={onCopy}>복사하기</button>
                    <button type="button" className="btn-sky" onClick={() => onEditPage(null)}>저작하기</button>
                </div>

                {/* <!-- pagenation --> */}
                <div className="text-center">
                    <nav className="pagenation" aria-label="Pagination">

                        {listOfPage && (listOfPage as number[]).length > 0 &&
                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                            <span className="sr-only">Previous</span>
                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                            </svg>
                        </div>}

                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                        return (
                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                        )})}

                        {listOfPage && (listOfPage as number[]).length > 0 &&
                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                            <span className="sr-only">Next</span>
                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                            </svg>
                        </div>}
                        
                    </nav>
                </div>
                {/* <!-- .// pagenation --> */}

            </div>

            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />
        </>
    );  
}
export default ContentsMyEdit;
