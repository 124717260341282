import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, IMenu, IMeta, ITextBook, ITextBookIndex, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import TextBookIndexEdit from "./TextBookIndexEdit";

import { ThemeProvider, CssBaseline, Typography } from "@mui/material";
import { Tree, MultiBackend, getBackendOptions, DndProvider, NodeModel, TreeMethods } from "@minoru/react-dnd-treeview";
import { CustomNode } from "./DND/CustomNode";
import { CustomDragPreview } from "./DND/CustomDragPreview";
import { Placeholder } from "./DND/Placeholder";
import { theme } from "./DND/theme";
import styles from "./DND/style.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { CustomNode_ } from "./DND/CustomNode_";

interface ITextBookIndexList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
    showContentsList: (view: boolean) => void;
}
  
function TextBookIndexList(props: ITextBookIndexList) {
    
    const [list, setList] = useState([] as any);
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [modalShow, setModalShow] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null as any);
    const [nodeData, setNodeData] = useState<NodeModel[]>([]);
    const [showViewAddContents, setShowViewAddContents] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [lastUpdateDate, setLastUpdateDate] = useState("");

    const [textbook, setTextbook] = useState<ITextBook>(null as any);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            const _pageNum = localStorage.getItem("TextBook_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    const refreshList = () => {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        const data = {
            brand_id: brand.id ? brand.id : null,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        if(data) {
            data.detail = true;
        }
        
        setViewList(false);

        const list = await util.getTextbookIndexList(data);
        setList(list);
        if(list.length > 0) {
            setSelectedItem(list[0]);
            makeTree(list[0]);
        }
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function makeTree (item: ITextBookIndex) {
        
        logger.log("makeTree", item);

        let lastUpdateDate = "";
        if(item && item.updatedate) {
            lastUpdateDate = item.updatedate?.substring(0, 10).replaceAll("-", ".")
        } else {
            if(item && item.regdate) {
                lastUpdateDate = item.regdate?.substring(0, 10).replaceAll("-", ".")
            } else {
                lastUpdateDate = util.getDate();
            }
        }
        setLastUpdateDate(lastUpdateDate);

        const _nodeModel : NodeModel[] = [];
        if(item.curriculumList && item.curriculumList.length > 10) {
            const arr = JSON.parse(item.curriculumList) as any[];
            if(arr && arr.length > 0) {
                arr.map((_item) => {

                    if(_item) {
                        const node : NodeModel = {
                            id: _item.key,
                            parent: _item.parent,
                            text: _item.text,
                        }    
                        _nodeModel.push(node);
                    }

                })
            }    
        }
        setNodeData(_nodeModel);    

        setTimeout(() => {
            handleOpenAll();            
        }, 100);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const TB_keyword = document.getElementById("TB_keyword") as HTMLInputElement;        
        
        const TB_curriBook = document.getElementById("TB_curriBook") as HTMLSelectElement;
        const TB_curriBook_val = curriInfo.curriBook[TB_curriBook.selectedIndex-1];
        const TB_curriYear = document.getElementById("TB_curriYear") as HTMLSelectElement;
        const TB_curriYear_val = curriInfo.curriYear[TB_curriYear.selectedIndex-1];
        const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
        const TB_curriSchool_val = curriInfo.curriSchool[TB_curriSchool.selectedIndex-1];
        const TB_usage = document.getElementById("TB_usage") as HTMLSelectElement;
        const TB_usage_val = TB_usage.value;

        const data = {
            brand_id: brand.id ? brand.id : null,
            keyword: TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined,

            curriYear: TB_curriYear_val ? TB_curriYear_val.id : undefined,
            curriSchool: TB_curriSchool_val ? TB_curriSchool_val.id : undefined,
            curriBook: TB_curriBook_val ? TB_curriBook_val.id : undefined,
            usage: TB_usage ? TB_usage_val : undefined,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        // setSearchData(data);
        // _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("TextBook_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onPublishHistory() {
    }

    function onManageIndex() {
    }

    function onEditTextBook() {
        alert("편집 작업중...");
    }

    function onAddContents() {
        setShowViewAddContents(true);
        setSelectedItem(null);
    }

    async function onEditContents() {
        // setShowViewAddContents(true);
        //textbook 가져오기

        logger.log("selectedItem", selectedItem);

        if(selectedItem) {
            const textbookIndex = selectedItem as ITextBookIndex;
            if(textbookIndex.textbook_id) {
                props.showLoading(true);
                const textbook = await util.getTextBookInfo(textbookIndex.textbook_id);
                props.showLoading(false);
                if(textbook) {
                    logger.log("getTextBookInfo", textbook);
                    setTextbook(textbook);
                    setIsEditing(true);        
                }    
            }
        }
    }

    function onCloseAddContents() {
        setShowViewAddContents(false);
    }
    
    function onAfterSave(data: ITextBookIndex) {
        logger.log("onAfterSave", data, selectedItem);
        setSelectedItem(data);
        setIsEditing(true);
    }

    function onEditClose() {
        refreshList();
        setIsEditing(false);
    }
    
    const ref = useRef(null);

    const handleOpenAll = () => {
        if(ref && ref.current) {
            (ref.current as TreeMethods).openAll();            
        }
    }
    const handleCloseAll = () => {
        if(ref && ref.current) {
            (ref.current as TreeMethods).closeAll();            
        }
    }

    return (
        <div>               
            {!isEditing && <>
            <div className="top-title">
                <h3>목차 관리</h3>
                <p onClick={() => props.showContentsList(false)}><a href="#">{"교과서 목록 > 목차 관리"}</a></p>
            </div>

            {/* <div className="text-right">
                <button type="button" className="btn-sky" onClick={onAddContents}>목차 등록</button>
            </div> */}
            
            <h4 className="mt-0">목차 미리보기</h4>
            {/* <!-- 목차 미리보기 --> */}
            <div className="border border-gray-300 rounded-xl">
                <div className="overflow-y-auto h-64 ml-8 pr-8 py-5" style={{display:selectedItem ? "" : "none"}}>                    
                    <div className="flex pb-2 justify-between items-center border-b border-gray-300" >
                        <strong className="text-base">{(selectedItem as ITextBookIndex) ? (selectedItem as ITextBookIndex).name : ""}</strong>
                        <div className="mr-2">
                            <span className="text-sm mr-3">{lastUpdateDate}(최종수정)</span>
                            <button type="button" className="btn-white-s" onClick={onEditContents}>수정</button>
                        </div>
                    </div>
                    <div className="mt-3" >

                        <ThemeProvider theme={theme}>
                        <CssBaseline />
                            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                                <div className={styles.app}>

                                    <Tree
                                        ref={ref}
                                        tree={nodeData}
                                        rootId={0}
                                        sort={false}
                                        insertDroppableFirst={false}
                                        dropTargetOffset={10}
                                        canDrop={(tree, { dragSource, dropTargetId }) => {
                                            if (dragSource?.parent === dropTargetId) {
                                                return true;
                                            }
                                        }}
                                        render={(node, options) => {
                                            // logger.log("node", node, options);
                                            return(
                                                <CustomNode_ node={node} {...options} />                                                    
                                            )}
                                        }

                                        // dragPreviewRender={(monitorProps) => (
                                        //     <CustomDragPreview monitorProps={monitorProps} />
                                        // )}
                                        // placeholderRender={(node, { depth }) => (
                                        //     <Placeholder node={node} depth={depth} />
                                        // )}

                                        onDrop={() => {}}
                                        classes={{
                                            root: styles.treeRoot_,
                                            // draggingSource: styles.draggingSource,
                                            // placeholder: styles.placeholderContainer
                                        }}
                                        initialOpen={true}
                                    />

                                </div>
                            </DndProvider>
                        </ThemeProvider>

                        {/* <table className="border-none">
                            <tbody>
                            {nodeData && (nodeData as any[]).map((item, idx) => {                
                                return(
                                    <tr key={idx}>
                                        <td className="text-left">{item.text}</td>
                                    </tr>    
                                );
                            })}
                            </tbody>
                        </table> */}

                    </div>
                </div>

                <div className="overflow-y-auto ml-8 pr-8 py-5" style={{display:!selectedItem ? "" : "none"}}>                    
                    <div className="flex pb-2 justify-between items-center border-gray-300">
                        <strong className="text-base">목차를 등록 해 주세요</strong>
                    </div>
                </div>

            </div>
            {/* <!-- .//목차 미리보기 --> */}
            
            <h4>목차 리스트</h4>
            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">목차 리스트</caption>
                <thead>
                    <tr>
                        <th scope="col" className="pl-4 pr-3">번호</th>
                        <th scope="col" className="pl-4 pr-3">Key</th>
                        <th scope="col" className="px-3">제목</th>
                        <th scope="col" className="px-3">등록일</th>
                        <th scope="col" className="pl-3 pr-4">등록자</th>
                    </tr>
                </thead>
                <tbody>
                    {list && (list as ITextBookIndex[]).length === 0 &&
                    <tr>    
                        <td colSpan={4}>등록된 목차가 없습니다. 교과서 목차를 등록 해 주세요.</td>
                    </tr>}
                    {list && (list as ITextBookIndex[]).map((item, idx) => {

                        const totalCnt = item.full_count ? item.full_count : 0;
                        const _numOfPage = localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        return(
                            <tr key={idx} onClick={() => {
                                // logger.log(item);
                                makeTree(item);
                                setSelectedItem(item);
                            }}>
                                <td>{_num}</td>
                                <td>{item.id}</td>
                                <td><a href="#">{item.name}</a></td>
                                <td>{item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>
                                <td>{item.creator_name}</td>
                            </tr>    
                        );
                    })}
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}                

            <div className="relative mt-5">

                {/* <!-- pagenation --> */}
                <div className="text-center">
                    <nav className="pagenation" aria-label="Pagination">

                        {listOfPage && (listOfPage as number[]).length > 0 &&
                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                            <span className="sr-only">Previous</span>
                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                            </svg>
                        </div>}

                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                        return (
                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                        )})}

                        {listOfPage && (listOfPage as number[]).length > 0 &&
                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                            <span className="sr-only">Next</span>
                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                            </svg>
                        </div>}
                        
                    </nav>
                </div>
                {/* <!-- .// pagenation --> */}
            </div>
            </>}

            {isEditing && <>
            {/* <div className="top-title">
                <h3>목차 등록(수정)</h3>
                <p onClick={() => {
                    setIsEditing(false);
                }}><a href="#">{"교과서 목록 > 목차 관리 > 목차 등록"}</a></p>
            </div>  */}
            <TextBookIndexEdit brand={props.brand} menu={props.menu} textbook={textbook} textbookIndexId={selectedItem.id} showModal={props.showModal} showLoading={props.showLoading} onClose={onEditClose} /></>}

            {/* {showViewAddContents && <PopupAddContents selectedItem={selectedItem} onAfterSave={onAfterSave} onClose={(onCloseAddContents)} showModal={props.showModal} showLoading={props.showLoading} />} */}

        </div>
    );  
}
export default TextBookIndexList;
