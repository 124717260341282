import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from '../util';
import { IBrand, ICurriInfo, IMeta, IMetaMap } from "../common";
import { logger } from '../logger';

import PopupCommunicationSearch from "./PopupCommunicationSearch";
import PopupMaterialSearch from "./PopupMaterialSearch";
import PopupLanguageFormatSearch from "./PopupLanguageFormatSearch";
import { log } from "console";

export interface IEngMeta {
    depth0: IMeta;
    depth1: IMeta;
    depth2: IMeta;
    depth3?: IMeta;
}

interface IString123 {
    s1: string;
    s2: string;
    s3: string;
}

interface IMetaStudyMapEngEdit {
    brand: IBrand;
	item: IMeta | null;
	parentItem: IMeta | null;
	metalist: IMeta[];
    type: string;
    onCloseEdit: (isEdited: boolean, from: string) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaStudyMapEngEdit(props: IMetaStudyMapEngEdit) {

    const [view, setView] = useState(false);
    const [UUID, setUUID] = useState("");
    const [contentArea, setContentArea] = useState<IMeta[]>([]);
    const [depth1List, setDepth1List] = useState<IMeta[]>([]);

    const [standardList, setStandardList] = useState<string[]>([""]);
    const [preStudyList, setPreStudyList] = useState<string[]>([""]);
    const [postStudyList, setPostStudyList] = useState<string[]>([""]);
    // const [achievementStandardList, setAchievementStandardList] = useState<string[]>([""]);
    // const [achievementStandardDescList, setAchievementStandardDescList] = useState<string[]>([""]);

    const [evaluationArea1List, setEvaluationArea1List] = useState<string[]>(null as any);
    const [evaluationArea2List, setEvaluationArea2List] = useState<string[]>(null as any);

    const [evaluationArea1, setEvaluationArea1] = useState<IMeta[]>(null as any);
    const [evaluationArea2, setEvaluationArea2] = useState<IMeta[]>(null as any);
    const [evaluationArea3, setEvaluationArea3] = useState<IMeta[]>(null as any);

    const [materialList, setMaterialList] = useState<IEngMeta[]>(null as any);
    // const [material1List, setMaterial1List] = useState<IMeta[][]>([]);
    // const [material2List, setMaterial2List] = useState<IMeta[][]>([]);
    // const [material3List, setMaterial3List] = useState<IMeta[][]>([]);
    const [viewPopupMaterial, setViewPopupMaterial] = useState(false);
    const [selectedMaterialId, setSelectedMaterialId] = useState(-1);

    const [communicationList, setCommunicationList] = useState<IEngMeta[]>(null as any);
    const [viewPopupCommunication, setViewPopupCommunication] = useState(false);
    const [selectedCommunicationId, setSelectedCommunicationId] = useState(-1);

    const [languageFormatList, setLanguageFormatList] = useState<IEngMeta[]>(null as any);
    const [viewPopupLanguageFormat, setViewPopupLanguageFormat] = useState(false);
    const [selectedLanguageFormatId, setSelectedLanguageFormatId] = useState(-1);

    const [achievementStandardList, setachievementStandardList] = useState<IMeta[]>(null as any);

    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    const deli = "#^|";

    let bDone = false;
    useEffect(() => {

        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.onCloseEdit(false, "");
        //     return;
        // }

        if(!bDone) {

            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);

            if(props.type === "unit1") {

                getContentArea();

                const metalist = props.metalist.filter((item) => item.depth === 2);
                const meta_code = (document.getElementById('meta_code') as HTMLInputElement);
                if(metalist.length > 0) {
                    meta_code.value = metalist[0].code;
                    meta_code.readOnly = true;
                    meta_code.disabled = true;
                }
                setDepth1List(metalist);

                const item = props.item;
                if(item) {
                    if(item.uuid && item.uuid.length > 10) {
                        setUUID(item.uuid);
                    }

                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                        meta_code.readOnly = true;
                        meta_code.disabled = true;
                    }
        
                } else {
                    if(metalist.length > 0) {
                        const studyMap1 = metalist[0];  
                        if(studyMap1.uuid && studyMap1.uuid.length > 10) {
                            setUUID(studyMap1.uuid);
                        }          
                    } else {
                        props.showModal("", "대단원이 없습니다.");
                        props.onCloseEdit(false, "");
                    }
                }

                const self_study_active = (document.getElementById('self_study_active') as HTMLInputElement);
                self_study_active.checked = true;
                const self_study_non_active = (document.getElementById('self_study_non_active') as HTMLInputElement);
                self_study_non_active.checked = false;

                if(props.item && props.item.val1) {
                    self_study_active.checked =  props.item.val1 === "1" ? true : false;
                    self_study_non_active.checked =  props.item.val1 === "0" ? true : false;
                }    
                
            } else if(props.type === "1") {

                getEvaluationArea();
                setEvaluationArea1List([""]);
                setEvaluationArea2List([""]);

                setMaterialList([{
                } as IEngMeta]);

                setCommunicationList([{
                } as IEngMeta]);

                setLanguageFormatList([{
                } as IEngMeta]);

                getAchievementStandard();

                const item = props.item;
                logger.log("item", item);

                const ea_rd1 = (document.getElementById('ea-rd1') as HTMLInputElement)
                if(ea_rd1) {
                    ea_rd1.checked = true;
                }
                
                if(item) {
                    
                    const meta_val = (document.getElementById('meta_val') as HTMLInputElement)
                    if(meta_val) {
                        meta_val.value = item.val;
                    }
                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                        meta_code.readOnly = true;
                        meta_code.disabled = true;
                    }

                    let eaType = "";
                    let eaVal = "";
                    if(item.val4 && item.val5) {
                        eaType = item.val4;
                        const ea_rd = (document.getElementById("ea-rd" + eaType) as HTMLInputElement);
                        ea_rd.checked = true;

                        eaVal = item.val5;      
                        if(eaType === "1") {
                            const arr = eaVal.split(deli);
                            const _evaluationArea1List : string[] = [];
                            arr.map((item) => {
                                _evaluationArea1List.push(item);                                
                            });    
                            setEvaluationArea1List(_evaluationArea1List);                

                            setTimeout(() => {
                                arr.map((item, idx) => {
                                    const meta_evaluationArea1_ = document.getElementById("meta_evaluationArea1_"+idx) as HTMLSelectElement;
                                    if(meta_evaluationArea1_) {
                                        meta_evaluationArea1_.value = item;
                                    }
                                });    
                            }, 1000);
                        } else if(eaType === "2") {
                            const arr = eaVal.split(deli);
                            const _evaluationArea2List : string[] = [];
                            arr.map((item) => {
                                _evaluationArea2List.push(item);                                
                            });    
                            setEvaluationArea2List(_evaluationArea2List);                

                            setTimeout(() => {
                                arr.map((item, idx) => {
                                    const meta_evaluationArea2_ = document.getElementById("meta_evaluationArea2_"+idx) as HTMLSelectElement;
                                    if(meta_evaluationArea2_) {
                                        meta_evaluationArea2_.value = item;
                                    }
                                });    
                            }, 1000);

                            // setTimeout(() => {
                            //     const meta_contentsItem = document.getElementById("meta_contentsItem") as HTMLSelectElement;
                            //     if(meta_contentsItem) {
                            //         meta_contentsItem.value = eaVal;
                            //     }                                    
                            // }, 1000);
                        } else if(eaType === "3") {
                            setTimeout(() => {
                                const meta_evaluationArea3 = document.getElementById("meta_evaluationArea3") as HTMLSelectElement;
                                if(meta_evaluationArea3) {
                                    meta_evaluationArea3.value = eaVal;
                                }                                    
                            }, 1000);
                        }
                    }

                    getMetaOfMetaExById(String(item.id));
                }

            }

            const active = (document.getElementById('active') as HTMLInputElement);
            const non_active = (document.getElementById('non_active') as HTMLInputElement);
            if(props.item) {
                active.checked = props.item.is_active;
                non_active.checked = !props.item.is_active;
            } else {
                active.checked = true;
            }
        
            // const meta_val = (document.getElementById('meta_val') as HTMLInputElement);

            // (document.getElementById('active') as HTMLInputElement).checked = true;
            // (document.getElementById('non_active') as HTMLInputElement).checked = false;
    
            // if(props.item) {
            //     if(!props.item.is_active) {
            //         (document.getElementById('active') as HTMLInputElement).checked = false;
            //         (document.getElementById('non_active') as HTMLInputElement).checked = true;
            //     }
            //     if(meta_val)
            //         (document.getElementById('meta_val') as HTMLInputElement).value = props.item.val;

            //     (document.getElementById('meta_description') as HTMLInputElement).value = props.item.description ? props.item.description : "";
            //     (document.getElementById('meta_code') as HTMLInputElement).value = props.item.code;
            //     (document.getElementById('meta_code') as HTMLInputElement).readOnly = false;
            //     const maxDepth = (document.getElementById('max_depth') as HTMLSelectElement);
            //     if(maxDepth) {
            //         maxDepth.value = props.item.max_depth ? String(props.item.max_depth) : "";
            //     }
    
            //     getMetaOfMetaExById(String(props.item.id));
            // }

            // if(meta_val)
            //     (document.getElementById('meta_val') as HTMLInputElement).focus();
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(contentArea && contentArea.length > 0) {
            if(props.item) {
                getMetaOfMetaExById(String(props.item.id));
            }
        }
    }, [contentArea])

    useEffect(() => {
        if(evaluationArea1 && evaluationArea2 && evaluationArea3) {
            // if(props.item) {
            //     getMetaOfMetaExById(String(props.item.id));
            // }
        }
    }, [evaluationArea1, evaluationArea2, evaluationArea3])

    useEffect(() => {
        if(evaluationArea1List) {
            logger.log("evaluationArea1List", evaluationArea1List);
        }
    }, [evaluationArea1List])

    useEffect(() => {
        if(depth1List && depth1List.length > 0) {
            if(props.item) {
                const meta_unit1 = document.getElementById("meta_unit1") as HTMLSelectElement;
                if(meta_unit1) {
                    // meta_unit1.disabled = true;
                    meta_unit1.value = props.item.val;  
                    
                    logger.log("depth1List", depth1List, props.item.val);

                }
            }
        }
    }, [depth1List])

    useEffect(() => {
        if(languageFormatList && languageFormatList.length > 0) {
            logger.log("languageFormatList", languageFormatList);
        }
    }, [languageFormatList])
    
    async function setParentVal(code: string, idx: number, id: string) {
     
        const meta_parent_ = document.getElementById(id) as HTMLInputElement;
        if(meta_parent_) {

            let parentCode = "";
            const arrCode = code.split("-");
            arrCode.map((item, idx) => {
                if(idx < arrCode.length-1) {
                    if(parentCode === "") {
                        parentCode = item
                    } else {
                        parentCode = parentCode + "-" + item;
                    }                                
                }
            });

            const list = await util.getDescendantsList(parentCode);
            const parent = list.find((item) => item.code === parentCode);                
            if(parent) {
                meta_parent_.value = parent.val;
            }
        }    
    }

    useEffect(() => {
        if(achievementStandardList) {
            logger.log("achievementStandardList", achievementStandardList);

            const item = props.item;
            if(item) {
                if(item.val1) {
                    const arr = item.val1.split(deli);
                    const _standardList : string[] = [];
                    arr.map((item, idx) => {
                        _standardList.push(item);
                    });
                    setStandardList(_standardList)
    
                    setTimeout(() => {
                        arr.map((_item, idx) => {
    
                            const meta = achievementStandardList.find((__item) => __item.val1 === _item);
                            logger.log("meta", meta, _item, achievementStandardList);
    
                            if(meta) {
                                const meta_standard_ = document.getElementById("meta_standard_"+idx) as HTMLSelectElement;
                                if(meta_standard_) {
                                    meta_standard_.value = _item;
                                }
                                const meta_standard_val_ = document.getElementById("meta_standard_val_"+idx) as HTMLSelectElement;
                                if(meta_standard_val_ && meta) {                                
                                    meta_standard_val_.value = meta.val;
                                }
        
                                setParentVal(meta.code, idx, "meta_standard_parent_"+idx);
                            }
                    
                        });    
                    }, 10);
    
                } else {
                    setStandardList([""]);
                }
    
                if(item.val2) {
                    const arr = item.val2.split(deli);
                    const _preStudyList : string[] = [];
                    arr.map((item, idx) => {
                        _preStudyList.push(item);
                    });
                    setPreStudyList(_preStudyList)
    
                    setTimeout(() => {
                        arr.map((_item, idx) => {
    
                            const meta = achievementStandardList.find((__item) => __item.val1 === _item);
                            logger.log("meta", meta, _item, achievementStandardList);
    
                            if(meta) {
                                const meta_preStudy_ = document.getElementById("meta_preStudy_"+idx) as HTMLSelectElement;
                                if(meta_preStudy_) {
                                    meta_preStudy_.value = _item;
                                }
                                const meta_preStudy_val_ = document.getElementById("meta_preStudy_val_"+idx) as HTMLSelectElement;
                                if(meta_preStudy_val_ && meta) {                                
                                    meta_preStudy_val_.value = meta.val;
                                }
        
                                setParentVal(meta.code, idx, "meta_preStudy_parent_"+idx);
                            }
                    
                        });    
                    }, 10);

                } else {
                    setPreStudyList([""]);
                }
    
                if(item.val3) {
                    const arr = item.val3.split(deli);
                    const _postStudyList : string[] = [];
                    arr.map((item, idx) => {
                        _postStudyList.push(item);
                    });
                    setPostStudyList(_postStudyList)
    
                    setTimeout(() => {
                        arr.map((_item, idx) => {
    
                            const meta = achievementStandardList.find((__item) => __item.val1 === _item);
                            logger.log("meta", meta, _item, achievementStandardList);
    
                            if(meta) {
                                const meta_postStudy_ = document.getElementById("meta_postStudy_"+idx) as HTMLSelectElement;
                                if(meta_postStudy_) {
                                    meta_postStudy_.value = _item;
                                }
                                const meta_postStudy_val_ = document.getElementById("meta_postStudy_val_"+idx) as HTMLSelectElement;
                                if(meta_postStudy_val_ && meta) {                                
                                    meta_postStudy_val_.value = meta.val;
                                }
        
                                setParentVal(meta.code, idx, "meta_postStudy_parent_"+idx);
                            }
                    
                        });    
                    }, 10);

                } else {
                    setPostStudyList([""]);
                }
            }
        }
    }, [achievementStandardList])
    

    async function getMetaOfMetaExById(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("getMetaOfMetaExById", list);

        const _contentArea = list.find((meta, _) => meta.name === "contentArea");
        if(_contentArea) {
            const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
            meta_contentArea.value = _contentArea.val;    
        }

        const item = props.item;
        if(item) {
            if(item.val6) {

                const _materialList : IEngMeta[] = [];

                const _list = list.filter((meta, _) => meta.name.toLowerCase() === "material");
                if(_list && _list.length > 0) {
                    const items = JSON.parse(item.val6) as string[];
                    items.map((items1) => {

                        const arr = items1.split(deli);

                        const meta0 = _list.find((__item) => __item.id === Number(arr.length > 0 ? arr[0] : -1));
                        const meta1 = _list.find((__item) => __item.id === Number(arr.length > 1 ? arr[1] : -1));
                        const meta2 = _list.find((__item) => __item.id === Number(arr.length > 2 ? arr[2] : -1));
                        const meta3 = _list.find((__item) => __item.id === Number(arr.length > 3 ? arr[3] : -1));

                        const _material1 : IEngMeta = {
                            depth0: meta0!,
                            depth1: meta1!,
                            depth2: meta2!,
                            depth3: meta3,
                        };

                        _materialList.push(_material1);
                    })
                }        

                if(_materialList.length === 0) {
                    _materialList.push({} as IEngMeta);
                }
                setMaterialList(_materialList);
                logger.log("_materialList", _materialList);
            }

            if(item.val7) {

                const _commList : IEngMeta[] = [];

                const _list = list.filter((meta, _) => meta.name === "communication");
                if(_list && _list.length > 0) {
                    const items = JSON.parse(item.val7) as string[];
                    items.map((items1) => {

                        const arr = items1.split(deli);
                        logger.log("arr", arr);

                        const meta0 = _list.find((__item) => __item.id === Number(arr.length > 0 ? arr[0] : -1));
                        const meta1 = _list.find((__item) => __item.id === Number(arr.length > 1 ? arr[1] : -1));
                        const meta2 = _list.find((__item) => __item.id === Number(arr.length > 2 ? arr[2] : -1));
                        const meta3 = _list.find((__item) => __item.id === Number(arr.length > 3 ? arr[3] : -1));

                        const _comm : IEngMeta = {
                            depth0: meta0!,
                            depth1: meta1!,
                            depth2: meta2!,
                            depth3: meta3,
                        };

                        _commList.push(_comm);
                    })
                }        

                if(_commList.length === 0) {
                    _commList.push({} as IEngMeta);
                }
                setCommunicationList(_commList);
                logger.log("_commList", _commList);

            }            

            if(item.val8) {

                const _lfList : IEngMeta[] = [];

                const _list = list.filter((meta, _) => meta.name === "languageFormat");
                if(_list && _list.length > 0) {
                    const items = JSON.parse(item.val8) as string[];
                    items.map((items1) => {

                        const arr = items1.split(deli);
                        logger.log("arr", arr);

                        const meta0 = _list.find((__item) => __item.id === Number(arr.length > 0 ? arr[0] : -1));
                        const meta1 = _list.find((__item) => __item.id === Number(arr.length > 1 ? arr[1] : -1));
                        const meta2 = _list.find((__item) => __item.id === Number(arr.length > 2 ? arr[2] : -1));
                        const meta3 = _list.find((__item) => __item.id === Number(arr.length > 3 ? arr[3] : -1));

                        const _lf : IEngMeta = {
                            depth0: meta0!,
                            depth1: meta1!,
                            depth2: meta2!,
                            depth3: meta3,
                        };

                        _lfList.push(_lf);
                    })
                }        

                if(_lfList.length === 0) {
                    _lfList.push({} as IEngMeta);
                }
                setLanguageFormatList(_lfList);
                logger.log("_lfList", _lfList);

            }            

        }
    }

    async function getContentArea() {
        const list = await getMetaList("1", "contentArea");
        setContentArea(list);
    }

    async function getEvaluationArea() {
        const list1 = await getMetaList("1", "evaluationArea");
        setEvaluationArea1(list1);
        const list2 = await getMetaList("1", "contentsItem");
        setEvaluationArea2(list2);
        const list3 = await getMetaList("1", "evaluationArea3");
        setEvaluationArea3(list3);
    }

    async function getAchievementStandard() {
        const list = await getMetaList("4", "achievementStandard");
        setachievementStandardList(list);
    }
    
    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);
        
        return __list;
    }

    async function insertMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);

        const result = await util.insertMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.updateMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function onSave() {

        const brand = util.getCurrentBrand();
        if(!brand) {
            props.showModal("", "브랜드가 정상적이지 않습니다.");
            return;
        }

        let val = "";
        const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
        if(meta_val)
        {
            val = (document.getElementById('meta_val') as HTMLInputElement).value
            if(val === "") {
                props.showModal("", "메타값을 입력해 주세요.");
                return;
            }
        }
        const description = (document.getElementById('meta_description') as HTMLInputElement).value
        const code = (document.getElementById('meta_code') as HTMLInputElement).value
        const is_active = (document.getElementById('active') as HTMLInputElement).checked

        if(code === "") {
            props.showModal("", "메타코드를 입력해 주세요.");
            return;
        }

        // var regType1 = /^[A-Za-z0-9]+$/;
        // if (!regType1.test(code)) { 
        //     props.showModal("", "code는 영문자, 숫자만 가능합니다.");
        //     return;
        // }

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        if(props.type === "unit1") {

            const meta_unit1 = document.getElementById("meta_unit1") as HTMLSelectElement;
            const studyMap1 = depth1List[meta_unit1.selectedIndex];    

            let studyMap1Val = studyMap1.val;
            let meta_mapping_meta : IMeta[] = [];
            const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
            if(meta_contentArea) {
                const meta_contentArea_val = contentArea[meta_contentArea.selectedIndex];
                // studyMap1Val = studyMap1Val + "(내용영역:" + meta_contentArea_val.val + ")";
                meta_mapping_meta.push(meta_contentArea_val);
            }

            const is_self_study_active = (document.getElementById('self_study_active') as HTMLInputElement).checked

            const meta = {
                id: studyMap1.id,

                // code: studyMap1.code,
                // name: studyMap1.name,
                val: studyMap1Val,
                // depth: studyMap1.depth,
                // parent_id: studyMap1.parent_id,
                is_active: is_active ? true : false,
                description: description,
                // brand_id: brand.id ? brand.id : null,

                name1: "self_study_active",
                val1: is_self_study_active ? "1" : "0",
                
                updater: uid,
                updater_id: _id,
                updater_name: uname,

                uuid: props.item ? undefined : UUID,
            }
            
            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);
    
            updateMetaMetaMap(metamap);  
    
        } else if(props.type === "1") {

            const parentItem = props.parentItem;
            // logger.log('...parentItem', parentItem);

            let _depth = 1;
            let _code = code;
            let _parent_id = 0;
            if(parentItem) {
                _depth = parentItem.depth + 1;
                _code = props.item ? code : parentItem.code + "-" + code;
                const parent = await util.getMetaByCode(parentItem.code);
                logger.log('...parent', parent);
                if(parent) {
                    const _parent = parent as IMeta;
                    _parent_id = _parent.id!;
                } else {
                    alert("오류, 관리자에게 문의 바랍니다.");
                    return;
                }
            } else {
                alert("오류, 관리자에게 문의 바랍니다.");
                return;
            }

            // //code check
            // if(props.item === null) {
            //     const newCode = (curriInfo?.all as IMeta[]).find((item) => item.code === _code);
            //     if(newCode) {
            //         props.showModal("", "중복된 코드가 사용되었습니다.");
            //         (document.getElementById('meta_code') as HTMLInputElement).focus();
            //         (document.getElementById('meta_code') as HTMLInputElement).select();
            //         return;
            //     }    
            // }

            let meta_mapping_meta : IMeta[] = [];

            let evaluationArea_val = "";

            let ea_type = "1";
            const ea_rd1 = (document.getElementById("ea-rd1") as HTMLInputElement);
            const ea_rd2 = (document.getElementById("ea-rd2") as HTMLInputElement);
            const ea_rd3 = (document.getElementById("ea-rd3") as HTMLInputElement);
            if(ea_rd1 && ea_rd1.checked) {
                ea_type = "1";
                evaluationArea1List.map((_, idx) => {
                    const meta_evaluationArea1_view_ = document.getElementById("meta_evaluationArea1_view_"+idx) as HTMLInputElement;
                    const meta_evaluationArea1_ = document.getElementById("meta_evaluationArea1_"+idx) as HTMLSelectElement;
                    if(meta_evaluationArea1_ && meta_evaluationArea1_view_.style.display !== "none" && meta_evaluationArea1_.value.trim().length > 0) {
                        const val = meta_evaluationArea1_.value.trim();
                        if(evaluationArea_val === "") {
                            evaluationArea_val = val;
                        } else {
                            evaluationArea_val = evaluationArea_val + deli + val;
                        }                
                        const meta = evaluationArea1.find((item) => item.val === val);
                        if(meta) {
                            meta_mapping_meta.push(meta);
                        }                        
                    }
                });
            }
            if(ea_rd2 && ea_rd2.checked) {
                ea_type = "2";
                evaluationArea2List.map((_, idx) => {
                    const meta_evaluationArea2_view_ = document.getElementById("meta_evaluationArea2_view_"+idx) as HTMLInputElement;
                    const meta_evaluationArea2_ = document.getElementById("meta_evaluationArea2_"+idx) as HTMLSelectElement;
                    if(meta_evaluationArea2_ && meta_evaluationArea2_view_.style.display !== "none" && meta_evaluationArea2_.value.trim().length > 0) {
                        const val = meta_evaluationArea2_.value.trim();
                        if(evaluationArea_val === "") {
                            evaluationArea_val = val;
                        } else {
                            evaluationArea_val = evaluationArea_val + deli + val;
                        }                
                        const meta = evaluationArea1.find((item) => item.val === val);
                        if(meta) {
                            meta_mapping_meta.push(meta);
                        }                        
                    }
                });

                // const meta_contentsItem = document.getElementById("meta_contentsItem") as HTMLSelectElement;
                // if(meta_contentsItem) {
                //     evaluationArea_val = meta_contentsItem.value.trim();
                //     const meta = evaluationArea2.find((item) => item.val === evaluationArea_val);
                //     if(meta) {
                //         meta_mapping_meta.push(meta);
                //     }
                // }
            }
            if(ea_rd3 && ea_rd3.checked) {
                ea_type = "3";
                const meta_evaluationArea3 = document.getElementById("meta_evaluationArea3") as HTMLSelectElement;
                if(meta_evaluationArea3) {
                    evaluationArea_val = meta_evaluationArea3.value.trim();
                    const meta = evaluationArea3.find((item) => item.val === evaluationArea_val);
                    if(meta) {
                        meta_mapping_meta.push(meta);
                    }
                }            
            }

            let standard = "";
            standardList.map((_, idx) => {

                const meta_standard_view_ = document.getElementById("meta_standard_view_"+idx) as HTMLInputElement;
                const meta_standard_ = (document.getElementById('meta_standard_'+idx) as HTMLSelectElement);
                if(meta_standard_ && meta_standard_view_.style.display !== "none" && meta_standard_.value.trim().length > 0) {
                    const val = meta_standard_.value.trim();
                    if(standard === "") {
                        standard = val;
                    } else {
                        standard = standard + deli + val;
                    }
                    const meta = achievementStandardList[meta_standard_.selectedIndex-1];
                    if(meta) {
                        meta_mapping_meta.push(meta);
                    }
                }
            });

            let preStudy = "";
            preStudyList.map((item, idx) => {

                const meta_preStudy_view_ = document.getElementById("meta_preStudy_view_"+idx) as HTMLInputElement;
                const meta_preStudy_ = (document.getElementById('meta_preStudy_'+idx) as HTMLSelectElement)

                if(meta_preStudy_ && meta_preStudy_view_.style.display !== "none" && meta_preStudy_.value.trim().length > 0) {
                    const val = meta_preStudy_.value.trim();
                    if(preStudy === "") {
                        preStudy = val;
                    } else {
                        preStudy = preStudy + deli + val;
                    }
                    const meta = achievementStandardList[meta_preStudy_.selectedIndex-1];
                    if(meta) {
                        meta_mapping_meta.push(meta);
                    }
                }
            });

            let postStudy = "";
            postStudyList.map((item, idx) => {

                const meta_postStudy_view_ = document.getElementById("meta_postStudy_view_"+idx) as HTMLInputElement;
                const meta_postStudy_ = (document.getElementById('meta_postStudy_'+idx) as HTMLSelectElement)

                if(meta_postStudy_ && meta_postStudy_view_.style.display !== "none" && meta_postStudy_.value.trim().length > 0) {
                    const val = meta_postStudy_.value.trim();
                    if(postStudy === "") {
                        postStudy = val;
                    } else {
                        postStudy = postStudy + deli + val;
                    }
                    const meta = achievementStandardList[meta_postStudy_.selectedIndex-1];
                    if(meta) {
                        meta_mapping_meta.push(meta);
                    }
                }
            });

            let material : string[] = [];
            materialList.map((item, idx) => {
                if(item.depth0) {
                    meta_mapping_meta.push(item.depth0);
                }
                if(item.depth1) {
                    meta_mapping_meta.push(item.depth1);
                }
                if(item.depth2) {
                    meta_mapping_meta.push(item.depth2);
                }
                if(item.depth3) {
                    meta_mapping_meta.push(item.depth3);
                }
                const val = item.depth0?.id + deli + item.depth1?.id + deli + item.depth2?.id + deli + item.depth3?.id;
                material.push(val);
            });
            let communication : string[] = [];
            communicationList.map((item, idx) => {
                if(item.depth0) {
                    meta_mapping_meta.push(item.depth0);
                }
                if(item.depth1) {
                    meta_mapping_meta.push(item.depth1);
                }
                if(item.depth2) {
                    meta_mapping_meta.push(item.depth2);
                }
                if(item.depth3) {
                    meta_mapping_meta.push(item.depth3);
                }
                const val = item.depth0?.id + deli + item.depth1?.id + deli + item.depth2?.id + deli + item.depth3?.id;
                communication.push(val);
            });
            let languageFormat : string[] = [];
            languageFormatList.map((item, idx) => {
                if(item.depth0) {
                    meta_mapping_meta.push(item.depth0);
                }
                if(item.depth1) {
                    meta_mapping_meta.push(item.depth1);
                }
                if(item.depth2) {
                    meta_mapping_meta.push(item.depth2);
                }
                if(item.depth3) {
                    meta_mapping_meta.push(item.depth3);
                }
                const val = item.depth0?.id + deli + item.depth1?.id + deli + item.depth2?.id + deli + item.depth3?.id;
                languageFormat.push(val);
            });

            const meta = {

                id: props.item ? props.item.id : undefined,
    
                code: _code,
                name: "studyMap_1",
                val: val,
                depth: _depth,
                parent_id: _parent_id,
                is_active: is_active ? true : false,
                creator: props.item ? undefined : uid,
                creator_id: props.item ? undefined : _id,
                creator_name: props.item ? undefined : uname,
                description: description,
                brand_id: brand.id ? brand.id : null,
    
                updater: props.item ? uid : undefined,
                updater_id: props.item ? _id : undefined,
                updater_name: props.item ? uname : undefined,
                
                uuid: props.item ? undefined : UUID,

                name1: "표준체계 ID",
                name2: "선수학습 ID",
                name3: "후속학습 ID",
                name4: "평가영역 type",
                name5: "평가영역 value",
                name6: "소재",
                name7: "의사소통기능​",
                name8: "언어형식​",

                val1: standard,
                val2: preStudy,
                val3: postStudy,
                val4: ea_type,
                val5: evaluationArea_val,
                val6: JSON.stringify(material),
                val7: JSON.stringify(communication),
                val8: JSON.stringify(languageFormat),
        
            }

            const filteredMap : IMeta[] = [];
            meta_mapping_meta.map((item) => {
                if(filteredMap.length === 0) {
                    filteredMap.push(item);
                } else {
                    if(filteredMap.findIndex((_item) => _item.id === item.id) === -1) {
                        filteredMap.push(item);
                    }        
                }
            });

            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": filteredMap}
            logger.log("meta_mapping_meta", metamap);
    
            if(props.item) {
                updateMetaMetaMap(metamap);  
            } else {
                insertMetaMetaMap(metamap);  
            }

        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>) {
        const item = depth1List[e.target.selectedIndex];
        (document.getElementById('meta_code') as HTMLInputElement).value = item.code;
        if(item.uuid && item.uuid.length > 10) {
            setUUID(item.uuid);
        } else {
            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);
        }
        logger.log("onChangeUnit1", item, item.uuid);        
    }

    function onAddStandard() {
        const _standardList = JSON.parse(JSON.stringify(standardList));
        _standardList.push("");
        setStandardList(_standardList);
    }
    
    function onDelStandard(idx: number) {
        const meta_standard_view_ = (document.getElementById("meta_standard_view_"+idx) as HTMLInputElement);
        if(meta_standard_view_) {
            meta_standard_view_.style.display = "none";
        }
    }
    
    function onAddPreStudy() {
        const _preStudyList = JSON.parse(JSON.stringify(preStudyList));
        _preStudyList.push("");
        setPreStudyList(_preStudyList);
    }

    function onDelPreStudy(idx: number) {
        const meta_preStudy_view_ = (document.getElementById("meta_preStudy_view_"+idx) as HTMLInputElement);
        if(meta_preStudy_view_) {
            meta_preStudy_view_.style.display = "none";
        }
    }

    function onAddPostStudy() {
        const _postStudyList = JSON.parse(JSON.stringify(postStudyList));
        _postStudyList.push("");
        setPostStudyList(_postStudyList);
    }

    function onDelPostStudy(idx: number) {
        const meta_postStudy_view_ = (document.getElementById("meta_postStudy_view_"+idx) as HTMLInputElement);
        if(meta_postStudy_view_) {
            meta_postStudy_view_.style.display = "none";
        }
    }

    function onUI2EvaluationArea1List() : string[] {

        const _evaluationArea1List = JSON.parse(JSON.stringify(evaluationArea1List)) as string[];
        logger.log("_evaluationArea1List 1", _evaluationArea1List);

        for (let i = 0; i < _evaluationArea1List.length; i++) {
            const meta_evaluationArea1_ = (document.getElementById('meta_evaluationArea1_'+i) as HTMLSelectElement);
            if(meta_evaluationArea1_) {
                _evaluationArea1List[i] = meta_evaluationArea1_.value;
            }            
        }

        logger.log("_evaluationArea1List 2", _evaluationArea1List);
        return _evaluationArea1List;
    }

    function onAddEvaluationArea1() {

        const _evaluationArea1List = JSON.parse(JSON.stringify(evaluationArea1List));
        _evaluationArea1List.push("");
        setEvaluationArea1List(_evaluationArea1List);                
    }
    
    function onDelEvaluationArea1(idx: number) {

        const meta_evaluationArea1_view_ = (document.getElementById("meta_evaluationArea1_view_"+idx) as HTMLInputElement);
        if(meta_evaluationArea1_view_) {
            meta_evaluationArea1_view_.style.display = "none";
        }
    }

    function onAddEvaluationArea2() {

        let cnt = 0;
        for (let i = 0; i < evaluationArea2List.length; i++) {
            const meta_evaluationArea2_view_ = (document.getElementById("meta_evaluationArea2_view_"+i) as HTMLInputElement);
            if(meta_evaluationArea2_view_.style.display !== "none") {
                cnt++;
            }   
        }

        if(cnt < 5) {
            const _evaluationArea2List = JSON.parse(JSON.stringify(evaluationArea2List));
            _evaluationArea2List.push("");
            setEvaluationArea2List(_evaluationArea2List);                    
        }
    }
    
    function onDelEvaluationArea2(idx: number) {

        const meta_evaluationArea2_view_ = (document.getElementById("meta_evaluationArea2_view_"+idx) as HTMLInputElement);
        if(meta_evaluationArea2_view_) {
            meta_evaluationArea2_view_.style.display = "none";
        }
    }

    function onAddMaterial() {
        const _materialList = JSON.parse(JSON.stringify(materialList));
        const _material = {
        }
        _materialList.push(_material);
        logger.log("onAddStudyMap", _materialList);
        setMaterialList(_materialList);
    }

    function onDelMaterial(item: IEngMeta) {
        const _materialList = materialList;
        const newMaterialList = _materialList.filter((e) => e !== item);
        logger.log("onDelMaterial", newMaterialList);
        setMaterialList(newMaterialList);
    }

    function onSelectMaterial(item: IEngMeta) {
        const _materialList = JSON.parse(JSON.stringify(materialList));
        _materialList[selectedMaterialId] = item;
        setMaterialList(_materialList);
        logger.log("onSelectMaterial", item, selectedMaterialId, _materialList);

        setViewPopupMaterial(false);
    }

    function onAddCommunication() {
        const _list = JSON.parse(JSON.stringify(communicationList));
        const _item = {
        }
        _list.push(_item);
        logger.log("onAddCommunication", _list);
        setCommunicationList(_list);
    }

    function onDelCommunication(item: IEngMeta) {
        const _list = communicationList;
        const newList = _list.filter((e) => e !== item);
        logger.log("onDelCommunication", newList);
        setCommunicationList(newList);
    }

    function onSelectCommunication(item: IEngMeta) {
        const _list = JSON.parse(JSON.stringify(communicationList));
        _list[selectedCommunicationId] = item;
        setCommunicationList(_list);
        logger.log("onSelectCommunication", item, selectedCommunicationId, _list);

        setViewPopupCommunication(false);
    }

    function onAddLanguageFormat() {
        const _list = JSON.parse(JSON.stringify(languageFormatList));
        const _item = {
        }
        _list.push(_item);
        logger.log("onAddLanguageFormat", _list);
        setLanguageFormatList(_list);
    }

    function onDelLanguageFormat(item: IEngMeta) {
        const _list = languageFormatList;
        const newList = _list.filter((e) => e !== item);
        logger.log("onDelLanguageFormat", newList);
        setLanguageFormatList(newList);
    }

    function onSelectLanguageFormat(item: IEngMeta) {
        const _list = JSON.parse(JSON.stringify(languageFormatList));
        _list[selectedLanguageFormatId] = item;
        setLanguageFormatList(_list);
        logger.log("onSelectLanguageFormat", item, selectedLanguageFormatId, _list);

        setViewPopupLanguageFormat(false);
    }

    async function onChangeStandard(e: ChangeEvent<HTMLSelectElement>, idx: number) {

        const meta_standard_val_ = document.getElementById("meta_standard_val_"+idx) as HTMLInputElement;
        const meta_standard_parent_ = document.getElementById("meta_standard_parent_"+idx) as HTMLInputElement;

        if(e.target.selectedIndex === 0) {
            if(meta_standard_val_) {
                meta_standard_val_.value = "";
            }
            if(meta_standard_parent_) {
                meta_standard_parent_.value = "";
            }

            return;
        }

        const _achievementStandardList = achievementStandardList[e.target.selectedIndex - 1];

        if(meta_standard_val_) {
            meta_standard_val_.value = _achievementStandardList.val;
        }
        if(meta_standard_parent_) {

            let parentCode = "";
            const arrCode = _achievementStandardList.code.split("-");
            arrCode.map((item, idx) => {
                if(idx < arrCode.length-1) {
                    if(parentCode === "") {
                        parentCode = item
                    } else {
                        parentCode = parentCode + "-" + item;
                    }                                
                }
            });

            const list = await util.getDescendantsList(parentCode);
            const parent = list.find((item) => item.code === parentCode);                
            if(parent) {
                meta_standard_parent_.value = parent.val;
            }
        }

        logger.log("onChangeStandard", _achievementStandardList, idx);
    }
    
    async function onChangePreStudy(e: ChangeEvent<HTMLSelectElement>, idx: number) {

        const meta_preStudy_val_ = document.getElementById("meta_preStudy_val_"+idx) as HTMLInputElement;
        const meta_preStudy_parent_ = document.getElementById("meta_preStudy_parent_"+idx) as HTMLInputElement;

        if(e.target.selectedIndex === 0) {
            if(meta_preStudy_val_) {
                meta_preStudy_val_.value = "";
            }
            if(meta_preStudy_parent_) {
                meta_preStudy_parent_.value = "";
            }

            return;
        }

        const _achievementStandardList = achievementStandardList[e.target.selectedIndex - 1];

        if(meta_preStudy_val_) {
            meta_preStudy_val_.value = _achievementStandardList.val;
        }
        if(meta_preStudy_parent_) {

            let parentCode = "";
            const arrCode = _achievementStandardList.code.split("-");
            arrCode.map((item, idx) => {
                if(idx < arrCode.length-1) {
                    if(parentCode === "") {
                        parentCode = item
                    } else {
                        parentCode = parentCode + "-" + item;
                    }                                
                }
            });

            const list = await util.getDescendantsList(parentCode);
            const parent = list.find((item) => item.code === parentCode);                
            if(parent) {
                meta_preStudy_parent_.value = parent.val;
            }
        }

        logger.log("onChangePreStudy", _achievementStandardList, idx);
    }
        
    async function onChangePostStudy(e: ChangeEvent<HTMLSelectElement>, idx: number) {

        const meta_postStudy_val_ = document.getElementById("meta_postStudy_val_"+idx) as HTMLInputElement;
        const meta_postStudy_parent_ = document.getElementById("meta_postStudy_parent_"+idx) as HTMLInputElement;

        if(e.target.selectedIndex === 0) {
            if(meta_postStudy_val_) {
                meta_postStudy_val_.value = "";
            }
            if(meta_postStudy_parent_) {
                meta_postStudy_parent_.value = "";
            }

            return;
        }

        const _achievementStandardList = achievementStandardList[e.target.selectedIndex - 1];

        if(meta_postStudy_val_) {
            meta_postStudy_val_.value = _achievementStandardList.val;
        }
        if(meta_postStudy_parent_) {

            let parentCode = "";
            const arrCode = _achievementStandardList.code.split("-");
            arrCode.map((item, idx) => {
                if(idx < arrCode.length-1) {
                    if(parentCode === "") {
                        parentCode = item
                    } else {
                        parentCode = parentCode + "-" + item;
                    }                                
                }
            });

            const list = await util.getDescendantsList(parentCode);
            const parent = list.find((item) => item.code === parentCode);                
            if(parent) {
                meta_postStudy_parent_.value = parent.val;
            }
        }

        logger.log("onChangePostStudy", _achievementStandardList, idx);
    }

    return (
        <div>

            {/* <!-- table --> */}
            <table>
                <caption className="sr-only">관리테이블</caption>
                <tbody>

                    {props.type === "unit1" &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-year">메타 값<em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_contentArea">
                                {contentArea && contentArea.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    {(props.type === "1") &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-meta">메타 값 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_val" className="w-avail"/>
                        </td>
                    </tr>}

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-disc">설명</label></th>
                        <td className="text-left">
                            <input type="text" id="meta_description" className="w-avail"/>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <input type="text" id="meta_code" className="w-1/2" />
                        </td>
                    </tr>

                    {props.type === "1" && <>
                    <tr style={{display:"none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-year">평가 영역1</label></th>
                        <td className="text-left">

                            <table className="border-none">
                                <tbody>
                                    <tr>
                                        <td className="w-8" >
                                            <input id="ea-rd1" name="ea-rd" type="radio" />
                                        </td>                                    
                                        <td className="text-left" style={{borderLeftWidth:0, padding: 0}}>
                                            {evaluationArea1List && evaluationArea1List.map((_, idx) => {
                                                // const meta_evaluationArea1_ = document.getElementById("meta_evaluationArea1_"+idx) as HTMLSelectElement;
                                                // if(meta_evaluationArea1_) {
                                                //     if(item.trim() !== "") {
                                                //         meta_evaluationArea1_.value = item;
                                                //     } else {
                                                //         meta_evaluationArea1_.selectedIndex = 0;
                                                //     }
                                                // }
                                                return(
                                                    <div key={idx} id={"meta_evaluationArea1_view_"+idx}>

                                                        <select id={"meta_evaluationArea1_"+idx} className={(idx > 0 ? " mt-2" : "")}>
                                                            {evaluationArea1 && evaluationArea1.map((item, idx) => {
                                                                return(
                                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                                );
                                                            })}
                                                        </select>

                                                        {idx === 0 &&
                                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                                        type="button" className="btn-sky ml-2" onClick={onAddEvaluationArea1}>+</button>}
                                                        {idx > 0 &&
                                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                                        type="button" className="btn-sky ml-2" onClick={() => onDelEvaluationArea1(idx)}>-</button>}

                                                    </div>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr style={{display:"none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-year">평가 영역2</label></th>
                        <td className="text-left">

                            <table className="border-none">
                                <tbody>
                                    <tr>
                                        <td className="w-8" >
                                            <input id="ea-rd2" name="ea-rd" type="radio" />
                                        </td>                                    
                                        <td className="text-left" style={{borderLeftWidth:0, padding: 0}}>
                                            {evaluationArea2List && evaluationArea2List.map((_, idx) => {
                                                return(
                                                    <div key={idx} id={"meta_evaluationArea2_view_"+idx}>

                                                        <select id={"meta_evaluationArea2_"+idx} className={(idx > 0 ? " mt-2" : "")}>
                                                            {evaluationArea2 && evaluationArea2.map((item, idx) => {
                                                                return(
                                                                    <option key={idx} value={item.val}>{item.val}</option>
                                                                );
                                                            })}
                                                        </select>

                                                        {idx === 0 &&
                                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                                        type="button" className="btn-sky ml-2" onClick={onAddEvaluationArea2}>+</button>}
                                                        {idx > 0 &&
                                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                                        type="button" className="btn-sky ml-2" onClick={() => onDelEvaluationArea2(idx)}>-</button>}

                                                    </div>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>                    
                    {/* <tr>
                        <th scope="row" className="w-56"><label htmlFor="sel-year">평가 영역2</label></th>
                        <td className="text-left">

                            <table className="border-none">
                                <tbody>
                                    <tr>
                                        <td className="w-8" >
                                            <input id="ea-rd2" name="ea-rd" type="radio" />
                                        </td>                                    
                                        <td className="text-left" style={{borderLeftWidth:0, padding: 0}}>
                                            <select id="meta_contentsItem">
                                                {evaluationArea2 && evaluationArea2.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr> */}
                    <tr style={{display:"none"}}>
                        <th scope="row" className="w-56"><label htmlFor="sel-year">평가 영역3</label></th>
                        <td className="text-left">

                            <table className="border-none">
                                <tbody>
                                    <tr>
                                        <td className="w-8" >
                                            <input id="ea-rd3" name="ea-rd" type="radio" />
                                        </td>                                    
                                        <td className="text-left" style={{borderLeftWidth:0, padding: 0}}>
                                            <select id="meta_evaluationArea3">
                                                {evaluationArea3 && evaluationArea3.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </td>
                    </tr>
                    </>}

                    {props.type === "1" && <>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">표준체계 ID <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">

                            {standardList && standardList.map((item, idx) => {

                                return(
                                    <div key={idx} id={"meta_standard_view_"+idx}>

                                        {/* <input type="text" id={"standard_"+idx} className={"w-1/2" + (idx > 0 ? " mt-2" : "")} defaultValue={item} /> */}

                                        <select id={"meta_standard_"+idx} className={(idx > 0 ? " mt-2" : "")} onChange={(e) => onChangeStandard(e, idx)}>
                                            <option value="">선택</option>
                                            {achievementStandardList && achievementStandardList.map((item1, idx1) => {
                                                return(
                                                    <option key={idx1} value={item1.val1}>{item1.val1}</option>
                                                );
                                            })}
                                        </select>

                                        <input id={"meta_standard_val_"+idx} type="text" className={"w-1/2 ml-3"} readOnly />
                                        <input id={"meta_standard_parent_"+idx} type="text" className={"ml-3"} readOnly />

                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddStandard}>+</button>}
                                        {idx > 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelStandard(idx)}>-</button>}
                                    </div>
                                );
                            })}

                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">선수학습 ID</label></th>
                        <td className="text-left">

                            {preStudyList && preStudyList.map((item, idx) => {
                                return(
                                    <div key={idx} id={"meta_preStudy_view_"+idx}>

                                        <select id={"meta_preStudy_"+idx} className={(idx > 0 ? " mt-2" : "")} onChange={(e) => onChangePreStudy(e, idx)}>
                                            <option value="">선택</option>
                                            {achievementStandardList && achievementStandardList.map((item1, idx1) => {
                                                return(
                                                    <option key={idx1} value={item1.val1}>{item1.val1}</option>
                                                );
                                            })}
                                        </select>

                                        <input id={"meta_preStudy_val_"+idx} type="text" className={"w-1/2 ml-3"} readOnly />
                                        <input id={"meta_preStudy_parent_"+idx} type="text" className={"ml-3"} readOnly />

                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddPreStudy}>+</button>}
                                        {idx > 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelPreStudy(idx)}>-</button>}
                                    </div>
                                );
                            })}

                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">후속학습 ID</label></th>
                        <td className="text-left">
                            {postStudyList && postStudyList.map((item, idx) => {
                                return(
                                    <div key={idx} id={"meta_postStudy_view_"+idx}>

                                        <select id={"meta_postStudy_"+idx} className={(idx > 0 ? " mt-2" : "")} onChange={(e) => onChangePostStudy(e, idx)}>
                                            <option value="">선택</option>
                                            {achievementStandardList && achievementStandardList.map((item1, idx1) => {
                                                return(
                                                    <option key={idx1} value={item1.val1}>{item1.val1}</option>
                                                );
                                            })}
                                        </select>

                                        <input id={"meta_postStudy_val_"+idx} type="text" className={"w-1/2 ml-3"} readOnly />
                                        <input id={"meta_postStudy_parent_"+idx} type="text" className={"ml-3"} readOnly />

                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddPostStudy}>+</button>}
                                        {idx > 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelPostStudy(idx)}>-</button>}
                                    </div>
                                );
                            })}
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">소재</label></th>
                        <td className="text-left">
                            {materialList && materialList.map((item, idx) => {

                                let _unitVal = "";
                                if(item.depth0) {
                                    _unitVal = item.depth0.val;    
                                }
                                if(item.depth1) {
                                    _unitVal = _unitVal + " > " + item.depth1.val;    
                                }
                                if(item.depth2) {
                                    _unitVal = _unitVal + " > " + item.depth2.val;    
                                }
                                if(item.depth3) {
                                    _unitVal = _unitVal + " > " + item.depth3.val;    
                                }

                                return(
                                    <div key={idx}>

                                            {/* <select id={"meta_material1_"+idx} onChange={(e) => onChangeMaterial1(e)}>
                                                <option value="">선택</option>
                                                {material1 && material1.map((item1, idx1) => {
                                                    return(
                                                        <option key={idx1} value={item1.val}>{item1.val}</option>
                                                    );
                                                })}
                                            </select>
                                            <select className="ml-2" id={"meta_material2_"+idx} onChange={(e) => onChangeMaterial2(e)}>
                                                <option value="">선택</option>
                                                {material2 && material2.map((item2, idx2) => {
                                                    return(
                                                        <option key={idx2} value={item2.val}>{item2.val}</option>
                                                    );
                                                })}
                                            </select>
                                            <select className="ml-2" id={"meta_material3_"+idx}>
                                                <option value="">선택</option>
                                                {material3 && material3.map((item3, idx3) => {
                                                    return(
                                                        <option key={idx3} value={item3.val}>{item3.val}</option>
                                                    );
                                                })}
                                            </select> */}


                                        <button type="button" className={idx > 0 ? "btn-sky mt-2" : "btn-sky"} onClick={() => {
                                            setSelectedMaterialId(idx);
                                            setViewPopupMaterial(true);
                                        }}>검색</button>
                                        <span className="ml-2" dangerouslySetInnerHTML={{ __html: _unitVal }}></span>

                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddMaterial}>+</button>}
                                        {idx > 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelMaterial(item)}>-</button>}
                                    </div>
                                );
                            })}
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">의사소통기능​</label></th>
                        <td className="text-left">
                            {communicationList && communicationList.map((item, idx) => {

                                let _unitVal = "";
                                if(item.depth0) {
                                    _unitVal = item.depth0.val;    
                                }
                                if(item.depth1) {
                                    _unitVal = _unitVal + " > " + item.depth1.val;    
                                }
                                if(item.depth2) {
                                    _unitVal = _unitVal + " > " + item.depth2.val;    
                                }
                                if(item.depth3) {
                                    _unitVal = _unitVal + " > " + item.depth3.val;    
                                }

                                return(
                                    <div key={idx}>

                                        <button type="button" className={idx > 0 ? "btn-sky mt-2" : "btn-sky"} onClick={() => {
                                            setSelectedCommunicationId(idx);
                                            setViewPopupCommunication(true);
                                        }}>검색</button>
                                        <span className="ml-2" dangerouslySetInnerHTML={{ __html: _unitVal }}></span>

                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddCommunication}>+</button>}
                                        {idx > 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelCommunication(item)}>-</button>}
                                    </div>
                                );
                            })}
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">언어형식​</label></th>
                        <td className="text-left">
                            {languageFormatList && languageFormatList.map((item, idx) => {

                                let _unitVal = "";
                                if(item.depth0) {
                                    _unitVal = item.depth0.val;    
                                }
                                if(item.depth1) {
                                    _unitVal = _unitVal + " > " + item.depth1.val;    
                                }
                                if(item.depth2) {
                                    _unitVal = _unitVal + " > " + item.depth2.val;    
                                }
                                if(item.depth3) {
                                    _unitVal = _unitVal + " > " + item.depth3.val;    
                                }

                                return(
                                    <div key={idx}>

                                        <button type="button" className={idx > 0 ? "btn-sky mt-2" : "btn-sky"} onClick={() => {
                                            setSelectedLanguageFormatId(idx);
                                            setViewPopupLanguageFormat(true);
                                        }}>검색</button>
                                        <span className="ml-2" dangerouslySetInnerHTML={{ __html: _unitVal }}></span>

                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddLanguageFormat}>+</button>}
                                        {idx > 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelLanguageFormat(item)}>-</button>}
                                    </div>
                                );
                            })}
                        </td>
                    </tr>

                    {/* <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">성취기준 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            {achievementStandardList && achievementStandardList.map((item, idx) => {
                                return(
                                    <div key={idx}>
                                        <input type="text" id={"achievementStandard_"+idx} className={"w-1/2" + (idx > 0 ? " mt-2" : "")} defaultValue={item} />
                                        {idx === 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={onAddAchievementStandard}>+</button>}
                                        {idx > 0 &&
                                        <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                        type="button" className="btn-sky ml-2" onClick={() => onDelAchievementStandard(idx)}>-</button>}
                                    </div>
                                );
                            })}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">성취기준 내용 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            {achievementStandardDescList && achievementStandardDescList.map((item, idx) => {
                                return(
                                    <div key={idx}>
                                        <input type="text" id={"achievementStandardDesc_"+idx} className={"w-avail" + (idx > 0 ? " mt-2" : "")} defaultValue={item} />
                                    </div>
                                );
                            })}
                        </td>
                    </tr> */}
                    
                    </>}

                    {props.type === "unit1" &&
                    <tr>
                        <th scope="row" className="w-56"><label htmlFor="inp-code">단원 선택 <em className="text-sky-500 align-top">*</em></label></th>
                        <td className="text-left">
                            <select id="meta_unit1" onChange={(e) => onChangeUnit1(e)}>
                                {depth1List && depth1List.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    );
                                })}
                            </select>
                        </td>
                    </tr>}

                    <tr>
                        <th scope="row" className="w-56">고유코드</th>
                        <td className="text-left">{UUID}</td>
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록자</th>
                        {props.item &&
                        <td className="text-left">{props.item.creator_name} ({props.item.creator})</td>}
                        {!props.item &&
                        <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                    </tr>
                    <tr>
                        <th scope="row" className="w-56">등록일</th>
                        {props.item &&
                        <td className="text-left">{props.item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>}
                        {!props.item &&
                        <td className="text-left">{util.getDate()}</td>}
                    </tr>

                    <tr style={{display: props.type === "unit1" ? "" : "none"}}>
                        <th scope="row" className="w-56">자기주도학습 사용 설정<em className="text-sky-500 align-top">*</em></th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="self_study_active" name="self_study" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="self_study_non_active" name="self_study" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                        <td className="text-left">
                            <div className="radio-wrap">
                                <span className="inp-radio">
                                    <input id="active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-on">사용</label>
                                </span>
                                <span className="inp-radio">
                                    <input id="non_active" name="rd-use" type="radio" />
                                    <label htmlFor="rd-off">미사용</label>
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <!-- .//table -->	 */}
                    
            <div className="relative mt-5">
                <div className="flex justify-between">
                    <button type="button" className="btn-white" onClick={() => props.onCloseEdit(false, "")}>취소</button>
                    <button type="button" className="btn-sky" onClick={onSave}>{props.item ? "수정" : "등록"}</button>
                </div>
            </div>

            {viewPopupMaterial &&
            <PopupMaterialSearch showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectMaterial} onClose={function (): void {
                setViewPopupMaterial(false);
            } } />}

            {viewPopupCommunication &&
            <PopupCommunicationSearch showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectCommunication} onClose={function (): void {
                setViewPopupCommunication(false);
            } } />}

            {viewPopupLanguageFormat &&
            <PopupLanguageFormatSearch showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectLanguageFormat} onClose={function (): void {
                setViewPopupLanguageFormat(false);
            } } />}

        </div>
    );
}

export default MetaStudyMapEngEdit;
