import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, ILibrary, ILibraryFile, IMeta, IScript, IText, S3Server, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import PopupEditMultiLang from "./popupEditMultiLang";
import LibraryText from "./LibraryText";
import LibraryMedia from "./LibraryMedia";
import PopupLibrarySearch from "./PopupLibrarySearch";
import PopupStudyMapSearch from "./PopupStudyMapSearch";
import PopupMediaPreview from "./PopupMediaPreview";

export interface IStudyMap {
    id: number;
    depth0: IMeta;
    depth1: IMeta;
    depth2: IMeta;
    depth3: IMeta;
    depth4: IMeta;
    depth5: IMeta;
    depth6: IMeta;
}

interface ILibraryRegister {
    type: string;
    brand: IBrand;
    selectedItem: ILibrary | null;
    onChangeTextType3: (val: string) => void;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function LibraryRegister(props: ILibraryRegister) {

    const [type2List, setType2List] = useState([] as any);
    const [type2Text, setType2Text] = useState(null as any);
    const [type3TextList, setType3TextList] = useState([] as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    
    // const [brand, setBrand] = useState(null as any);

    const [contentsReload, setContentsReload] = useState(0);

    const [fileContents, setFileContents] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);

    const [fileUploading, setFileUploading] = useState(false);
    
    const [viewEditMultiLang, setViewEditMultiLang] = useState(false);
    const [defaultLang, setDefaultLang] = useState("");

    const [viewSearchScript, setViewSearchScript] = useState(false);
    const [searchType, setSearchType] = useState("");
    const [searchedThumb, setSearchedThumb] = useState(null as any);
    const [searchedScript, setSearchedScript] = useState<IText>(null as any);
    const [studyMapList, setStudyMapList] = useState<IStudyMap[]>([]);

    const [viewPopupSearchStudyMap, setViewPopupSearchStudyMap] = useState(false);
    const [selectedStudyMapId, setSelectedStudyMapId] = useState(0);

    const [viewMediaPreview, setViewMediaPreview] = useState(false);
    const [mediaType, setMediaType] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [selectedItemUrl, setSelectedItemUrl] = useState("");

    const listUsedIn = ["사용처1", "사용처2", "사용처3", "사용처4"];
    // const listParts = ["Noun", "Pronoun", "Verb", "Adjective", "Adverb", "Preposition", "Conjunction", "Interjection", "Determiner"];
    const curriInfo = util.getCurrentCurriInfo();
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {

        if(!bDone) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     props.OnClose();
            //     return;
            // }
    
            (document.getElementById('LR_rd-on') as HTMLInputElement).checked = true;
            (document.getElementById('LR_rd-off') as HTMLInputElement).checked = false;
            (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = true;
            (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = false;
    
            const LR_type1 = document.getElementById("LR_type1") as HTMLSelectElement;
            const LR_type2 = document.getElementById("LR_type2") as HTMLSelectElement;
    
            if(props.type === "image") {
                LR_type1.selectedIndex = 0;
                setType2List(_type2List[0]);
            } else if(props.type === "audio") {
                LR_type1.selectedIndex = 1;
                setType2List(_type2List[1]);
            } else if(props.type === "video") {
                LR_type1.selectedIndex = 2;
                setType2List(_type2List[2]);
            } else if(props.type === "text") {
                LR_type1.selectedIndex = 3;
                setType2List(_type2List[3]);
            }
            LR_type2.selectedIndex = 0;
    
            // setBrand(util.getCurrentBrand());
            const _contents : ILibraryFile[] = [];
            _contents.push({
                type: "",
                file: undefined,
                url: "",
                thumbnail: "",
                isDelete: false,
                isUploaded: false,
            });
            setFileContents(_contents);
            setContentsReload(1);
            setCurriDepth(0);
            setType2Text("어휘");
            setType3TextList(_type3TextList[0]);
    
            const _studyMapList : IStudyMap[] = [];
            const _studyMap = {
                id: -1,
            }
            _studyMapList.push(_studyMap as IStudyMap);
            setStudyMapList(_studyMapList);

            const selectedItem = props.selectedItem;
            if(selectedItem) {
                init(selectedItem.id);
                // getLibraryFileList(selectedItem.id);
                // getLibraryMetaList(selectedItem.id);
                logger.log("selectedItem", selectedItem);
            }
        }
        bDone = true;

    }, [])

    function onChangeType1(e: React.ChangeEvent<HTMLSelectElement>): void {
        logger.log("type2", e.target.value);
        
        if(e.target.value === "이미지") {
            setType2List(_type2List[0]);
        } else if(e.target.value === "오디오") {
            setType2List(_type2List[1]);
        } else if(e.target.value === "비디오") {
            setType2List(_type2List[2]);
        } else if(e.target.value === "텍스트") {
            setType2List(_type2List[3]);
            setType3TextList(_type3TextList[0]);
        }

        const LR_type2 = document.getElementById("LR_type2") as HTMLSelectElement;
        if(LR_type2) {
            const selectElement = LR_type2 as HTMLSelectElement;
            selectElement.selectedIndex = 0;
        }
    }

    async function init(id: number) {

        props.showLoading(true);
        const result = await util.getLibraryInfo(id);
        logger.log("init", result);
        props.showLoading(false);

        if(result) {
            
            const selectedItem  = result as ILibrary;

            const _contents : ILibraryFile[] = [];
            const content : ILibraryFile = {
                type: "",
                file: undefined,
                url: selectedItem.url,
                thumbnail: selectedItem.thumbnail,
                isDelete: false,
                isUploaded: true
            } 
            _contents.push(content);
            setFileContents(_contents);
            setContentsReload(1);
            
            setSelectedItemUrl(selectedItem.url);

            if(selectedItem.studyMap && selectedItem.studyMap.length > 0) {
                setStudyMapList(selectedItem.studyMap);
            }

            (document.getElementById('LR_title') as HTMLInputElement).value = selectedItem.name;
    
            (document.getElementById('LR_rd-on') as HTMLInputElement).checked = selectedItem.is_active;
            (document.getElementById('LR_rd-off') as HTMLInputElement).checked = !selectedItem.is_active;    
            (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = selectedItem.is_publicOpen;
            (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = !selectedItem.is_publicOpen;    
            (document.getElementById('LR_description') as HTMLSelectElement).value = selectedItem.description;
    
            setTimeout(() => {
                (document.getElementById('LR_type2') as HTMLSelectElement).value = selectedItem.type_2;                    
            }, 10);
    
            if(selectedItem.type_3) {
                (document.getElementById('LR_type3') as HTMLSelectElement).value = selectedItem.type_3;
            }

            const LR_thumb = (document.getElementById('LR_thumb') as HTMLSelectElement);
            if(LR_thumb) {
                LR_thumb.value = selectedItem.thumbnail ? selectedItem.thumbnail : "";
            }
            const LR_script = (document.getElementById('LR_script') as HTMLSelectElement);
            if(LR_script) {
                LR_script.value = String(selectedItem.script_id ? selectedItem.script_id : "");
            }

            const metaMap = selectedItem.metaMap;
            if(metaMap && metaMap.length > 0) {
                const curriSchool = metaMap.find((item) => item.name === "curriSchool");
                if(curriSchool) {
                    const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
                    LR_school.value = curriSchool.val;
                }
                const curriSubject = metaMap.find((item) => item.name === "curriSubject");
                if(curriSubject) {
                    const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
                    LR_subject.value = curriSubject.val;
                }
                const difficulty = metaMap.find((meta, _) => meta.name === "difficulty");
                const LR_level = (document.getElementById('LR_level') as HTMLSelectElement);
                if(LR_level) {
                    LR_level.value = difficulty ? difficulty.val : "";
                }
            }

            const libtextMap = selectedItem.libtextMap;
            if(libtextMap && libtextMap.length > 0) {
                const script = libtextMap.find((item) => item.type_1 === "지문");
                if(script) {
                    setSearchedScript(script);
                }
            }

            const studyMapListString = JSON.parse(selectedItem.studyMapListString);
            if(studyMapListString) {
                setStudyMapList(studyMapListString);
            }
            
            const curriBook = curriInfo?.curriBook.find((meta, _) => meta.id === selectedItem.curriBook);
    
            if(curriInfo && curriBook) {
    
                const _bookDescendantsList = await getDescendantsList(curriBook.code);
        
                const LR_textbook = (document.getElementById("LR_textbook") as HTMLSelectElement);
                if(LR_textbook) {
                    LR_textbook.value = curriBook.val;
                }
    
                if(_bookDescendantsList && _bookDescendantsList.length > 0) {

                    const curriUnit1s = _bookDescendantsList.filter((item) => item.parent_id === curriBook.id);
                    setCurriUnit1(curriUnit1s);
        
                    setTimeout(() => {

                        const curriUnit1 = curriUnit1s.find((meta, _) => meta.id === selectedItem.curriUnit1);
                        if(curriUnit1) {
                            (document.getElementById("LR_curriUnit1") as HTMLSelectElement).value = String(curriUnit1.id);
            
                            const curriUnit2s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit1.id);
                            setCurriUnit2(curriUnit2s);
                            setTimeout(() => {
                                const curriUnit2 = curriUnit2s.find((meta, _) => meta.id === selectedItem.curriUnit2);
                                if(curriUnit2) {
                                    (document.getElementById("LR_curriUnit2") as HTMLSelectElement).value = String(curriUnit2.id);
            
                                    const curriUnit3s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit2.id);
                                    setCurriUnit3(curriUnit3s);
                                    setTimeout(() => {
                                        const curriUnit3 = curriUnit3s.find((meta, _) => meta.id === selectedItem.curriUnit3);
                                        if(curriUnit3) {
                                            (document.getElementById("LR_curriUnit3") as HTMLSelectElement).value = String(curriUnit3.id);
            
                                            const curriUnit4s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit3.id);
                                            setCurriUnit4(curriUnit4s);
                                            setTimeout(() => {
                                                const curriUnit4 = curriUnit4s.find((meta, _) => meta.id === selectedItem.curriUnit4);
                                                if(curriUnit4) {
                                                    (document.getElementById("LR_curriUnit4") as HTMLSelectElement).value = String(curriUnit4.id);
            
                                                    const curriUnit5s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit4.id);
                                                    setCurriUnit5(curriUnit5s);
                                                    setTimeout(() => {
                                                        const curriUnit5 = curriUnit5s.find((meta, _) => meta.id === selectedItem.curriUnit5);
                                                        if(curriUnit5) {
                                                            (document.getElementById("LR_curriUnit5") as HTMLSelectElement).value = String(curriUnit5.id);
                                                        }                            
                                                    }, 10);
                                                }                            
                                            }, 10);
                                        }                            
                                    }, 10);    
                                }                            
                            }, 10);        
                        }                            
                    }, 10);    
                }          
            }            
        }        
    }

    function onChangeType2(e: React.ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeType2", e.target.value);
        setType2Text(e.target.value);
        props.onChangeTextType3(e.target.value);

        if(e.target.value === "어휘") {
            setType3TextList(_type3TextList[0]);
        } else if(e.target.value === "문장") {
            setType3TextList(_type3TextList[1]);
        } else if(e.target.value === "지문") {
            setType3TextList(_type3TextList[2]);
        } else {
        }

        const html = document.getElementById("LS_type3");
        if(html) {
            const selectElement = html as HTMLSelectElement;
            selectElement.selectedIndex = 0;
        }
    }

    function onChangeType3(e: React.ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeType3", e.target.value);
        setType2Text(e.target.value);

        // if(e.target.value === "어휘") {
        //     setType3TextList(_type3TextList[0]);
        // } else if(e.target.value === "문장") {
        //     setType3TextList(_type3TextList[1]);
        // } else if(e.target.value === "지문") {
        //     setType3TextList(_type3TextList[2]);
        // } else {
        // }

        // const html = document.getElementById("LS_type3");
        // if(html) {
        //     const selectElement = html as HTMLSelectElement;
        //     selectElement.selectedIndex = 0;
        // }
    }

    async function getLibraryFileList(id: number) {

        const list = await util.getLibraryFileList(id);    
        const _contents : ILibraryFile[] = [];
        list.map((item) => {
            item.isUploaded = true;
            item.isDelete = false;
            logger.log("getLibraryFileList", item);
            _contents.push(item);
        });
        setFileContents(_contents);
        setContentsReload(contentsReload + 1);
        logger.log("getLibraryFileList", list, _contents);
    }

    async function getLibraryMetaList(id: number) {

        const list = await util.getLibraryMetaList(id);    
        const curriSchool = list.find((meta, _) => meta.name === "curriSchool");
        const curriSubject = list.find((meta, _) => meta.name === "curriSubject");
        const difficulty = list.find((meta, _) => meta.name === "difficulty");
        logger.log("getLibraryMetaList", list);  

        const LR_school = (document.getElementById('LR_school') as HTMLSelectElement);
        if(LR_school) {
            LR_school.value = curriSchool ? curriSchool.val : "";
        }
        const LR_subject = (document.getElementById('LR_subject') as HTMLSelectElement);
        if(LR_subject) {
            LR_subject.value = curriSubject ? curriSubject.val : "";
        }
        const LR_level = (document.getElementById('LR_level') as HTMLSelectElement);
        if(LR_level) {
            LR_level.value = difficulty ? difficulty.val : "";
        }

        let max_depth = 0;
        const curriBook = list.find((meta, _) => meta.name === "curriBook");
        if(curriBook && curriBook.max_depth) {
            max_depth = curriBook.max_depth;
        } else {
            const curriUnit1 = list.find((meta, _) => meta.name === "curriUnit1");
            if(curriUnit1) {
                max_depth = 1;
            }
            const curriUnit2 = list.find((meta, _) => meta.name === "curriUnit2");
            if(curriUnit2) {
                max_depth = 2;
            }
            const curriUnit3 = list.find((meta, _) => meta.name === "curriUnit3");
            if(curriUnit3) {
                max_depth = 3;
            }
            const curriUnit4 = list.find((meta, _) => meta.name === "curriUnit4");
            if(curriUnit4) {
                max_depth = 4;
            }
            const curriUnit5 = list.find((meta, _) => meta.name === "curriUnit5");
            if(curriUnit5) {
                max_depth = 5;
            }
        }
        setCurriDepth(max_depth);
    }

    async function getChildrenList(id: string, name: string) {

        if(id === "0") {
            return;
        }
        
        const list = await util.getChildrenList(id);    
        logger.log("getChildrenList", list);  

        if(name === "CurriBook") {
            logger.log("setCurriUnit1", list);  
            setCurriUnit1(list);
        } else if(name === "CurriUnit1") {
            logger.log("setCurriUnit2", list);  
            setCurriUnit2(list);
        } else if(name === "CurriUnit2") {
            logger.log("setCurriUnit3", list);  
            setCurriUnit3(list);
        } else if(name === "CurriUnit3") {
            logger.log("setCurriUnit4", list);  
            setCurriUnit4(list);
        } else if(name === "CurriUnit4") {
            logger.log("setCurriUnit5", list);  
            setCurriUnit5(list);
        }
    }

    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {

            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        
        const curriBook = curriInfo?.curriBook.find((meta, _) => meta.val === e.target.value);
        if(curriBook) {
            getDescendantsList(curriBook.code);

            const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
            const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
            const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
            const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
            const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
        
            LR_html_curriUnit1.selectedIndex = 0;
            LR_html_curriUnit2.selectedIndex = 0;
            LR_html_curriUnit3.selectedIndex = 0;
            LR_html_curriUnit4.selectedIndex = 0;
            if(LR_html_curriUnit5) {
                LR_html_curriUnit5.selectedIndex = 0;
            }
    
            setCurriUnit2([]);
            setCurriUnit3([]);
            setCurriUnit4([]);
            setCurriUnit5([]);
        } else {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        LR_html_curriUnit2.selectedIndex = 0;
        LR_html_curriUnit3.selectedIndex = 0;
        LR_html_curriUnit4.selectedIndex = 0;
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        LR_html_curriUnit3.selectedIndex = 0;
        LR_html_curriUnit4.selectedIndex = 0;
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        LR_html_curriUnit4.selectedIndex = 0;
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;    
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function OnClose(): void {
        props.OnClose();
    }

    async function onFileUploadForSave() {

        if(true || !props.selectedItem) {
            
            const _fileContents = fileContents as ILibraryFile[];
            const _filtered_contents = _fileContents.filter((item) => item.isDelete === false);
            logger.log("fileContents", _fileContents, _filtered_contents);
    
            const brandId = props.brand.id;
            // const filepath = "upload/"+brandId+"/"+util.getDate().replaceAll(".", "")+"/";
    
            const LR_textbook = document.getElementById("LR_textbook") as HTMLSelectElement;
            if(LR_textbook.value === "0") {
                props.showModal("", "교과를 입력해 주세요.");
                return;
            }
            let curriBookName = "";
            let curriUnit1Name = "";

            if(curriInfo) {
                const LR_textbook_val = curriInfo.curriBook[LR_textbook.selectedIndex-1];
                curriBookName = LR_textbook_val.val;

                const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
                if(LR_html_curriUnit1.value === "0") {
                    props.showModal("", "교과 표준체계 Depth1을 입력해 주세요.");
                    return;
                }
    
                if(LR_html_curriUnit1) {
                    const LR_html_curriUnit1_val = curriUnit1[LR_html_curriUnit1.selectedIndex-1];
                    curriUnit1Name = LR_html_curriUnit1_val.val;
                }
            }
    
            const filepath = "CBS_Library_Multimedia/"+props.type+"/"+curriBookName+"/"+curriUnit1Name+"/";
            // - 폴더구조: CBS테스트서버 > CBS_Library_Multimedia_audio > 교과명 > 교과표준체계 1depth값

            logger.log("onFileUploadForSave started!!!");      
    
            setFileUploading(true);
    
            for (let i = 0; i < _filtered_contents.length; i++) {
                const item = _filtered_contents[i];
                if(item.file) {
                    const result = await util.S3Upload(item.file, filepath);      
                    logger.log("onFileUploadForSave S3Upload", item, result);       
                    if(!result) {
                        logger.log("onFileUploadForSave failed!!!");  
                        setFileUploading(false);   
                        return; 
                    }
                }
            }
            logger.log("onFileUploadForSave finished!!!");  
            setFileUploading(false);
        }

        onSave();
    }

    function onSave() {

        if(!curriInfo) {
            return;
        }

        const LR_type1 = document.getElementById("LR_type1") as HTMLSelectElement;
        const LR_type1_val = type1ListValue[LR_type1.selectedIndex];

        const LR_type2 = document.getElementById("LR_type2") as HTMLSelectElement;
        const LR_type2_val = type2List[LR_type2.selectedIndex];

        // let LR_type3_val = "";
        // const LR_type3 = document.getElementById("LR_type3") as HTMLSelectElement;
        // if(LR_type3) {
        //     LR_type3_val = type3TextList[LR_type2_val][LR_type3.selectedIndex];
        // }

        const LR_title = document.getElementById("LR_title") as HTMLInputElement;
        const LR_title_val = LR_title.value;
        if(LR_title_val.trim() === "") {
            LR_title.focus();
            props.showModal("", "타이틀을 입력해 주세요.");
            return;
        }

        const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
        const LR_school_val = curriInfo.curriSchool[LR_school.selectedIndex];
        
        const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
        const LR_subject_val = curriInfo.curriSubject[LR_subject.selectedIndex];
        
        const LR_textbook = document.getElementById("LR_textbook") as HTMLSelectElement;
        if(LR_textbook.value === "0") {
            props.showModal("", "교과를 입력해 주세요.");
            return;
        }
        const LR_textbook_val = curriInfo.curriBook[LR_textbook.selectedIndex-1];
        
        const LR_usedin = document.getElementById("LR_usedin") as HTMLSelectElement;
        const LR_usedin_val = listUsedIn[LR_usedin.selectedIndex];
        
        const LR_level = document.getElementById("LR_level") as HTMLSelectElement;
        // if(LR_level.value === "0") {
        //     props.showModal("", "난이도를 입력해 주세요.");
        //     return;
        // }
        const LR_level_val = curriInfo.difficulty[LR_level.selectedIndex-1];
    
        const LR_description = document.getElementById("LR_description") as HTMLInputElement;
        const LR_description_val = LR_description.value;

        const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
        if(LR_html_curriUnit1.value === "0") {
            props.showModal("", "교과 표준체계 Depth1을 입력해 주세요.");
            return;
        }

        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        // if(LR_html_curriUnit2.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        // if(LR_html_curriUnit3.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        // if(LR_html_curriUnit4.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
        // if(LR_html_curriUnit5 && LR_html_curriUnit5.value === "0") {
        //     props.showModal("", "교과 표준체계를 입력해 주세요.");
        //     return;
        // }
    
        const LR_html_curriUnit1_val = LR_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[LR_html_curriUnit1.selectedIndex-1] as IMeta;
        const LR_html_curriUnit2_val = LR_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[LR_html_curriUnit2.selectedIndex-1] as IMeta;
        const LR_html_curriUnit3_val = LR_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[LR_html_curriUnit3.selectedIndex-1] as IMeta;
        const LR_html_curriUnit4_val = LR_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[LR_html_curriUnit4.selectedIndex-1] as IMeta;
        const LR_html_curriUnit5_val = LR_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[LR_html_curriUnit5.selectedIndex-1] as IMeta;
    
        const LR_rd_on = document.getElementById('LR_rd-on') as HTMLInputElement;    
        const LR_rd_on_val = LR_rd_on.checked

        const LR_rd_public_on = document.getElementById('LR_rd-public-on') as HTMLInputElement;    
        const LR_rd_public_on_val = LR_rd_public_on.checked

        let LR_thumb_val = undefined;
        const LR_thumb = document.getElementById("LR_thumb") as HTMLInputElement;
        if(LR_thumb) {
            LR_thumb_val = LR_thumb.value;
        }

        let LR_script_val = undefined;
        const LR_script = document.getElementById("LR_script") as HTMLInputElement;
        if(LR_script) {
            LR_script_val = LR_script.value;
        }

        const brandId = props.brand.id;
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");

        const listFile: { url: string; thumbnail: string }[] = [];
        if(true || !props.selectedItem) {
            const _fileContents = fileContents as ILibraryFile[];
            const _filtered_contents = _fileContents.filter((item) => item.isDelete === false);
            logger.log("fileContents", _fileContents, _filtered_contents);

            _filtered_contents.map((item) => {
                if(item.url && item.file) {
                    // var md5 = require('md5');
                    // const _md5 = md5(item.file?.name);
                    // const fileExt = item.file?.name.split('.').pop();
                    // const filename = "upload/"+brandId+"/"+util.getDate().replaceAll("-", "")+"/"+_md5+"."+fileExt;

                    // const filepath = "upload/"+brandId+"/"+util.getDate().replaceAll(".", "")+"/";
                    // const filename = "upload/"+brandId+"/"+util.getDate().replaceAll(".", "")+"/"+item.file?.name;

                    let curriBookName = "";
                    let curriUnit1Name = "";
        
                    if(curriInfo) {
                        const LR_textbook_val = curriInfo.curriBook[LR_textbook.selectedIndex-1];
                        curriBookName = LR_textbook_val.val;
                        const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;            
                        if(LR_html_curriUnit1) {
                            const LR_html_curriUnit1_val = curriUnit1[LR_html_curriUnit1.selectedIndex-1];
                            curriUnit1Name = LR_html_curriUnit1_val.val;
                        }
                    }        
                    const filename = "CBS_Library_Multimedia/"+props.type+"/"+curriBookName+"/"+curriUnit1Name+"/"+item.file.name;

                    // util.handleS3Upload(item.file!, filename);
                    // util.S3Upload(item.file!, filepath);
    
                    const file = {
                        "url":filename,
                        "thumbnail":filename,
                        // "type":props.type,
                    }
                    listFile.push(file);
                }
            });

            if(!props.selectedItem && listFile.length === 0) {
                props.showModal("", "콘텐츠를 입력해 주세요.");
                return;
            }    
        }
        
        const listMetaMap : IMeta[] = [];

        listMetaMap.push(LR_school_val);
        listMetaMap.push(LR_subject_val);

        if(LR_level.selectedIndex > 0) {
            listMetaMap.push(LR_level_val);
        }

        const _studyMapList = studyMapList.filter((item) => item.id! > 0);
        _studyMapList.map((item) => {
            if(item.depth0) {
                listMetaMap.push(item.depth0);
            }
            if(item.depth1) {
                listMetaMap.push(item.depth1);
            }
            if(item.depth2) {
                listMetaMap.push(item.depth2);
            }
            if(item.depth3) {
                listMetaMap.push(item.depth3);
            }
            if(item.depth4) {
                listMetaMap.push(item.depth4);
            }
            if(item.depth5) {
                listMetaMap.push(item.depth5);
            }
            if(item.depth6) {
                listMetaMap.push(item.depth6);
            }
            
        });

        const data = {
            "id": props.selectedItem ? props.selectedItem.id : undefined,
            "brand_id": brandId,
            "type_1": LR_type1_val,
            "type_2": LR_type2_val, 
            // "type_3": LR_type3_val, 
            "name": LR_title_val, 
            "is_active": LR_rd_on_val, 
            "is_publicOpen": LR_rd_public_on_val, 
            "creator": props.selectedItem ? undefined : uid,
            "creator_name": props.selectedItem ? undefined : uname,
            "creator_id": props.selectedItem ? undefined : _id,
            "updater": props.selectedItem ? uid : undefined,
            "updater_name": props.selectedItem ? uname : undefined,
            "updater_id": props.selectedItem ? _id : undefined,
            "description": LR_description_val,
            "thumbnail": LR_thumb_val,
            // "used_in": LR_usedin_val,
            "curriBook": LR_textbook_val?.id,
            "curriUnit1": LR_html_curriUnit1_val ? LR_html_curriUnit1_val.id : undefined,
            "curriUnit2": LR_html_curriUnit2_val ? LR_html_curriUnit2_val.id : undefined,
            "curriUnit3": LR_html_curriUnit3_val ? LR_html_curriUnit3_val.id : undefined,
            "curriUnit4": LR_html_curriUnit4_val ? LR_html_curriUnit4_val.id : undefined,
            "curriUnit5": LR_html_curriUnit5_val ? LR_html_curriUnit5_val.id : undefined,
            "metaMap": listMetaMap, 
            "files": listFile, 
            // "files": props.selectedItem ? undefined : listFile, 
            "script_id": LR_script_val, 
            "studyMapListString": JSON.stringify(_studyMapList),
        };

        logger.log("onSave", data);
    
        _onSave(data, props.selectedItem ? "update" : "insert");
    }

    async function _onSave(data: any, type: string) {

        props.showLoading(true);

        const result = await util.mediaForSave(data, type);
        logger.log("_onSave", result);

        props.showLoading(false);

        props.showModal("", "저장이 완료 되었습니다.");

        props.OnClose();         
    }

    function onAddStudyMap() {
        const _studyMapList = JSON.parse(JSON.stringify(studyMapList));
        const _studyMap = {
            id: -1,
        }
        _studyMapList.push(_studyMap);
        logger.log("onAddStudyMap", _studyMapList);
        setStudyMapList(_studyMapList);
    }

    function onDelStudyMap(item: IStudyMap) {
        const _studyMapList = studyMapList;
        const newStudyMapList = _studyMapList.filter((e) => e !== item);
        logger.log("onDelStudyMap", newStudyMapList);
        setStudyMapList(newStudyMapList);
    }

    function onAddContents(): void {
        const _contents = fileContents as ILibraryFile[];
        _contents.push({
            type: "",
            file:undefined,
            url: "",
            thumbnail: "",
            isDelete: false,
            isUploaded: false,
        });
        setFileContents(_contents);
        setContentsReload(contentsReload + 1);
        logger.log("onAddContents", _contents);
    }

    function onDeleteContents(idx: number): void {
        logger.log("onDeleteContents", idx);
        const _contents = fileContents as ILibraryFile[];
        // _contents.splice(idx, 1);
        const _content = _contents[idx];
        _content.isDelete = true;
        setFileContents(_contents);
        setContentsReload(contentsReload + 1);
        logger.log("onDeleteContents", _contents);
    }

    async function onFileUpload() {

        const _contents = fileContents as ILibraryFile[];
		const _filtered_contents = _contents.filter((item) => item.isDelete === false);

        const listFile: { url: string; }[] = [];
        _filtered_contents.map(async (item) => {
            if(item.url) {
                const filename = "upload/"+item.file?.name
                const data = await util.handleS3Upload(item.file!, filename);
                logger.log("handleS3Upload", data.$metadata.httpStatusCode);

                const file = {
                    "url":filename,
                }
                listFile.push(file);
            }
        })
    }

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
  
        logger.log(e.target.files);

        if (!e.target.files || e.target.files.length === 0) {
            logger.log("select file!!!");
            return;
        }

        const _contents = fileContents as ILibraryFile[];
        const _content = _contents[idx];
        const _url = e.target.files![0];
        _content.file = _url;
        _content.url = _url.name;
        _content.type = _url.type;
        logger.log("handleChange", _content);
    
        setFileContents(_contents);
        setContentsReload(contentsReload + 1);

        // const reader = new FileReader();
        // reader.onload = (event) => {
        //     if(event.target) {
        //         // logger.log(event.target.result);
        //         (document.getElementById('LR_divOutput_'+idx) as HTMLImageElement).src = event.target.result as string;
        //     }
        // }
        // reader.readAsDataURL(_url);
      
        // handle the input...
    }
  
    function onEditMultiLang(): void {
        const LR_word = document.getElementById("LR_word") as HTMLInputElement;
        if(LR_word) {
            setDefaultLang(LR_word.value);
            setViewEditMultiLang(true);
        }
    }

    function onSearchScript() {
        setSearchType("텍스트");
        setViewSearchScript(true);
    }

    function onSelectObject(item: any) {
        logger.log("onSelectObject", item, searchType);

        if(searchType === "텍스트") {       
            setSearchedScript(item); 
            const LR_script = (document.getElementById("LR_script") as HTMLInputElement);
            LR_script.value = item.id;
        } else if(searchType === "이미지") {
            setSearchedThumb(item);
            const LR_thumb = (document.getElementById("LR_thumb") as HTMLInputElement);
            LR_thumb.value = item.url;
        } else {
            return;
        }

        setViewSearchScript(false);
    }

    function onRegThumb() {
        setSearchType("이미지");
        setViewSearchScript(true);
    }

    function onSelectStudyMap(item: IStudyMap) {
        const _studyMapList = JSON.parse(JSON.stringify(studyMapList));
        _studyMapList[selectedStudyMapId] = item;
        setStudyMapList(_studyMapList);
        logger.log("onSelectStudyMap", item, selectedStudyMapId, _studyMapList);

        setViewPopupSearchStudyMap(false);
    }

    function onMediaPreview(type: string, url: string) {
        setMediaType(type);
        setMediaUrl(url);
        setViewMediaPreview(true);
    }

    return (
        <div>

            <div>

            <h4>기본 정보 등록</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">{props.type} 관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">브랜드</th>
							<td className="text-left">{props.brand.name}</td>
						</tr>
						<tr>
							<th scope="row" className="w-56"><label htmlFor="sel-divi">구분<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">

                                <select className="mr-2" id="LR_type1" name="type1" onChange={(e) => onChangeType1(e)} disabled>
                                    {type1List && type1List.map((type1, idx1) => {
                                    return(
                                        <option disabled key={idx1} value={type1}>{type1}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_type2" name="type2" style={{display: type2List && type2List.length > 0 ? "" : "none"}} onChange={(e) => onChangeType2(e)}>
                                    {type2List && (type2List as string[]).map((type2, idx2) => {                                        
                                    return(
                                        <option key={idx2} value={type2} >{type2}</option>
                                    )})}
                                </select>
                                {props.type === "text" &&
                                <select className="mr-2" id="LS_type3" name="type3" onChange={(e) => onChangeType3(e)}>
                                    {type3TextList && (type3TextList as string[]).map((type3, idx3) => {
                                    return(
                                        <option key={idx3} value={type3}>{type3}</option>              
                                    )})}
                                </select>}

                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">KEY</th>
							<td className="text-left">{props.selectedItem ? props.selectedItem.id : ""}</td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">타이틀<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="LR_title" className="w-1/2" onChange={() => {}}/>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-class">학교 급<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_school" name="sel-school">
                                    {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-subject">교과목<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_subject" name="sel-subject">
                                    {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-textbook">교과<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_textbook" name="sel-textbook" onChange={(e) => onChangeCurriBook(e)}>
                                    <option value="0">교과</option>              
                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-unit">교과과정<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">

                                <select className="mr-2" id="LR_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                    <option value="0">2Depth</option>              
                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                    <option value="0">3Depth</option>              
                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                    {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                    <option value="0">4Depth</option>              
                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                    <option value="0">5Depth</option>              
                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>      

                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">작성자</th>
                            {props.selectedItem &&
                            <td className="text-left">{props.selectedItem.creator_name} ({props.selectedItem.creator})</td>}
							{!props.selectedItem &&
                            <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
						</tr>
                        <tr>
							<th scope="row" className="w-56">등록일</th>
                            {props.selectedItem &&
							<td className="text-left">{props.selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
							{!props.selectedItem &&
							<td className="text-left">{util.getDate()}</td>}
                            </tr>
                        <tr>
							<th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-on" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-on">사용</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-off" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-off">미사용</label>
                                    </span>
                                </div>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">공개 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-on" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-on">공개</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-off" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-off">비공개</label>
                                    </span>
                                </div>
                            </td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}

				<h4>데이터 구성</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">{props.type} 관리 테이블</caption>
					<tbody>
						<tr style={{display:"none"}}>
							<th scope="row" className="w-56">
                                <label htmlFor="sel-usedin" className="mr-0">Used In</label>
                            </th>
							<td className="text-left">
								<select id="LR_usedin" name="sel-usedin">

                                {listUsedIn && listUsedIn.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item}>{item}</option>              
                                    )})}
								</select>
							</td>
						</tr>

                        {/* 이미지, 오디오, 비디오 */}
						<tr style={{display:props.type !== "text"?"":"none"}}>
							<th scope="row">CONTENTS <em className="text-sky-500 align-top">*</em></th>
							<td className="p-0">
                                {contentsReload > 0 && fileContents && (fileContents as ILibraryFile[]).map((item, idx) => {
                                    let fileType = "이미지";
                                    let className = "icon-img block w-6 h-6 mx-3";
                                    if(props.type === "audio") {
                                        fileType = "오디오";
                                        className = "icon-audio block w-6 h-6 mx-3";
                                    } else if(props.type === "video") {
                                        fileType = "비디오";
                                        className = "icon-video block w-6 h-6 mx-3";
                                    }

                                    let bValid = false;

                                    const media_src = selectedItemUrl !== "" ? selectedItemUrl : item.url;
                                    if(media_src) {
                                        // bValid = util.existsUrl(S3Server + img_src);    
                                    }
                                    
                                    const fileLen = (fileContents as ILibraryFile[]).filter((item) => item.isDelete === false).length;
                                    bValid = item.url && item.url.length > 1 ? true : false;

                                return(
                                    <dl className="flex" key={idx} style={{display:item.isDelete?"none":""}}>
                                        <dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
                                            <span className="mr-0 font-semibold text-gray-900">{fileType}</span>
                                            {/* <div style={{display: props.selectedItem? "none" : ""}}>
                                                <button type="button" className="btn-white-s h-8 mr-2" onClick={onAddContents}>추가</button>;
                                                <button type="button" className="btn-white-s h-8" onClick={() => onDeleteContents(idx)} style={{display:fileLen>1?"":"none"}}>삭제</button>
                                            </div> */}
                                        </dt>
                                        <dd className="flex items-center text-left px-3">
                                            {/* <input type="text" className="w-80" onChange={() => {}} /> */}
                                            {item.isUploaded && <input className="w-[30rem]" name="file" type="text" readOnly value={media_src} />}
                                            {/* {!item.isUploaded && <input className="w-80" name="file" type="file" onChange={(e) => handleChange(e, idx)} />} */}
                                            <input className={!item.isUploaded ? "w-96" : "w-96 ml-3"} name="file" type="file" onChange={(e) => handleChange(e, idx)} /*style={{width: "4.5rem"}}*/ />

                                            {!item.isUploaded && <span className={className} style={{display:bValid?"none":""}}></span>}
                                            {/* {item.isUploaded && fileType === "이미지" && <img className="icon-img block w-6 h-6 mx-3" id={'LR_divOutput_'+idx} src={S3Server+img_src} style={{cursor:"pointer"}} onClick={() => onMediaPreview(props.type, S3Server+item.url)} />} */}
                                            {item.isUploaded && fileType === "이미지" && <span className="icon-img-on block w-6 h-6 mx-3" style={{cursor:"pointer"}} onClick={() => onMediaPreview(props.type, S3Server+media_src)}></span>}
                                            {item.isUploaded && fileType === "오디오" && <span className="icon-audio-on block w-6 h-6 mx-3" style={{cursor:"pointer"}} onClick={() => onMediaPreview(props.type, S3Server+media_src)}></span>}
                                            {item.isUploaded && fileType === "비디오" && <span className="icon-video-on block w-6 h-6 mx-3" style={{cursor:"pointer"}} onClick={() => onMediaPreview(props.type, S3Server+media_src)}></span>}
                                            {/* <button type="button" className="btn-sky-s" onClick={() => onFileUpload(item.url)} style={{display:(item.url === undefined || item.isUploaded)?"none":""}}>업로드</button> */}
                                        </dd>
                                    </dl>
                                )})}
								<dl className="flex border-gray-300 border-t">
									<dt className="flex h-12 px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">설명</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_description" type="text" className="w-[30rem]" placeholder="내용입력" />
									</dd>
								</dl>
                                {(props.type === "audio" || props.type === "video") && <>
								<dl className="flex border-gray-300 border-t" style={{height:"4rem"}}>
                                    <dt className="flex px-3 items-center justify-between w-56 text-left bg-slate-100">
                                        <span className="mr-0 font-semibold text-gray-900">썸네일 등록​</span>
                                    </dt>
                                    <dd className="flex items-center text-left px-3">
                                        <input id="LR_thumb" type="text" className="w-[30rem]" placeholder="내용입력" readOnly />
                                    </dd>
                                    <dd className="flex items-center text-right">
                                        {/* <span id="LR_thumb_icon" className="icon-img block w-6 h-6"></span> */}
                                        <button type="button" className="btn-sky" onClick={onRegThumb}>검색</button>
                                    </dd>
                                </dl>
                            
                                <dl className="flex border-gray-300 border-t" style={{height:"4rem"}}>
									<dt className="flex px-3 items-center justify-between w-56 text-left bg-slate-100">
										<span className="mr-0 font-semibold text-gray-900">자막 검색</span>
									</dt>
									<dd className="flex items-center text-left px-3">
										<input id="LR_script" type="text" className="w-[30rem]" placeholder="내용입력" readOnly />
									</dd>
									<dd className="flex items-center text-right">
                                        <button type="button" className="btn-sky" onClick={onSearchScript}>검색</button>
									</dd>
								</dl></>}
							</td>
						</tr>
                        {/* 이미지, 오디오, 비디오 */}

					</tbody>
				</table>
				{/* <!-- .//table -->	 */}

                {searchedScript && (searchedScript.dialog as IScript[]).length > 0 &&
				<table className="table-type1">
					<caption className="sr-only">텍스트 관리 테이블</caption>
                    <thead>
                        <tr>
                            <th scope="row" colSpan={searchedScript.type_2 === "일반형" ? 6 : 7}>SCRIPT</th>
                        </tr>
                        <tr>
                            {/* <th scope="row"><input className="h-4 w-4 rounded" type="checkbox" disabled /></th> */}
                            <th scope="row">문단<br/>Key</th>
                            <th scope="row">문장<br/>Key</th>
                            {/* {scriptType === "혼합형" &&
                            <th scope="row">양식</th>}                             */}
                            {searchedScript.type_2 !== "일반형" &&
                            <th scope="row">SPEAKER</th>}
                            <th scope="row">ENTRY</th>
                            <th scope="row">다국어</th>
                            <th scope="row">Start</th>
                            <th scope="row">End</th>
                            {/* <th scope="row">문단별<br/>문항key</th>
                            <th scope="row">문단​<br/>Summary</th> */}
                        </tr>
                    </thead>
					<tbody>

                        {searchedScript && (searchedScript.dialog as IScript[]).map((item, idx) => {
                            return(
                                <tr key={idx}>
                                    {/* <td>
                                        <input id={"script_ck_"+idx} name="script_ck" type="checkbox" className="h-4 w-4 rounded"/>
                                    </td> */}
                                    <td className="w-16">
                                        <span>{item.pkey}</span>
                                        {/* <input id={"pkey_"+idx} type="text" className="w-8" /> */}
                                    </td>
                                    <td className="w-16">
                                        <span>{item.skey}</span>
                                        {/* <span id={"skey_"+idx} className="w-8">{item.skey}</span> */}
                                    </td>
                                    {/* {scriptType === "혼합형" &&
                                    <td>
                                        <div className="radio-wrap">
                                            <span className="inp-radio">
                                                <input id={"rd_type1_"+idx} name={"rd_type_"+idx} type="radio" onChange={() => onChangeScriptRDType(idx)} />
                                                <label htmlFor="rd-mode4-1">대화형</label>
                                            </span>
                                            <span className="inp-radio">
                                                <input id={"rd_type2_"+idx} name={"rd_type_"+idx} type="radio" onChange={() => onChangeScriptRDType(idx)} />
                                                <label htmlFor="rd-mode4-2">일반형</label>
                                            </span>
                                        </div>
                                    </td>} */}
        
                                    {searchedScript.type_2 !== "일반형" &&
                                    <td className="w-36">
                                        <span>{item.speaker}</span>
                                        {/* <input id={"speaker_"+idx} type="text" className="" disabled={item.type === "일반형"} /> */}
                                    </td>}
                                    <td>
                                    <span>{item.entry}</span>
                                        {/* <input id={"entry_"+idx} type="text" className="" /> */}
                                    </td>
                                    <td>
                                        <span>{item.multiLang}</span>
                                        {/* <input id={"multiLang_"+idx} type="text" className=""  /> */}
                                    </td>
                                    <td className="w-24">
                                        <span>{item.startTime}</span>
                                        {/* <input id={"start_"+idx} type="text" className="w-16"  /> */}
                                    </td>
                                    <td className="w-24">
                                        <span>{item.endTime}</span>
                                        {/* <input id={"end_"+idx} type="text" className="w-16" /> */}
                                    </td>
                                    {/* <td>
                                        <input id={"akey_"+idx} type="text" className="" />
                                    </td>
                                    <td>
                                        <input id={"summary_"+idx} type="text" className="" />
                                    </td> */}
                                </tr>
                            );
                        })}
					</tbody>
				</table>}

                <h4 style={{display:"none"}}>추가 정보</h4>
				{/* <!-- table --> */}
				<table style={{display:"none"}}>
					<caption className="sr-only">{props.type} 관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">
                                <label htmlFor="sel-level" className="mr-0">난이도</label>
                            </th>
							<td className="text-left">
								<select id="LR_level" name="sel-level">
                                    <option value="0">난이도</option>              
                                    {curriInfo && curriInfo.difficulty && (curriInfo.difficulty as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
							</td>
						</tr>

                        {studyMapList && studyMapList.map((item, idx) => {

                        let _unitVal = "";
                        
                        const arr = [item.depth1, item.depth2, item.depth3, item.depth4, item.depth5, item.depth6];
                        arr.map((val, idx) => {
                            if(val) {
                                if(_unitVal === "") {
                                    _unitVal = val.val;    
                                } else {
                                    if(idx === 2) {
                                        _unitVal = _unitVal + " > " + "" + val.val;

                                    } else {
                                        _unitVal = _unitVal + " > " + val.val;
                                    }
                                }
                            }
                        });

                        return(

                        <tr key={idx}>
                            <th scope="row">
                                학습맵 {idx+1}<br />
                                {idx === 0 &&
                                <button type="button" className="btn-white-s h-8" onClick={onAddStudyMap}>추가</button>}
                                {idx !== 0 &&
                                <button type="button" className="btn-white-s h-8" onClick={() => onDelStudyMap(item)}>삭제</button>}
                            </th>
                            <td className="text-left">
                                <button type="button" className="btn-sky" onClick={() => {
                                    setSelectedStudyMapId(idx);
                                    setViewPopupSearchStudyMap(true);
                                }}>검색</button>
                                <span className="ml-2" dangerouslySetInnerHTML={{ __html: _unitVal }}></span>
                            </td>
                        </tr>                                
                        );
                        })}


					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
						<button type="button" className="btn-sky" onClick={onFileUploadForSave} >{props.selectedItem ? "수정" : "저장"}</button>
					</div>
				</div>

            </div>

            {fileUploading && <>
                <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="dim">
                        <CircularProgress size={40} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: `${-40/2}px`, marginLeft: `${-40/2}px` }} />
                    </div>
                </div>
            </>}

            {/* <PopupEditMultiLang view={viewEditMultiLang} default={defaultLang} onClose={function (): void {
                setDefaultLang("");
                setViewEditMultiLang(false);
            } } /> */}
            
            {viewSearchScript &&
            <PopupLibrarySearch brand={props.brand} searchType={searchType} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectObject} onClose={function (): void {
                setViewSearchScript(false);
            } } />}

            {viewPopupSearchStudyMap &&
            <PopupStudyMapSearch showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectStudyMap} onClose={function (): void {
                setViewPopupSearchStudyMap(false);
            } } />}

            {viewMediaPreview &&
            <PopupMediaPreview type={mediaType} url={mediaUrl} onClose={function (): void {
                setViewMediaPreview(false);
            } } />}

        </div>
    );
}
export default LibraryRegister;
