import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { HOST, IBrand, ICurriInfo, ILibrary, ILibrarySearchData, IMenu, IMeta, headers, isDev } from "../common";
import LibraryRegister from "./LibraryRegister";
import LibrarySearch from "./LibrarySearch";
import LibraryBulkRegister from "./LibraryBulkRegister";
import { logger } from '../logger';
import { dir } from "console";

interface ILibraryMedia {
    type: string;
    brand: IBrand;
    menu?: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function LibraryMedia(props: ILibraryMedia) {
    
    const [list, setList] = useState([] as any);
    const [selectedItem, setSelectedItem] = useState(null as any);
    const [viewList, setViewList] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isBulkEditing, setIsBulkEditing] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [textType3, setTextType3] = useState("");

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setViewList(true);
            setIsEditing(false);
            setIsBulkEditing(false);
            setSelectedItem(null);

            const _pageNum = localStorage.getItem("Library_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(_pageNum) {
                pagenum.value = _pageNum;
            } else {
                pagenum.value = "10";                
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.type) {
            logger.log("props.type", props);
            if(props.brand) {
                refreshList();
            }
        }
    }, [props.type])

    useEffect(() => {
        if(props.brand) {
            logger.log("props.brand", props);
            refreshList();
        }
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            setListOfPage([]);
            if(isEditing || isBulkEditing) {
                onEditClose();
            }
        }
    }, [props.menu])

    useEffect(() => {
        if(isEditing || isBulkEditing) {
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     setIsBulkEditing(false);
            // }
        } 

        if(!isEditing && !isBulkEditing) {
            
            logger.log("isEditing", isEditing);

            const _searchData = searchData;
            if(_searchData) {
                onSearch(_searchData);            
            }
        }

    }, [isEditing, isBulkEditing])

    const refreshList = () => {

        setIsEditing(false);
        setIsBulkEditing(false);
        setSelectedItem(null);

        setSearchData(null);
        setList([]);

        setCurrPage(1);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        setViewList(false);
        const list = await util.getLibrarySearchList(data);
        setList(list);
        logger.log("onSearch", data, list);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
            }
            calcPage(totalCnt, currentPage);
        }
        setViewList(true);

        props.showLoading(false);
    }

    function onSearch(data: any): void {
        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                onChangePage(_currPage);
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                onChangePage(_currPage);
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Library_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        logger.log("numOfPage", numOfPage);
        onSearch(_searchData);            
    }

    function onBulkAdd(): void {
        setIsBulkEditing(true);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onAdd(): void {
        setIsEditing(true);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onEditClose(): void {

        setIsEditing(false);
        setIsBulkEditing(false);
        setSelectedItem(null);

        // refreshList();
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }

    function onSave(): void {
    }

    function onChangePage(page: number) {
        
        const _searchData = searchData;
        _searchData.limit_page = page;
        onSearch(_searchData);
        
        logger.log("onChangePage", page, searchData, _searchData);

        setCurrPage(page);
    }
    
    function onChangeTextType3(val: string): void {
        setTextType3(val);
    }
    
    function onConfirm(item: ILibrary): void {
        setIsEditing(true);
        setSelectedItem(item);
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }
    
    return (
        <div>
            <div className="top-title">
                <h3>{common.leftmenu_2[0][currMenu.menu2]} {props.type === "text" && isEditing && <strong className="text-sky-500">({textType3 === "" ? "어휘" : textType3})</strong>}</h3>
            </div>

            <div style={{display: isEditing || isBulkEditing ? "none" : ""}}>
            
                {/* <!-- 검색영역 --> */}
                <LibrarySearch view={true} onSearch={onSearch} type={props.type} brand={props.brand} pageInfo={{ page: currPage, pageItemCnt: localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10 }} showLoading={props.showLoading} />
                {/* <!-- .//검색영역 --> */}

                <div className="mt-5 mb-3 text-right">
                    <label htmlFor="page-num">페이지당 표기 수</label>
                    <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </select>
                </div>

                {!isEditing && !isBulkEditing && viewList && <>

                {/* <!-- table --> */}
                <table>
                    <caption className="sr-only">{props.type} 관리 테이블</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="pl-4 pr-3">번호</th>
                            <th scope="col" className="px-3">구분</th>
                            <th scope="col" className="px-3">KEY</th>
                            <th scope="col" className="px-3">타이틀</th>
                            <th scope="col" className="px-3">교과</th>
                            {/* <th scope="col" className="px-3">교과과정</th> */}
                            <th scope="col" className="px-3">등록자</th>
                            <th scope="col" className="px-3">등록일</th>
                            <th scope="col" className="pl-3 pr-4">상세보기</th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewList && list &&
                        <tr style={{display: (list as ILibrary[]).length === 0 ? "" : "none"}}>
                            <td colSpan={8}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as ILibrary[]).map((item, idx) => {

                            let _unitVal = "";
                            let _curriBookVal = ""

                            if(curriInfo && curriInfo.curriBook) {
                                const curriBook = curriInfo.curriBook as IMeta[];
                                const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                                _curriBookVal = _curriBook ? _curriBook.val : "";

                            //     const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                            //     const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                            //     const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                            //     const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                            //     const curriUnit5 = curriInfo.curriUnit5 as IMeta[];
    
                            //     const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === item.curriUnit1);
                            //     const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === item.curriUnit2);
                            //     const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === item.curriUnit3);
                            //     const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === item.curriUnit4);
                            //     const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === item.curriUnit5);
    
                            //     const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                            //     arr.map((val, idx) => {
                            //         if(val) {
                            //             if(_unitVal === "") {
                            //                 _unitVal = val;    
                            //             } else {
                            //                 if(idx === 2) {
                            //                     _unitVal = _unitVal + " > " + "<br/>" + val;

                            //                 } else {
                            //                     _unitVal = _unitVal + " > " + val;
                            //                 }
                            //             }
                            //         }
                            //     });
                            }

                            const totalCnt = item.full_count;
                            const _numOfPage = localStorage.getItem("Library_numOfPage") ? Number(localStorage.getItem("Library_numOfPage")) : 10;
                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                            // logger.log(totalCnt, _numOfPage, currPage, _num);

                            let type_1 = "";
                            if(item.type_1 === "image") {
                                type_1 = "이미지";
                            } else if(item.type_1 === "audio") {
                                type_1 = "오디오";
                            } else if(item.type_1 === "video") {
                                type_1 = "비디오";
                            } else if(item.type_1 === "text") {
                                type_1 = "텍스트";
                            }

                            let type = type_1;
                            if(item.type_2) {
                                type = type + ' > ' + item.type_2;
                            }

                            let title = item.name;
                            if(title && title.length > 50) {
                                title = title.substring(0, 47) + "...";
                            }

                            return (
                                // <div key={idx}><img src={item.url} width={40}/>{JSON.stringify(item)}</div>
                                <tr key={idx}>    
                                    <td>{_num}</td>
                                    <td>{type}</td>
                                    <td>{item.id}</td>
                                    <td>{title}</td>
                                    <td>{_curriBookVal}</td>
                                    {/* <td dangerouslySetInnerHTML={{ __html: _unitVal }}></td> */}
                                    <td>{item.creator_name} ({item.creator})</td>
                                    <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                    <td>
                                        <button type="button" className="btn-white-s" onClick={() => onConfirm(item)}>확인</button>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                {/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    <div className="mt-3 text-right">
                        {/* <button type="button" className="btn-sky mr-2" onClick={onBulkAdd}>일괄등록</button> */}
                        <button type="button" className="btn-sky" onClick={onAdd}>등록</button>
                    </div>

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div></>}

            </div>

            {isEditing && <LibraryRegister OnClose={onEditClose} type={props.type} brand={props.brand} onChangeTextType3={onChangeTextType3} selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} />}
            {isBulkEditing && <LibraryBulkRegister OnClose={onEditClose} type={props.type} brand={props.brand} showModal={props.showModal} showLoading={props.showLoading} />}

        </div>
    );  
}
export default LibraryMedia;
