import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, IMenu, IMeta, ITextBook, ITextBookIndex, ITextBookTab, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";

import { ThemeProvider, CssBaseline } from "@mui/material";
import { Tree, MultiBackend, getBackendOptions, DndProvider, NodeModel, TreeMethods } from "@minoru/react-dnd-treeview";
import { CustomNode } from "./DND/CustomNode";
import { CustomDragPreview } from "./DND/CustomDragPreview";
import { Placeholder } from "./DND/Placeholder";
import { theme } from "./DND/theme";
import styles from "./DND/style.module.css";
import { DragSourceMonitor } from "react-dnd/dist/types/monitors";
import PopupContentsList from "./PopupContentsList";
import { log } from "console";
import PopupAddCurriUnit from "./PopupAddCurriUnit";
import PopupConfirm from "../contents/PopupConfirm";
import PopupIndexName from "./PopupIndexName";

export interface NodeData {
    key: number;
    is_active: boolean;
    is_addContents: boolean;
    is_delete: boolean;
    depth: number;
    child_depth: number;
    tabIndex: number;
    startPage: number;
    endPage: number;
    curriUnitList: string;
    studyMapList: string;
    studyMapValList: string;
    indexName: string;
};

interface ITextBookIndexEdit {
    brand: IBrand;
    menu: IMenu;
    textbook: ITextBook;
    textbookIndexId: number | undefined;
    // listItem: NodeModel[];
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
    onClose: (textbookIndex: ITextBookIndex | null) => void;    
}
  
function TextBookIndexEdit(props: ITextBookIndexEdit) {
        
    const [treeData, setTreeData] = useState<NodeModel[]>([]);
    const handleDrop = (newTree: NodeModel[]) => setTreeData(newTree);

    const [depth1cnt, setDepth1cnt] = useState(0);
    const [depth2cnt, setDepth2cnt] = useState(0);
    const [depth3cnt, setDepth3cnt] = useState(0);
    const [depth4cnt, setDepth4cnt] = useState(0);
    const [depth5cnt, setDepth5cnt] = useState(0);

    const [lastUpdateDate, setLastUpdateDate] = useState("");
    const [maxDepth, setMaxDepth] = useState(5);
    const [showViewPopup, setShowViewPopup] = useState(false);

    // const [selectedItem, setSelectedItem] = useState(null as any);
    const [selectedNode, setSelectedNode] = useState(null as any);
    const [indexData, setIndexData] = useState(null as any);
    const [textBookIndexId, setTextBookIndexId] = useState(null as any);

    const [viewPopupAddCurriUnit, setViewPopupAddCurriUnit] = useState(false);
    const [curriBook, setCurriBook] = useState(null as any);
    const [viewPopupConfirm, setViewPopupConfirm] = useState(false);
    const [delDescendant, setDelDescendant] = useState<NodeModel[]>([]);

    const [textbook, setTextbook] = useState<ITextBook>(null as any);

    const [viewPopupIndexName, setViewPopupIndexName] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            logger.log("props", props);

            const _textbook = props.textbook;
            setTextbook(_textbook);

            const textbookIndex_id = props.textbookIndexId
            if(textbookIndex_id) {
                setTextBookIndexId(textbookIndex_id);
                getTextBookIndexInfo(textbookIndex_id);
            } else {
                if(_textbook && _textbook.textbookIndex_id) {
                    setTextBookIndexId(_textbook.textbookIndex_id);
                    getTextBookIndexInfo(_textbook.textbookIndex_id);
                } else {
                    if(_textbook.curriBook) {
                        const curriBook = curriInfo?.curriBook.find((item) => item.id === _textbook.curriBook);
                        setCurriBook(curriBook);    
                    }        
                    init(null);
                }                   
            }
        }
        bDone = true;
    }, [])

    useEffect(() => {
        logger.log("treeData", treeData);
    }, [treeData])

    async function getTextBookIndexInfo(textbookIndex_id: number) {

        props.showLoading(true);

        if(textbookIndex_id) {
            const data = {
                // "detail":true,
                "id": textbookIndex_id,
            }
            const result = await util.getTextbookIndexInfo(data);    
            logger.log("getTextBookIndexInfo", result);
            if(result) {
                setIndexData(result);
                const _result = result as ITextBookIndex;
                setCurriBook(_result.curriBook);
                init(result);    
            }
        } else {
            init(null);    
        }
        props.showLoading(false);
    }

    async function getTextbookTabList(textbookIndex_id: number, key_id: number) {

        props.showLoading(true);

        const data = {
            "textbookIndex_id": textbookIndex_id,
            "textbookCurriculum_key": key_id,
        }
        const result = await util.getTextbookTabList(data);    

        props.showLoading(false);

        return result;
    }

    function init(textbookindex: common.ITextBookIndex | null) {

        let nodeModel : NodeModel[] = [];

        logger.log("textbookindex", textbookindex);

        if(textbookindex) {
            (document.getElementById("CE_is_active") as HTMLInputElement).checked = textbookindex.is_active;
            (document.getElementById("Contents_name") as HTMLInputElement).value = textbookindex.name;
            nodeModel = makeTree(textbookindex);

            if(textbookindex.curriBook) {
                const curriBook = curriInfo?.curriBook.find((item) => item.id === textbookindex.curriBook);
                setCurriBook(curriBook);    
            }
        } else {
            (document.getElementById("CE_is_active") as HTMLInputElement).checked = true;
            (document.getElementById("Contents_name") as HTMLInputElement).value = props.textbook.name ? props.textbook.name : "";    
        }
        
        if(nodeModel.length === 0) {
            for (let i = 0; i < 3; i++) {
                const data : NodeData = {
                    key: i + 1,
                    is_active: true,
                    is_addContents: true,
                    is_delete: false,
                    depth: 0,
                    child_depth: 0,
                    tabIndex: 0,
                    startPage: 0,
                    endPage: 0,
                    curriUnitList: "",
                    studyMapList: "",
                    studyMapValList: "",
                    indexName: "",
                }
                const nodeData : NodeModel = {
                    id: i+1,
                    parent: 0,
                    text: "",
                    droppable: true,
                    data: data,
                }    
                nodeModel.push(nodeData);
            }
        }
        setTreeData(nodeModel);    

        setTimeout(() => {
            handleOpenAll();            
        }, 100);
        
        let lastUpdateDate = "";
        if(textbookindex && textbookindex.updatedate) {
            lastUpdateDate = textbookindex.updatedate?.substring(0, 10).replaceAll("-", ".")
        } else {
            if(textbookindex && textbookindex.regdate) {
                lastUpdateDate = textbookindex.regdate?.substring(0, 10).replaceAll("-", ".")
            } else {
                lastUpdateDate = util.getDate();
            }
        }
        setLastUpdateDate(lastUpdateDate);
        const depth = textbookindex?.max_depth;
        if(depth) {
            setMaxDepth(depth);   
        }
    }

    function makeTree (item: any) : NodeModel[] {
        const nodeModel : NodeModel[] = [];
        if(item.curriculumList && item.curriculumList.length > 10) {
            const arr = JSON.parse(item.curriculumList) as any[];
            if(arr && arr.length > 0) {
                arr.map((item) => {

                    const data : NodeModel = {
                        id: item.key,
                        parent: item.parent,
                        text: item.text,
                        droppable: item.droppable,
                        data: item.data,
                    }
                    nodeModel.push(data);
                })
                logger.log("curriculumList", nodeModel);
            }    
        }
        return nodeModel;
    }
    
    function onEnterPress(id: number) {
        const next = document.getElementById("nodeVal_"+(id+1)) as HTMLInputElement;
        if(next) {
            next.focus();
        } else {
            (document.getElementById("nodeVal_1") as HTMLInputElement).focus();
        }
    }

    function onSetup(node: NodeModel) {
        // logger.log(node);

        setSelectedNode(node);
        setViewPopupAddCurriUnit(true);

        logger.log("onSetup", node, treeData);    
    }

    let descendant : NodeModel[] = [];
    function getChildren(id: number) {
        const children = treeData.filter((node) => node.parent === id);
        children.map((child) => {
            descendant.push(child);
            getChildren(Number(child.id));
        });
        // return children;
    }

    function onSaveAddCurriUnit(units: any, studyMaps: any, studyMapVals: any, chk_all_children: boolean) {    
    
        onClosePopup();

        const _selectedNode = selectedNode as NodeModel;

        if(chk_all_children) {
            
            descendant = [_selectedNode];
            getChildren(Number(_selectedNode.id));
            logger.log("descendant", descendant);

            descendant.map((_node) => {
                const data = treeData.find((item) => item.id === _node.id);

                if(data) {
                    const nodeData = (data.data as NodeData);
                    if(nodeData) {
                        nodeData.curriUnitList = JSON.stringify(units);
                        nodeData.studyMapList = JSON.stringify(studyMaps);
                        nodeData.studyMapValList = JSON.stringify(studyMapVals);
                    }
                }        
            });
            setTreeData(treeData);
            logger.log("onSaveAddCurriUnit", units, studyMaps, treeData);    

        } else {
            const data = treeData.find((item) => item.id === _selectedNode.id);

            if(data) {
                const nodeData = (data.data as NodeData);
                if(nodeData) {
                    nodeData.curriUnitList = JSON.stringify(units);
                    nodeData.studyMapList = JSON.stringify(studyMaps);
                    nodeData.studyMapValList = JSON.stringify(studyMapVals);
                }
                setTreeData(treeData);
                logger.log("onSaveAddCurriUnit", units, studyMaps, treeData);    
            }    
        }
    }

    async function onDelDescendant() {

        setViewPopupConfirm(false);

        const _delDescendant = JSON.parse(JSON.stringify(delDescendant));

        // logger.log("onDelDescendant1", _delDescendant);

        if(_delDescendant) {
            for (let i = 0; i < (_delDescendant as NodeModel[]).length; i++) {
                const node = (_delDescendant as NodeModel[])[i];                
                const tabInfo = await getTextbookTabList(textBookIndexId, Number(node.id));

                logger.log("onDelDescendant2", node, tabInfo);

                if(tabInfo && tabInfo.length > 0) {
                    for (let j = 0; j < tabInfo.length; j++) {
                        const tab = tabInfo[j];
                        if(tab.set_id && tab.set_id > 0) {
                            props.showModal("", "세트지가 존재합니다.\n삭제할 수 없습니다.");
                            // setViewPopupConfirm(false);
                            return;
                        }                        
                    }
                }    
            }

            let _treeData = JSON.parse(JSON.stringify(treeData)) as NodeModel[];
            for (let i = 0; i < (_delDescendant as NodeModel[]).length; i++) {
                const node = (_delDescendant as NodeModel[])[i];                
                _treeData = _treeData.filter((item) => item.id !== node.id);
            }
            setTreeData(_treeData);    
        }
    }
    
    async function onDel(node: NodeModel) {

        logger.log(node);

        if(Number(node.id) > 0) {

            descendant = [node];
            getChildren(Number(node.id));
            if(descendant && descendant.length > 1) {
                setDelDescendant(descendant);
                setViewPopupConfirm(true);

                logger.log("onDel", descendant);

                // props.showModal("", "하위목차가 존재합니다.\n삭제할 수 없습니다.");
                return;
            }
        
            if(textBookIndexId) {
                const tabInfo = await getTextbookTabList(textBookIndexId, Number(node.id));
                if(tabInfo && tabInfo.length > 0) {
                    for (let j = 0; j < tabInfo.length; j++) {
                        const tab = tabInfo[j];
                        if(tab.set_id && tab.set_id > 0) {
                            props.showModal("", "세트지가 존재합니다.\n삭제할 수 없습니다.");
                            return;
                        }                        
                    }
                }    
            }

            const _treeData = treeData.filter((item) => item.id !== node.id);
            setTreeData(_treeData);

            // const data = treeData.find((item) => item.id === node.id);
            // if(data) {
            //     const nodeData = (data.data as NodeData);
            //     if(nodeData) {
            //         nodeData.is_delete = true;
            //     }
            //     setTreeData(treeData);
            //     logger.log("onDel", treeData);    
            // }    
            
            // logger.log("tabInfo", tabInfo);
        }
    }

    function onAddNode(node: NodeModel) {
        
        logger.log("onAddNode", node);

        if(node.data as NodeData) {
            const depth = (node.data as NodeData).depth;
            if(depth > maxDepth-2) {
                props.showModal("", "최대 Depth 입니다.");
                return;
            }
            if(depth > 2) {
                props.showModal("", "_최대 Depth 입니다.");
                return;
            }
        }

        const _treeData = treeData as NodeModel[];
        // const obj = _treeData.find((item) => item.id === node.id);
        // if(obj) {
        //     obj.droppable = true;
        // }

        let newId = 1;
        const befortSort = JSON.parse(JSON.stringify(treeData)) as NodeModel[];
        const _sortedlist = befortSort.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        if(_sortedlist.length > 0) {
            logger.log("_sortedlist", _sortedlist, _sortedlist[0].id);
            newId = Number(_sortedlist[0].id) + 1;                
        }
        // _treeData.length+1

        const data : NodeData = {
            key: newId,
            is_active: true,
            is_addContents: true,
            is_delete: false,
            depth: 0,
            child_depth: 0,
            tabIndex: 0,
            startPage: 0,
            endPage: 0,
            curriUnitList: "",
            studyMapList: "",
            studyMapValList: "",
            indexName: "",
        }
        const newNodeData : NodeModel = {
            id: newId,
            parent: node.id,
            text: "",
            droppable: true,
            data: data,
        }
        _treeData.push(newNodeData);

        logger.log("onAddNode", _treeData);

        setTreeData(_treeData);   
        
        handleClose(Number(node.id));
        setTimeout(() => {
            handleOpen(Number(node.id));            
        }, 10);
    }

    function onChangeStartPage(id: number, val: string) {
        
        // logger.log("onChangeStartPage", id, val);
                
        // let regMatch = /^[0-9\b]+$/.test(val);
        // if(val !== "" && !regMatch) {
        //     props.showModal("", "숫자만 입력 가능합니다.");
        //     return;
        // }

        const data = treeData.find((item) => item.id === id);

        if(data) {
            const nodeData = (data.data as NodeData);
            if(nodeData) {
                nodeData.startPage = Number(val);
            }
            setTreeData(treeData);
        }
    }

    function onChangeEndPage(id: number, val: string) {
        
        // let regMatch = /^[0-9\b]+$/.test(val);
        // if(val !== "" && !regMatch) {
        //     props.showModal("", "숫자만 입력 가능합니다.");
        //     return;
        // }

        const data = treeData.find((item) => item.id === id);

        if(data) {
            const nodeData = (data.data as NodeData);
            if(nodeData) {
                nodeData.endPage = Number(val);
            }
            setTreeData(treeData);
        }
    }

    function onChangeText(id: number, val: string) {

        const data = treeData.find((item) => item.id === id);
        if(data) {
            data.text = val;
            setTreeData(treeData);
        }
    }

    function onChangeUsage(id: number, is_active: boolean) {

        childrenNode = [];
        getChildrenNode(id);
        const data = treeData.find((item) => item.id === id);
        if(data) {
            childrenNode.push(data);
            childrenNode.map((item) => {
                (item.data as NodeData).is_active = is_active;
            });
            setTreeData(treeData);
        }
        // logger.log("childrenNode", childrenNode, treeData);

        childrenNode.map((child) => {
            const usage = document.getElementById("chk_"+child.id) as HTMLInputElement;
            usage.checked = is_active;
        });
    }

    function onChangeAddContents(id: number, is_addContents: boolean) {

        const data = treeData.find((item) => item.id === id);
        if(data) {
            (data.data as NodeData).is_addContents = is_addContents;
            setTreeData(treeData);
        }
    }

    function onChangeNode(id: number, depth: number) {

        let depth1cnt = 0;
        let depth2cnt = 0;
        let depth3cnt = 0;
        let depth4cnt = 0;
        let depth5cnt = 0;

        treeData.map((item) => {
            if((item.data as NodeData).depth === 0) {
                depth1cnt = depth1cnt + 1;
            } else if((item.data as NodeData).depth === 1) {
                depth2cnt = depth2cnt + 1;
            } else if((item.data as NodeData).depth === 2) {
                depth3cnt = depth3cnt + 1;
            } else if((item.data as NodeData).depth === 3) {
                depth4cnt = depth4cnt + 1;
            } else if((item.data as NodeData).depth === 4) {
                depth5cnt = depth5cnt + 1;
            }
        });

        setTimeout(() => {
            setDepth1cnt(depth1cnt);
            setDepth2cnt(depth2cnt);
            setDepth3cnt(depth3cnt);
            setDepth4cnt(depth4cnt);
            setDepth5cnt(depth5cnt);                
        }, 100);

        const data = treeData.find((item) => item.id === id);
        if(data) {
            (data.data as NodeData).depth = depth;
            // logger.log("onChangeDepth", treeData);

            (treeData as NodeModel[]).map((ori) => {
                const children1 = (treeData as NodeModel[]).filter((item) => item.parent === ori.id);
                if(children1.length === 0) {
                    // logger.log(ori, "0");
                    const node = treeData.find((_item) => _item.id === ori.id);
                    if(node) {
                        (node.data as NodeData).child_depth = 0;
                    }            
                }
                children1.map((item2) => {
                    const children2 = (treeData as NodeModel[]).filter((item) => item.parent === item2.id);
                        if(children2.length === 0) {
                            // logger.log(ori, "1");
                            const node = treeData.find((_item) => _item.id === ori.id);
                            if(node) {
                                (node.data as NodeData).child_depth = 1;
                            }            
                        }
                        children2.map((item3) => {
                            const children3 = (treeData as NodeModel[]).filter((item) => item.parent === item3.id);
                            if(children3.length === 0) {
                                // logger.log(ori, "2");
                                const node = treeData.find((_item) => _item.id === ori.id);
                                if(node) {
                                    (node.data as NodeData).child_depth = 2;
                                }            
                            }
                            children3.map((item4) => {
                                const children4 = (treeData as NodeModel[]).filter((item) => item.parent === item4.id);
                                if(children4.length === 0) {
                                    // logger.log(ori, "3");
                                    const node = treeData.find((_item) => _item.id === ori.id);
                                    if(node) {
                                        (node.data as NodeData).child_depth = 3;
                                    }            
                                }
                                children4.map((item5) => {
                                    const children5 = (treeData as NodeModel[]).filter((item) => item.parent === item5.id);
                                    if(children5.length === 0) {
                                        // logger.log(ori, "4");
                                        const node = treeData.find((_item) => _item.id === ori.id);
                                        if(node) {
                                            (node.data as NodeData).child_depth = 4;
                                        }            
                                    }
                                    children5.map((item6) => {
                                        const children6 = (treeData as NodeModel[]).filter((item) => item.parent === item6.id);
                                        if(children6.length === 0) {
                                            // logger.log(ori, "5");
                                            const node = treeData.find((_item) => _item.id === ori.id);
                                            if(node) {
                                                (node.data as NodeData).child_depth = 5;
                                            }            
                                        }
                                    })        
                                })        
                            })        
                    })    
                })
            });
            // setTimeout(() => {
            //     setTreeData(treeData);                
            // }, 100);
        }
    }

    let childrenNode : NodeModel[] = [];
    function getChildrenNode(nid: number) {

        const _treeNode = (treeData as NodeModel[]);

        const children = _treeNode.filter((item) => item.parent === nid);
        if(children.length > 0) {
            children.map((child) => {
                childrenNode.push(child);
                getChildrenNode(Number(child.id));
            });    
        }
    }

    async function onEditIndexName(node: NodeModel) {
        logger.log(node);
        setSelectedNode(node);
        setViewPopupIndexName(true);
    }

    function onSaveIndexName(name: string) {

        setViewPopupIndexName(false);
        
        logger.log("onSaveIndexName", name);

        if(!selectedNode) {
            return;
        }

        const data = treeData.find((item) => item.id === selectedNode.id);

        if(data) {
            const nodeData = (data.data as NodeData);
            if(nodeData) {
                nodeData.indexName = name;
            }
        }        

        setTreeData(treeData);
    }

    function onSave() {

        handleOpenAll();

        setTimeout(async () => {

            const _newTree = [];

            const arrVal = document.getElementsByClassName("orderVal") as HTMLCollectionOf<HTMLElement>;
            for (let i = 0; i < arrVal.length; i++) {
                const item = arrVal[i];            
                const data = treeData.find((_item) => _item.id === Number(item.innerHTML));
                if(data) {
                    const _data = data.data as NodeData;
                    _data.tabIndex = i + 1;

                    const newData = {
                        key: Number(data.id),
                        parent: data.parent,
                        text: data.text,
                        droppable: data.droppable,
                        data: data.data,
                    }
                    _newTree.push(newData);    
                }
            }
            logger.log("onSave", _newTree);  
    
            const brand = util.getCurrentBrand();
            if(!brand || !brand.id) {
                return;
            }
    
            const is_active = (document.getElementById("CE_is_active") as HTMLInputElement).checked;
            const name = (document.getElementById("Contents_name") as HTMLInputElement).value;

            const _id = util.getUserInfo("id");
            const uid = util.getUserInfo("uid");
            const uname = util.getUserInfo("name");

            const data : ITextBookIndex = {

                id: textBookIndexId ? textBookIndexId : undefined,
                max_depth: textBookIndexId ? indexData.max_depth : 5,
                name: name,
                is_publicOpen: true,
                is_active: is_active,
                brand_id: brand.id,
                
                curriculumList: JSON.stringify(_newTree),

                creator: textBookIndexId ? undefined : uid,
                creator_id: textBookIndexId ? undefined : _id,
                creator_name: textBookIndexId ? undefined : uname,
                updater: !textBookIndexId ? undefined : uid,
                updater_id: !textBookIndexId ? undefined : _id,
                updater_name: !textBookIndexId ? undefined : uname,

            }    

            const saveType = textBookIndexId ? "update" : "insert";
            const textbook_id = props.textbook && props.textbook.id ? props.textbook.id : -1;
    
            props.showLoading(true);
            const result = await util.textbookIndexForSave(textbook_id, data, saveType);            
            logger.log("result", result, data, JSON.stringify(data).length);
            props.showLoading(false);

            // props.showModal("", "저장이 완료 되었습니다.");
            if(saveType === "insert") {
                onClose(result);
            } else {
                onClose(data);
            }

        }, 100);
    }

    const ref = useRef(null);
    const handleRef = useRef(null);

    const handleOpenAll = () => {
        if(ref && ref.current) {
            (ref.current as TreeMethods).openAll();            
        }
    }
    const handleCloseAll = () => {
        if(ref && ref.current) {
            (ref.current as TreeMethods).closeAll();            
        }
    }
    // open /close method can be passed a node ID or an array of node IDs
    const handleOpen = (nodeId: number) => {
        if(ref && ref.current) {
            logger.log("handleOpen", nodeId);
            (ref.current as TreeMethods).open(nodeId);            
        }
    }
    const handleClose = (nodeId: number) => {
        if(ref && ref.current) {
            logger.log("handleClose", nodeId);
            (ref.current as TreeMethods).close(nodeId);            
        }
    }

    function onClosePopup() {
        setShowViewPopup(false);
        setViewPopupAddCurriUnit(false);
    }

    function onRequestIndex(item: ITextBookIndex) {
        
        logger.log("onRequestIndex", item, indexData);

        let _indexData = indexData;

        if(_indexData) {
            _indexData.curriculumList = item.curriculumList;
        } else {
            _indexData = {  
                name: item.name + "(불러오기)",   
                is_active: true,
                max_depth: item.max_depth,
                curriculumList: item.curriculumList,
            };

            (document.getElementById("CE_is_active") as HTMLInputElement).checked = _indexData.is_active;
            (document.getElementById("Contents_name") as HTMLInputElement).value = _indexData.name;    
        }

        setIndexData(_indexData);

        init(_indexData);
        setShowViewPopup(false);    
    }
    
    function onSaveAddListDetail(item: any) {
    }

    function onClose(data: ITextBookIndex | null = null) {
        props.onClose(data);
    }

    return (
        <div>               
            <div className="top-title">
                <h3>목차 등록(수정)</h3>
                <p onClick={() => {}}>{"교과서 목록 > 목차 관리 > 목차 등록"}</p>
            </div> 

            <div className="text-right">
                    <button type="button" className="btn-white" onClick={() => {
                        setShowViewPopup(true);
                    }}>불러오기</button>
            </div>
            <div className="mt-3 mb-5 px-8 py-5 border border-gray-300 rounded-xl">
                <table>
                    <tbody>
                        <tr>
                            <th scope="col" className="w-56"><label htmlFor="inp-contents-list">목차 제목</label></th>
                            <td>
                                <input type="text" id="Contents_name" className="w-full" />
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" className="w-56">목차 현황</th>
                            <td className="text-left text-slate-950 text-sm">
                                {maxDepth > 0 && <span>1Depth <strong className="text-sky-600">{depth1cnt}</strong></span>}
                                {maxDepth > 1 && <span className="ml-2">2Depth <strong className="text-sky-600">{depth2cnt}</strong></span>}
                                {maxDepth > 2 && <span className="ml-2">3Depth <strong className="text-sky-600">{depth3cnt}</strong></span>}
                                {maxDepth > 3 && <span className="ml-2">4Depth <strong className="text-sky-600">{depth4cnt}</strong></span>}
                                {/* {maxDepth > 4 && <span className="ml-2">5Depth <strong className="text-sky-600">{depth5cnt}</strong></span>} */}
                            </td>
                        </tr>
                        <tr>
                            <th scope="col" className="w-56">사용 여부</th>
                            <td className="text-left text-slate-950 text-sm">
                                <div className="radio-wrap">
                                    <span className="inp-radio text-sky-500">
                                        <input id="CE_is_active" name="rd-use" type="radio" />
                                        <label htmlFor="rd-on">사용</label>
                                    </span>
                                    <span className="inp-radio text-red-600">
                                        <input id="CE_non_active" name="rd-use" type="radio" />
                                        <label htmlFor="rd-off">미사용</label>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span className="block mt-2 text-right text-sm">{lastUpdateDate}(최종수정)</span>
            </div>

            <div className="mb-5 text-right">
                <button type="button" className="btn-white" onClick={() => {

                    const node : NodeModel = {
                        id: 0,
                        parent: 0,
                        text: "",
                        droppable: true,
                        data: undefined,
                    }    

                    onAddNode(node);

                }}>목차 추가</button>
            </div>

            <table>
                <caption className="sr-only">목차 수정</caption>
                <thead>
                    <tr>
                        <th scope="col" colSpan={2} className="w-avail">교과과정 정보</th>
                        <th scope="col" className="w-[80px]" style={{borderLeftWidth:"1px"}}>하위Depth</th>
                        <th scope="col" className="w-36" style={{borderLeftWidth:"1px"}}>PDF페이지</th>
                        <th scope="col" className="w-[80px]" style={{borderLeftWidth:"1px"}}>사용</th>
                        <th scope="col" className="w-[80px]" style={{borderLeftWidth:"1px"}}>하위목차</th>
                        <th scope="col" className="w-[80px]" style={{borderLeftWidth:"1px"}}>교과과정​<br/>맵핑</th>
                        <th scope="col" className="w-[80px]" style={{borderLeftWidth:"1px"}}>목차명</th>
                        <th scope="col" className="w-[100px]" style={{borderLeftWidth:"1px"}}>콘텐츠등록</th>
                        <th scope="col" className="w-[80px]" style={{borderLeftWidth:"1px"}}>삭제</th>
                        <th scope="col" className="w-16" style={{paddingLeft:"10px", borderLeftWidth:"1px"}}>정렬</th>
                    </tr>
                </thead>
            </table>

            <ThemeProvider theme={theme}>
                <CssBaseline />
                <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                    <div className={styles.app}>

                        <Tree
                            ref={ref}
                            // tree={treeData.filter((data) => (data.data as NodeData).is_delete === false)}
                            tree={treeData}
                            rootId={0}
                            sort={false}
                            insertDroppableFirst={false}
                            dropTargetOffset={10}
                            canDrop={(tree, { dragSource, dropTargetId }) => {
                                if (dragSource?.parent === dropTargetId) {
                                    return true;
                                }
                            }}
                            render={(node, options) => {
                                // logger.log("render", node, options);
                                onChangeNode(Number(node.id), options.depth);
                                return(
                                    <CustomNode node={node} {...options} isPublished={textbook.deployStatus === 1}
                                    onChangeText={onChangeText} onChangeStartPage={onChangeStartPage} onChangeEndPage={onChangeEndPage} onChangeUsage={onChangeUsage}                                     
                                    onAddNode={onAddNode} onEnterPress={onEnterPress} onSetup={onSetup} onChangeAddContents={onChangeAddContents} onDel={onDel} 
                                    onEditIndexName={onEditIndexName} showModal={props.showModal} />            
                                )}
                            }

                            dragPreviewRender={(monitorProps) => (
                                <CustomDragPreview monitorProps={monitorProps} />
                            )}
                            placeholderRender={(node, { depth }) => (
                                <Placeholder node={node} depth={depth} />
                            )}
                            onDrop={handleDrop}
                            classes={{
                                root: styles.treeRoot,
                                draggingSource: styles.draggingSource,
                                placeholder: styles.placeholderContainer
                            }}
                            initialOpen={true}
                        />

                    </div>
                </DndProvider>
            </ThemeProvider>

            <div className="flex justify-between mt-5">
                <button type="button" className="btn-white" onClick={() => onClose()}>닫기</button>
                <button type="button" className="btn-sky" onClick={onSave}>저장</button>
            </div>

            {showViewPopup && <PopupContentsList onClose={onClosePopup} showModal={props.showModal} showLoading={props.showLoading} onRequestIndex={onRequestIndex} />}

            {viewPopupAddCurriUnit && <PopupAddCurriUnit onClose={onClosePopup} onSave={onSaveAddCurriUnit} showModal={props.showModal} showLoading={props.showLoading} selectedNode={selectedNode} curriBook={curriBook} treeData={treeData} />}

            <PopupConfirm view={viewPopupConfirm} title={"Confirm"} description={"하위목차를 모두 삭제하시겠습니까?"} onClose={() => {
                setViewPopupConfirm(false);
            }} onOk={() => {
                onDelDescendant();    
            }} showModal={props.showModal} />

            <PopupIndexName view={viewPopupIndexName} selectedNode={selectedNode} onClose={() => {
                setViewPopupIndexName(false);
            }} onSave={onSaveIndexName} showModal={props.showModal} />

        </div>
    );  
}
export default TextBookIndexEdit;
