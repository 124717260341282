import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, IEngTemp, IGrammarTemplate, ILibrary, ILibraryFile, IMeta, ISet, IText, S3Server, _type2List, _type3TextList, type1List, type1ListValue } from "../common";
import { logger } from '../logger';
import CircularProgress from "@mui/material/CircularProgress";
import PopupEditMultiLang from "./popupEditMultiLang";
import LibraryText from "./LibraryText";
import LibraryMedia from "./LibraryMedia";
import PopupLibrarySearch from "./PopupLibrarySearch";
import PopupStudyMapSearch from "./PopupStudyMapSearch";
import { IStudyMap } from "./LibraryRegister";
import PopupContentsList from "../contents/PopupContentsList";
import PopupSetList from "../textbook/PopupSetList";

interface ILibraryEngTempRegister {
    brand: IBrand;
    selectedItem: IEngTemp | null;
    onChangeTextType3: (val: string) => void;
	OnClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

export interface ITempDiv2EV1 {
    tempDiv2: IMeta;
    EV1_1?: IMeta;
    EV1_2?: IMeta;
    EV1_3?: IMeta;
    EV1_4?: IMeta;
    EV1_5?: IMeta;
    EV1_6?: IMeta;
}

export interface ITempDiv2EV3 {
    tempDiv2: IMeta;
    EV3?: IMeta;
}

function LibraryEngTempRegister(props: ILibraryEngTempRegister) {

    // const [type1List, setType1List] = useState<IType123[]>([]);
    // const [type2List, setType2List] = useState<IType123[]>([]);
    // const [type3List, setType3List] = useState<IType123[]>([]);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState<IMeta[]>([]);
    
    const [curriDepth, setCurriDepth] = useState(0);
    
    const [viewSearchObject, setViewSearchObject] = useState(false);
    const [studyMapList, setStudyMapList] = useState<IStudyMap[]>([]);

    const [viewPopupSearchStudyMap, setViewPopupSearchStudyMap] = useState(false);
    const [selectedStudyMapId, setSelectedStudyMapId] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const [tempType, setTempType] = useState("");
    const [searchType, setSearchType] = useState("");
    const [audioKey, setAudioKey] = useState(null as any);
    const [videoKey, setVideoKey] = useState(null as any);
    const [textKey, setTextKey] = useState<IText[]>([]);
    const [tempDepth, setTempDepth] = useState(1);

    // const [tempList, setTempList] = useState<IGrammarTemplate[]>([]);
    
    const [tempDiv1, setTempDiv1] = useState<IMeta[]>([]);
    const [tempDiv2, setTempDiv2] = useState<IMeta[]>([]);

    const [tempDiv2EV1List, setTempDiv2EV1List] = useState<ITempDiv2EV1[]>([]);
    const [tempDiv2EV3List, setTempDiv2EV3List] = useState<ITempDiv2EV3[]>([]);

    const [evaluationArea1, setEvaluationArea1] = useState<IMeta[]>([]);
    const [evaluationArea2, setEvaluationArea2] = useState<IMeta[]>([]);
    const [evaluationArea3, setEvaluationArea3] = useState<IMeta[]>([]);

    const [evaluationArea2Children, setEvaluationArea2Children] = useState<IMeta[]>([]);
    const [viewPopupContentsList, setViewPopupContentsList] = useState(false);
    const [articleSearchIndex, setArticleSearchIndex] = useState(-1);

    const [viewPopupSetList, setViewPopupSetList] = useState(false);

    const curriInfo = util.getCurrentCurriInfo();
    const currMenu = util.getCurrentMenu();

    // const LC_children = ["Script", "Skimming", "Listen & Repeat", "Role-Play", "Practice on Pronunciation", 
    // "Sentence Writing", "Sequencing", "Dictation", "Translating"];
    // const RC_children = ["Passage", "Skimming", "Filling in the blanks", "Practice on Pronunciation", 
    // "Sentence Writing", "Translating word for word", "Summarizing"];

    const arrGrammarTemplate : IGrammarTemplate[] = [
        {id: 0, name: "문법 타이틀", val: ""}, {id: 1, name: "개념 학습", val: ""}, {id: 2, name: "연습 문제1", val: ""}, 
        {id: 3, name: "연습 문제2", val: ""}, {id: 4, name: "실전 테스트1", val: ""}, {id: 5, name: "실전 테스트2", val: ""}, 
        {id: 6, name: "실전 테스트3", val: ""}, {id: 7, name: "문법 타이틀", val: ""}, {id: 8, name: "개념 학습", val: ""}, 
        {id: 9, name: "연습 문제1", val: ""}, {id: 10, name: "연습 문제2", val: ""}, {id: 11, name: "실전 테스트1", val: ""}, 
        {id: 12, name: "실전 테스트2", val: ""}, {id: 13, name: "실전 테스트3", val: ""}, {id: 14, name: "단원 테스트1", val: ""}, 
        {id: 15, name: "단원 테스트2", val: ""}, {id: 16, name: "단원 테스트3", val: ""}, 
    ];
    
    let bDone = false;
    useEffect(() => {

        if(!bDone) {
            
            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     props.OnClose();
            //     return;
            // }
    
            getTempDiv();

            setCurriDepth(0);
            setTempDepth(1);
    
            const _studyMapList : IStudyMap[] = [];
            const _studyMap = {
                id: -1,
            }
            _studyMapList.push(_studyMap as IStudyMap);
            setStudyMapList(_studyMapList);

            // const _tempList : IGrammarTemplate[] = [];
            // const _temp : IGrammarTemplate = {
            //     id: 1,
            //     name: "",
            //     depth: 1,
            //     parent: "",
            //     code: "0001",
            //     articles: "",
            // }
            // _tempList.push(_temp as IGrammarTemplate);
            // setTempList(_tempList);

            const _tempDiv2EV1List : ITempDiv2EV1 = {
                tempDiv2: {id: -1} as IMeta,
            }
            setTempDiv2EV1List([_tempDiv2EV1List]);

            const _tempDiv2EV3List : ITempDiv2EV3 = {
                tempDiv2: {id: -1} as IMeta,
                EV3: {id: -1} as IMeta,
            }
            setTempDiv2EV3List([_tempDiv2EV3List]);

            const selectedItem = props.selectedItem;
            if(selectedItem) {
                // init(selectedItem.id);
                // logger.log("selectedItem", selectedItem);
            } else {
                // const type1List = g_EngTempTypeList.filter((item) => item.parent === 0);
                // setType1List(type1List);
                // setTempType(type1List[0].val);
                
                // const type2List = g_EngTempTypeList.filter((item) => item.parent === type1List[0].id);
                // setType2List(type2List);
                // const type3List = g_EngTempTypeList.filter((item) => item.parent === type2List[0].id);
                // setType3List(type3List);

                (document.getElementById('LR_rd-on') as HTMLInputElement).checked = true;
                (document.getElementById('LR_rd-off') as HTMLInputElement).checked = false;
                (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = true;
                (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = false;

                const LR_ck_audio = (document.getElementById('LR_ck_audio') as HTMLInputElement);
                if(LR_ck_audio) {
                    LR_ck_audio.checked = true;
                }
                const LR_ck_video = (document.getElementById('LR_ck_video') as HTMLInputElement);
                if(LR_ck_video) {
                    LR_ck_video.checked = true;
                }
                
                // const LR_type1 = document.getElementById("LR_type1") as HTMLSelectElement;
                // LR_type1.selectedIndex = 0;
                // const LR_type2 = document.getElementById("LR_type2") as HTMLSelectElement;
                // LR_type2.selectedIndex = 0;    
            }
        }
        bDone = true;

    }, [])

    // useEffect(() => {
    //     // logger.log("tempList", tempList);
    //     onGrammar2UI();
    // }, [tempList])

    useEffect(() => {
        if(tempDiv1.length > 0) {
            getEvaluationArea();
            const selectedItem = props.selectedItem;
            if(selectedItem) {
                const item = tempDiv1.find((temp) => temp.val === selectedItem.type_1);
                if(item) {
                    getTemp2List(item.code);
                }
            } else {
                // if(tempDiv2.length === 0) {
                //     getTemp2List(tempDiv1[0].code);
                // }        
            }
        }
    }, [tempDiv1])

    useEffect(() => {
        if(evaluationArea1.length > 0 && evaluationArea2.length > 0 && evaluationArea3.length > 0) {
            const selectedItem = props.selectedItem;
            if(selectedItem) {
                init(selectedItem.id);
            }
        }
    }, [evaluationArea1, evaluationArea2, evaluationArea3])

    useEffect(() => {
        if(tempDiv2.length > 0) {
            if(tempType === "LC" || tempType === "RC") {
                const _tempDiv2EV1List = [];
                const _tempDiv2EV1 : ITempDiv2EV1 = {
                    tempDiv2: {id: -1} as IMeta,
                }
                _tempDiv2EV1List.push(_tempDiv2EV1);
                setTempDiv2EV1List(_tempDiv2EV1List);            
            }
        }
    }, [tempDiv2])

    useEffect(() => {
        // logger.log("tempDiv2EV1List", tempDiv2EV1List);
        if(tempDiv2) {
            onTempDiv2EV12UI();
        }
    }, [tempDiv2EV1List])

    useEffect(() => {
        // logger.log("tempDiv2EV3List", tempDiv2EV3List);
        if(tempDiv2) {
            onTempDiv2EV32UI();
        }
    }, [tempDiv2EV3List])

    useEffect(() => {
        // logger.log("tempType", tempType);
        if(tempType && tempType === "GR") {
            if(evaluationArea2 && evaluationArea2.length > 0) {
                const ev2 = evaluationArea2[0];
                getEvaluationArea2Children(ev2.code);
            }
        }
    }, [tempType])

    let bDoneEvaluationArea2Children = false;
    useEffect(() => {
        if(evaluationArea2Children.length > 0) {
            if(!bDoneEvaluationArea2Children && props.selectedItem && props.selectedItem.evaluationArea2String) {
                const arr = props.selectedItem.evaluationArea2String.split(",");
                if(arr && arr.length === 2) {
                    const EV2Children = document.getElementById("EV2Children") as HTMLSelectElement;
                    if(EV2Children) {
                        EV2Children.value = arr[1];
                    }                                            
                }
            }
            bDoneEvaluationArea2Children = true;
        }
    }, [evaluationArea2Children])

    // useEffect(() => {
    //     logger.log("useEffect contents", fileContents);
    // }, [fileContents])

    // useEffect(() => {
    //     logger.log("props.type", props.type);
    // }, [props.type])

    async function init(id: number) {

        props.showLoading(true);
        const result = await util.getEngTempInfo(id);
        props.showLoading(false);
        
        if(result) {

            const selectedItem  = result as IEngTemp;
            setSelectedItem(selectedItem);


            (document.getElementById('LR_title') as HTMLInputElement).value = selectedItem.name;

            (document.getElementById('LR_rd-on') as HTMLInputElement).checked = selectedItem.is_active;
            (document.getElementById('LR_rd-off') as HTMLInputElement).checked = !selectedItem.is_active;    
            (document.getElementById('LR_rd-public-on') as HTMLInputElement).checked = selectedItem.is_publicOpen;
            (document.getElementById('LR_rd-public-off') as HTMLInputElement).checked = !selectedItem.is_publicOpen;

            let _type_1 = selectedItem.type_1;
            // if(_type_1 === "L/C") {
            //     _type_1 = "Listening Comprehension";
            // } else if(_type_1 === "R/C") {
            //     _type_1 = "Reading Comprehension";
            // }

            const type_1 = tempDiv1.find((temp) => temp.val === _type_1);
            if(type_1) {
                (document.getElementById('LR_type1') as HTMLSelectElement).value = type_1.val;
                setTempType(type_1.code);
            }            

            logger.log("init", selectedItem, type_1);

            const LR_description = document.getElementById("LR_description") as HTMLInputElement;
            LR_description.value = selectedItem.description;
                
            const metaMap = selectedItem.metaMap;
            if(metaMap && metaMap.length > 0) {
                const curriSchool = metaMap.find((item) => item.name === "curriSchool");
                if(curriSchool) {
                    const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
                    LR_school.value = curriSchool.val;
                }
                const curriSubject = metaMap.find((item) => item.name === "curriSubject");
                if(curriSubject) {
                    const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
                    LR_subject.value = curriSubject.val;
                }
                // const difficulty = metaMap.find((meta, _) => meta.name === "difficulty");
                // const LR_level = (document.getElementById('LR_level') as HTMLSelectElement);
                // if(LR_level) {
                //     LR_level.value = difficulty ? difficulty.val : "";
                // }
            }
            
            // if(selectedItem.studyMap && selectedItem.studyMap.length > 0) {
            //     setStudyMapList(selectedItem.studyMap);
            // }

            // const studyMapListString = JSON.parse(selectedItem.studyMapListString);
            // if(studyMapListString) {
            //     setStudyMapList(studyMapListString);
            // }

            setTimeout(() => {

                // if(selectedItem.tempDepth) {
                //     setTempDepth(selectedItem.tempDepth);
                //     const LR_TempDepth = document.getElementById("LR_TempDepth") as HTMLInputElement;
                //     if(LR_TempDepth) {
                //         logger.log("LR_TempDepth", selectedItem.tempDepth);
                //         LR_TempDepth.value = String(selectedItem.tempDepth);
                //     }                
                // }
    
                if(selectedItem.tempListString) {
                    const _tempList = JSON.parse(selectedItem.tempListString) as IGrammarTemplate[];
                    if(_tempList && _tempList.length > 0) {
                        _tempList.map((item) => {
                            const LR_grammar_ = document.getElementById("LR_grammar_"+item.id) as HTMLInputElement; 
                            LR_grammar_.value = item.val;
                        });

                        logger.log("_tempList", _tempList);
                    }
                }    
                
                if(selectedItem.evaluationArea1String) {
                    const _tempDiv2EV1 = JSON.parse(selectedItem.evaluationArea1String) as ITempDiv2EV1[];
                    if(_tempDiv2EV1 && _tempDiv2EV1.length > 0) {
                        setTempDiv2EV1List(_tempDiv2EV1);
                    }
                }

                if(selectedItem.evaluationArea2String) {
                    const arr = selectedItem.evaluationArea2String.split(",");
                    if(arr && arr.length === 2) {
                        const EV2 = document.getElementById("EV2") as HTMLSelectElement;
                        if(EV2) {
                            EV2.value = arr[0];
                            const item = evaluationArea2.find((ev) => ev.val === EV2.value);
                            if(item) {
                                getEvaluationArea2Children(item.code);
                            }
                        }
                    }
                }

                if(selectedItem.evaluationArea3String) {
                    const _tempDiv2EV3 = JSON.parse(selectedItem.evaluationArea3String) as ITempDiv2EV3[];
                    if(_tempDiv2EV3 && _tempDiv2EV3.length > 0) {
                        setTempDiv2EV3List(_tempDiv2EV3);
                    }
                }

            }, 100);
            
            // setTimeout(() => {
            //     const children = selectedItem.children ? selectedItem.children : "";
            //     LC_children.map((item, idx) => {
            //         const child = document.getElementById("LC_Child_"+idx) as HTMLInputElement;
            //         if(child) {
            //             if(children.indexOf(item) >= 0) {
            //                 child.checked = true;
            //             } else {
            //                 child.checked = false;
            //             }
            //         }
            //     });    
            // }, 100);

            setTimeout(() => {
                const LR_AudioKey = document.getElementById("LR_AudioKey") as HTMLInputElement;
                const LR_ck_audio = document.getElementById("LR_ck_audio") as HTMLInputElement;
                if(LR_AudioKey) {
                    if(selectedItem.audioKey) {
                        setAudioKey(selectedItem.audioKey);
                        LR_AudioKey.value = String(selectedItem.audioKey.id);
                        LR_ck_audio.checked = true;    
                    } else {
                        LR_AudioKey.value = "";
                        LR_ck_audio.checked = false;
                    }
                }
                const LR_VideoKey = document.getElementById("LR_VideoKey") as HTMLInputElement;
                const LR_ck_video = document.getElementById("LR_ck_video") as HTMLInputElement;
                if(LR_VideoKey) {
                    if(selectedItem.videoKey) {
                        setVideoKey(selectedItem.videoKey);
                        LR_VideoKey.value = String(selectedItem.videoKey.id);
                        LR_ck_video.checked = true;
                    } else {
                        LR_VideoKey.value = "";
                        LR_ck_video.checked = false;
                    }
                }                    
            }, 100);

            setTimeout(() => {
                const LR_TextKey = document.getElementById("LR_TextKey") as HTMLInputElement;
                if(LR_TextKey) {
                    if(selectedItem.textKey) {
                        setTextKey(selectedItem.textKey);
                        let textIds = "";
                        selectedItem.textKey.map((item) => {
                            if(textIds === "") {
                                textIds = String(item.id);
                            } else {
                                textIds = textIds + "," + String(item.id);
                            }
                        });
                        LR_TextKey.value = textIds;
                    } else {
                        LR_TextKey.value = "";
                    }
                }                    
            }, 100);

            const curriBook = curriInfo?.curriBook.find((meta, _) => meta.id === selectedItem.curriBook);
    
            if(curriInfo && curriBook) {
    
                const _bookDescendantsList = await getDescendantsList(curriBook.code);
    
                const LR_textbook = (document.getElementById("LR_textbook") as HTMLSelectElement);
                if(LR_textbook) {
                    LR_textbook.value = curriBook.val;
                }
    
                if(_bookDescendantsList && _bookDescendantsList.length > 0) {
                    const curriUnit1s = _bookDescendantsList.filter((item) => item.parent_id === curriBook.id);
                    setCurriUnit1(curriUnit1s);
        
                    setTimeout(() => {
                        const curriUnit1 = curriUnit1s.find((meta, _) => meta.id === selectedItem.curriUnit1);
                        if(curriUnit1) {
                            (document.getElementById("LR_curriUnit1") as HTMLSelectElement).value = String(curriUnit1.id);
            
                            const curriUnit2s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit1.id);
                            setCurriUnit2(curriUnit2s);
                            setTimeout(() => {
                                const curriUnit2 = curriUnit2s.find((meta, _) => meta.id === selectedItem.curriUnit2);
                                if(curriUnit2) {
                                    (document.getElementById("LR_curriUnit2") as HTMLSelectElement).value = String(curriUnit2.id);
            
                                    const curriUnit3s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit2.id);
                                    setCurriUnit3(curriUnit3s);
                                    setTimeout(() => {
                                        const curriUnit3 = curriUnit3s.find((meta, _) => meta.id === selectedItem.curriUnit3);
                                        if(curriUnit3) {
                                            (document.getElementById("LR_curriUnit3") as HTMLSelectElement).value = String(curriUnit3.id);
            
                                            const curriUnit4s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit3.id);
                                            setCurriUnit4(curriUnit4s);
                                            setTimeout(() => {
                                                const curriUnit4 = curriUnit4s.find((meta, _) => meta.id === selectedItem.curriUnit4);
                                                if(curriUnit4) {
                                                    (document.getElementById("LR_curriUnit4") as HTMLSelectElement).value = String(curriUnit4.id);
            
                                                    const curriUnit5s = _bookDescendantsList.filter((item) => item.parent_id === curriUnit4.id);
                                                    setCurriUnit5(curriUnit5s);
                                                    setTimeout(() => {
                                                        const curriUnit5 = curriUnit5s.find((meta, _) => meta.id === selectedItem.curriUnit5);
                                                        if(curriUnit5) {
                                                            (document.getElementById("LR_curriUnit5") as HTMLSelectElement).value = String(curriUnit5.id);
                                                        }                            
                                                    }, 10);
                                                }                            
                                            }, 10);
                                        }                            
                                    }, 10);    
                                }                            
                            }, 10);        
                        }                            
                    }, 10);    
                }
            }
        }
    }

    async function getTempDiv() {
        const list = await getMetaList("1", "templateDiv");
        const _list = list.sort((a, b) => a.id! > b.id! ? 1 : -1);	
        setTempDiv1(_list);
    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    async function getTemp2List(code: string) {

        props.showLoading(true);

        setTempType(code);

        const list = await util.getDescendantsList(code);
        const newList = list.filter((item) => item.depth === 2);
        logger.log("getTemp2List", newList);

        setTempDiv2(newList);

        props.showLoading(false);
    }

    async function getEvaluationArea2Children(code: string) {

        props.showLoading(true);

        const list = await util.getDescendantsList(code);
        const newList = list.filter((item) => item.depth === 2);
        logger.log("getEvaluationArea2Children", newList);

        setEvaluationArea2Children(newList);

        props.showLoading(false);
    }

    function onChangeTemp1(e: React.ChangeEvent<HTMLSelectElement>): void {
        if(e.target.selectedIndex === 0) {
            setTempType("");
            setTempDiv2([]);
        } else {
            const selectedItem = tempDiv1[e.target.selectedIndex-1];
            if(selectedItem) {
                logger.log("onChangeTemp1", e.target.value, selectedItem);
                getTemp2List(selectedItem.code);    
            }    
        }
    }

    async function getEvaluationArea() {
        const list1 = await getMetaList("1", "evaluationArea");
        setEvaluationArea1(list1);
        const list2 = await getMetaList("1", "contentsItem");
        setEvaluationArea2(list2);
        const list3 = await getMetaList("1", "evaluationArea3");
        setEvaluationArea3(list3);
    }

    function onChangeType1(e: React.ChangeEvent<HTMLSelectElement>): void {
        logger.log("type1", e.target.value, e.target.selectedIndex);
        setTempType(e.target.value);

        // const _id = type1List[e.target.selectedIndex].id;
        // const type2List = g_EngTempTypeList.filter((item) => item.parent === _id);
        // if(type2List) {
        //     setType2List(type2List);
        
        //     const html = document.getElementById("LR_type2");
        //     if(html) {
        //         const selectElement = html as HTMLSelectElement;
        //         selectElement.selectedIndex = 0;
        //     }
        //     if(type2List.length > 0) {
        //         const type3List = g_EngTempTypeList.filter((item) => item.parent === type2List[0].id);
        //         if(type3List) {
        //             setType3List(type3List);        
        //         }    
        //     }
        // }
    }

    // function onChangeType2(e: React.ChangeEvent<HTMLSelectElement>): void {
    //     logger.log("type2", e.target.value, e.target.selectedIndex);

    //     const _id = type2List[e.target.selectedIndex].id;
    //     const type3List = g_EngTempTypeList.filter((item) => item.parent === _id);
    //     setType3List(type3List);    
    
    //     const html = document.getElementById("LR_type3");
    //     if(html) {
    //         const selectElement = html as HTMLSelectElement;
    //         selectElement.selectedIndex = 0;
    //     }
    // }

    // function onChangeType3(e: React.ChangeEvent<HTMLSelectElement>): void {
    //     logger.log("onChangeType3", e.target.value);
    // }

    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList([]);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {

            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        
        const curriBook = curriInfo?.curriBook.find((meta, _) => meta.val === e.target.value);
        if(curriBook) {
            getDescendantsList(curriBook.code);

            const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
            const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
            const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
            const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
            const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
        
            if(LR_html_curriUnit1) {
                LR_html_curriUnit1.selectedIndex = 0;
            }
            if(LR_html_curriUnit2) {
                LR_html_curriUnit2.selectedIndex = 0;
            }
            if(LR_html_curriUnit3) {
                LR_html_curriUnit3.selectedIndex = 0;
            }
            if(LR_html_curriUnit4) {
                LR_html_curriUnit4.selectedIndex = 0;
            }
            if(LR_html_curriUnit5) {
                LR_html_curriUnit5.selectedIndex = 0;
            }
    
            setCurriUnit2([]);
            setCurriUnit3([]);
            setCurriUnit4([]);
            setCurriUnit5([]);
        } else {
            setBookDescendantsList([]);
            setCurriUnit1(null);
            setCurriDepth(0);
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit2) {
            LR_html_curriUnit2.selectedIndex = 0;
        }
        if(LR_html_curriUnit3) {
            LR_html_curriUnit3.selectedIndex = 0;
        }
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit3) {
            LR_html_curriUnit3.selectedIndex = 0;
        }
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        if(LR_html_curriUnit4) {
            LR_html_curriUnit4.selectedIndex = 0;
        }
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;    
        if(LR_html_curriUnit5) {
            LR_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function OnClose(): void {
        props.OnClose();
    }

    function onSave() {

        if(!curriInfo) {
            return;
        }

        // const LR_type2 = document.getElementById("LR_type2") as HTMLSelectElement;
        // const LR_type2_val = type2List.length > 0 ? type2List[LR_type2.selectedIndex].val : "";

        // const LR_type3 = document.getElementById("LR_type3") as HTMLSelectElement;
        // const LR_type3_val = type3List.length > 0 ? type3List[LR_type3.selectedIndex].val : "";

        // let children_val = "";
        // if(tempType === "LC") {
        //     LC_children.map((item, idx) => {
        //         const child = document.getElementById("LC_Child_"+idx) as HTMLInputElement;
        //         if(child && child.checked) {
        //             if(children_val === "") {
        //                 children_val = item;
        //             } else {
        //                 children_val = children_val + "," + item;
        //             }
        //         }
        //     });
        // } else {
        //     RC_children.map((item, idx) => {
        //         const child = document.getElementById("RC_Child_"+idx) as HTMLInputElement;
        //         if(child && child.checked) {
        //             if(children_val === "") {
        //                 children_val = item;
        //             } else {
        //                 children_val = children_val + "," + item;
        //             }
        //         }
        //     });
        // }

        const LR_textbook = document.getElementById("LR_textbook") as HTMLSelectElement;
        if(LR_textbook.value === "") {
            props.showModal("", "교과를 선택해 주세요.");
            return;
        }
        const LR_textbook_val = curriInfo.curriBook[LR_textbook.selectedIndex-1];

        const LR_type1 = document.getElementById("LR_type1") as HTMLSelectElement;
        const LR_type1_val = LR_type1.value;
        if(LR_type1_val === "") {
            props.showModal("", "템플릿 구분값(1depth)을 선택해 주세요.");
            return;
        }

        const LR_title = document.getElementById("LR_title") as HTMLInputElement;
        const LR_title_val = LR_title.value;
        if(LR_title_val === "") {
            LR_title.focus();
            props.showModal("", "타이틀을 입력해 주세요.");
            return;
        }
        
        let LR_ck_audio_val = undefined;
        const LR_ck_audio = document.getElementById("LR_ck_audio") as HTMLInputElement;
        if(LR_ck_audio) {
            LR_ck_audio_val = LR_ck_audio.checked;
        }
        let LR_ck_video_val = undefined;
        const LR_ck_video = document.getElementById("LR_ck_video") as HTMLInputElement;
        if(LR_ck_video) {
            LR_ck_video_val = LR_ck_video.checked;
        }

        const LR_school = document.getElementById("LR_school") as HTMLSelectElement;
        const LR_school_val = curriInfo.curriSchool[LR_school.selectedIndex];
        
        const LR_subject = document.getElementById("LR_subject") as HTMLSelectElement;
        const LR_subject_val = curriInfo.curriSubject[LR_subject.selectedIndex];

        const LR_level = document.getElementById("LR_level") as HTMLSelectElement;
        const LR_level_val = curriInfo.difficulty[LR_level.selectedIndex-1];
    
        const LR_description = document.getElementById("LR_description") as HTMLInputElement;
        const LR_description_val = LR_description.value;

        const LR_html_curriUnit1 = document.getElementById("LR_curriUnit1") as HTMLSelectElement;
        const LR_html_curriUnit2 = document.getElementById("LR_curriUnit2") as HTMLSelectElement;
        const LR_html_curriUnit3 = document.getElementById("LR_curriUnit3") as HTMLSelectElement;
        const LR_html_curriUnit4 = document.getElementById("LR_curriUnit4") as HTMLSelectElement;
        const LR_html_curriUnit5 = document.getElementById("LR_curriUnit5") as HTMLSelectElement;
    
        const LR_html_curriUnit1_val = LR_html_curriUnit1 ? LR_html_curriUnit1.selectedIndex === 0 ? undefined : curriUnit1[LR_html_curriUnit1.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit2_val = LR_html_curriUnit2 ? LR_html_curriUnit2.selectedIndex === 0 ? undefined : curriUnit2[LR_html_curriUnit2.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit3_val = LR_html_curriUnit3 ? LR_html_curriUnit3.selectedIndex === 0 ? undefined : curriUnit3[LR_html_curriUnit3.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit4_val = LR_html_curriUnit4 ? LR_html_curriUnit4.selectedIndex === 0 ? undefined : curriUnit4[LR_html_curriUnit4.selectedIndex-1] as IMeta : undefined;
        const LR_html_curriUnit5_val = LR_html_curriUnit5 ? LR_html_curriUnit5.selectedIndex === 0 ? undefined : curriUnit5[LR_html_curriUnit5.selectedIndex-1] as IMeta : undefined;
    
        const LR_rd_on = document.getElementById('LR_rd-on') as HTMLInputElement;    
        const LR_rd_on_val = LR_rd_on.checked

        const LR_rd_public_on = document.getElementById('LR_rd-public-on') as HTMLInputElement;    
        const LR_rd_public_on_val = LR_rd_public_on.checked

        const brandId = props.brand.id;

        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");
        const _id = util.getUserInfo("id");
        
        const listMetaMap : IMeta[] = [];

        listMetaMap.push(LR_school_val);
        listMetaMap.push(LR_subject_val);

        if(LR_level.selectedIndex > 0) {
            listMetaMap.push(LR_level_val);
        }

        const _tempDiv1 = tempDiv1[LR_type1.selectedIndex-1];
        if(LR_type1.selectedIndex > 0) {
            listMetaMap.push(_tempDiv1);
        }

        // const _studyMapList = studyMapList.filter((item) => item.id! > 0);
        // _studyMapList.map((item) => {
        //     if(item.depth0) {
        //         listMetaMap.push(item.depth0);
        //     }
        //     if(item.depth1) {
        //         listMetaMap.push(item.depth1);
        //     }
        //     if(item.depth2) {
        //         listMetaMap.push(item.depth2);
        //     }
        //     if(item.depth3) {
        //         listMetaMap.push(item.depth3);
        //     }
        //     if(item.depth4) {
        //         listMetaMap.push(item.depth4);
        //     }
        //     if(item.depth5) {
        //         listMetaMap.push(item.depth5);
        //     }
        //     if(item.depth6) {
        //         listMetaMap.push(item.depth6);
        //     }
            
        // });

        let ev2Val = "";
        const EV2 = document.getElementById("EV2") as HTMLSelectElement;
        if(EV2) {
            ev2Val = evaluationArea2[EV2.selectedIndex].val;
        }
        const EV2Children = document.getElementById("EV2Children") as HTMLSelectElement;
        if(EV2Children) {
            ev2Val = ev2Val + "," + evaluationArea2Children[EV2Children.selectedIndex].val
        }

        const _tempDiv2EV1 = onUI2TempDiv2EV1();
        const _tempDiv2EV3 = onUI2TempDiv2EV3();

        let children_val = "";
        if(tempType === "LC" || tempType === "RC") {
            _tempDiv2EV1.map((item) => {
                if(children_val === "") {
                    children_val = item.tempDiv2.val;
                } else {
                    children_val = children_val + "," + item.tempDiv2.val;
                }

                listMetaMap.push(item.tempDiv2);
            });
        } else if(tempType === "VC") {
            _tempDiv2EV3.map((item) => {
                if(children_val === "") {
                    children_val = item.tempDiv2.val;
                } else {
                    children_val = children_val + "," + item.tempDiv2.val;
                }

                listMetaMap.push(item.tempDiv2);
            });
        } else if(tempType === "GR") {
            for (let i = 0; i < arrGrammarTemplate.length; i++) {
                const gt = arrGrammarTemplate[i];
                const LR_grammar_ = document.getElementById("LR_grammar_"+i) as HTMLInputElement; 
                gt.val =  LR_grammar_.value;
            }
        }

        const data = {
            "id": props.selectedItem ? props.selectedItem.id : undefined,
            "brand_id": brandId,
            "type_1": LR_type1_val,
            // "type_2": LR_type2_val, 
            // "type_3": LR_type3_val, 
            "name": LR_title_val, 
            "is_active": LR_rd_on_val, 
            "is_publicOpen": LR_rd_public_on_val, 

            "creator": props.selectedItem ? undefined : uid,
            "creator_name": props.selectedItem ? undefined : uname,
            "creator_id": props.selectedItem ? undefined : _id,
            
            "updater": props.selectedItem ? uid : undefined,
            "updater_name": props.selectedItem ? uname : undefined,
            "updater_id": props.selectedItem ? _id : undefined,
            
            "description": LR_description_val,
            
            "curriBook": LR_textbook_val?.id,
            "curriUnit1": LR_html_curriUnit1_val ? LR_html_curriUnit1_val.id : undefined,
            "curriUnit2": LR_html_curriUnit2_val ? LR_html_curriUnit2_val.id : undefined,
            "curriUnit3": LR_html_curriUnit3_val ? LR_html_curriUnit3_val.id : undefined,
            "curriUnit4": LR_html_curriUnit4_val ? LR_html_curriUnit4_val.id : undefined,
            "curriUnit5": LR_html_curriUnit5_val ? LR_html_curriUnit5_val.id : undefined,
            
            "metaMap": listMetaMap, 
            
            // "studyMapListString": JSON.stringify(_studyMapList),

            "children" : children_val,

            "audioKey": LR_ck_audio_val ? audioKey : undefined,
            "videoKey": LR_ck_video_val ? videoKey : undefined,

            "textKey": tempType === "VC" ? textKey : undefined,

            "tempDepth" : tempType === "GR" ? tempDepth : undefined,

            "tempListString" : tempType === "GR" ? JSON.stringify(arrGrammarTemplate) : undefined,

            "evaluationArea1String" : tempType === "LC" || tempType === "RC" ? JSON.stringify(_tempDiv2EV1) : undefined,
            "evaluationArea2String" : tempType === "GR" ? ev2Val : undefined,
            "evaluationArea3String" : tempType === "VC" ? JSON.stringify(_tempDiv2EV3) : undefined,
        };

        logger.log("onSave", data);
    
        _onSave(data, props.selectedItem ? "update" : "insert");
    }

    async function _onSave(data: any, type: string) {

        props.showLoading(true);

        const result = await util.engtempForSave(data, type);
        logger.log("_onSave", result);

        props.showLoading(false);

        props.showModal("", "저장이 완료 되었습니다.");

        props.OnClose();         
    }

    function onSearchObject(stype: string) {
        setSearchType(stype);
        setViewSearchObject(true);
    }

    function onSelectObject(item: any) {

        if(searchType === "audio") {
            const media = item as ILibrary;
            setAudioKey(media);

            const LR_AudioKey = (document.getElementById("LR_AudioKey") as HTMLInputElement);
            LR_AudioKey.value = String(media.id);

            const LR_ck_audio = (document.getElementById('LR_ck_audio') as HTMLInputElement);
            if(LR_ck_audio) {
                LR_ck_audio.checked = true;
            }

        } else if(searchType === "video") {
            const media = item as ILibrary;
            setVideoKey(media);

            const LR_VideoKey = (document.getElementById("LR_VideoKey") as HTMLInputElement);
            LR_VideoKey.value = String(media.id);

            const LR_ck_video = (document.getElementById('LR_ck_video') as HTMLInputElement);
            if(LR_ck_video) {
                LR_ck_video.checked = true;
            }
        } else if(searchType === "어휘") {

            const text = item as IText;
            const _textKey = textKey;

            const isIn = _textKey.find((_item) => _item.id === text.id);
            if(!isIn) {
                _textKey.push(text);
                setTextKey(_textKey);        
            }

            logger.log("onSelectObject", _textKey);

            const LR_TextKey = (document.getElementById("LR_TextKey") as HTMLInputElement);
            let textKeyVal = LR_TextKey.value;
            if(textKeyVal.trim() === "") {
                textKeyVal = item.id;
            } else {
                const arr = textKeyVal.split(",");
                const isIn = arr.find((media) => media.trim() === String(item.id));
                if(!isIn) {
                    textKeyVal = textKeyVal + "," + item.id;
                }
            }
            LR_TextKey.value = textKeyVal;
        }

        if(item) {
            if(item.type_1 === "audio" || item.type_1 === "video") {

                const media = item as ILibrary;

                // if(media && media.script_id && media.script_id > 0) {

                //     if(media.type_1 === "audio") {
                //         const _videoKey = videoKey as ILibrary;
                //         if(_videoKey) {
                //             if(media.script_id === _videoKey.script_id) {
                //                 setAudioKey(item);
                //             } else {
                //                 props.showModal("", "다른 자막Key 입니다.");
                //                 setViewSearchObject(false);
                //                 return;
                //             }
                //         } else {
                //             setAudioKey(item);
                //         }

                //         const LR_AudioKey = (document.getElementById("LR_AudioKey") as HTMLInputElement);
                //         LR_AudioKey.value = String(media.id);

                //     } else if(media.type_1 === "video") {
                //         const _audioKey = audioKey as ILibrary;
                //         if(_audioKey) {
                //             if(media.script_id === _audioKey.script_id) {
                //                 setVideoKey(item);
                //             } else {
                //                 props.showModal("", "다른 자막Key 입니다.");
                //                 setViewSearchObject(false);
                //                 return;
                //             }
                //         } else {
                //             setVideoKey(item);
                //         }

                //         const LR_VideoKey = (document.getElementById("LR_VideoKey") as HTMLInputElement);
                //         LR_VideoKey.value = String(media.id);

                //     }
    
                //     logger.log("onSelectObject", audioKey, videoKey);
        
                // } else {
                //     props.showModal("", "자막이 없습니다.");
                // }

            // } else {

            //     const text = item as IText;

            //     const _textKey = textKey;

            //     if(_textKey.length > 0) {
            //         if(_textKey[0].type_1 === text.type_1) {
            //             _textKey.push(item);
            //         } else {
            //             props.showModal("", "타입값이 다릅니다.");
            //             setViewSearchObject(false);
            //             return;
            //         }
            //     } else {
            //         _textKey.push(item);
            //     }
            //     setTextKey(_textKey);    

            //     logger.log("onSelectObject", _textKey);

            //     const LR_TextKey = (document.getElementById("LR_TextKey") as HTMLInputElement);
            //     let textKeyVal = LR_TextKey.value;
            //     if(textKeyVal.trim() === "") {
            //         textKeyVal = item.id;
            //     } else {
            //         const arr = textKeyVal.split(",");
            //         const isIn = arr.find((media) => media.trim() === String(item.id));
            //         if(!isIn) {
            //             textKeyVal = textKeyVal + "," + item.id;
            //         }
            //     }
            //     LR_TextKey.value = textKeyVal;

            }
        } 

        setViewSearchObject(false);
    }

    function onAddStudyMap() {
        const _studyMapList = JSON.parse(JSON.stringify(studyMapList));
        const _studyMap = {
            id: -1,
        }
        _studyMapList.push(_studyMap);
        logger.log("onAddStudyMap", _studyMapList);
        setStudyMapList(_studyMapList);
    }

    function onDelStudyMap(item: IStudyMap) {
        const _studyMapList = studyMapList;
        const newStudyMapList = _studyMapList.filter((e) => e !== item);
        logger.log("onDelStudyMap", newStudyMapList);
        setStudyMapList(newStudyMapList);
    }

    function onSelectStudyMap(item: IStudyMap) {
        const _studyMapList = JSON.parse(JSON.stringify(studyMapList));
        _studyMapList[selectedStudyMapId] = item;
        setStudyMapList(_studyMapList);
        logger.log("onSelectStudyMap", item, selectedStudyMapId, _studyMapList);

        setViewPopupSearchStudyMap(false);
    }

    // function onUI2Grammar() {

    //     // logger.log("onUI2Grammar1", tempList);

    //     for (let i = 0; i < tempList.length; i++) {
    //         const temp = tempList[i];
    //         const val = document.getElementById("LR_grammar_"+i) as HTMLInputElement;
    //         temp.val = val.value;
    //         const LR_grammar_akey_ = document.getElementById("LR_grammar_akey_"+i) as HTMLInputElement;
    //         if(LR_grammar_akey_) {
    //             temp.articles = LR_grammar_akey_.value;
    //         }
    //     }

    //     const _tempList = JSON.parse(JSON.stringify(tempList)) as IGrammarTemplate[];
    //     // logger.log("onUI2Grammar2", _tempList);

    //     setTempList(_tempList);

    //     return _tempList;
    // }

    // function onGrammar2UI() {
    //     for (let i = 0; i < tempList.length; i++) {
    //         const temp = tempList[i];
    //         const val = document.getElementById("LR_grammar_"+i) as HTMLInputElement;
    //         if(val) {
    //             val.value = temp.val;            
    //         }
    //         const LR_grammar_akey_ = document.getElementById("LR_grammar_akey_"+i) as HTMLInputElement;
    //         if(LR_grammar_akey_) {
    //             LR_grammar_akey_.value = temp.articles ? temp.articles : "";            
    //         }
    //     }
    // }

    // function onAddGrammarTemp(item: IGrammarTemplate) {

    //     onUI2Grammar();

    //     const _tempList = JSON.parse(JSON.stringify(tempList)) as IGrammarTemplate[];
    //     if(_tempList && _tempList.length > 0) {

    //         const item_children = _tempList.filter((_item) => _item.parent === item.code);
    //         let newCode = item_children.length + 1;
    //         const _new_item_children = item_children.sort((a, b) => a.code! < b.code! ? 1 : -1);	
    //         if(_new_item_children.length > 0) {
    //             const maxCode = _new_item_children[0].code;
    //             const arr = maxCode.split("-");
    //             const lastCode = arr[arr.length-1];
    //             const nCode = Number(lastCode);
    //             newCode = nCode + 1;
    //         }
    //         // logger.log("_new_item_children", _new_item_children, newCode);

    //         let item_children_code = "";
    //         if(item_children.length < 10) {
    //             item_children_code = "000" + newCode;
    //         } else if(item_children.length < 100) {
    //             item_children_code = "00" + newCode;
    //         } else if(item_children.length < 1000) {
    //             item_children_code = "0" + newCode;
    //         } else {
    //             item_children_code = "" + newCode;
    //         }

    //         const temp : IGrammarTemplate = {
    //             id: _tempList.length+1,
    //             val: "",
    //             depth: item.depth+1,
    //             parent: item.code,
    //             code: item.code + "-" + item_children_code,
    //             articles: "",
    //         }
    //         _tempList.push(temp);

    //         const _newlist = _tempList.sort((a, b) => a.code! > b.code! ? 1 : -1);	
    //         setTempList(_newlist);    

    //         // logger.log("after add", _newlist);
    //     }     
    // }

    // function onDelGrammarTemp(item: IGrammarTemplate) {

    //     onUI2Grammar();

    //     const _tempList = JSON.parse(JSON.stringify(tempList)) as IGrammarTemplate[];
    //     if(_tempList && _tempList.length > 0) {
    //         const _newlist = _tempList.filter((_item) => _item.code !== item.code);
    //         setTempList(_newlist);    
    //     }
    // }

    function onSearchArticle(idx: number) {
        setArticleSearchIndex(idx);
        // setViewPopupContentsList(true);
        setViewPopupSetList(true);
    }
    
    async function onSelectContent(item: any) {
        
        logger.log("onSelectContent", item);
        
        // const _tempList = JSON.parse(JSON.stringify(tempList)) as IGrammarTemplate[];

        // const _articleSearchItem = _tempList[articleSearchIndex];

        // logger.log("onSelectContent _tempList", _tempList, _articleSearchItem);

        // if(_articleSearchItem.articles === "") {
        //     _articleSearchItem.articles = "" + item.id;
        // } else {
        //     _articleSearchItem.articles = _articleSearchItem.articles + "," + item.id;
        // }

        // setTempList(_tempList);

        // const gt = arrGrammarTemplate[articleSearchIndex];
        // if(gt.articles === "") {
        //     gt.articles = "" + item.id;
        // } else {
        //     gt.articles = gt.articles + "," + item.id;
        // }

        const LR_grammar_ = document.getElementById("LR_grammar_"+articleSearchIndex) as HTMLInputElement; 

        let articles = LR_grammar_.value;
        if(articles === "") {
            articles = "" + item.id;
        } else {
            articles = articles + "," + item.id;
        }
        const arr = articles.split(",");
        let newArticles = "";
        arr.map((item) => {
            if(newArticles === "") {
                newArticles = item;
            } else {
                if(newArticles.indexOf(item) < 0) {
                    newArticles = newArticles + "," + item;
                }
            }
        });
        LR_grammar_.value = newArticles;        

        setViewPopupContentsList(false);
    }

    async function onSelectMultiContent(ids: string) {
        
        logger.log("onSelectMultiContent", ids);
        
        const LR_grammar_ = document.getElementById("LR_grammar_"+articleSearchIndex) as HTMLInputElement; 

        let articles = LR_grammar_.value;
        if(articles === "") {
            articles = ids;
        } else {
            articles = articles + "," + ids;
        }

        const arr = articles.split(",");
        let newArticles = "";
        arr.map((item) => {
            if(newArticles === "") {
                newArticles = item;
            } else {
                if(newArticles.indexOf(item) < 0) {
                    newArticles = newArticles + "," + item;
                }
            }
        });
        LR_grammar_.value = newArticles;        

        setViewPopupContentsList(false);
    }

    async function onRequstedSet(item: ISet) {
        
        const LR_grammar_ = document.getElementById("LR_grammar_"+articleSearchIndex) as HTMLInputElement; 

        let sets = LR_grammar_.value;
        if(sets === "") {
            sets = "" + item.id;
        } else {
            sets = sets + "," + item.id;
        }
        const arr = sets.split(",");
        let newSets = "";
        arr.map((item) => {
            if(newSets === "") {
                newSets = item;
            } else {
                if(newSets.indexOf(item) < 0) {
                    newSets = newSets + "," + item;
                }
            }
        });
        LR_grammar_.value = newSets;        
    }

    function onUI2TempDiv2EV1() {

        // logger.log("onUI2TempDiv2EV1", tempDiv2EV1List);

        for (let i = 0; i < tempDiv2EV1List.length; i++) {
            const item = tempDiv2EV1List[i];

            const tempDiv2EV1_ = document.getElementById("tempDiv2EV1_"+i) as HTMLSelectElement; 
            if(tempDiv2EV1_) {
                const _item = tempDiv2.find((temp) => temp.val === tempDiv2EV1_.value);
                if(_item) {
                    item.tempDiv2 = {id: _item.id, val: _item.val} as IMeta;
                }
            }
            
            const EV1_1_ = document.getElementById("EV1_1_"+i) as HTMLSelectElement;
            if(EV1_1_) {
                const _item = evaluationArea1.find((ev) => ev.val === EV1_1_.value);
                if(_item) {
                    item.EV1_1 = {id: _item.id, val: _item.val} as IMeta;
                }
            }
            const EV1_2_ = document.getElementById("EV1_2_"+i) as HTMLSelectElement;
            if(EV1_2_) {
                const _item = evaluationArea1.find((ev) => ev.val === EV1_2_.value);
                if(_item) {
                    item.EV1_2 = {id: _item.id, val: _item.val} as IMeta;
                }
            }
            const EV1_3_ = document.getElementById("EV1_3_"+i) as HTMLSelectElement;
            if(EV1_3_) {
                const _item = evaluationArea1.find((ev) => ev.val === EV1_3_.value);
                if(_item) {
                    item.EV1_3 = {id: _item.id, val: _item.val} as IMeta;
                }
            }
            const EV1_4_ = document.getElementById("EV1_4_"+i) as HTMLSelectElement;
            if(EV1_4_) {
                const _item = evaluationArea1.find((ev) => ev.val === EV1_4_.value);
                if(_item) {
                    item.EV1_4 = {id: _item.id, val: _item.val} as IMeta;
                }
            }
            const EV1_5_ = document.getElementById("EV1_5_"+i) as HTMLSelectElement;
            if(EV1_5_) {
                const _item = evaluationArea1.find((ev) => ev.val === EV1_5_.value);
                if(_item) {
                    item.EV1_5 = {id: _item.id, val: _item.val} as IMeta;
                }
            }
            const EV1_6_ = document.getElementById("EV1_6_"+i) as HTMLSelectElement;
            if(EV1_6_) {
                const _item = evaluationArea1.find((ev) => ev.val === EV1_6_.value);
                if(_item) {
                    item.EV1_6 = {id: _item.id, val: _item.val} as IMeta;
                }
            }    
        }

        const _tempDiv2EV1List = JSON.parse(JSON.stringify(tempDiv2EV1List)) as ITempDiv2EV1[];
        // logger.log("onUI2TempDiv2EV1", _tempDiv2EV1List);

        setTempDiv2EV1List(_tempDiv2EV1List);

        return _tempDiv2EV1List;
    }

    function onTempDiv2EV12UI() {
        for (let i = 0; i < tempDiv2EV1List.length; i++) {
            const item = tempDiv2EV1List[i];

            const tempDiv2EV1_ = document.getElementById("tempDiv2EV1_"+i) as HTMLSelectElement; 
            if(tempDiv2EV1_) {
                tempDiv2EV1_.value = item.tempDiv2?.val ? item.tempDiv2?.val : tempDiv2[0].val;
            }
            
            const EV1_1_ = document.getElementById("EV1_1_"+i) as HTMLSelectElement;
            if(EV1_1_) {
                EV1_1_.value = item.EV1_1?.val ? item.EV1_1?.val : "";
            }
            const EV1_2_ = document.getElementById("EV1_2_"+i) as HTMLSelectElement;
            if(EV1_2_) {
                EV1_2_.value = item.EV1_2?.val ? item.EV1_2?.val : "";
            }
            const EV1_3_ = document.getElementById("EV1_3_"+i) as HTMLSelectElement;
            if(EV1_3_) {
                EV1_3_.value = item.EV1_3?.val ? item.EV1_3?.val : "";
            }
            const EV1_4_ = document.getElementById("EV1_4_"+i) as HTMLSelectElement;
            if(EV1_4_) {
                EV1_4_.value = item.EV1_4?.val ? item.EV1_4?.val : "";
            }
            const EV1_5_ = document.getElementById("EV1_5_"+i) as HTMLSelectElement;
            if(EV1_5_) {
                EV1_5_.value = item.EV1_5?.val ? item.EV1_5?.val : "";
            }
            const EV1_6_ = document.getElementById("EV1_6_"+i) as HTMLSelectElement;
            if(EV1_6_) {
                EV1_6_.value = item.EV1_6?.val ? item.EV1_6?.val : "";
            }    
        }
    }

    function onAddTempDiv2EV1() {

        const _tempDiv2EV1List = onUI2TempDiv2EV1();

        const _tempDiv2EV1 : ITempDiv2EV1 = {
            tempDiv2: {id: -1} as IMeta,
        }
        _tempDiv2EV1List.push(_tempDiv2EV1);
        setTempDiv2EV1List(_tempDiv2EV1List);

        logger.log("onAddTempDiv2EV1", _tempDiv2EV1List);

    }

    function onDelTempDiv2EV1(idx: number) {

        const _tempDiv2EV1List = onUI2TempDiv2EV1();

        if(_tempDiv2EV1List && _tempDiv2EV1List.length > 0) {

            const _newlist : ITempDiv2EV1[] = [];
            _tempDiv2EV1List.map((temp, i) => {
                if(idx != i) {
                    _newlist.push(temp);
                }
            });
            setTempDiv2EV1List(_newlist);
            logger.log("onDelTempDiv2EV1", _newlist);
        }
    }

    function onUI2TempDiv2EV3() {

        for (let i = 0; i < tempDiv2EV3List.length; i++) {
            const item = tempDiv2EV3List[i];

            const tempDiv2EV3_ = document.getElementById("tempDiv2EV3_"+i) as HTMLSelectElement; 
            if(tempDiv2EV3_) {
                const _item = tempDiv2.find((temp) => temp.val === tempDiv2EV3_.value);
                if(_item) {
                    item.tempDiv2 = {id: _item.id, val: _item.val} as IMeta;
                }
            }    
            const EV3_ = document.getElementById("EV3_"+i) as HTMLSelectElement;
            if(EV3_) {
                const _item = evaluationArea3.find((ev) => ev.val === EV3_.value);
                if(_item) {
                    item.EV3 = {id: _item.id, val: _item.val} as IMeta;
                }
            }
        }

        const _tempDiv2EV3List = JSON.parse(JSON.stringify(tempDiv2EV3List)) as ITempDiv2EV3[];

        setTempDiv2EV3List(_tempDiv2EV3List);

        return _tempDiv2EV3List;
    }

    function onTempDiv2EV32UI() {
        for (let i = 0; i < tempDiv2EV3List.length; i++) {
            const item = tempDiv2EV3List[i];

            const tempDiv2EV3_ = document.getElementById("tempDiv2EV3_"+i) as HTMLSelectElement; 
            if(tempDiv2EV3_) {
                tempDiv2EV3_.value = item.tempDiv2?.val ? item.tempDiv2?.val : tempDiv2[0].val;
            }
            
            const EV3_ = document.getElementById("EV3_"+i) as HTMLSelectElement;
            if(EV3_) {
                EV3_.value = item.EV3?.val ? item.EV3?.val : "";
            }
        }
    }

    function onAddTempDiv2EV3() {

        const _tempDiv2EV3List = onUI2TempDiv2EV3();

        const _tempDiv2EV3 : ITempDiv2EV3 = {
            tempDiv2: {id: -1} as IMeta,
            EV3: {id: -1} as IMeta,
        }
        _tempDiv2EV3List.push(_tempDiv2EV3);
        setTempDiv2EV3List(_tempDiv2EV3List);

        logger.log("onAddTempDiv2EV3", _tempDiv2EV3List);

    }

    function onDelTempDiv2EV3(idx: number) {

        const _tempDiv2EV3List = onUI2TempDiv2EV3();

        if(_tempDiv2EV3List && _tempDiv2EV3List.length > 0) {

            const _newlist : ITempDiv2EV3[] = [];
            _tempDiv2EV3List.map((temp, i) => {
                if(idx != i) {
                    _newlist.push(temp);
                }
            });
            setTempDiv2EV3List(_newlist);
            logger.log("onDelTempDiv2EV3", _newlist);
        }
    }

    return (
        <div>

            <div>

                {/* <h4>일괄등록 양식 검색</h4>
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>

					</tbody>
				</table> */}

                <h4>기본 정보 등록</h4>
				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>
                        <tr>
							<th scope="row" className="w-56">브랜드</th>
							<td className="text-left">{props.brand.name}</td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-class">학교 급<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_school" name="sel-school">
                                    {curriInfo && curriInfo.curriSchool && (curriInfo.curriSchool as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-subject">교과목<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_subject" name="sel-subject">
                                    {curriInfo && curriInfo.curriSubject && (curriInfo.curriSubject as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-textbook">교과<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select id="LR_textbook" name="sel-textbook" onChange={(e) => onChangeCurriBook(e)}>
                                    <option value="">교과</option>              
                                    {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="sel-unit">교과과정<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">

                                <select className="mr-2" id="LR_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                    <option value="0">1Depth</option>              
                                    {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                    <option value="0">2Depth</option>              
                                    {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                    <option value="0">3Depth</option>              
                                    {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                    {/* <option value="0">{curriDepth === 4 ? "차시" : "토픽"}</option>               */}
                                    <option value="0">4Depth</option>              
                                    {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                    <option value="0">5Depth</option>              
                                    {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                    return(
                                    <option key={idx} value={item.id}>{item.val}</option>              
                                    )})}
                                </select> 
                            </td>
						</tr>
						<tr>
							<th scope="row" className="w-56"><label htmlFor="sel-divi">템플릿 구분값 (1depth)<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <select className="mr-2" id="LR_type1" name="type1" onChange={(e) => onChangeTemp1(e)}>
                                    <option value="">선택</option>
                                    {tempDiv1 && tempDiv1.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    )})}
                                </select>

                                {/* <select className="mr-2" id="LR_type2" name="type2" style={{display: type2List && type2List.length > 0 ? "" : "none"}} onChange={(e) => onChangeType2(e)}>
                                    {type2List && type2List.map((type2, idx2) => {                                        
                                    return(
                                        <option key={idx2} value={type2.val} >{type2.val}</option>
                                    )})}
                                </select>
                                <select className="mr-2" id="LR_type3" name="type3" style={{display: type3List && type3List.length > 0 ? "" : "none"}} onChange={(e) => onChangeType3(e)}>
                                    {type3List && type3List.map((type3, idx3) => {
                                    return(
                                        <option key={idx3} value={type3.val}>{type3.val}</option>              
                                    )})}
                                </select> */}

                                {/* {tempType === "L/C" &&
                                <div className="pt-3 mt-3 border-t">
                                    {LC_children.map((item, idx) => {
                                        return(
                                            <span key={idx}>
                                                {idx === 5 && <div key={idx*100} className="py-1"></div>}
                                                <span key={idx} className="text-left ml-3">
                                                    <input id={"LC_Child_"+idx} type="checkbox" defaultChecked />
                                                    <span className="ml-1">{item}</span>
                                                </span>
                                            </span>
                                        );
                                    })}                                    
                                </div>}

                                {tempType === "R/C" &&
                                <div className="pt-3 mt-3 border-t">
                                    {RC_children.map((item, idx) => {
                                        return(
                                            <span key={idx}>
                                                {idx === 4 && <div key={idx*100} className="py-1"></div>}
                                                <span key={idx} className="text-left ml-3">
                                                    <input id={"RC_Child_"+idx} type="checkbox" defaultChecked />
                                                    <span className="ml-1">{item}</span>
                                                </span>
                                            </span>
                                        );
                                    })}                                    
                                </div>} */}

                            </td>
						</tr>    

                        {(tempType === "LC" || tempType === "RC") &&
						<tr>
                            <td colSpan={2} style={{padding:0}}>
                                <table className="border-none mt-2 mb-2">
                                    <tbody>
                                    <tr>
                                        <th scope="row" className="w-[340px]"><label htmlFor="sel-divi">템플릿 구분값 (2depth)<em className="text-sky-500 align-top">*</em></label></th>                                        
                                        <th className="text-center">
                                            <label htmlFor="sel-divi">평가영역<em className="text-sky-500 align-top">*</em></label>
                                        </th>
                                    </tr>

                                    {tempDiv2EV1List && tempDiv2EV1List.map((item, idx) => {

                                    return(
                                        <tr key={idx}>
                                            <td className="text-left">
                                                <select className="mr-2" id={"tempDiv2EV1_"+idx} name="tempDiv2EV1">
                                                    {tempDiv2 && tempDiv2.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                <button type="button" className="btn-sky-s" onClick={() => {
                                                    if(idx === 0) {
                                                        onAddTempDiv2EV1();
                                                    } else {
                                                        onDelTempDiv2EV1(idx);
                                                    }
                                                    
                                                }}>{idx === 0 ? "추가" : "삭제"}</button>
                                            </td>
                                            <td className="text-left">
                                                <select className="mr-2" id={"EV1_1_"+idx} name="EV1">
                                                    <option value="">선택</option>
                                                    {evaluationArea1 && evaluationArea1.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                <select className="mr-2" id={"EV1_2_"+idx} name="EV1">
                                                    <option value="">선택</option>
                                                    {evaluationArea1 && evaluationArea1.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                <select className="mr-2" id={"EV1_3_"+idx} name="EV1">
                                                    <option value="">선택</option>
                                                    {evaluationArea1 && evaluationArea1.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                <select className="mr-2" id={"EV1_4_"+idx} name="EV1">
                                                    <option value="">선택</option>
                                                    {evaluationArea1 && evaluationArea1.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                <select className="mr-2" id={"EV1_5_"+idx} name="EV1">
                                                    <option value="">선택</option>
                                                    {evaluationArea1 && evaluationArea1.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                <select className="mr-2" id={"EV1_6_"+idx} name="EV1">
                                                    <option value="">선택</option>
                                                    {evaluationArea1 && evaluationArea1.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                
                                            </td>
                                        </tr>
                                    )})}
                                    
                                    </tbody>
                                </table>
                            </td>
						</tr>} 

                        {(tempType === "VC") &&
						<tr>
                            <td colSpan={2} style={{padding:0}}>
                                <table className="border-none mt-2 mb-2">
                                    <tbody>
                                    <tr>
                                        <th scope="row" className="w-[340px]"><label htmlFor="sel-divi">템플릿 구분값 (2depth)<em className="text-sky-500 align-top">*</em></label></th>                                        
                                        <th className="text-center">
                                            <label htmlFor="sel-divi">평가영역 3<em className="text-sky-500 align-top">*</em></label>
                                        </th>
                                    </tr>

                                    {tempDiv2EV3List && tempDiv2EV3List.map((item, idx) => {

                                    return(
                                        <tr key={idx}>
                                            <td className="text-left">
                                                <select className="mr-2" id={"tempDiv2EV3_"+idx} name="tempDiv2EV3">
                                                    {tempDiv2 && tempDiv2.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                                <button type="button" className="btn-sky-s" onClick={() => {
                                                    if(idx === 0) {
                                                        onAddTempDiv2EV3();
                                                    } else {
                                                        onDelTempDiv2EV3(idx);
                                                    }
                                                    
                                                }}>{idx === 0 ? "추가" : "삭제"}</button>
                                            </td>
                                            <td className="text-left">
                                                <select className="mr-2" id={"EV3_"+idx} name="EV3">
                                                    <option value="">선택</option>
                                                    {evaluationArea3 && evaluationArea3.map((item, idx) => {
                                                    return(
                                                        <option key={idx} value={item.val}>{item.val}</option>
                                                    )})}
                                                </select>
                                            </td>
                                        </tr>
                                    )})}
                                    
                                    </tbody>
                                </table>
                            </td>
						</tr>} 

                        {tempType === "GR" && <>
                        <tr>
                            <th scope="row" className="w-56"><label htmlFor="sel-divi">평가영역 2<em className="text-sky-500 align-top">*</em></label></th>
                            <td className="text-left">
                                <select className="mr-2" id={"EV2"} name="EV2" onChange={(e) => {
                                    const ev2 = evaluationArea2[e.target.selectedIndex];
                                    getEvaluationArea2Children(ev2.code);
                                }}>
                                    {evaluationArea2 && evaluationArea2.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    )})}
                                </select>
                                <select className="mr-2" id={"EV2Children"} name="EV2">
                                    {evaluationArea2Children && evaluationArea2Children.map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>
                                    )})}
                                </select>

                            </td>
                        </tr>
						<tr style={{display:"none"}}>
							<th scope="row" className="w-56"><label htmlFor="sel-divi">템플릿 Depth</label></th>
							<td className="text-left">
                                <select className="mr-2" id="LR_TempDepth" onChange={(e) => {
                                    setTempDepth(Number(e.target.value));
                                }}>
                                    <option value="1">1 Depth</option>              
                                    <option value="2">2 Depth</option>              
                                    <option value="3">3 Depth</option>              
                                    <option value="4">4 Depth</option>              
                                    <option value="5">5 Depth</option>              
                                </select>

                            </td>
						</tr></>}
                                              
                        {(tempType === "LC" || tempType === "RC") &&
                        <tr>
                            <th scope="row" className="w-56">라이브러리 검색</th>
                            <td style={{padding:0}}>
                                <table className="border-none">
                                    <tbody>
                                    <tr className="border-b border-slate-200">
                                        <td className="w-32 bg-slate-100">오디오 KEY</td>
                                        <td className="text-left w-32">
                                            <input id="LR_ck_audio" type="checkbox" defaultChecked />
                                            <input type="text" id="LR_AudioKey" className="ml-3" readOnly />                                            
                                        </td>
                                        <td className="text-left" style={{borderLeftWidth:0}}>
                                            <button type="button" className="btn-sky-s" onClick={() => onSearchObject("audio")}>검색</button>
                                        </td>
                                    </tr>
                                    <tr className="border-b border-slate-200">
                                        <td className="w-32 bg-slate-100">비디오 KEY</td>
                                        <td className="text-left">
                                            <input id="LR_ck_video" type="checkbox" defaultChecked />
                                            <input type="text" id="LR_VideoKey" className="ml-3" readOnly />                                            
                                        </td>
                                        <td className="text-left" style={{borderLeftWidth:0}}>
                                            <button type="button" className="btn-sky-s" onClick={() => onSearchObject("video")}>검색</button>
                                        </td>
                                    </tr>                                    
                                    </tbody>
                                </table>
                            </td>
						</tr>}

                        {tempType === "VC" &&
                        <tr>
                            <th scope="row" className="w-56">라이브러리 검색</th>
                            <td style={{padding:0}}>
                                <table className="border-none">
                                    <tbody>
                                        <tr>
                                            <td className="w-32 bg-slate-100">어휘 KEY</td>
                                            <td className="text-left">
                                                {/* <input id="LR_libText" name="rd-lib" type="radio"/>
                                                <select id="LR_libTextType" name="sel-textbook" className="ml-3">
                                                    <option value="어휘">어휘</option>              
                                                    <option value="문장">문장</option>              
                                                    <option value="지문">지문</option>              
                                                </select> */}
                                                <input type="text" id="LR_TextKey" className="w-avail ml-3" readOnly />
                                            </td>
                                            <td className="text-left" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => {
                                                    // const LR_libTextType = document.getElementById("LR_libTextType") as HTMLSelectElement;
                                                    // let stype = "";
                                                    // if(LR_libTextType) {
                                                    //     stype = LR_libTextType.value;
                                                    // }                                                
                                                    onSearchObject("어휘");
                                                }}>검색</button>
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </td>
						</tr>}

                        {tempType === "GR" &&
                        <tr>                        
                            <td colSpan={2} style={{paddingLeft:0, paddingRight:0}}>
                                <table className="border-none">
                                    <tbody>
                                        
                                        <tr>
                                            <th colSpan={4}>템플릿 구성</th>
                                        </tr>

                                        <tr>
                                            <th className="w-56" rowSpan={7}>문법1</th>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)", borderLeftWidth:0}}>문법 타이틀</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_0"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>개념 학습​</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_1"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(1)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>연습 문제1</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_2"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(2)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>연습 문제2</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_3"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(3)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>실전 테스트1</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_4"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(4)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>실전 테스트2</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_5"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(5)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>실전 테스트3</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_6"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(6)}>수업자료 검색</button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="w-56" rowSpan={7}>문법2</th>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)", borderLeftWidth:0}}>문법 타이틀</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_7"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>개념 학습​</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_8"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(7)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>연습 문제1</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_9"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(8)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>연습 문제2</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_10"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(9)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>실전 테스트1</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_11"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(10)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>실전 테스트2</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_12"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(11)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left w-56" style={{backgroundColor:"rgb(235 235 235 / var(--tw-bg-opacity)"}}>실전 테스트3</td>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_13"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(12)}>수업자료 검색</button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="w-56" colSpan={2}>단원 테스트1</th>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_14"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(13)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="w-56" colSpan={2}>단원 테스트2</th>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_15"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(14)}>수업자료 검색</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="w-56" colSpan={2}>단원 테스트3</th>        
                                            <td className="w-avail">
                                                <input type="text" id={"LR_grammar_16"} className="w-avail" />
                                            </td>        
                                            <td className="w-16" style={{borderLeftWidth:0}}>
                                                <button type="button" className="btn-sky-s" onClick={() => onSearchArticle(15)}>수업자료 검색</button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <table className="">
                                    <tbody>

                                        {/* <tr>
                                            <th colSpan={2}>템플릿 구성</th>
                                            <th>아티클Key</th>
                                        </tr>

                                        {tempList && tempList.map((item, idx) => {

                                            let clsName = "";
                                            if(item.depth === 1) {
                                                clsName = "";
                                            } else if(item.depth === 2) {
                                                clsName = "pl-5";
                                            } else if(item.depth === 3) {
                                                clsName = "pl-10";
                                            } else if(item.depth === 4) {
                                                clsName = "pl-15";
                                            } else if(item.depth === 5) {
                                                clsName = "pl-20";
                                            }

                                            const LR_grammar = document.getElementById("LR_grammar_"+idx) as HTMLInputElement;
                                            if(LR_grammar) {
                                                LR_grammar.value = item.val;
                                            }

                                            const bViewAddBtn = tempDepth > item.depth;
                                            // logger.log(tempDepth, bViewAddBtn);

                                            const item_children = tempList.filter((_item) => _item.parent === item.code);
                                            const bViewDelBtn = item.depth === 1 || item_children.length > 0;

                                            return(
                                                <tr key={idx} className="border-b border-slate-200">
                                                    <td className={"text-left w-avail " + clsName}>
                                                        <input type="text" id={"LR_grammar_"+idx} className="w-avail" onBlur={() => {
                                                            onUI2Grammar();
                                                        }} />
                                                    </td>
                                                    <td className="text-right w-32" style={{borderLeftWidth:0}}>
                                                        <button type="button" className="btn-sky-s" onClick={() => onAddGrammarTemp(item)} 
                                                        disabled={!bViewAddBtn}>추가</button>
                                                        <button type="button" className="btn-sky-s ml-2" onClick={() => onDelGrammarTemp(item)}
                                                        disabled={bViewDelBtn}>삭제</button>
                                                    </td>
                                                    <td className="w-56">
                                                        <input type="text" id={"LR_grammar_akey_"+idx} onBlur={() => {
                                                            onUI2Grammar();
                                                        }} readOnly />
                                                        <button type="button" className="btn-sky-s ml-2" onClick={() => onSearchArticle(idx)}>검색</button>
                                                    </td>
                                                </tr>    
                                            );
                                        })}

                                        <tr>
                                            <th colSpan={3}></th>
                                        </tr> */}

                                    </tbody>
                                </table>
                            </td>
						</tr>}

                        <tr>
							<th scope="row" className="w-56">KEY</th>
							<td className="text-left">{props.selectedItem ? props.selectedItem.id : ""}</td>
						</tr>

                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">타이틀<em className="text-sky-500 align-top">*</em></label></th>
							<td className="text-left">
                                <input type="text" id="LR_title" className="w-1/2" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56"><label htmlFor="inp-title">설명</label></th>
							<td className="text-left">
                                <input type="text" id="LR_description" className="w-avail" />
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">작성자</th>
                            {props.selectedItem &&
                            <td className="text-left">{props.selectedItem.creator_name} ({props.selectedItem.creator})</td>}
							{!props.selectedItem &&
                            <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
						</tr>
                        <tr>
							<th scope="row" className="w-56">등록일</th>
                            {props.selectedItem &&
							<td className="text-left">{props.selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
							{!props.selectedItem &&
							<td className="text-left">{util.getDate()}</td>}
                            </tr>
                        <tr>
							<th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-on" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-on">사용</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-off" name="rd-use" type="radio"/>
                                        <label htmlFor="rd-off">미사용</label>
                                    </span>
                                </div>
                            </td>
						</tr>
                        <tr>
							<th scope="row" className="w-56">공개 설정<em className="text-sky-500 align-top">*</em></th>
							<td className="text-left">
                                <div className="radio-wrap">
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-on" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-on">공개</label>
                                    </span>
                                    <span className="inp-radio">
                                        <input id="LR_rd-public-off" name="rd-public" type="radio"/>
                                        <label htmlFor="rd-off">비공개</label>
                                    </span>
                                </div>
                            </td>
						</tr>
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}

                <h4 style={{display:"none"}}>추가 정보</h4>
				{/* <!-- table --> */}
				<table style={{display:"none"}}>
					<caption className="sr-only">관리 테이블</caption>
					<tbody>
						<tr>
							<th scope="row" className="w-56">
                                <label htmlFor="sel-level" className="mr-0">난이도</label>
                            </th>
							<td className="text-left">
								<select id="LR_level" name="sel-level">
                                    <option value="0">난이도</option>              
                                    {curriInfo && curriInfo.difficulty && (curriInfo.difficulty as IMeta[]).map((item, idx) => {
                                    return(
                                        <option key={idx} value={item.val}>{item.val}</option>              
                                    )})}
								</select>
							</td>
						</tr>

                        {studyMapList && studyMapList.map((item, idx) => {

                            let _unitVal = "";
                                                    
                            const arr = [item.depth1, item.depth2, item.depth3, item.depth4, item.depth5, item.depth6];
                            arr.map((meta, idx) => {
                                if(meta) {
                                    if(_unitVal === "") {
                                        if(selectedItem) {
                                            const metaMap = (selectedItem as IEngTemp).metaMap;
                                            if(metaMap && metaMap.length > 0) {
                                                const found = metaMap.find((mm) => mm.id === meta.id);
                                                if(found) {
                                                    _unitVal = found.val;            
                                                }    
                                            }
                                        } else {
                                            _unitVal = meta.val;    
                                        }
                                    } else {
                                        if(idx === 2) {
                                            if(selectedItem) {
                                                const metaMap = (selectedItem as IEngTemp).metaMap;
                                                if(metaMap && metaMap.length > 0) {
                                                    const found = metaMap.find((mm) => mm.id === meta.id);
                                                    if(found) {
                                                        _unitVal = _unitVal + " > " + "" + found.val;
                                                    }
                                                }
                                            } else {
                                                _unitVal = _unitVal + " > " + "" + meta.val;
                                            }
                                        } else {
                                            if(selectedItem) {
                                                const metaMap = (selectedItem as IEngTemp).metaMap;
                                                if(metaMap && metaMap.length > 0) {
                                                    const found = metaMap.find((mm) => mm.id === meta.id);
                                                    if(found) {
                                                        _unitVal = _unitVal + " > " + found.val;
                                                    }
                                                }
                                            } else {
                                                _unitVal = _unitVal + " > " + meta.val;
                                            }
                                        }
                                    }
                                }
                            });

                        return(

                        <tr key={idx}>
                            <th scope="row">
                                학습맵 {idx+1}<br />
                                {idx === 0 &&
                                <button type="button" className="btn-white-s h-8" onClick={onAddStudyMap}>추가</button>}
                                {idx !== 0 &&
                                <button type="button" className="btn-white-s h-8" onClick={() => onDelStudyMap(item)}>삭제</button>}
                            </th>
                            <td className="text-left">
                                <button type="button" className="btn-sky" onClick={() => {
                                    setSelectedStudyMapId(idx);
                                    setViewPopupSearchStudyMap(true);
                                }}>검색</button>
                                <span className="ml-2" dangerouslySetInnerHTML={{ __html: _unitVal }}></span>
                            </td>
                        </tr>                                
                        );
                        })}

					</tbody>
				</table>
				{/* <!-- .//table -->	 */}
				
				<div className="relative mt-5">
					<div className="flex justify-between">
						<button type="button" className="btn-white" onClick={OnClose}>목록</button>
						<button type="button" className="btn-sky" onClick={onSave} >{props.selectedItem ? "수정" : "저장"}</button>
					</div>
				</div>

            </div>
            
            {viewSearchObject &&
            <PopupLibrarySearch brand={props.brand} searchType={searchType} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectObject} onClose={function (): void {
                setViewSearchObject(false);
            } } />}

            {viewPopupSearchStudyMap &&
            <PopupStudyMapSearch showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectStudyMap} onClose={function (): void {
                setViewPopupSearchStudyMap(false);
            } } />}

            {viewPopupContentsList &&
            <PopupContentsList brand={props.brand} textType={""} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectContent} onClose={function (): void {
                setViewPopupContentsList(false);
            }} multiSelect={false} onSelectMulti={onSelectMultiContent} />}

            {viewPopupSetList &&
            <PopupSetList showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewPopupSetList(false);
            }} onRequst={onRequstedSet} />}

        </div>
    );
}
export default LibraryEngTempRegister;