import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, ITextBook, IUser } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";

interface IPopupHistoryNDeploy {
    textbook: ITextBook;
    onClose: () => void;
    onDeploy: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

function PopupHistoryNDeploy(props: IPopupHistoryNDeploy) {

    const [list, setList] = useState([] as any);
    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [listCount, setListCount] = useState(0);
    const [viewConfirm, setViewConfirm] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);

    const curriInfo = util.getCurrentCurriInfo();

    const numOfViewPage = 10;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            refreshList();
        }
        bDone = true;
    }, [])

    function onClose() {
        props.onDeploy();
        props.onClose();
    }

    const refreshList = () => {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        const data = {
            brand_id: brand.id ? brand.id : null,
            textbook_id: props.textbook.id,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        const _list = await util.textbookDeployList(data);
        let list = _list;

        const deployedItem = _list.find((item) => item.deployStatus === 1);
        if(deployedItem) {
            list = [deployedItem].concat(_list.filter((item) => item.deployStatus !== 1));
        }

        setList(list);
        logger.log("onSearch", data, deployedItem, list);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0]?.full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onDeployView(item: any) {
        if(item.deployStatus === 0 || item.deployStatus === 2) {
            setViewConfirm(true);
            setSelectedItem(item);
        }
    }

    async function onDeploy() {
    
        if(selectedItem) {

            props.showLoading(true);

            const data = {
                "id": selectedItem.id,
                "textbook_id": selectedItem.textbook_id,    
                "deployStatus": 1,
            }

            const result = await util.textbookDeployChangeStatus(data);        
            if(result) {
                logger.log("onDeploy", result);
                setSelectedItem(null as any);
            }

            refreshList();

            props.showLoading(false);
        }

        setViewConfirm(false);
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{maxWidth:"60rem", maxHeight:"60rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">수정이력 확인 및 배포</h2>

                            {/* <!-- table --> */}
                            <div className="ml-5 mr-5 mt-5">
                                <table>
                                    <caption className="sr-only">목차 리스트</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pl-4 pr-3" style={{textAlign: "center"}}>번호</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>버전이력</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>최종 배포일</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>버전</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>작성자</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>배포</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && (list as any[]).map((item, idx) => {

                                            const totalCnt = item && item.full_count ? item.full_count : 0;
                                            const _numOfPage = 10;
                                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                                            const lastUpdateDate = item && item.updatedate ? item.updatedate : item?.regdate;
                                            const deployStatus = item?.deployStatus === 0 ? "배포" : item?.deployStatus === 1 ? "배포중" : "배포종료";

                                            return(
                                                <tr key={idx} className="rt-tr action" /*onClick={() => {}}*/>
                                                    <td className="rt-td_">{_num}</td>
                                                    <td className="rt-td_"><a href="#">{item?.versionHistory}</a></td>
                                                    <td className="rt-td_">{lastUpdateDate?.substring(0, 10).replaceAll("-", ".")}</td>
                                                    <td className="rt-td_">{item?.version}</td>
                                                    <td className="rt-td_">{item?.creator_name}</td>
                                                    <td>
                                                        <button type="button" className="btn-white" style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}} disabled={item?.deployStatus === 1} onClick={() => onDeployView(item)} >{deployStatus}</button>
                                                    </td>
                                                </tr>    

                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- .//table -->	 */}                

                            <div className="relative mt-10 mb-5">

                                {/* <!-- pagenation --> */}
                                <div className="text-center">
                                    <nav className="pagenation" aria-label="Pagination">

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Previous</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}

                                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                        return (
                                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                        )})}

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Next</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}
                                        
                                    </nav>
                                </div>
                                {/* <!-- .// pagenation --> */}
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>


            <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : viewConfirm ? "" : "none"}}>
                <div className="dim"></div>
                <div className="popup-contents">
                    <div className="popup-contents-inner">
                        <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                        <div className="popup-box max-w-xl min-w-[576px]">
                            {/* <!-- 팝업 내용 --> */}
                            <div className="inner">
                                <button type="button" className="btn-close" onClick={() => {setViewConfirm(false);}}>
                                    <span className="sr-only">Close</span>
                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                                <h2 className="pop-title">심사 확인</h2>
                                <div className="px-5">
                                    <div className="py-8 text-center">
                                        <p>교과서를 배포 하시겠습니까?<br/>교과서당 버전은 1개만 배포가 가능합니다.</p>
                                    </div>
                                </div>
                                <div className="my-5 text-center">
                                    <button type="button" className="btn-sky" onClick={onDeploy}>확인</button>

                                </div>
                            </div>
                            {/* <!-- .//팝업 내용 --> */}
                        </div>
                    </div>
                </div>
            </div>


        </div>        
    );
}
  
export default PopupHistoryNDeploy;
