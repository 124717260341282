import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IUser } from "../common";
import { logger } from "../logger";

interface IPopupMediaPreview {
    type: string;
    url: string;
    onClose: () => void;
}

function PopupMediaPreview(props: IPopupMediaPreview) {
      
    const url = props.url;
    logger.log("PopupMediaPreview", props.url, url);

    return (
                
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box max-w-xl min-w-[576px]">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={props.onClose}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">미디어 미리보기</h2>
                            <div className="px-5 text-center">
                                <div className="py-5 text-center" style={{minHeight:props.type === "audio" ? "5rem" : "20rem"}}>
                                    {props.type === "image" &&
                                    <img src={url} />}
                                    
                                    {props.type === "audio" &&
                                    <center><audio controls autoPlay><source src={url} type="audio/mp3" /></audio></center>}

                                    {props.type === "video" &&
                                    <center><video controls autoPlay><source src={url} type="video/mp4" /></video></center>}
                                </div>
                            </div>
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>
                </div>
            </div>
        </div>

    );
}
  
export default PopupMediaPreview;
