import { useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, ITextBook, IUser } from "../common";
import { logger } from "../logger";
import "../common.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import DatePicker from "@gsebdev/react-simple-datepicker/dist/DatePicker";
import "../library/LibrarySearch.scss";

interface IPopupDeployHistory {
    selectedItem: ITextBook;
    onClose: () => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

function PopupDeployHistory(props: IPopupDeployHistory) {

    const [list, setList] = useState([] as any);
    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);
    const [listCount, setListCount] = useState(0);
    const [typeTextBook, setTypeTextBook] = useState("");

    const curriInfo = util.getCurrentCurriInfo();

    const numOfViewPage = 10;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            const div = document.getElementsByClassName("SG-datepicker__input-container");
            // logger.log(div, div.length);
            if(div) {
                
                const new_DH_sdate = document.getElementById("new_DH_sdate") as HTMLInputElement;
                if(!new_DH_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_DH_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[div.length-2].appendChild(node)
    
                }

                const DH_sdate = document.getElementById("DH_sdate") as HTMLInputElement;
                if(DH_sdate) {
                    DH_sdate.style.display = "none";
                }

                const new_DH_edate = document.getElementById("new_DH_edate") as HTMLInputElement;
                if(!new_DH_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_DH_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[div.length-1].appendChild(node_e)
                }

                const DH_edate = document.getElementById("DH_edate") as HTMLInputElement;
                if(DH_edate) {
                    DH_edate.style.display = "none";
                }
            }
            setTypeTextBook("web");
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(typeTextBook !== "") {
            refreshList();
        }
    }, [typeTextBook])

    function onClose() {
        props.onClose();
    }

    const refreshList = () => {

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        const data = {
            brand_id: brand.id ? brand.id : null,
            type: typeTextBook,
            orderby_col: "id",
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: 10,
        };
        setSearchData(data);
        _onSearch(data);

        logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        const list = await util.textbookDeployStatusList(data);

        setList(list);
        logger.log("onSearch", data, list);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        
        const TB_curriBook = document.getElementById("MM_curriBook") as HTMLSelectElement;
        const TB_curriBook_val = curriInfo.curriBook[TB_curriBook.selectedIndex-1];

        const TB_sdate = document.getElementById("new_DH_sdate") as HTMLInputElement;
        const TB_edate = document.getElementById("new_DH_edate") as HTMLInputElement;
        
        let sdate = undefined;
        let edate = undefined;

        if(TB_sdate && TB_edate) {
            const arrSD = TB_sdate.value.split("-");
            const arrED = TB_edate.value.split("-");
            if(arrSD.length === 3 && arrED.length === 3) {
                sdate = TB_sdate.value;
                edate = TB_edate.value 
            }      
        }

        const data = {
            brand_id: brand.id ? brand.id : null,
            curriBook: TB_curriBook_val ? TB_curriBook_val.id : undefined,

            reg_sdate: sdate,  
            reg_edate: edate,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onClickSDateIcon() : void {
        const new_DH_sdate = document.getElementById("new_DH_sdate") as HTMLInputElement;
        if(new_DH_sdate) {
            // new_DH_sdate.value = "";
            const DH_sdate = document.getElementById("DH_sdate") as HTMLInputElement;
            DH_sdate.value = "";
            DH_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const DH_edate = document.getElementById("DH_edate") as HTMLInputElement;
                    DH_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_DH_edate = document.getElementById("new_DH_edate") as HTMLInputElement;
        if(new_DH_edate) {
            // new_DH_edate.value = "";
            const DH_edate = document.getElementById("DH_edate") as HTMLInputElement;
            DH_edate.value = "";
            DH_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const DH_sdate = document.getElementById("DH_sdate") as HTMLInputElement;
                    DH_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "DH_sdate") {
            const new_DH_sdate = document.getElementById("new_DH_sdate") as HTMLInputElement;
            if(new_DH_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_DH_sdate.value = val;
            }
        } else if(e.target.id === "DH_edate") {
            const new_DH_edate = document.getElementById("new_DH_edate") as HTMLInputElement;
            if(new_DH_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_DH_edate.value = val;
            }
        }
    }

    function onChangeType(type: string) {
        setTypeTextBook(type);
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            <div className="popup-contents">
                <div className="popup-contents-inner" style={{padding:0}}>
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box" style={{maxWidth:"60rem"/*, maxHeight:"60rem"*/, minHeight:"40rem"}}>
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">교과서 배포이력​</h2>

                            <div className="overflow-x-auto px-5 mt-5 mb-3">
                                <ul className="tab-list !flex-nowrap !mb-0">
                                    <li onClick={() => onChangeType("web")}><a href="#" aria-current={typeTextBook === "web" ? "page" : undefined} className={typeTextBook === "web" ? "active" : undefined}>웹 교과서</a></li>
                                    {/* <li className="ml-1"><a href="#" aria-current={typeTextBook === "ebook" ? "page" : undefined} className={typeTextBook === "ebook" ? "active" : undefined}>e book 교과서</a></li> */}
                                    <li onClick={() => onChangeType("ebook")} className="ml-1"><a href="#" aria-current={typeTextBook === "ebook" ? "page" : undefined} className={typeTextBook === "ebook" ? "active" : undefined}>e book 교과서</a></li>
                                </ul>
                            </div>

                            {/* <!-- 검색영역 --> */}
                            <div className="px-5 py-5 border border-gray-300 rounded-xl mt-5 ml-5 mr-5 mb-5">
                                <div className="text-right">
                                    <fieldset style={{display: "inline-flex", alignItems: "center"}}>
                                        <label htmlFor="sel-title">{"교과과정"}</label>
                                        <select id="MM_curriBook">
                                            <option value="">전체</option>
                                            {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>              
                                            )})}
                                        </select>
                                        {/* <label htmlFor="sel-title" className="ml-5">{"학년"}</label>
                                        <select id="MM_curriBook">
                                            <option value="">전체</option>
                                            {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                                return(
                                                    <option key={idx} value={item.val}>{item.val}</option>              
                                            )})}
                                        </select> */}

                                    </fieldset>

                                    <fieldset>
                                        <label htmlFor="date" className="ml-8">배포시작일​</label>
                                    </fieldset>

                                    <fieldset>
                                        <DatePicker id='DH_sdate' name='DH_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                                    </fieldset>

                                    <fieldset>
                                        <button type="button" className="inline-block ml-1111 align-[-8px] ml-5" onClick={onClickSDateIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                            </svg>                                            
                                        </button> 
                                    </fieldset>

                                    <fieldset>
                                        <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                                    </fieldset>

                                    <fieldset>
                                        <DatePicker id='DH_edate' name='DH_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                                    </fieldset>

                                    <fieldset>
                                        <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                            </svg>                                            
                                        </button> 
                                    </fieldset>

                                    <fieldset>
                                        {/* <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" /> */}
                                        <button type="button" className="h-[36px] btn-sky-s ml-5" onClick={onSearch}>
                                            <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                                        </button>
                                    </fieldset>


                                </div>
                            </div>
                            {/* <!-- .//검색영역 --> */}

                            {/* <!-- table --> */}
                            <div className="ml-5 mr-5 mt-5">
                                <table>
                                    <caption className="sr-only">목차 리스트</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="pl-4 pr-3" style={{textAlign: "center"}}>번호</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>교과과정</th>
                                            <th scope="col" className="px-3" style={{textAlign: "center"}}>교과서 명​</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>배포기간</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>배포상태​</th>
                                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>관리자​</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list && (list as any[]).map((item, idx) => {

                                            const totalCnt = item.full_count ? item.full_count : 0;
                                            const _numOfPage = 10;
                                            let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                                            let curriBook = undefined;
                                            if(curriInfo) {
                                                curriBook = curriInfo.curriBook.find((book) => book.id === item.curriBook);
                                            }
                                            
                                            const deployStatus = item?.deployStatus === 1 ? "배포중" : item?.deployStatus === 2 ? "배포종료" : "-";

                                            const deploydate_1 = item?.deploydate_1?.substring(0, 10).replaceAll("-", ".");
                                            const deploydate_2 = item?.deploydate_2?.substring(0, 10).replaceAll("-", ".");

                                            let deploydate = ""
                                            if(deploydate_1) {
                                                deploydate = deploydate_1 + "</br> ~ </br>";
                                                if(deploydate_2) {
                                                    deploydate = deploydate + deploydate_2;
                                                }
                                            }
                                            return(
                                                <tr key={idx} onClick={() => {}}>
                                                    <td>{_num}</td>
                                                    <td><a href="#">{curriBook?.val}</a></td>
                                                    <td>{item.name}</td>
                                                    <td dangerouslySetInnerHTML={{ __html: deploydate }}></td>
                                                    <td>{deployStatus}</td>
                                                    <td>{item.creator_name}</td>
                                                </tr>    
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- .//table -->	 */}                

                            <div className="relative mt-10 mb-5">

                                {/* <!-- pagenation --> */}
                                <div className="text-center">
                                    <nav className="pagenation" aria-label="Pagination">

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Previous</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}

                                        {listOfPage && (listOfPage as number[]).map((item, idx) => {
                                        return (
                                            currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                                        )})}

                                        {listOfPage && (listOfPage as number[]).length > 0 &&
                                        <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                            <span className="sr-only">Next</span>
                                            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                            </svg>
                                        </div>}
                                        
                                    </nav>
                                </div>
                                {/* <!-- .// pagenation --> */}
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>
        </div>        
    );
}
  
export default PopupDeployHistory;
