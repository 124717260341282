import React, { useEffect, useState } from 'react';
import './App.css';
import * as util from './util';

import { IBrand, IMenu, authGroupInfo, authGroupInfo_new, leftmenu_1, leftmenu_2 } from './common';
import { logger } from './logger';

interface ILeftMenu {
    brand: IBrand;
    onSelectMenu: (menu: IMenu) => void;    
    showModal: (title: string, description: string) => void;    
    onLogout: () => void;
}

function LeftMenu(props: ILeftMenu) {

    const [selectedMenu1, setSelectedMenu1] = useState(0);
    const [selectedMenu2, setSelectedMenu2] = useState(0);

    // const [hasAuth, setHasAuth] = useState<boolean[]>([]);
    // const [auth, setAuth] = useState("");

    const [view, setView] = useState(false);

    useEffect(() => {
    }, [])

    useEffect(() => {
        if(props.brand) {
            //brand changed...

            /* 2024.09.11

            const auth = util.getCurrentAuth();
            if(auth.trim() === "") {
                return;
            }

            let val : boolean[] = [];
            leftmenu_1.map((item) => {
                val.push(false);
            });
            
            // Read Auth
            const _hasAuth = val;
            authGroupInfo.map((item, idx) => {
                if(auth.indexOf(item) > -1) {
                    _hasAuth[idx] = true;
                } else {
                    _hasAuth[idx] = false;
                }
            });
            // const _hasAuth = [true, true, true, true, true, true];
            util.setMenuAuth(_hasAuth);
            setHasAuth(util.getMenuAuth());
            //...End

            //Write Auth
            const _hasEditAuth = val;
            authGroupInfo.map((item, idx) => {
                if(auth.indexOf(item+"1") > -1) {
                    _hasEditAuth[idx] = true;
                } else {
                    _hasEditAuth[idx] = false;
                }
            });
            // const _hasEditAuth = [true, true, true, true, true, true];
            util.setEditAuth(_hasEditAuth);
            //...End

            //보이는 메뉴중 최상위 선택
            let menu1 = -1;
            _hasAuth.map((item, idx) => {
                if(item && menu1 === -1) {
                    menu1 = idx;
                    return;
                }
            });

            setViewTextDepth3(false);

            const currentMenu = util.getCurrentMenu();
            const newMenu1 = currentMenu.menu1 < 0 ? menu1 : currentMenu.menu1;
            logger.log("currentMenu", currentMenu, _hasAuth, newMenu1, _hasAuth[newMenu1]);

            if(newMenu1 > -1) {

                const view = isVisibleMenu({menu1: newMenu1, menu2: currentMenu.menu2});
                let newMenu2 = currentMenu.menu2;
                if(!view) {
                    // props.showModal("", "메뉴권한이 없어 이동합니다.");
                    for (let i = 0;; i++) {
                        const _view = isVisibleMenu({menu1: newMenu1, menu2: i});
                        if(_view) {
                            newMenu2 = i;
                            const menu1_ = document.getElementById('menu1_'+newMenu1) as HTMLElement;
                            if(menu1_) {
                                menu1_.scrollIntoView(true);
                            }
                            break;
                        }                        
                    }
                }

                if(_hasAuth[newMenu1]) {
                    onSelectMenu(newMenu1, newMenu2);                    
                } else {
                    // props.showModal("", "메뉴권한이 없어 이동합니다.");
                    onSelectMenu(menu1, 0);                    
                    const menu1_ = document.getElementById('menu1_'+menu1) as HTMLElement;
                    if(menu1_) {
                        menu1_.scrollIntoView(true);
                    }
                    // props.onSelectMenu({ menu1: menu1, menu2: 0 });
                }    
            }

            //...End

            logger.log("props.brand", auth, _hasAuth, _hasEditAuth);

            /* 2024.09.11 */

            const currMenu = util.getCurrentMenu();
            if(currMenu) {
    
                let view = true;
                view = isVisibleMenu({menu1: currMenu.menu1, menu2: currMenu.menu2});
                if(view) {
                    const auth = util.getCurrentAuth();
                    if(auth.indexOf("["+authGroupInfo_new[currMenu.menu1][currMenu.menu2]+"]") > -1) {
                        view = true;
                    } else {
                        view = false;
                    } 
                }
                if(view) {
                    setSelectedMenu1(currMenu.menu1);
                    setSelectedMenu2(currMenu.menu2);   
                    
                    onSelectMenu(currMenu.menu1, currMenu.menu2);
                } else {
                    setSelectedMenu1(0);
                    setSelectedMenu2(0);    

                    onSelectMenu(0, 0);
                }
            }        
    
            setView(true);
        }
    }, [props.brand])

    // useEffect(() => {
    //     if(props.reload) {
    //         //menu changed...
    //         logger.log("props.reload");
    //     }
    // }, [props.reload])

    function onSelectMenu(idx1: number, idx2: number) {

        logger.log("select menu", idx1, idx2);

        if(idx1 < 0) {
            return;
        }

        let new_idx2 = idx2;

        // 메뉴가 추가 삭제 수정되면 이 부분 반드시 수정해야함
        if(idx1 === 0 && idx2 === 3) {
            new_idx2 = 4;
        }  
        if(idx1 === 8 && idx2 === 1) {
            new_idx2 = 2;
        }

        setSelectedMenu1(idx1);
        setSelectedMenu2(new_idx2);

        logger.log("select menu", idx1, new_idx2);

        props.onSelectMenu({menu1: idx1, menu2: new_idx2});    
    }

    function isVisibleMenu(menu: IMenu) : boolean {

        const item = leftmenu_1[menu.menu1];
        const idx2 = menu.menu2;
        const brand = props.brand;

        let view = true;

        if(item === "메타 정보 관리") {   
            if(idx2 === 17 || idx2 === 18 || idx2 === 19 || idx2 === 21 || idx2 === 22 || idx2 === 23 || idx2 === 24) {
                if(brand && brand.code === "VAET01") {
                    view = true;
                } else {
                    view = false;
                }
            }
            if(idx2 === 20) {
                if(brand && brand.code === "VIVA00") {
                    view = true;
                } else {
                    view = false;
                }
            }
            if(idx2 === 25 || idx2 === 26 || idx2 === 27) {
                if(brand && brand.code === "VIVA01") {
                    view = true;
                } else {
                    view = false;
                }
            }
            if(idx2 === 28 || idx2 === 29 || idx2 === 30 || idx2 === 31) {
                if(brand && brand.code === "VSTB00") {
                    view = true;
                } else {
                    view = false;
                }
            }
            
            // if(idx2 === 25) {
            //     if(brand && brand.code === "VSTB00") {
            //         view = true;
            //     }
            // }

        }        

        if(item === "AI 튜터 관리") {  
            if(idx2 === 1 || idx2 === 2 || idx2 === 3) {
                if(brand && brand.code === "VAET01") {
                    view = true;
                } else {
                    view = false;
                }
            }
        }

        if(item === "교과도구 관리") {  
            if(idx2 === 0) {
                if(brand && brand.code === "VAET01") {
                    view = false;
                } else {
                    view = true;
                }
            } else if(idx2 === 1) {
                if(brand && brand.code === "VAET01") {
                    view = true;
                } else {
                    view = false;
                }
            }
        }

        return view;
    }

    return (
        <div className="left-menu" style={{display: view ? "" : "none"}}>
            {/* <!-- nav --> */}
            <nav className="nav">
                <ul role="list" className="menu">

                    {leftmenu_1 && leftmenu_1.map((item, idx1) => {

                        // let view1 = hasAuth[idx1];
                        let view1 = false;
                        const auth = util.getCurrentAuth();
                        if(auth.indexOf("["+authGroupInfo[idx1]+"]") > -1) {
                            view1 = true;
                        }    

                        return (
                            <li id={"menu1_"+idx1} key={idx1} style={{display: view1 ? "" : "none"}}>
                                <h2>
                                    {idx1 === 0 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
                                    </svg>}
                                    {idx1 === 1 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
                                    </svg>}
                                    {idx1 === 2 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                    </svg>}
                                    {idx1 === 3 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>}
                                    {idx1 === 4 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>}
                                    {idx1 === 5 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                    </svg>}
                                    {idx1 === 6 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-2">
										<path strokeLinecap="round" strokeLinejoin="round" d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"></path>
									</svg>}
                                    {idx1 === 7 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>}
                                    {idx1 === 8 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>}
                                    {idx1 === 9 && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>}
                                    {item}
                                </h2>
                                <ul role="list" className="two-depth">
                                    {leftmenu_2[idx1] && leftmenu_2[idx1].map((item2, idx2) => {

                                        let className = "";
                                        let view2 = true;
                                        view2 = isVisibleMenu({menu1: idx1, menu2: idx2});
                                        if(view2) {
                                            const auth = util.getCurrentAuth();
                                            if(auth.indexOf("["+authGroupInfo_new[idx1][idx2]+"]") > -1) {
                                                view2 = true;
                                            } else {
                                                view2 = false;
                                            } 
                                        }
                
                                        if(item === "라이브러리 관리") {   
                                            if(idx2 === 4 || idx2 === 5 || idx2 === 6) {
                                                className = "ml-5";
                                            }
                                        }  

                                        if(item === "AI 튜터 관리") {  
                                            if(idx2 === 2 || idx2 === 3) {
                                                className = "ml-5";
                                            }
                                        }

                                        let menuName = item2;

                                        return (                                            
                                            <li className={className} key={idx2} onClick={() => onSelectMenu(idx1, idx2)} style={{display: view2 ? "" : "none"}}>
                                                {(selectedMenu1 === idx1 && selectedMenu2 === idx2) ? 
                                                <a href="#" aria-current="page">{menuName}</a> : <a href="#">{menuName}</a>}
                                            </li>
                                        )})
                                    }
                                </ul>
                            </li>
                        );
                    })}

                    <li style={{display: view ? "" : "none"}}>
                        <h2>                                        
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='mr-2'>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <a href='#' onClick={props.onLogout}>{"로그아웃"}</a>                            
                        </h2>
                    </li>

                </ul>
            </nav>
            {/* <!-- .//nav --> */}
        </div>
    );      
}

export default LeftMenu;
