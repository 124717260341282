import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, IMenu, IMeta, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import PopupConfirm from "../contents/PopupConfirm";

interface ITeachingContentsList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}
  
function TeachingContentsList(props: ITeachingContentsList) {
    
    const [list, setList] = useState([] as any);
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [currArticleCategory, setCurrArticleCategory] = useState("");

    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    const [teachingCategoryList, setTeachingCategoryList] = useState(null as any);
    const [teachingUsageList, setTeachingUsageList] = useState(null as any);
    const [teachingSubjectList, setTeachingSubjectList] = useState(null as any);
    const [teachingCurriBookList, setTeachingCurriBookList] = useState(null as any);

    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            getTeachingInfo();

            setTeachingCurriBookList(curriInfo?.curriBook);

            // setViewList(true);
            setCurrArticleCategory("나의 저작");

            const _pageNum = localStorage.getItem("Teaching_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }
            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                
                const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
                if(!new_TB_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_TB_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[0].appendChild(node)
    
                }

                const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
                if(TB_sdate) {
                    TB_sdate.style.display = "none";
                }

                const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
                if(!new_TB_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_TB_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[1].appendChild(node_e)
                }

                const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
                if(TB_edate) {
                    TB_edate.style.display = "none";
                }
            }            
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(currArticleCategory !== "") {
            refreshList();
            onSearch();
        }
    }, [currArticleCategory])
    
    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        // let articleCategoryId = undefined;
        // if(curriInfo && curriInfo.articleCategory) {
        //     const articleCategory = curriInfo.articleCategory as IMeta[];
        //     const _articleCategory = articleCategory.find((article, _) => article.val === "교과");
        //     if(_articleCategory) {
        //         articleCategoryId = _articleCategory.id;
        //     }
        // }

        // const data = {
        //     brand_id: brand.id ? brand.id : null,
        //     articleCategory: articleCategoryId,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function getTeachingInfo() {
        const teachingCategory = await util.getMetaList("1", "teachingCategory");
        if(teachingCategory) {
            const _teachingCategory = teachingCategory.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingCategoryList(_teachingCategory);    
        }
        const teachingUsage = await util.getMetaList("1", "teachingUsage");
        if(teachingUsage) {
            const _teachingUsage = teachingUsage.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingUsageList(_teachingUsage);    
        }
        const teachingSubject = await util.getMetaList("1", "teachingSubject");
        if(teachingSubject) {
            const _teachingSubject = teachingSubject.sort((a, b) => a.id! < b.id! ? -1 : 1);	
            setTeachingSubjectList(_teachingSubject);    
        }
    }

    async function _onSearch(data: any) {

        if(!data) {
            return;
        }

        props.showLoading(true);

        if(data) {
            data.detail = true;
        }

        setViewList(false);

        let _list = [];
        if(currArticleCategory === "나의 저작") {
            _list = await util.getSetSearch(data);
        } else {
            _list = await util.getArticleSearch(data);
        }
        setList(_list);
        logger.log("onSearch", data, _list);
        setViewList(true);
        setListCount(0);
        if(_list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(_list.length > 0) {
                totalCnt = _list[0].full_count;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }

        const TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
        const TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;        
    
        let sdate = "";
        let edate = "";
        const arrSD = TB_sdate.value.split("-");
        const arrED = TB_edate.value.split("-");
        if(arrSD.length === 3 && arrED.length === 3) {
            sdate = TB_sdate.value;
            edate = TB_edate.value 
        }  

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const TB_public = document.getElementById("TB_public") as HTMLSelectElement;

        let articleTypeId = undefined;

        let articleCategoryId = undefined;
        if(curriInfo && curriInfo.articleCategory) {
            const articleCategory = curriInfo.articleCategory as IMeta[];
            const _articleCategory = articleCategory.find((article, _) => article.val === currArticleCategory);
            if(_articleCategory) {
                articleCategoryId = _articleCategory.id;
            }
        }

        const TB_keyword = document.getElementById("TB_keyword") as HTMLInputElement;        
        const TB_keyword_type = document.getElementById("TB_keyword_type") as HTMLInputElement;

        let search = "";
        if(TB_keyword) {
            search = TB_keyword.value;
        }
        let searchField = "";
        if(TB_keyword_type) {
            searchField = TB_keyword_type.value;
        }

        const TB_curriYear = document.getElementById("TB_curriYear") as HTMLSelectElement;
        const TB_curriYear_val = curriInfo.curriYear[TB_curriYear.selectedIndex-1];
        const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
        const TB_curriSchool_val = curriInfo.curriSchool[TB_curriSchool.selectedIndex-1];
        const TB_curriSubject = document.getElementById("TB_curriSubject") as HTMLSelectElement;
        const TB_curriSubject_val = curriInfo.curriSubject[TB_curriSubject.selectedIndex-1];
        const TB_curriGrade = document.getElementById("TB_curriGrade") as HTMLSelectElement;
        const TB_curriGrade_val = curriInfo.curriGrade[TB_curriGrade.selectedIndex-1];
        const TB_curriBook = document.getElementById("TB_curriBook") as HTMLSelectElement;
        const TB_curriBook_val = curriInfo.curriBook[TB_curriBook.selectedIndex-1];

        // const TB_Subject = document.getElementById("TB_Subject") as HTMLSelectElement;
        // const TB_Subject_val = teachingSubjectList && (teachingSubjectList as IMeta[]).length > 1 ? teachingSubjectList[TB_Subject.selectedIndex-1] : undefined;
        
        const TB_category = document.getElementById("TB_category") as HTMLSelectElement;
        const TB_category_val = teachingCategoryList && (teachingCategoryList as IMeta[]).length > 1 ? teachingCategoryList[TB_category.selectedIndex-1] : undefined;

        // const TB_usage = document.getElementById("TB_usage") as HTMLSelectElement;
        // const TB_usage_val = teachingUsageList && (teachingUsageList as IMeta[]).length > 1 ? teachingUsageList[TB_usage.selectedIndex-1] : undefined;

        const metaMap : IMeta[] = [];
        if(TB_curriYear_val) {
            metaMap.push(TB_curriYear_val);
        }
        if(TB_curriSchool_val) {
            metaMap.push(TB_curriSchool_val);
        }        
        if(TB_curriSubject_val) {
            metaMap.push(TB_curriSubject_val);
        }        
        if(TB_curriGrade_val) {
            metaMap.push(TB_curriGrade_val);
        }        
        if(TB_curriBook_val) {
            metaMap.push(TB_curriBook_val);
        }        
        // if(TB_Subject_val) {
        //     metaMap.push(TB_Subject_val);
        // }
        if(TB_category_val) {
            metaMap.push(TB_category_val);
        }
        // if(TB_usage_val) {
        //     metaMap.push(TB_usage_val);
        // }        

        const data = {
            brand_id: brand.id ? brand.id : null,
            articleCategory: articleCategoryId,
            articleType: articleTypeId,
            // name: TB_keyword && TB_keyword.value !== "" ? TB_name.value : undefined,
            // keyword: TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined,
            reg_sdate: sdate,  
            reg_edate: edate,

            is_publicOpen: TB_public.value === "" ? undefined : TB_public.value === "1" ? true : false,

            myuid: currArticleCategory === "나의 저작" ? util.getUserInfo("uid") : undefined,

            search: search,
            searchField: searchField,
            
            // difficulty: TB_difficulty.value === "" ? undefined : TB_difficulty.value,

            curriBook: TB_curriBook_val ? TB_curriBook_val.id : undefined,

            metaMap: metaMap,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("Teaching_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }
        
    function onClickSDateIcon() : void {
        const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
        if(new_TB_sdate) {
            // new_TB_sdate.value = "";
            const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
            TB_sdate.value = "";
            TB_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
                    TB_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
        if(new_TB_edate) {
            // new_TB_edate.value = "";
            const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
            TB_edate.value = "";
            TB_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
                    TB_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "TB_sdate") {
            const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
            if(new_TB_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_TB_sdate.value = val;
            }
        } else if(e.target.id === "TB_edate") {
            const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
            if(new_TB_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_TB_edate.value = val;
            }
        }
    }

    function onChangeCurriContents(newArticleCategory: string) {

        setCurrArticleCategory(newArticleCategory);

        // let articleCategoryId = undefined;
        // if(curriInfo && curriInfo.articleCategory) {
        //     const articleCategory = curriInfo.articleCategory as IMeta[];
        //     const _articleCategory = articleCategory.find((article, _) => article.val === newArticleCategory);
        //     if(_articleCategory) {
        //         articleCategoryId = _articleCategory.id;
        //     }
        // }

        // const _searchData = searchData;
        // // if(_searchData) {
        // //     _searchData.articleCategory = articleCategoryId;
        // // }

        // // logger.log("onChangeCurriContents", newArticleCategory, articleCategoryId, _searchData);

        // setSearchData(_searchData);
        // _onSearch(_searchData);
    }

    async function onChangePublicOpen(item: IArticle) {
        const TB_is_publicOpen = document.getElementById("TB_is_publicOpen") as HTMLInputElement;
        if(TB_is_publicOpen) {
            logger.log("TB_is_publicOpen.checked", item, TB_is_publicOpen.checked);

            if(currArticleCategory === "나의 저작") {                
                const data = {
                    id: item.id,
                    is_publicOpen: TB_is_publicOpen.checked,
                };
                logger.log(data);
                await util.setForSave(data, "update");
            } else {
                const data = {
                    saveType: "update",
                    article: {
                        id: item.id,
                        is_publicOpen: TB_is_publicOpen.checked,
                    },
                };
                logger.log(data);
                await util.updateArticle(data);
            }
            
            _onSearch(searchData);
        }
    }

    async function onPreView(item: IArticle) {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        // const sets = await util.getSetsByArticleId(item.id);
        // logger.log("onPreView", sets);
        // if(sets && sets.length > 0) {
        //     const url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
        //     +"&article_id="+item.id+"&token="+util.getUserInfo("token");
        //     window.open(url, "_blank", "noopener, noreferrer");    
        // }

        if(item) {
            if(currArticleCategory === "나의 저작") {                
                const url = "https://ibook.vivasam.com"+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&set_id="+item.id+"&token="+util.getUserInfo("token");
                window.open(url, "_blank", "noopener, noreferrer");            
            } else {
                const url = "https://ibook.vivasam.com"+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&article_id="+item.id+"&token="+util.getUserInfo("token");
                window.open(url, "_blank", "noopener, noreferrer");        
            }
        }

    }

    async function onEditPage(item: IArticle | null) {

        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        if(item) {
            if(currArticleCategory === "나의 저작") {                
                const url = "https://ibook.vivasam.com"+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&set_id="+item.id+"&token="+util.getUserInfo("token");
                logger.log("onEditPage", url);
                window.open(url, "_blank", "noopener, noreferrer");            
            } else {
                const url = "https://ibook.vivasam.com"+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                +"&article_id="+item.id+"&token="+util.getUserInfo("token");
                logger.log("onEditPage", url);
                window.open(url, "_blank", "noopener, noreferrer");        
            }
        }
    }

    async function onDelete(item: IArticle) {

        const me = util.getUserInfo("uid");
        logger.log("onDelete", item, me);

        if(me !== item.creator) {
            props.showModal("", "본인이 등록한 콘텐츠만 삭제할 수 있습니다.");
            return;        
        }

        setViewConfirmDelete(true);
        setDeleteTitle("삭제");
        setDeleteDesc("해당 콘텐츠를 사용중인 i-book이 있는지 확인 하셨습니까?<br/>'" + item.name + "' 작업(수업자료)을 삭제하시겠습니까?");
        setSelectedItem(item);

        // alert("해당 콘텐츠를 사용중인 i-book이 있는지 확인 하셨습니까?");
        
        // if(window.confirm("정말 삭제 하시겠습니까?") === true) {
        //     const data = {
        //         id: item.id,
        //     }
        //     const result = await util.deleteTextbook(item.id!);
        //     logger.log("onDelete", result);
            
        //     _onSearch(searchData);

        //     props.showLoading(false);
        // }
    }

    async function onDeleteOK() {

        onCloseDelete();

        props.showLoading(true);

        const result = await util.deleteSet(selectedItem);
        logger.log("onDelete", result);

        // props.showLoading(false);

        _onSearch(searchData);        

        props.showModal("", "삭제 되었습니다.");
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }

    async function onChangeForCurriBook() {
        
        const brand = util.getCurrentBrand();

        const metaMap: {"name":string, "id":number}[] = [];

        const data = {
            "brand_id": brand?.id,
            "name":"curriBook",
            "metaMap": metaMap,
        }

        if(curriInfo) {

            const TB_curriYear = document.getElementById("TB_curriYear") as HTMLSelectElement;
            if(TB_curriYear) {
                const _curriYear = curriInfo.curriYear.find((meta) => meta.val === TB_curriYear.value)
                if(_curriYear) {
                    metaMap.push({"name":"curriYear", "id":Number(_curriYear.id)});
                }
            }
            const TB_curriSchool = document.getElementById("TB_curriSchool") as HTMLSelectElement;
            if(TB_curriSchool) {
                const _curriSchool = curriInfo.curriSchool.find((meta) => meta.val === TB_curriSchool.value)
                if(_curriSchool) {
                    metaMap.push({"name":"curriSchool", "id":Number(_curriSchool.id)});
                }
            }
            const TB_curriSubject = document.getElementById("TB_curriSubject") as HTMLSelectElement;
            if(TB_curriSubject) {
                const _curriSubject = curriInfo.curriSubject.find((meta) => meta.val === TB_curriSubject.value)
                if(_curriSubject) {
                    metaMap.push({"name":"curriSubject", "id":Number(_curriSubject.id)});
                }
            }
            const TB_curriGrade = document.getElementById("TB_curriGrade") as HTMLSelectElement;
            if(TB_curriGrade) {
                const _curriGrade = curriInfo.curriGrade.find((meta) => meta.val === TB_curriGrade.value)
                if(_curriGrade) {
                    metaMap.push({"name":"curriGrade", "id":Number(_curriGrade.id)});
                }
            }
        }

        data.metaMap = metaMap;
        
        props.showLoading(true);

        const list = await util.searchMeta(data);
        if(list) {
            setTeachingCurriBookList(list);
        }

        logger.log("onChangeForCurriBook", data, curriInfo?.curriBook, list);

        props.showLoading(false);
    }

    function onEditSet() {
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }

        const url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
        +"&create=article"+"&token="+util.getUserInfo("token");
        window.open(url, "_blank", "noopener, noreferrer");
    }
    
    return (
        <div>
            <div className="top-title">
                <h3>콘텐츠 관리</h3>
            </div>
     
            <div className="" role="tabpanel" aria-labelledby="subject-tab"> 
                
                <div className="mb-3 text-right">
                    <button type="button" className="btn-sky" onClick={() => onEditSet()}>저작하기</button>
                </div>

                {/* <!-- 검색영역 --> */}
                <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    <div>
                        <fieldset>
                            <label htmlFor="search-3">교육과정</label>
                            <select id="TB_curriYear" name="search-3" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">학교급</label>
                            <select id="TB_curriSchool" name="search-4" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="search-5" className="ml-4">교수자료 유형</label>
                            <select id="TB_category" name="search-5">
                                <option value={""}>전체</option>
                                {teachingCategoryList && (teachingCategoryList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                    </div>
                    <div className="mt-5">

                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">학년</label>
                            <select id="TB_curriGrade" name="search-4" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriGrade && (curriInfo?.curriGrade as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">교과</label>
                            <select id="TB_curriSubject" name="search-4" onChange={onChangeForCurriBook}>
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriSubject && (curriInfo?.curriSubject as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="search-4" className="ml-4">교과서</label>
                            <select id="TB_curriBook" name="search-4">
                                <option value={""}>전체</option>
                                {teachingCurriBookList && (teachingCurriBookList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>

                        {/* <fieldset>
                            <label htmlFor="search-4" className="ml-4">과목</label>
                            <select id="TB_Subject" name="search-4">
                                <option value={""}>전체</option>
                                {teachingSubjectList && (teachingSubjectList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset> */}

                    </div>

                    {/* <div className="mt-5">
                        <fieldset>
                            <label htmlFor="search-5" className="ml-4">교수자료 용도</label>
                            <select id="TB_usage" name="search-5">
                                <option value={""}>전체</option>
                                {teachingUsageList && (teachingUsageList as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                    </div> */}

                    <div className="mt-3">
                        <fieldset>
                            <label htmlFor="date" className="">등록일​</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='TB_sdate' name='TB_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px] ml-5" onClick={onClickSDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='TB_edate' name='TB_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <label htmlFor="search-4" className="ml-8">콘텐츠 공개 여부</label>
                            <select id="TB_public" name="search-4">
                                <option value={""}>전체</option>
                                <option value={"1"}>공개</option>
                                <option value={"0"}>비공개</option>
                            </select>
                        </fieldset>

                    </div>

                    <div className="mt-3 text-right">

                        <fieldset>
                            <label htmlFor="keyword_type">검색</label>
                            <select className="mr-2" id="TB_keyword_type" name="keyword_type">
                                {/* <option value={"keyword"}>검색어</option> */}
                                <option value={"name"}>제목</option>
                                <option value={"creator"}>등록자</option>
                            </select>
                            <input type="text" name="keyword" id="TB_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>                        
                </div>

                {/* <!-- .//검색영역 --> */}

                <div className="mt-6 mb-3">
                    <form action="" className="flex justify-between">
                        <fieldset>
                            <span>총 <strong className="text-red">{listCount}</strong>건</span>
                            <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                            <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={100}>100</option>
                            </select>
                        </fieldset>
                        {/* <fieldset aria-labelledby="radiogruop" className="mt-2 items-center">
                            <input type="radio" id="rd-viewlist1" name="rd-viewlist" className="items-center" onChange={onChangeViewType} />
                            <label htmlFor="rd-viewlist1" className="items-center ml-1">간단히</label>
                            <input type="radio" id="rd-viewlist2" name="rd-viewlist" className="items-center" onChange={onChangeViewType}/>
                            <label htmlFor="rd-viewlist2" className="items-center ml-1">큰 아이콘</label>
                        </fieldset> */}
                    </form>
                    
                </div>

                <div className="mb-4 border-b border-gray-200">
                    <ul className="flex flex-wrap -mb-px font-medium text-center" id="default-tab" role="tablist">
                        <li className="w-1/2" role="presentation">
                            <button className={currArticleCategory === "나의 저작" ? "inline-block p-4 border-b-2 rounded-t-lg w-full text-sky-500 border-sky-500" : "inline-block p-4 border-b-2 rounded-t-lg w-full"} 
                            id="subject-tab" type="button" role="tab" aria-controls="subject" aria-selected={currArticleCategory === "공개 콘텐츠"} onClick={() => onChangeCurriContents("나의 저작")}>나의 저작</button>
                        </li>
                        <li className="w-1/2" role="presentation">
                            <button className={currArticleCategory === "공개 콘텐츠" ? "inline-block p-4 border-b-2 rounded-t-lg w-full text-sky-500 border-sky-500" : "inline-block p-4 border-b-2 rounded-t-lg w-full"} 
                            id="nosubject-tab" type="button" role="tab" aria-controls="nosubject" aria-selected={currArticleCategory === "나의 저작"} onClick={() => onChangeCurriContents("공개 콘텐츠")}>공개 콘텐츠</button>
                        </li>
                    </ul>
                </div>

                {/* <!-- table --> */}
                {viewList &&
                <table>
                    <caption className="sr-only">교과 콘텐츠 테이블</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="pl-4 pr-3">번호</th>
                            <th scope="col" className="px-3">Key</th>
                            <th scope="col" className="px-3">교육과정</th>
                            <th scope="col" className="px-3">학교급</th>
                            <th scope="col" className="px-3">교과서</th>
                            <th scope="col" className="px-3">콘텐츠 명</th>
                            <th scope="col" className="px-3">콘텐츠 공개</th>
                            <th scope="col" className="px-3">등록자</th>
                            <th scope="col" className="px-3">등록일</th>
                            <th scope="col" className="px-3">최근수정일</th>
                            <th scope="col" className="px-3">미리보기</th>
                            <th scope="col" className="px-3">편집</th>
                            {currArticleCategory === "나의 저작" &&
                            <th scope="col" className="px-3">삭제</th>}
                            <th scope="col" className="pl-3 pr-4">i-book</th>
                        </tr>
                    </thead>
                    <tbody>

                    {viewList && list &&
                        <tr style={{display: (list as IArticle[]).length === 0 ? "" : "none"}}>
                            <td colSpan={currArticleCategory === "나의 저작" ? 14 : 13}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                    {viewList && list && (list as IArticle[]).map((item, idx) => {

                        let _unitVal = "";
                        let _curriBookVal = ""

                        if(curriInfo && curriInfo.curriBook) {
                            const curriBook = curriInfo.curriBook as IMeta[];
                            const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                            _curriBookVal = _curriBook ? _curriBook.val : "";
                        }

                        const totalCnt = item.full_count;
                        const _numOfPage = localStorage.getItem("Teaching_numOfPage") ? Number(localStorage.getItem("Teaching_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        let _articleTypeVal = "";
                        if(curriInfo && curriInfo.articleType) {
                            const articleType = curriInfo.articleType as IMeta[];
                            const _articleType = articleType.find((article, _) => article.id === item.articleType);
                            _articleTypeVal = _articleType ? _articleType.val : "-";
                        }

                        let updateDate = "-";
                        if(item.updatedate) {
                            updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                        }
                        
                        let hashTag = "";
                        const arrHashTag = item.hashTag as string[];
                        if(arrHashTag) {
                            arrHashTag.map((item) => {
                                if(item.trim() !== "") {
                                    hashTag = hashTag + (item[0] !== "#" ? "#" : "") + item + " ";
                                }
                            });    
                        }

                        let libraryIds = "";
                        const libraryMap = item.libraryMap;
                        if(libraryMap) {
                            libraryMap.map((item) => {
                                if(libraryIds === "") {
                                    libraryIds = String(item.id);
                                } else {
                                    libraryIds = libraryIds + "," + item.id;
                                }
                            });
                        }

                        const curriYear = curriInfo?.curriYear.find((_item) => _item.id === item.curriYear);
                        const curriYear_val = curriYear?.val;
                        const curriSchool = curriInfo?.curriSchool.find((_item) => _item.id === item.curriSchool);
                        const curriSchool_val = curriSchool?.val;
                        
                        return (

                            <tr key={idx}>
                                <td>{_num}</td>
                                <td>{item.id}</td>
                                <td>{curriYear_val}</td>
                                <td>{curriSchool_val}</td>
                                <td>{_curriBookVal}</td>
                                <td>{item.name}</td>
                                <td>
                                    <label className="inp-toggle">
                                        <input type="checkbox" id="TB_is_publicOpen" value="" className="peer" defaultChecked={item.is_publicOpen} onChange={() => onChangePublicOpen(item)}/>
                                        <div className="peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-sky-500"></div>
                                    </label>
                                </td>
                                <td>{item.creator}</td>
                                <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                <td>{updateDate}</td>
                                <td><button type="button" className="btn-white-s" onClick={() => onPreView(item)}>미리보기</button></td>
                                <td><button type="button" className="btn-white-s" disabled={item.review === 1} onClick={() => onEditPage(item)}>편집</button></td>
                                {currArticleCategory === "나의 저작" &&
                                <td><button type="button" className="btn-white-s" onClick={() => onDelete(item)}>삭제</button></td>}
                                <td>
                                </td>
                            </tr>
                        )
                        })}

                    </tbody>
                </table>}
                {/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div>
            </div>

            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />

        </div>
    );  
}
export default TeachingContentsList;
