import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IAuthGroup, IBrand, IMenu, IUser, IUserAuthGroupMap, headers } from "../common";
import PopupCheckID from "./popupDupCheck";
import { logger } from '../logger';

interface IAdmin {
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

interface IBrandAuthGroup {
    brandName: string;
    authgroup: IAuthGroup[];
}

function Admin(props: IAdmin) {

    const [userList, setUserList] = useState([] as IUser[]);
    const [searchedList, setSearchedList] = useState([] as IUser[]);
    const [viewList, setViewList] = useState(false);

    const [brandIds, setBrandIds] = useState([] as number[]);
    const [authGroupList, setAuthGroupList] = useState([] as IAuthGroup[]);
    const [brandList, setBrandList] = useState([] as IBrand[]);
    
    const [searchData, setSearchData] = useState(null as any);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);
    // const [selectedUserAuthGroup, setSelectedUserAuthGroup] = useState([] as IAuthGroup[]);
    const [modalShow, setModalShow] = useState(false);
    const [msgCheckPwd, setMsgCheckPwd] = useState("");
    const [checkedId, setCheckedId] = useState("");
    
    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setIsEditing(false);
            getUserList();
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.menu) {
            if(isEditing) {
                setIsEditing(false);
            }
        }
    }, [props.menu])

    useEffect(() => {

        if(isEditing) {

            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     return;
            // }    

            const U_name = (document.getElementById('U_name') as HTMLInputElement);
            if(U_name) {
                U_name.focus();
            }                    
            const U_is_active = (document.getElementById('U_is_active') as HTMLInputElement);
            if(U_is_active) {
                U_is_active.checked = true;
            }
            const U_user_role = (document.getElementById('U_user_role') as HTMLInputElement);
            if(U_user_role) {
                U_user_role.checked = true;
            }

            {brandIds && brandIds.map((_, idx) => {
                (document.getElementById("A_auth_check_"+idx) as HTMLInputElement).checked = false;
                (document.getElementById("A_auth_"+idx) as HTMLSelectElement).selectedIndex = 0;
            })}
    
            (document.getElementById('U_id') as HTMLInputElement).readOnly = true;

            setMsgCheckPwd("");

            const _selectedItem = selectedItem as IUser;
            if(_selectedItem) {
    
                (document.getElementById('U_name') as HTMLInputElement).value = _selectedItem.name;
                (document.getElementById('U_id') as HTMLInputElement).value = _selectedItem.uid;
                (document.getElementById('U_pwd') as HTMLInputElement).value = _selectedItem.password;
                (document.getElementById('U_pwdc') as HTMLInputElement).value = "";
                (document.getElementById('U_email') as HTMLInputElement).value = _selectedItem.email ? _selectedItem.email : "";
                (document.getElementById('U_team') as HTMLInputElement).value = _selectedItem.team ? _selectedItem.team : "";

                // (document.getElementById('U_name') as HTMLInputElement).readOnly = true;
                (document.getElementById('U_id') as HTMLInputElement).readOnly = true;
                (document.getElementById('U_pwd') as HTMLInputElement).readOnly = true;
                (document.getElementById('U_pwdc') as HTMLInputElement).readOnly = true;
                // (document.getElementById('U_email') as HTMLInputElement).readOnly = true;
                // (document.getElementById('U_team') as HTMLInputElement).readOnly = true;

                (document.getElementById('U_is_active') as HTMLInputElement).checked = _selectedItem.is_active;    
                (document.getElementById('U_non_active') as HTMLInputElement).checked = !_selectedItem.is_active;    

                (document.getElementById('U_user_role') as HTMLInputElement).checked = Number(_selectedItem.role) < 100 ? true : false;    
                (document.getElementById('U_admin_role') as HTMLInputElement).checked = Number(_selectedItem.role) > 100 ? true : false;    

                {brandIds && brandIds.map((item, idx) => {

                    const auths = authGroupList.filter((authGroup) => authGroup.brand_id === item);    

                    if(_selectedItem && _selectedItem.authgroup) {
                        const arrAuth = _selectedItem.authgroup.split(",");
                        arrAuth.map((auth, i) => {
                            const found = auths.find((item) => item.id === Number(auth));
                            if(found) {
                                (document.getElementById("A_auth_check_"+idx) as HTMLInputElement).checked = true;    
                                (document.getElementById("A_auth_"+idx) as HTMLSelectElement).value = found.name;
                            }
                        });    
                    }    
                })}
            }
        } else {
                (document.getElementById('U_name') as HTMLInputElement).readOnly = false;
                (document.getElementById('U_id') as HTMLInputElement).readOnly = false;
                (document.getElementById('U_pwd') as HTMLInputElement).readOnly = false;
                (document.getElementById('U_pwdc') as HTMLInputElement).readOnly = false;
                (document.getElementById('U_email') as HTMLInputElement).readOnly = false;
                (document.getElementById('U_team') as HTMLInputElement).readOnly = false;

            (document.getElementById('U_name') as HTMLInputElement).value = "";
            (document.getElementById('U_id') as HTMLInputElement).value = "";
            (document.getElementById('U_pwd') as HTMLInputElement).value = "";
            (document.getElementById('U_pwdc') as HTMLInputElement).value = "";
            (document.getElementById('U_email') as HTMLInputElement).value = "";
            (document.getElementById('U_team') as HTMLInputElement).value = "";
            (document.getElementById('U_is_active') as HTMLInputElement).checked = true;
        }

    }, [isEditing])

    async function getUserList() {

        props.showLoading(true);

        const userList = await util.getUserList();
        if(!userList) {
            alert("가져오기 실패!!!");
            return;
        }
        const _userList = userList.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        logger.log("userList", _userList);

        setUserList(_userList);
        setSearchedList(_userList);

        const authGroupList = await util.getAuthGroupList();
        const brandList = await util.getBrandList();

        setAuthGroupList(authGroupList);
        setBrandList(brandList);

        let arrBrand: number[] = [];
        authGroupList.map((item) => {
            if(!arrBrand.find((id) => id === item.brand_id)) {
                arrBrand.push(item.brand_id ? item.brand_id : -1);
            }
        });
        setBrandIds(arrBrand);

        logger.log("authGroupList", authGroupList, arrBrand);

        setViewList(true);

        props.showLoading(false);
    }

    async function onEdit(item: IUser | null = null) {
        setIsEditing(true);
        setSelectedItem(item);
    }

    function onSearch(): void {

        const U_Active = document.getElementById("U_Active") as HTMLSelectElement;
        const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement).value;
        const U_Key = document.getElementById("U_Key") as HTMLSelectElement;

        const searchData = {
            "active":U_Active.value,
            "key":U_Key.value,
            "value":searchKeyword,
        }

        const result : IUser[] = [];
        let filterdUser : IUser[] = [];
        if(searchData.active === "1") {
            filterdUser = userList.filter((item) => item.is_active === true);
        } else if(searchData.active === "0") {
            filterdUser = userList.filter((item) => item.is_active === false);
        } else {
            filterdUser = userList;
        }
        if(searchData.value !== "") {
            if(searchData.key === "uid") {
                filterdUser.map((item) => {
                    if(item.uid.indexOf(searchData.value) > -1) {
                        result.push(item);
                    }
                });
            } else if(searchData.key === "uname") {
                filterdUser.map((item) => {
                    if(item.name && item.name.indexOf(searchData.value) > -1) {
                        result.push(item);
                    }
                });
            } else if(searchData.key === "team") {
                filterdUser.map((item) => {
                    if(item.team && item.team.indexOf(searchData.value) > -1) {
                        result.push(item);
                    }
                });
            }    
        } else {
            filterdUser.map((item) => {
                result.push(item);
            });
        }

        setSearchedList(result);
        logger.log("onSearch", searchData, result);
    }

    async function onSave() {

        const uid = (document.getElementById('U_id') as HTMLInputElement).value
        const name = (document.getElementById('U_name') as HTMLInputElement).value
        const pwd = (document.getElementById('U_pwd') as HTMLInputElement).value
        const pwdc = (document.getElementById('U_pwdc') as HTMLInputElement).value
        const email = (document.getElementById('U_email') as HTMLInputElement).value
        const team = (document.getElementById('U_team') as HTMLInputElement).value
        const is_active = (document.getElementById('U_is_active') as HTMLInputElement).checked
        const user_role = (document.getElementById('U_user_role') as HTMLInputElement).checked

        const authgroup : IAuthGroup[] = [];
        {brandIds && brandIds.map((item, idx) => {
            const checked = (document.getElementById("A_auth_check_"+idx) as HTMLInputElement).checked;
            if(checked) {
                const checkedAuth = (document.getElementById("A_auth_"+idx) as HTMLSelectElement);
                const auths = authGroupList.filter((authGroup) => authGroup.brand_id === item);                                    
                const auth = auths[checkedAuth.selectedIndex];
                authgroup.push(auth);
            }
        })}
        logger.log("authgroup", authgroup);

        if(name === "") {
            props.showModal("", "등록 하려는 회원의 이름을 입력해 주세요.");
            (document.getElementById('U_name') as HTMLInputElement).focus();
            return;
        } else if(uid === "") {
            props.showModal("", "아이디를 입력해 주세요.");
            (document.getElementById('U_id') as HTMLInputElement).focus();
            return;
        } else if(pwd === "") {
            if(!selectedItem) {
                props.showModal("", "비밀번호를 입력해 주세요.");
                (document.getElementById('U_pwd') as HTMLInputElement).focus();
                return;    
            }
        } else if(pwdc === "") {
            if(!selectedItem) {
                props.showModal("", "비밀번호 확인을 입력해 주세요.");
                (document.getElementById('U_pwdc') as HTMLInputElement).focus();
                return;
            }
        } else if(pwd !== pwdc) {
            if(!selectedItem) {
                props.showModal("", "비밀번호와 비밀번호 확인을 동일하게 입력해 주세요.");
                return;
            }
        } else if(email === "") {
            props.showModal("", "이메일 주소를 입력해 주세요.");
            (document.getElementById('U_email') as HTMLInputElement).focus();
            return;
        } else if(team === "") {
            props.showModal("", "부서명을 입력해 주세요.");
            (document.getElementById('U_team') as HTMLInputElement).focus();
            return;
        } else if(authgroup.length === 0) {
            props.showModal("", "권한 그룹 설정을 입력해 주세요.");
            return;
        }

        const creator_id = util.getUserInfo("id");
        const creator = util.getUserInfo("uid");
        const creator_name = util.getUserInfo("name");

        const _selectedItem = selectedItem as IUser;

        let sAuth = "";
        authgroup.map((auth => {
            sAuth = sAuth + String(auth.id) + ",";
        }));

        const data: IUser = {
            id: _selectedItem ? _selectedItem.id : undefined,
            uid: uid,
            name: name,
            password: _selectedItem ? "" : pwd,
            email: email,
            team: team,
            is_active: is_active,
            creator: _selectedItem ? undefined : creator,
            creator_id: _selectedItem ? undefined : creator_id,
            creator_name: _selectedItem ? undefined : creator_name,
            updater: _selectedItem ? creator : undefined,
            updater_id: _selectedItem ? creator_id : undefined,
            updater_name: _selectedItem ? creator_name : undefined,
            authgroup: sAuth,
            role: user_role ? "1" : "101",
        }

        props.showLoading(true);

        if(_selectedItem) {
            await util.updateUser(data);
        } else {
            await util.addUser(data);
        }

        setIsEditing(false);
        getUserList();

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");

        logger.log("onSave", data, _selectedItem);
    }

    function onCheckId(): void {
        setModalShow(true);
    }

    function onCloseModal() {
        setModalShow(false);

        // const uid = (document.getElementById('U_id') as HTMLInputElement).value;
        // const user = userList.find((user) => user.uid === uid);
        // if(user) {
        //     props.showModal("", "이미 등록된 아이디 입니다.");
        //     (document.getElementById('U_id') as HTMLInputElement).focus();
        //     (document.getElementById('U_id') as HTMLInputElement).select();
        //     return;
        // }
    }

    function onUseId(uid: string): void {
        (document.getElementById('U_id') as HTMLInputElement).value =  uid;
        setCheckedId(uid);
        setModalShow(false);
    }

    return (

        <>
        {/* <!-- 검색영역 --> */}
        <div className="px-8 py-8 mb-8 border border-gray-300 rounded-xl" style={{display:isEditing ? "none" : ""}}>
            <div className="text-right">
                <fieldset>
                    <label htmlFor="">사용 설정</label>
                    <select id="U_Active">
                        <option value="">전체</option>
                        <option value="1">사용</option>
                        <option value="0">미사용</option>
                    </select>
                    <label htmlFor="U_Key" className="ml-3">검색 선택</label>
                    <select id="U_Key">
                        <option value="uname">이름</option>
                        <option value="uid">아이디</option>
                        <option value="team">부서명</option>
                    </select>
                    <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            onSearch();
                        }
                    }} />
                    <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                        <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                    </button>
                </fieldset>
            </div>
        </div>
        {/* <!-- .//검색영역 --> */}        
    
        <table style={{display:isEditing ? "none" : ""}}>
            <caption className="sr-only">브랜드 관리</caption>
            <thead>
                <tr>
                    <th scope="col" className="pl-4 pr-3" style={{width:"80px"}}>번호</th>
                    <th scope="col" className="px-3">계정</th>
                    <th scope="col" className="px-3">부서명</th>
                    <th scope="col" className="px-3">권한그룹</th>
                    <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                    <th scope="col" className="px-3" style={{width:"100px"}}>등록자​</th>
                    <th scope="col" className="px-3" style={{width:"100px"}}>등록일​</th>
                    <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
                </tr>
            </thead>
            
            <tbody>
                {viewList && searchedList &&
                <tr style={{display: (searchedList as IUser[]).length === 0 ? "" : "none"}}>
                    <td colSpan={8}>등록된 메타 정보가 없습니다.</td>
                </tr>}
    
                {viewList && searchedList && (searchedList as IUser[]).map((item, idx) => {
                    let sAuth = "";
                    if(item.authgroup) {
                        const arrAuth = item.authgroup.split(",");
                        arrAuth.map((aid) => {
                            if(aid) {
                                const auths = authGroupList.find((authGroup) => authGroup.id === Number(aid)); 
                                sAuth = sAuth + auths?.name + "<br/>";
                            }
                        });
                    }

                return (
                    <tr key={idx}>
                        <td>{searchedList.length-idx}</td>
                        <td>{item.name} ({item.uid})</td>
                        <td>{item.team}</td>
                        <td dangerouslySetInnerHTML={{ __html: sAuth }}></td>
                        {item.is_active && <td>사용</td>}
                        {!item.is_active && <td><span className="text-red-600">미사용</span></td>}
                        <td className="leading-4">{item.creator}</td>
                        <td className="leading-4">{item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>
                        <td>
                            <button type="button" className="btn-white-s" onClick={() => onEdit(item)}>보기</button>
                        </td>
                    </tr>
                    );
                })}
            </tbody>
    
        </table>
    
        <div className="relative mt-5" style={{display:isEditing ? "none" : ""}}>
            <div className="flex">
                <div className="text-right" style={{width: "100%"}}>
                    <button type="button" className="btn-sky" onClick={() => onEdit()}>등록</button>
                </div>
            </div>
        </div>
    
        <table style={{display:isEditing ? "" : "none"}}>
            <caption className="sr-only">관리자 계정 관리</caption>
            <tbody>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">이름 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_name" onChange={() => {}}/>
                        <span className="ml-3">(30 Byte)</span>
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">아이디 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_id" onChange={() => {}}/>
                        <span className="ml-3">(30 Byte)</span>                        
                        <button type="button" className="btn-white-s ml-3" onClick={onCheckId} style={{display: selectedItem ? "none" : ""}}>중복확인</button>                        
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">비밀번호 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="password" id="U_pwd" onChange={() => {}}/>
                        <span className="ml-3">(30 Byte)</span>
                        <span className="ml-3">※ 8~16자 문자, 숫자를 결합하여 입력해 주세요.</span>
                    </td>
                </tr>
                <tr style={{display:selectedItem ? "none" : ""}}>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">비밀번호 확인 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left sky">
                        <input type="password" id="U_pwdc" onChange={() => {
                            const pwd = (document.getElementById('U_pwd') as HTMLInputElement).value
                            const pwdc = (document.getElementById('U_pwdc') as HTMLInputElement).value                        
                            if(pwd.length > 0 && pwdc.length > 0) {
                                if(pwd !== pwdc) {
                                    setMsgCheckPwd("비밀번호가 일치하지 않습니다.");
                                } else if(pwd === pwdc) {
                                    setMsgCheckPwd("비밀번호가 일치합니다.");                                
                                }    
                            }
                        }}/>
                        <span className="ml-3">(30 Byte)</span>
                        <span className="ml-3">{msgCheckPwd}</span>
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">이메일 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_email" onChange={() => {}}/>
                        <span className="ml-3">(50 Byte)</span>
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">부서명 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="U_team" onChange={() => {}}/>
                        <span className="ml-3">(30 Byte)</span>
                    </td>
                </tr>

                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">권한 그룹​<br/>(KEY)<em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">

                                <ul>
                                {brandIds && brandIds.map((item, idx) => {

                                    const brand = brandList.find((brand) => brand.id === item);
                                    const auths = authGroupList.filter((authGroup) => authGroup.brand_id === item);    

                                    return(
                                        // <tr key={idx} style={{borderTopWidth:0, borderBottomWidth:0}}>
                                        //     <td className="text-left w-56">
                                        //         <input id={"A_auth_check_"+idx} type="checkbox" /><span style={{paddingLeft:"10px"}}>{brand?.name}</span>
                                        //     </td>
                                        //     <td className="text-left" style={{borderLeftWidth:0}} >
                                        //         <select id={"A_auth_"+idx} onChange={() => {}}>
                                        //         {auths && auths.map((auth, idx2) => {
                                        //             return(
                                        //                 <option key={idx2} value={auth.name}>{auth.name}</option>
                                        //             )
                                        //         })}
                                        //         </select>
                                        //     </td>
                                        // </tr>

                                        <li className="flex items-center" key={idx} style={{paddingBottom:"0.5rem"}}>
                                            <input id={"A_auth_check_"+idx} type="checkbox" />
                                            <label htmlFor="chd-advise1" className="ml-2 w-36">{brand?.name}</label>
                                            <select id={"A_auth_"+idx} onChange={() => {}}>
                                            {auths && auths.map((auth, idx2) => {
                                                return(
                                                    <option key={idx2} value={auth.name}>{auth.name}</option>
                                                )
                                            })}
                                            </select>
                                        </li>

                                    )
                                    })}

                                </ul>

                    </td>

                </tr>


                <tr>
                    <th scope="row" className="w-56">역할(Role)</th>
                    <td className="text-left">
                        <div className="radio-wrap">
                            <span className="inp-radio text-sky-500">
                                <input id="U_user_role" name="rd-role" type="radio" />
                                <label htmlFor="rd-on">사용자</label>
                            </span>
                            <span className="inp-radio text-red-600">
                                <input id="U_admin_role" name="rd-role" type="radio" />
                                <label htmlFor="rd-off">관리자</label>
                            </span>
                        </div>

                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56">등록자</th>
                    {selectedItem &&
                    <td className="text-left">{selectedItem.creator_name} ({selectedItem.creator})</td>}
                    {!selectedItem &&
                    <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                </tr>
                <tr>
                    <th scope="row" className="w-56">등록일</th>
                    {selectedItem &&
                    <td className="text-left">{selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
                    {!selectedItem &&
                    <td className="text-left">{util.getDate()}</td>}
                </tr>
                <tr>
                    <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                    <td className="text-left">
                        <div className="radio-wrap">
                            <span className="inp-radio text-sky-500">
                                <input id="U_is_active" name="rd-use" type="radio" />
                                <label htmlFor="rd-on">사용</label>
                            </span>
                            <span className="inp-radio text-red-600">
                                <input id="U_non_active" name="rd-use" type="radio" />
                                <label htmlFor="rd-off">미사용</label>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
                
        <div className="relative mt-5" style={{display:isEditing ? "" : "none"}}>
            <div className="flex justify-between">
                <button type="button" className="btn-white" onClick={() => {
                    setIsEditing(false);
                }}>목록</button>
                <button type="button" className="btn-sky" onClick={onSave}>{selectedItem ? "수정" : "등록"}</button>
            </div>
        </div>

        <PopupCheckID view={modalShow} onClose={onCloseModal} onUseId={onUseId} userList={userList} checkedId={checkedId} />

        </>);
}
export default Admin;
