import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as util from '../util';
import { IArticle, IBrand, ICurriInfo, IMeta, IMetaMap } from "../common";
import { logger } from '../logger';
import PopupContentsList from "../contents/PopupContentsList";
import PopupTutorConceptEdit from "./PopupTutorConceptEdit";

interface IMetaStudyMapEdit {
    brand: IBrand;
	item: IMeta | null;
	parentItem: IMeta | null;
	metalist: IMeta[];
    type: string;
    onCloseEdit: (isEdited: boolean, from: string) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function MetaStudyMapEdit(props: IMetaStudyMapEdit) {

    const [view, setView] = useState(false);
    const [UUID, setUUID] = useState("");
    const [contentArea, setContentArea] = useState<IMeta[]>([]);
    const [depth1List, setDepth1List] = useState<IMeta[]>([]);

    const [standardList, setStandardList] = useState<string[]>([""]);
    const [preStudyList, setPreStudyList] = useState<string[]>([""]);
    const [postStudyList, setPostStudyList] = useState<string[]>([""]);
    const [achievementStandardList, setAchievementStandardList] = useState<string[]>([""]);
    const [achievementStandardDescList, setAchievementStandardDescList] = useState<string[]>([""]);
    const [representativeContentsList, setRepresentativeContentsList] = useState<IArticle[]>([] as any);
    const [viewPopupContentsList, setViewPopupContentsList] = useState(false);
    const [selectedContentsId, setSelectedContentsId] = useState(-1);

    const [viewPopupTutorConcept, setViewPopupTutorConcept] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const curriInfo = util.getCurrentCurriInfo();

    const currMenu = util.getCurrentMenu();

    const deli = "#^|";

    let bDone = false;
    useEffect(() => {

        // const editAuth = util.getEditAuth();
        // if(!editAuth[currMenu.menu1]) {
        //     props.showModal("", "권한이 없습니다.");
        //     props.onCloseEdit(false, "");
        //     return;
        // }

        if(!bDone) {

            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);

            if(props.type === "unit1") {

                getContentArea();

                const metalist = props.metalist.filter((item) => item.depth === 2);
                const meta_code = (document.getElementById('meta_code') as HTMLInputElement);
                if(metalist.length > 0) {
                    meta_code.value = metalist[0].code;
                    meta_code.readOnly = true;
                    meta_code.disabled = true;
                }
                setDepth1List(metalist);

                const item = props.item;
                if(item) {
                    if(item.uuid && item.uuid.length > 10) {
                        setUUID(item.uuid);
                    }

                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                        meta_code.readOnly = true;
                        meta_code.disabled = true;
                    }
        
                } else {
                    if(metalist.length > 0) {
                        const studyMap1 = metalist[0];  
                        if(studyMap1.uuid && studyMap1.uuid.length > 10) {
                            setUUID(studyMap1.uuid);
                        }          
                    } else {
                        props.showModal("", "대단원이 없습니다.");
                        props.onCloseEdit(false, "");
                    }
                }
                
            } else if(props.type === "1") {

                const item = props.item;
                logger.log("item", item);

                if(item) {
                    
                    const meta_val = (document.getElementById('meta_val') as HTMLInputElement)
                    if(meta_val) {
                        meta_val.value = item.val;
                    }
                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                        meta_code.readOnly = true;
                        meta_code.disabled = true;
                    }

                    if(item.val1) {
                        const arrVal1 = item.val1.split(deli);
                        const _standardList : string[] = [];
                        arrVal1.map((item, idx) => {
                            _standardList.push(item);
                        });
                        setStandardList(_standardList)
                    } else {
                        setStandardList([""]);
                    }

                    if(item.val2) {
                        const arrVal2 = item.val2.split(deli);
                        const _preStudyList : string[] = [];
                        arrVal2.map((item, idx) => {
                            _preStudyList.push(item);
                        });
                        setPreStudyList(_preStudyList)
                    } else {
                        setPreStudyList([""]);
                    }

                    if(item.val3) {
                        const arrVal3 = item.val3.split(deli);
                        const _postStudyList : string[] = [];
                        arrVal3.map((item, idx) => {
                            _postStudyList.push(item);
                        });
                        setPostStudyList(_postStudyList)
                    } else {
                        setPostStudyList([""]);
                    }

                    if(item.val4) {
                        const arrVal4 = item.val4.split(deli);
                        const _achievementStandardList : string[] = [];
                        arrVal4.map((item, idx) => {
                            _achievementStandardList.push(item);
                        });
                        setAchievementStandardList(_achievementStandardList)
                    } else {
                        setAchievementStandardList([""]);
                    }

                    if(item.val5) {
                        const arrVal5 = item.val5.split(deli);
                        const _achievementStandardDescList : string[] = [];
                        arrVal5.map((item, idx) => {
                            _achievementStandardDescList.push(item);
                        });
                        setAchievementStandardDescList(_achievementStandardDescList)
                    } else {
                        setAchievementStandardDescList([""]);
                    }
                }

            } else if(props.type === "2") {

                setRepresentativeContentsList([{id:-1, content_type:""} as IArticle]);

                const item = props.item;

                if(item) {
                    
                    const meta_val = (document.getElementById('meta_val') as HTMLInputElement)
                    if(meta_val) {
                        meta_val.value = item.val;
                    }
                    const meta_description = (document.getElementById('meta_description') as HTMLInputElement)
                    if(meta_description) {
                        meta_description.value = item.description ? item.description : "";
                    }
                    const meta_code = (document.getElementById('meta_code') as HTMLInputElement)
                    if(meta_code) {
                        meta_code.value = item.code;
                        meta_code.readOnly = true;
                        meta_code.disabled = true;
                    }

                    if(item.val1) {
                        const arr = JSON.parse(item.val1) as IArticle[];
                        if(arr && arr.length > 0) {
                            setRepresentativeContentsList(JSON.parse(item.val1));
                        } else {
                            setRepresentativeContentsList([{id:-1, content_type:""} as IArticle]);
                        }
                    }
                }
            }

            const active = (document.getElementById('active') as HTMLInputElement);
            const non_active = (document.getElementById('non_active') as HTMLInputElement);
            
            if(props.item) {
                active.checked = props.item.is_active;
                non_active.checked = !props.item.is_active;
            } else {
                active.checked = true;
            }
            
            // const meta_val = (document.getElementById('meta_val') as HTMLInputElement);

            // (document.getElementById('active') as HTMLInputElement).checked = true;
            // (document.getElementById('non_active') as HTMLInputElement).checked = false;
    
            // if(props.item) {
            //     if(!props.item.is_active) {
            //         (document.getElementById('active') as HTMLInputElement).checked = false;
            //         (document.getElementById('non_active') as HTMLInputElement).checked = true;
            //     }
            //     if(meta_val)
            //         (document.getElementById('meta_val') as HTMLInputElement).value = props.item.val;

            //     (document.getElementById('meta_description') as HTMLInputElement).value = props.item.description ? props.item.description : "";
            //     (document.getElementById('meta_code') as HTMLInputElement).value = props.item.code;
            //     (document.getElementById('meta_code') as HTMLInputElement).readOnly = false;
            //     const maxDepth = (document.getElementById('max_depth') as HTMLSelectElement);
            //     if(maxDepth) {
            //         maxDepth.value = props.item.max_depth ? String(props.item.max_depth) : "";
            //     }
    
            //     getMetaOfMetaExById(String(props.item.id));
            // }

            // if(meta_val)
            //     (document.getElementById('meta_val') as HTMLInputElement).focus();
        }
        bDone = true;

    }, [])

    useEffect(() => {
        if(representativeContentsList && representativeContentsList.length > 0) {
            logger.log("representativeContentsList", representativeContentsList);
            onContents2UI();
        }
    }, [representativeContentsList])

    useEffect(() => {
        if(contentArea && contentArea.length > 0) {
            if(props.item) {
                getMetaOfMetaExById(String(props.item.id));
            }
        }
    }, [contentArea])

    useEffect(() => {
        if(depth1List && depth1List.length > 0) {
            if(props.item) {
                const meta_unit1 = document.getElementById("meta_unit1") as HTMLSelectElement;
                if(meta_unit1) {
                    // meta_unit1.disabled = true;
                    meta_unit1.value = props.item.val;  
                    
                    logger.log("depth1List", depth1List, props.item.val);

                }
            }
        }
    }, [depth1List])

    async function getMetaOfMetaExById(id: string) {
        
        const list = await util.getMetaOfMetaExById(id);
        logger.log("getMetaOfMetaExById", list);

        const _contentArea = list.find((meta, _) => meta.name === "contentArea");
        if(_contentArea) {
            const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
            meta_contentArea.value = _contentArea.val;    
        }
    }

    async function getContentArea() {
        const list = await getMetaList("1", "contentArea");
        setContentArea(list);
    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    async function insertMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);

        const result = await util.insertMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function updateMetaMetaMap(metaMapList: IMetaMap) {

        props.showLoading(true);
        
        const result = await util.updateMetaMetaMap(metaMapList);
        props.onCloseEdit(true, "");

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    async function onSave() {

        const brand = util.getCurrentBrand();
        if(!brand) {
            props.showModal("", "브랜드가 정상적이지 않습니다.");
            return;
        }

        let val = "";
        const meta_val = (document.getElementById('meta_val') as HTMLInputElement);
        if(meta_val)
        {
            val = (document.getElementById('meta_val') as HTMLInputElement).value
            if(val === "") {
                props.showModal("", "메타값을 입력해 주세요.");
                return;
            }
        }
        const description = (document.getElementById('meta_description') as HTMLInputElement).value
        const code = (document.getElementById('meta_code') as HTMLInputElement).value
        const is_active = (document.getElementById('active') as HTMLInputElement).checked

        if(code === "") {
            props.showModal("", "메타코드를 입력해 주세요.");
            return;
        }

        // var regType1 = /^[A-Za-z0-9]+$/;
        // if (!regType1.test(code)) { 
        //     props.showModal("", "code는 영문자, 숫자만 가능합니다.");
        //     return;
        // }

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        if(props.type === "unit1") {

            const meta_unit1 = document.getElementById("meta_unit1") as HTMLSelectElement;
            const studyMap1 = depth1List[meta_unit1.selectedIndex];    

            let studyMap1Val = studyMap1.val;
            let meta_mapping_meta : IMeta[] = [];
            const meta_contentArea = document.getElementById("meta_contentArea") as HTMLSelectElement;
            if(meta_contentArea) {
                const meta_contentArea_val = contentArea[meta_contentArea.selectedIndex];
                // studyMap1Val = studyMap1Val + "(내용영역:" + meta_contentArea_val.val + ")";
                meta_mapping_meta.push(meta_contentArea_val);
            }

            const meta = {
                id: studyMap1.id,

                // code: studyMap1.code,
                // name: studyMap1.name,
                val: studyMap1Val,
                // depth: studyMap1.depth,
                // parent_id: studyMap1.parent_id,
                is_active: is_active ? true : false,
                description: description,
                // brand_id: brand.id ? brand.id : null,

                updater: uid,
                updater_id: _id,
                updater_name: uname,

                uuid: props.item ? undefined : UUID,
            }
            
            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": meta_mapping_meta}
            logger.log("meta_mapping_meta", metamap);
    
            updateMetaMetaMap(metamap);  
    
        } else if(props.type === "1") {

            const parentItem = props.parentItem;
            logger.log('parentItem', parentItem);

            // let _depth = 1;
            // let _code = code;
            // let _parent_id = 0;
            // if(parentItem) {
            //     _depth = parentItem.depth + 1;
            //     _code = props.item ? code : parentItem.code + "-" + code;
            //     _parent_id = parentItem.id!;
            // }

            let _depth = 1;
            let _code = code;
            let _parent_id = 0;
            if(parentItem) {
                _depth = parentItem.depth + 1;
                _code = props.item ? code : parentItem.code + "-" + code;
                const parent = await util.getMetaByCode(parentItem.code);
                logger.log('...parent', parent);
                if(parent) {
                    const _parent = parent as IMeta;
                    _parent_id = _parent.id!;
                } else {
                    alert("오류, 관리자에게 문의 바랍니다.");
                    return;
                }
            } else {
                alert("오류, 관리자에게 문의 바랍니다.");
                return;
            }

            // //code check
            // if(props.item === null) {
            //     const newCode = (curriInfo?.all as IMeta[]).find((item) => item.code === _code);
            //     if(newCode) {
            //         props.showModal("", "중복된 코드가 사용되었습니다.");
            //         (document.getElementById('meta_code') as HTMLInputElement).focus();
            //         (document.getElementById('meta_code') as HTMLInputElement).select();
            //         return;
            //     }    
            // }

            let standard = "";
            standardList.map((item, idx) => {

                const standard_ = (document.getElementById('standard_'+idx) as HTMLInputElement).value

                if(standard_.trim().length > 0) {
                    if(standard === "") {
                        standard = standard_.trim();
                    } else {
                        standard = standard + deli + standard_.trim();
                    }
                }
            });

            let preStudy = "";
            preStudyList.map((item, idx) => {

                const preStudy_ = (document.getElementById('preStudy_'+idx) as HTMLInputElement).value

                if(preStudy_.trim().length > 0) {
                    if(preStudy === "") {
                        preStudy = preStudy_.trim();
                    } else {
                        preStudy = preStudy + deli + preStudy_.trim();
                    }
                }
            });

            let postStudy = "";
            postStudyList.map((item, idx) => {

                const postStudy_ = (document.getElementById('postStudy_'+idx) as HTMLInputElement).value

                if(postStudy_.trim().length > 0) {
                    if(postStudy === "") {
                        postStudy = postStudy_.trim();
                    } else {
                        postStudy = postStudy + deli + postStudy_.trim();
                    }
                }
            });

            let achievementStandard = "";
            achievementStandardList.map((item, idx) => {

                const achievementStandard_ = (document.getElementById('achievementStandard_'+idx) as HTMLInputElement).value

                if(achievementStandard_.trim().length > 0) {
                }

                if(achievementStandard === "") {
                    achievementStandard = achievementStandard_.trim();
                } else {
                    achievementStandard = achievementStandard + deli + achievementStandard_.trim();
                }
            });

            let achievementStandardDesc = "";
            achievementStandardDescList.map((item, idx) => {

                const achievementStandardDesc_ = (document.getElementById('achievementStandardDesc_'+idx) as HTMLInputElement).value

                if(achievementStandardDesc_.trim().length > 0) {
                }

                if(achievementStandardDesc === "") {
                    achievementStandardDesc = achievementStandardDesc_.trim();
                } else {
                    achievementStandardDesc = achievementStandardDesc + deli + achievementStandardDesc_.trim();
                }
            });

            const meta = {

                id: props.item ? props.item.id : undefined,
    
                code: _code,
                name: "studyMap_1",
                val: val,
                depth: _depth,
                parent_id: _parent_id,
                is_active: is_active ? true : false,
                creator: props.item ? undefined : uid,
                creator_id: props.item ? undefined : _id,
                creator_name: props.item ? undefined : uname,
                description: description,
                brand_id: brand.id ? brand.id : null,
    
                updater: props.item ? uid : undefined,
                updater_id: props.item ? _id : undefined,
                updater_name: props.item ? uname : undefined,
                
                uuid: props.item ? undefined : UUID,

                name1: "표준체계 ID",
                name2: "선수학습 ID",
                name3: "후속학습 ID",
                name4: "성취기준",
                name5: "성취기준 내용",

                val1: standard,
                val2: preStudy,
                val3: postStudy,
                val4: achievementStandard,
                val5: achievementStandardDesc,
        
            }

            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": []}
            logger.log("meta_mapping_meta", metamap);
    
            if(props.item) {
                updateMetaMetaMap(metamap);  
            } else {
                insertMetaMetaMap(metamap);  
            }

        } else if(props.type === "2") {

            const parentItem = props.parentItem;
            logger.log('parentItem', parentItem);

            let _depth = 1;
            let _code = code;
            let _parent_id = 0;
            if(parentItem) {
                _depth = parentItem.depth + 1;
                _code = props.item ? code : parentItem.code + "-" + code;
                _parent_id = parentItem.id!;
            }

            const repArticle : {id:number, name: string, content_type: string}[] = [];
            representativeContentsList.map((item, idx) => {
                if(item.id > 0) {

                    const content_type1 = (document.getElementById('content_type1_'+idx) as HTMLInputElement);
                    let content_type = "";
                    if(content_type1.checked) {
                        content_type = "개념";
                    } else {
                        content_type = "영상";
                    }
                    
                    repArticle.push({id: item.id, name: item.name, content_type: content_type});
                }
            });

            const meta = {

                id: props.item ? props.item.id : undefined,
    
                code: _code,
                name: "studyMap_2",
                val: val,
                depth: _depth,
                parent_id: _parent_id,
                is_active: is_active ? true : false,
                creator: props.item ? undefined : uid,
                creator_id: props.item ? undefined : _id,
                creator_name: props.item ? undefined : uname,
                description: description,
                brand_id: brand.id ? brand.id : null,
    
                updater: props.item ? uid : undefined,
                updater_id: props.item ? _id : undefined,
                updater_name: props.item ? uname : undefined,
                
                uuid: props.item ? undefined : UUID,
        
                name1: "대표 콘텐츠",
                val1: JSON.stringify(repArticle),

            }

            // const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": [], "articles": representativeContentsList}
            const metamap : IMetaMap = {"meta": meta as IMeta, "metamapList": [], "articles": (representativeContentsList.length === 1 && representativeContentsList[0].id === -1) ? undefined : representativeContentsList}
            logger.log("meta_mapping_meta", metamap);
    
            if(props.item) {
                updateMetaMetaMap(metamap);  
            } else {
                insertMetaMetaMap(metamap);  
            }
        }

        if(props.item === null) {
            // insertMetaMetaMap(metamap);  
        } else {
            // updateMetaMetaMap(metamap);  
        }

        // logger.log("onSave", meta);
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>) {
        const item = depth1List[e.target.selectedIndex];
        (document.getElementById('meta_code') as HTMLInputElement).value = item.code;
        if(item.uuid && item.uuid.length > 10) {
            setUUID(item.uuid);
        } else {
            const { v4: uuidv4 } = require('uuid');
            const uuid = uuidv4();
            setUUID(uuid);
        }
        logger.log("onChangeUnit1", item, item.uuid);        
    }

    function onAddPreStudy() {
        const _preStudyList = JSON.parse(JSON.stringify(preStudyList));
        _preStudyList.push("");
        setPreStudyList(_preStudyList);
    }

    function onAddPostStudy() {
        const _postStudyList = JSON.parse(JSON.stringify(postStudyList));
        _postStudyList.push("");
        setPostStudyList(_postStudyList);
    }

    function onAddAchievementStandard() {
        const _achievementStandardList = JSON.parse(JSON.stringify(achievementStandardList));
        _achievementStandardList.push("");
        setAchievementStandardList(_achievementStandardList);

        const _achievementStandardDescList = JSON.parse(JSON.stringify(achievementStandardDescList));
        _achievementStandardDescList.push("");
        setAchievementStandardDescList(_achievementStandardDescList);
    }
    
    function onAddStandard() {
        const _standardList = JSON.parse(JSON.stringify(standardList));
        _standardList.push("");
        setStandardList(_standardList);
    }
    
    function onDelStandard(idx: number) {
        const _standardList : string[] = [];
        standardList.map((_, i) => {
            const standard = (document.getElementById('standard_'+i) as HTMLInputElement);
            if(standard) {
                _standardList.push(standard.value);
            }
        });
        _standardList[idx] = "__delete__";
        const _newStandardList = _standardList.filter((s) => s !== "__delete__");
        _newStandardList.map((item, i) => {
            const standard = (document.getElementById('standard_'+i) as HTMLInputElement);
            if(standard) {
                standard.value = item;
            }
        });
        setStandardList(_newStandardList);
    }
    
    function onDelPreStudy(idx: number) {
        const _list : string[] = [];
        preStudyList.map((_, i) => {
            const html = (document.getElementById('preStudy_'+i) as HTMLInputElement);
            if(html) {
                _list.push(html.value);
            }
        });
        _list[idx] = "__delete__";
        const _newList = _list.filter((s) => s !== "__delete__");
        _newList.map((item, i) => {
            const html = (document.getElementById('preStudy_'+i) as HTMLInputElement);
            if(html) {
                html.value = item;
            }
        });
        setPreStudyList(_newList);
    }

    function onDelPostStudy(idx: number) {
        const _list : string[] = [];
        postStudyList.map((_, i) => {
            const html = (document.getElementById('postStudy_'+i) as HTMLInputElement);
            if(html) {
                _list.push(html.value);
            }
        });
        _list[idx] = "__delete__";
        const _newList = _list.filter((s) => s !== "__delete__");
        _newList.map((item, i) => {
            const html = (document.getElementById('postStudy_'+i) as HTMLInputElement);
            if(html) {
                html.value = item;
            }
        });
        setPostStudyList(_newList);
    }

    function onDelAchievementStandard(idx: number) {

        {
            const _list : string[] = [];
            achievementStandardList.map((_, i) => {
                const html = (document.getElementById('achievementStandard_'+i) as HTMLInputElement);
                if(html) {
                    _list.push(html.value);
                }
            });
            _list[idx] = "__delete__";
            const _newList = _list.filter((s) => s !== "__delete__");
            _newList.map((item, i) => {
                const html = (document.getElementById('achievementStandard_'+i) as HTMLInputElement);
                if(html) {
                    html.value = item;
                }
            });
            setAchievementStandardList(_newList);    
        }

        {
            const _listDesc : string[] = [];
            achievementStandardList.map((_, i) => {
                const html = (document.getElementById('achievementStandardDesc_'+i) as HTMLInputElement);
                if(html) {
                    _listDesc.push(html.value);
                }
            });
            _listDesc[idx] = "__delete__";
            const _newListDesc = _listDesc.filter((s) => s !== "__delete__");
            _newListDesc.map((item, i) => {
                const html = (document.getElementById('achievementStandardDesc_'+i) as HTMLInputElement);
                if(html) {
                    html.value = item;
                }
            });
            setAchievementStandardDescList(_newListDesc);    
        }
    }

    function onContents2UI() {
        representativeContentsList.map((item, idx) => {
            const html = (document.getElementById('representativeContents_'+idx) as HTMLInputElement);
            if(html) {
                if(item.id > 0) {
                    html.value = item.name + " - " + item.id;
                } else {
                    html.value = "";
                }
            }
        });
    }

    function onAddContents() {
        const _representativeContentsList = JSON.parse(JSON.stringify(representativeContentsList));
        _representativeContentsList.push({id:-1, content_type:""} as IArticle);
        setRepresentativeContentsList(_representativeContentsList);
    }
    
    function onDelContents(idx: number) {
        if(idx === 0) {
            setRepresentativeContentsList([{id:-1, content_type:""} as IArticle]);
        } else {
            const delContent = representativeContentsList[idx];
            delContent.id = -1000;
            const _representativeContentsList = JSON.parse(JSON.stringify(representativeContentsList)) as IArticle[];
    
            const newList = _representativeContentsList.filter((item) => item.id !== -1000);
            setRepresentativeContentsList(newList);    
        }
    }

    function onSearchArticle(idx: number) {
        setSelectedContentsId(idx);
        setViewPopupContentsList(true);
    }

    async function onSelectContent(item: any) {
        
        logger.log("onSelectContent", item);

        const _representativeContentsList = JSON.parse(JSON.stringify(representativeContentsList)) as IArticle[];

        if(_representativeContentsList.length > selectedContentsId) {
            _representativeContentsList[selectedContentsId] = item;
            setRepresentativeContentsList(_representativeContentsList);
        }

        setViewPopupContentsList(false);
    }

    function onChangeContentType(idx: number) {
        const content_type1_ = document.getElementById("content_type1_"+idx) as HTMLInputElement;
        if(content_type1_) {
            const _representativeContentsList = JSON.parse(JSON.stringify(representativeContentsList)) as IArticle[];
            _representativeContentsList[idx].content_type = content_type1_.checked ? "개념" : "영상";
            setRepresentativeContentsList(_representativeContentsList);
            logger.log("onChangeContentType", idx, content_type1_.checked, _representativeContentsList);
        }
    }

    async function onUpdate_Math_data_gen(ids: string[]) {
        
        const url = "https://visang.tutorusresearch.com/visang/math-data-gen/api";

        const openai_api_key = [
            "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",
            "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",
            "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",
            "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",
            "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"
        ];

        const dateTime = Date.now();
        const timestamp = Math.floor(dateTime / 1000);

        const data = 
        {
            "request_id": "concept_"+timestamp,
            "article_id": ids,
            "type": "concept",
            "image_domain":"https://t-cms.vsaidt.com",
            "data_domain":"https://t-cmsapi.vsaidt.com",
            "openai_api_key":openai_api_key[1],
        }

        // logger.log("onUpdate_Math_data_gen", data);

        let result : any = null;
        await axios.post(url, data, { headers: {}
        }).then(async (response) => {
            logger.log(response);
            result = response.data;
        }).catch((err) => {
            logger.log(err);
        });          

        return result;
    }

    async function onCreateConcept(idx: number) {
        props.showLoading(true);
        const content = representativeContentsList[idx];
        if(content && content.id > -1) {
            const result = await onUpdate_Math_data_gen([String(content.id)]);
            // const concept = await onUpdate_Math_data_get(content.id, "concept");
            logger.log("onCreateConcept", result);
        }
        props.showLoading(false);
    }

    async function onViewConcept(idx: number) {
        const content = representativeContentsList[idx];
        if(content && content.id > -1) {
            setSelectedItem(content);
        }
        setViewPopupTutorConcept(true);
    }

    return (
        <div>

        {/* <!-- table --> */}
        <table>
            <caption className="sr-only">관리테이블</caption>
            <tbody>

                {props.type === "unit1" &&
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="sel-year">메타 값<em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <select id="meta_contentArea">
                            {contentArea && contentArea.map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                        </select>
                    </td>
                </tr>}

                {(props.type === "1" || props.type === "2") &&
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-meta">메타 값 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="meta_val" className="w-avail"/>
                    </td>
                </tr>}

                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-disc">설명</label></th>
                    <td className="text-left">
                        <input type="text" id="meta_description" className="w-avail"/>
                    </td>
                </tr>

                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <input type="text" id="meta_code" className="w-1/2" />
                    </td>
                </tr>

                {props.type === "1" && <>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">표준체계 ID <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">

                        {standardList && standardList.map((item, idx) => {
                            return(
                                <div key={idx}>
                                    <input type="text" id={"standard_"+idx} className={"w-1/2" + (idx > 0 ? " mt-2" : "")} defaultValue={item} />
                                    {idx === 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={onAddStandard}>+</button>}
                                    {idx > 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={() => onDelStandard(idx)}>-</button>}
                                </div>
                            );
                        })}

                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">선수학습 ID</label></th>
                    <td className="text-left">

                        {preStudyList && preStudyList.map((item, idx) => {
                            return(
                                <div key={idx}>
                                    <input type="text" id={"preStudy_"+idx} className={"w-1/2" + (idx > 0 ? " mt-2" : "")} defaultValue={item} />
                                    {idx === 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={onAddPreStudy}>+</button>}
                                    {idx > 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={() => onDelPreStudy(idx)}>-</button>}
                                </div>
                            );
                        })}

                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">후속학습 ID</label></th>
                    <td className="text-left">
                        {postStudyList && postStudyList.map((item, idx) => {
                            return(
                                <div key={idx}>
                                    <input type="text" id={"postStudy_"+idx} className={"w-1/2" + (idx > 0 ? " mt-2" : "")} defaultValue={item} />
                                    {idx === 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={onAddPostStudy}>+</button>}
                                    {idx > 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={() => onDelPostStudy(idx)}>-</button>}
                                </div>
                            );
                        })}
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">성취기준 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        {achievementStandardList && achievementStandardList.map((item, idx) => {
                            return(
                                <div key={idx}>
                                    <input type="text" id={"achievementStandard_"+idx} className={"w-1/2" + (idx > 0 ? " mt-2" : "")} defaultValue={item} />
                                    {idx === 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={onAddAchievementStandard}>+</button>}
                                    {idx > 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={() => onDelAchievementStandard(idx)}>-</button>}
                                </div>
                            );
                        })}
                    </td>
                </tr>
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">성취기준 내용 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        {achievementStandardDescList && achievementStandardDescList.map((item, idx) => {
                            return(
                                <div key={idx}>
                                    <input type="text" id={"achievementStandardDesc_"+idx} className={"w-avail" + (idx > 0 ? " mt-2" : "")} defaultValue={item} />
                                </div>
                            );
                        })}
                    </td>
                </tr></>}

                {props.type === "2" &&
                <tr style={{display:""}}>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">대표 콘텐츠</label></th>
                    <td className="text-left">
                        <div>
                        {representativeContentsList && representativeContentsList.map((item, idx) => {                            

                            return(
                                <div key={idx} className={(idx > 0 ? " mt-2" : "")}>
                                    <input type="text" id={"representativeContents_"+idx} className={"w-1/2"} readOnly />
                                    <button type="button" className="btn-sky-s ml-2" onClick={() => onSearchArticle(idx)}>검색</button>
                                    <span className="ml-3">
                                        <input id={"content_type1_"+idx} name={"content_type_"+idx} type="radio" defaultChecked={item.content_type !== "영상"} onChange={() => onChangeContentType(idx)}/>
                                        <label htmlFor="content_type1">개념</label>
                                    </span>
                                    <span className="">
                                        <input id={"content_type2_"+idx} name={"content_type_"+idx} type="radio" defaultChecked={item.content_type === "영상"} onChange={() => onChangeContentType(idx)} />
                                        <label htmlFor="content_type2">영상</label>
                                    </span>
                                    {idx === 0 &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={onAddContents}>+</button>}
                                    {(idx > 0 || (representativeContentsList.length === 1 && idx === 0)) &&
                                    <button style={{borderRadius: "2rem", paddingTop: 0, paddingBottom: 0, minWidth: "35px", minHeight: "35px"}} 
                                    type="button" className="btn-sky ml-2" onClick={() => onDelContents(idx)}>-</button>}

                                    <button type="button" className="btn-sky-s ml-2" disabled={item.id === -1 || item.content_type === "영상"} onClick={() => onCreateConcept(idx)}>튜터 개념 생성​</button>
                                    <button type="button" className="btn-sky-s ml-2" disabled={item.id === -1 || item.content_type === "영상"} onClick={() => onViewConcept(idx)}>튜터 개념 확인</button>

                                </div>
                            );
                        })}
                        </div>
                    </td>
                </tr>}
                            
                {props.type === "unit1" &&
                <tr>
                    <th scope="row" className="w-56"><label htmlFor="inp-code">단원 선택 <em className="text-sky-500 align-top">*</em></label></th>
                    <td className="text-left">
                        <select id="meta_unit1" onChange={(e) => onChangeUnit1(e)}>
                            {depth1List && depth1List.map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                        </select>
                    </td>
                </tr>}

                <tr>
                    <th scope="row" className="w-56">고유코드</th>
                    <td className="text-left">{UUID}</td>
                </tr>
                <tr>
                    <th scope="row" className="w-56">등록자</th>
                    {props.item &&
                    <td className="text-left">{props.item.creator_name} ({props.item.creator})</td>}
                    {!props.item &&
                    <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
                </tr>
                <tr>
                    <th scope="row" className="w-56">등록일</th>
                    {props.item &&
                    <td className="text-left">{props.item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>}
                    {!props.item &&
                    <td className="text-left">{util.getDate()}</td>}
                </tr>
                <tr>
                    <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                    <td className="text-left">
                        <div className="radio-wrap">
                            <span className="inp-radio">
                                <input id="active" name="rd-use" type="radio" />
                                <label htmlFor="rd-on">사용</label>
                            </span>
                            <span className="inp-radio">
                                <input id="non_active" name="rd-use" type="radio" />
                                <label htmlFor="rd-off">미사용</label>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        {/* <!-- .//table -->	 */}
				
        <div className="relative mt-5">
            <div className="flex justify-between">
                <button type="button" className="btn-white" onClick={() => props.onCloseEdit(false, "")}>취소</button>
                <button type="button" className="btn-sky" onClick={onSave}>{props.item ? "수정" : "등록"}</button>
            </div>
        </div>

        {viewPopupContentsList &&
        <PopupContentsList brand={props.brand} textType={""} showModal={props.showModal} showLoading={props.showLoading} onSelect={onSelectContent} onClose={function (): void {
            setViewPopupContentsList(false);
        } } />}

        {viewPopupTutorConcept &&
        <PopupTutorConceptEdit view={viewPopupTutorConcept} showModal={props.showModal} showLoading={props.showLoading} selectedItem={selectedItem} onClose={() => {
            setViewPopupTutorConcept(false);
        }} />}

        </div>
    );
}

export default MetaStudyMapEdit;
