import { useEffect, useState } from "react";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IBrand, IMenu, ITextBook, ITextBookHistory, latex_delimiters, latex_macros } from "../common";
import { logger } from '../logger';
import PopupConfirm from "../contents/PopupConfirm";
import 'katex/dist/katex.min.css';
import "../latex.css";
import Latex from 'react-latex-next';
import PopupUpdateHistory from "./PopupUpdateHistory";

interface ITextBookHistoryList {
    brand: IBrand;
    menu: IMenu;
    selectedItem: ITextBook;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    onClose: () => void;
}
 
function TextBookHistoryList(props: ITextBookHistoryList) {
    
    const [textbookHistoryList, setTextbookHistoryList] = useState<ITextBookHistory[]>([]);

    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);    
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");

    const [viewUpdateHistory, setViewUpdateHistory] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);

    const [title, setTitle] = useState("");
    const [checkUpdateDate, setCheckUpdateDate] = useState("");

    const currMenu = util.getCurrentMenu();

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            if(props.selectedItem) {
                if(props.selectedItem.id) {
                    getTextBookInfo(props.selectedItem.id);
                }

                let curriBookName = "";
                if(curriInfo && curriInfo.curriBook) {
                    const _curriBook = curriInfo.curriBook.find((item) => item.id === props.selectedItem.curriBook);                    
                    if(_curriBook) {
                        curriBookName = _curriBook.val;
                    }
                }
                
                const _title = props.selectedItem.name + ", " + curriBookName + ", " + props.selectedItem.creator_name;
                setTitle(_title);

                setCheckUpdateDate("");
            }
        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            // logger.log("props.brand", props.brand);
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            // logger.log("props.menu", props.menu);
        }
    }, [props.menu])

    async function getTextBookInfo(id: number) {
        props.showLoading(true);
        const _textbookInfo = await util.getTextBookInfo(id) as any;
        logger.log("getTextBookInfo", _textbookInfo);

        const _textbookHistoryList : ITextBookHistory[] = [];

        if(_textbookInfo && _textbookInfo.textbookIndex && _textbookInfo.textbookIndex.textbookCurriculumList) {
            const _textbookIndex = _textbookInfo.textbookIndex;

            for (let i = 0; i < _textbookIndex.textbookCurriculumList.length; i++) {
                const _textbookCurriculum = _textbookIndex.textbookCurriculumList[i];
                // logger.log("_textbookCurriculum", _textbookCurriculum);
                for (let j = 0; j < _textbookCurriculum.textbookTab.length; j++) {
                    const _textbookTab = _textbookCurriculum.textbookTab[j];                    
                    logger.log("_textbookTab", _textbookTab);

                    if(_textbookTab) {

                        const textbookHistory : ITextBookHistory = {
                            textbookCurriculumId: _textbookCurriculum.id,
                            textbookCurriculumName: _textbookCurriculum.text,
                            textbookTabId: _textbookTab.id,
                            textbookTabName: _textbookTab.name,
                            textbookSetId: _textbookTab.set_id ? _textbookTab.set_id : -1,
                            textbookSetName: _textbookTab.setInfo ? _textbookTab.setInfo.name : "-",
                            textbookArticleCount: _textbookTab.setInfo ? _textbookTab.setInfo.articles.length : 0,
                            dirty: _textbookTab.setInfo ? _textbookTab.setInfo.dirty : false,
                            contentsHistoryExist: _textbookTab.contentsHistoryExist,
                        }
                        // logger.log("textbookHistory", textbookHistory);
                        _textbookHistoryList.push(textbookHistory);    
    
                    }

                    // if(_textbookTab.set_id && Number(_textbookTab.set_id) > -1 && _textbookTab.setInfo) {
                    //     const textbookHistory : ITextBookHistory = {
                    //         textbookCurriculumId: _textbookCurriculum.id,
                    //         textbookCurriculumName: _textbookCurriculum.text,
                    //         textbookTabId: _textbookTab.id,
                    //         textbookTabName: _textbookTab.name,
                    //         textbookSetId: _textbookTab.set_id,
                    //         textbookSetName: _textbookTab.setInfo.name,
                    //         textbookArticleCount: _textbookTab.setInfo.articles.length,
                    //     }
                    //     logger.log("textbookHistory", textbookHistory);
                    //     _textbookHistoryList.push(textbookHistory);    
                    // }

                }                
            }
            logger.log("_textbookHistoryList", _textbookHistoryList);
            setTextbookHistoryList(_textbookHistoryList);
        }

        // const checkUpdateDate = await util.deployRequestDateList({"limit_page":1,"limit_pageItemCnt":1 });
        // if(checkUpdateDate) {
        //     logger.log("checkUpdateDate", checkUpdateDate);
        //     const _date = checkUpdateDate[0].regdate.substring(0, 10).replaceAll("-", ".");
        //     setCheckUpdateDate(_date);
        // }

        props.showLoading(false);
    }

    async function onDelete() {


        setViewConfirmDelete(true);
        setDeleteTitle("삭제");
        setDeleteDesc("삭제하시겠습니까? 복구가 불가합니다.");


    }

    async function onDeleteOK() {
        onCloseDelete();
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }

    function checkUpdateHistory() {
        setCheckUpdateDate(util.getDate());
    }

    return (
        <div>
            <div className="top-title">
                <h3>웹교과서 목록</h3>
            </div>

            <div className="" id="subject" role="tabpanel" aria-labelledby="subject-tab"> 
                
                <div className="mt-3 mb-5 flex justify-center items-center">
                    <span className="text-left w-full mr-3">{title}</span>
                    <span className="text-right mr-3 font-bold" style={{width: "20rem", fontSize: "0.9em", color: "red"}}>{checkUpdateDate === "" ? "" : (checkUpdateDate + " 변경이력 확인​")}</span>
                    <span>                    
                        <button type="button" className="btn-white" style={{width: "10rem"}} onClick={() => {
                        // alert("작업중입니다.");
                        // checkUpdateHistory();
                    }}>변경이력 불러오기</button></span>                    
                </div>

				{/* <!-- table --> */}
				<table>
					<caption className="sr-only">목록</caption>
					<thead>
						<tr>
                            <th scope="col" className="pl-4 pr-3" style={{textAlign: "center"}}>번호</th>
                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>목차명​(key)</th>
                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>탭명</th>
                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>세트지명​(key)</th>
                            <th scope="col" className="px-3" style={{textAlign: "center"}}>아티클수​</th>
                            <th scope="col" className="pl-3" style={{textAlign: "center"}}>바로가기​</th>
                            <th scope="col" className="pl-3 pr-4" style={{textAlign: "center"}}>업데이트 이력</th>
						</tr>
					</thead>
					<tbody>
                        {textbookHistoryList && (textbookHistoryList as ITextBookHistory[]).length === 0 &&
                        <tr>    
                            <td colSpan={7}>{"아직 등록 된 자료가 없습니다."}</td>
                        </tr>}

                        {textbookHistoryList && (textbookHistoryList as ITextBookHistory[]).map((item, idx) => {

                        const totalCnt = textbookHistoryList.length;
                        let _num = totalCnt - idx;
                        
                        return (
                            <tr className="" key={idx}>
                                <td className="">{_num}</td>
                                <td className=""><Latex delimiters={latex_delimiters} macros={latex_macros}>{item.textbookCurriculumName}</Latex>({item.textbookCurriculumId})</td>
                                <td className="">{item.textbookTabName}</td>
                                <td className="">{item.textbookSetName} {item.textbookSetId === -1 ? "" : "("+item.textbookSetId+")"}</td>
                                <td className="">{item.textbookArticleCount}</td>
                                <td className="">
                                    {item.textbookSetId === -1 ? "-" :
                                    <button type="button" className="btn-white-s" onClick={() => {
                                        const brand = util.getCurrentBrand();
                                        if(!brand || !brand.id) {
                                            return;
                                        }

                                        let url = "";
                                        if(item) {
                                            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                            +"&set_id="+item.textbookSetId+"&token="+util.getUserInfo("token");
                                        } else {
                                            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
                                            +"&create=y"+"&token="+util.getUserInfo("token");
                                        }
                                        window.open(url, "_blank", "noopener, noreferrer");
                                    }}>{"바로가기"}</button>}                                    
                                </td>
                                <td className="">
                                    {item.textbookSetId === -1 ? "-" :
                                    <button type="button" className="btn-white-s" style={{backgroundColor: item.contentsHistoryExist ? "#00dd00" : "#ffffff", color: item.contentsHistoryExist ? "#ffffff" : "#000000"}} 
                                    onClick={() => {
                                        // alert("작업중입니다.");
                                        setSelectedItem(item);
                                        setViewUpdateHistory(true);
                                    }}>{"확인"}</button>}                                    
                                </td>
                            </tr>
                        )})}
					</tbody>
				</table>
				{/* <!-- .//table -->	 */}

                <div className="flex justify-between text-right py-5">
                    <button type="button" className="btn-white" onClick={() => {props.onClose()}}>목록</button>
                </div>

            </div>

            {viewUpdateHistory && <PopupUpdateHistory selectedItem={selectedItem} title={title} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewUpdateHistory(false);
            }} />}

            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />

        </div>

    );  
}
export default TextBookHistoryList;
