import React, { ChangeEvent, useEffect, useState } from 'react';
import './App.css';
import * as util from './util';

import { HOST, IAuthGroup, IBrand, IUser, VERSION, isDev } from './common';
import { logger } from './logger';

interface IHeader {
    brand: IBrand;
    onChangeBrand: (brand: IBrand) => void;
    onLogout: () => void;
}

function Header(props: IHeader) {

    const [myBrandList, setMyBrandList] = useState([] as any);
    const [myID, setMyID] = useState("");
    const [view, setView] = useState(false);

    let bDone = false;

    useEffect(() => {
        if(!bDone && myBrandList.length === 0) {
            getMyBrandList();
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.brand) {
            const brand = document.getElementById('brand') as HTMLSelectElement;
            if(brand) {
                brand.value = props.brand.code;
            }
            setView(true);
        }
    }, [props.brand])

    async function getMyBrandList() {

        const brandList = await util.getBrandList();
        logger.log("getMyBrandList", brandList);
        if(!brandList) {
            alert("브랜드 가져오기 실패!!!");
            return;
        }

        const authGroupList = await util.getAuthGroupList();
        if(!authGroupList) {
            alert("권한 가져오기 실패!!!");
            return;
        }

        setMyID(util.getUserInfo("uid"));
        const me = await util.getUserById(util.getUserInfo("uid")); 
        if(!me) {
            alert("사용자 가져오기 실패!!!");
            onLogout();
            return;
        }

        const authgroup = (me as IUser).authgroup;

        let myAuthGroup : IAuthGroup[] = [];
        const arrAuthGroupId = authgroup.split(",");
        arrAuthGroupId.map((aid) => {
            const authGroup = authGroupList.find((item) => item.id === Number(aid));
            if(authGroup) {
                myAuthGroup.push(authGroup);
            }
        });
        logger.log("setMyBrandList", arrAuthGroupId, myAuthGroup);

        const _myBrandList : IBrand[] = [];

        myAuthGroup.map((item) => {
            const _brand = brandList.find((brand) => brand.id === item.brand_id);
            if(_brand) {
                _myBrandList.push(_brand);
            }
        });

        setMyBrandList(_myBrandList);
        // logger.log("setMyBrandList", _myBrandList);

        let currBrand = util.getCurrentBrand();
        if(!currBrand) {
            currBrand = _myBrandList[0];
        }
        props.onChangeBrand(currBrand);
    }

    function onChangeBrand(event: ChangeEvent<HTMLSelectElement>): void {
        const _result = (myBrandList as IBrand[]).find((_brand, _) => _brand.code === event.target.value);
        if(_result) {
            props.onChangeBrand(_result);
        }
    }

    function onLogout(): void {
        props.onLogout();
    }
    
    return (
        <header className="header">
            <h1 className="logo">
                <a aria-label="Home page" href="./index.html">
                    <svg aria-hidden="true" viewBox="0 0 36 36" fill="none">
                        <g fill="none" stroke="#38BDF8" strokeLinejoin="round" strokeWidth="3">
                            <path d="M10.308 5L18 17.5 10.308 30 2.615 17.5 10.308 5z"></path>
                            <path d="M18 17.5L10.308 5h15.144l7.933 12.5M18 17.5h15.385L25.452 30H10.308L18 17.5z"></path>
                        </g>
                    </svg>
                    <span>{isDev ? "비상교육 콘텐츠 은행(Dev) " + localStorage.getItem("HOST") : "비상교육 콘텐츠 은행"}</span>
                </a>
            </h1>
            {/* <br/><button onClick={() => onLogout()}>로그아웃</button><br/> */}
            <div className="text-left" style={{display: view ? "" : "none"}}><span>{myID}</span></div>
            <div className="text-left" style={{color:"white"}}>V.{VERSION}</div>
            <div className="brand-sel" style={{display: view ? "" : "none"}}>
                <label htmlFor="location">브랜드 선택</label>
                <select id="brand" name="brand" onChange={(e) => onChangeBrand(e)}>
                    {myBrandList && (myBrandList as IBrand[]).map((brand, idx) => {
                        return (
                            <option key={idx} value={brand.code}>{brand.name}</option>              
                        )
                    })}
                </select>
            </div>
        </header>
    );      
}

export default Header;
