import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IAuthGroup, IBrand, IMenu, authGroupInfo, authGroupInfo_new, leftmenu_1, leftmenu_2 } from "../common";
import { logger } from '../logger';

interface IMenuInfo {
    id: number;
    name: string;
    parent: string;
    depth: number;
    menu: string;
    auth: string;
    order?: number;
}

interface _IAuthGroup {
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function AuthGroup(props: _IAuthGroup) {

    const [authGroupList, setAuthGroupList] = useState([] as IAuthGroup[]);
    const [brandList, setBrandList] = useState([] as IBrand[]);
    const [viewList, setViewList] = useState(false);
    const [searchedBrandList, setSearchedBrandList] = useState([] as IAuthGroup[]);

    const [searchData, setSearchData] = useState(null as any);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);

    const [menu, setMenu] = useState<IMenuInfo[]>([]);

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            let _menuInfo : IMenuInfo[] = [];
            for (let i = 0; i < leftmenu_1.length; i++) {
                const _menu : IMenuInfo = {
                    id: _menuInfo.length,
                    name: leftmenu_1[i],
                    parent: "",
                    depth: 1,
                    menu: ""+i+":",
                    auth: "["+authGroupInfo[i]+"]",
                };
                _menuInfo.push(_menu);
                for (let j = 0; j < leftmenu_2[i].length; j++) {
                    const _menu : IMenuInfo = {
                        id: _menuInfo.length,
                        name: leftmenu_2[i][j],
                        parent: leftmenu_1[i],
                        depth: 2,
                        menu: ""+i+":"+j,
                        auth: "["+authGroupInfo_new[i][j]+"]",
                    }
                    _menuInfo.push(_menu);
                }
            }
            logger.log("_menuInfo", _menuInfo);
            setMenu(_menuInfo);
            
            setIsEditing(false);
            getAuthGroupList();
        }
        bDone = true;
    }, [])

    useEffect(() => {
        if(props.menu) {
            if(isEditing) {
                setIsEditing(false);
            }
        }
    }, [props.menu])

    useEffect(() => {

        if(isEditing) {

            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     return;
            // }    

            const AG_name = (document.getElementById('AG_name') as HTMLInputElement);
            if(AG_name) {
                AG_name.focus();
            }                    
            const AG_is_active = (document.getElementById('AG_is_active') as HTMLInputElement);
            if(AG_is_active) {
                AG_is_active.checked = true;
            }
            (document.getElementById('AG_code') as HTMLInputElement).readOnly = true;

            menu.map((_, idx) => {
                const AG_auth_read_ = (document.getElementById('AG_auth_read_'+idx) as HTMLInputElement);
                if(AG_auth_read_) {
                    AG_auth_read_.checked = true;
                }
                const AG_auth_write_ = (document.getElementById('AG_auth_write_'+idx) as HTMLInputElement);
                if(AG_auth_write_) {
                    AG_auth_write_.checked = true;
                }
            });

            (document.getElementById('AG_brand') as HTMLSelectElement).disabled = false;

            const _selectedItem = selectedItem as IAuthGroup;
            logger.log("_selectedItem", _selectedItem);

            if(_selectedItem) {
                (document.getElementById('AG_name') as HTMLInputElement).value = _selectedItem.name;
                let description = (document.getElementById('AG_desc') as HTMLInputElement);
                description.value = _selectedItem.description ? _selectedItem.description : "";
                (document.getElementById('AG_code') as HTMLInputElement).value = _selectedItem.code;
                (document.getElementById('AG_code') as HTMLInputElement).readOnly = true;
                (document.getElementById('AG_is_active') as HTMLInputElement).checked = _selectedItem.is_active;    
                (document.getElementById('AG_non_active') as HTMLInputElement).checked = !_selectedItem.is_active;    
                const AG_brand = (document.getElementById('AG_brand') as HTMLSelectElement);
                AG_brand.disabled = true;
                AG_brand.value = String(_selectedItem.brand_id);
                const authgroup = _selectedItem.auth;

                menu.map((_, idx) => {
                    const AG_auth_read_ = (document.getElementById('AG_auth_read_'+idx) as HTMLInputElement);
                    if(AG_auth_read_) {
                        AG_auth_read_.checked = false;
                    }
                    const AG_auth_write_ = (document.getElementById('AG_auth_write_'+idx) as HTMLInputElement);
                    if(AG_auth_write_) {
                        AG_auth_write_.checked = false;
                    }
                });

                if(authgroup.indexOf("[") < 0) {
                    authGroupInfo.map((item) => {
                        if(authgroup.indexOf(item) > -1) {
                            for (let i = 0; i < menu.length; i++) {
                                const ele = menu[i];
                                if(ele.auth.indexOf("["+item+"") > -1) {
                                    const AG_auth_read_ = (document.getElementById('AG_auth_read_'+i) as HTMLInputElement);
                                    if(AG_auth_read_) {
                                        AG_auth_read_.checked = true;
                                    }            
                                }
                            }                            
                        }
                        if(authgroup.indexOf(item+"1") > -1) {
                            for (let i = 0; i < menu.length; i++) {
                                const ele = menu[i];
                                if(ele.auth.indexOf("["+item+"") > -1) {
                                    const AG_auth_write_ = (document.getElementById('AG_auth_write_'+i) as HTMLInputElement);
                                    if(AG_auth_write_) {
                                        AG_auth_write_.checked = true;
                                    }
                                }
                            }
                        }
                    });
                } else {
                    menu.map((item, idx) => {
                        if(authgroup.indexOf(item.auth) > -1) {
                            const AG_auth_read_ = (document.getElementById('AG_auth_read_'+idx) as HTMLInputElement);
                            if(AG_auth_read_) {
                                AG_auth_read_.checked = true;
                            }            
                            const AG_auth_write_ = (document.getElementById('AG_auth_write_'+idx) as HTMLInputElement);
                            if(AG_auth_write_) {
                                AG_auth_write_.checked = true;
                            }
                        }
                    });
                }
            }
        } else {
            (document.getElementById('AG_name') as HTMLInputElement).value = "";
            let description = (document.getElementById('AG_desc') as HTMLInputElement);
            description.value = "";
            (document.getElementById('AG_code') as HTMLInputElement).value = "";
            (document.getElementById('AG_code') as HTMLInputElement).readOnly = false;
            (document.getElementById('AG_is_active') as HTMLInputElement).checked = true;
            (document.getElementById('AG_brand') as HTMLSelectElement).selectedIndex = 0;
        }

    }, [isEditing])

    function isVisibleMenu(idx1: number, idx2: number) : boolean {

        const item = leftmenu_1[idx1];
        const item2 = leftmenu_2[idx1][idx2];
        // const brand = util.getCurrentBrand();

        let brand = brandList[0];
        const _selectedItem = selectedItem as IAuthGroup;
        if(_selectedItem) {
            brand = brandList.find((brand) => brand.id === _selectedItem.brand_id)!;
        }

        let view = true;

        // if(item === "라이브러리 관리") {   
        //     if(idx2 === 4 || idx2 === 5 || idx2 === 6) {
        //         view = viewTextDepth3;
        //     }
        // }

        if(item === "메타 정보 관리") {   
            if(idx2 === 17 || idx2 === 18 || idx2 === 19 || idx2 === 21 || idx2 === 22 || idx2 === 23 || idx2 === 24) {
                if(brand && brand.code === "VAET01") {
                    view = true;
                } else {
                    view = false;
                }
            }
            if(idx2 === 20) {
                if(brand && brand.code === "VIVA00") {
                    view = true;
                } else {
                    view = false;
                }
            }
            if(idx2 === 25 || idx2 === 26 || idx2 === 27) {
                if(brand && brand.code === "VIVA01") {
                    view = true;
                } else {
                    view = false;
                }
            }
            if(idx2 === 28 || idx2 === 29 || idx2 === 30 || idx2 === 31) {
                if(brand && brand.code === "VSTB00") {
                    view = true;
                } else {
                    view = false;
                }
            }
            
            // if(idx2 === 25) {
            //     if(brand && brand.code === "VSTB00") {
            //         view = true;
            //     }
            // }

        }        

        if(item === "AI 튜터 관리") {  
            if(idx2 === 1 || idx2 === 2 || idx2 === 3) {
                if(brand && brand.code === "VAET01") {
                    view = true;
                } else {
                    view = false;
                }
            }
        }

        if(item === "교과도구 관리") {  
            if(idx2 === 0) {
                if(brand && brand.code === "VAET01") {
                    view = false;
                } else {
                    view = true;
                }
            } else if(idx2 === 1) {
                if(brand && brand.code === "VAET01") {
                    view = true;
                } else {
                    view = false;
                }
            }
        }

        return view;
    }

    async function getAuthGroupList() {

        props.showLoading(true);

        const authGroupList = await util.getAuthGroupList();
        if(!authGroupList) {
            alert("권한 그룹 가져오기 실패!!!");
            return;
        }
        
        const _authGroupList = authGroupList.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        setAuthGroupList(_authGroupList);
        setSearchedBrandList(_authGroupList);
        logger.log("authGroupList", _authGroupList);  
        
        const brandList = await util.getBrandList();
        setBrandList(brandList);
        logger.log("brandList", brandList);  

        setViewList(true);

        props.showLoading(false);
    }

    function onEdit(item: IAuthGroup | null = null): void {
        setIsEditing(true);
        setSelectedItem(item);
        logger.log("onEdit", item);
    }

    function onSearch(): void {

        const AG_Active = document.getElementById("AG_Active") as HTMLSelectElement;
        const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement).value;
        const AG_Key = document.getElementById("AG_Key") as HTMLSelectElement;

        const searchData = {
            "active":AG_Active.value,
            "key":AG_Key.value,
            "value":searchKeyword,
        }

        const result : IAuthGroup[] = [];
        let filterdBrand : IAuthGroup[] = [];
        if(searchData.active === "1") {
            filterdBrand = authGroupList.filter((item) => item.is_active === true);
        } else if(searchData.active === "0") {
            filterdBrand = authGroupList.filter((item) => item.is_active === false);
        } else {
            filterdBrand = authGroupList;
        }
        if(searchData.value !== "") {
            if(searchData.key === "group") {
                filterdBrand.map((item) => {
                    if(item.name.indexOf(searchData.value) > -1) {
                        result.push(item);
                    }
                });
            } else if(searchData.key === "creator") {
                filterdBrand.map((item) => {
                    if(item.creator && item.creator.indexOf(searchData.value) > -1) {
                        result.push(item);
                    }
                });
            }    
        } else {
            filterdBrand.map((item) => {
                result.push(item);
            });
        }

        setSearchedBrandList(result);
        logger.log("onSearch", searchData, result);
    }

    async function onSave() {

        const name = (document.getElementById('AG_name') as HTMLInputElement).value
        const brand = (document.getElementById('AG_brand') as HTMLSelectElement)
        const is_active = (document.getElementById('AG_is_active') as HTMLInputElement).checked

        const brand_name = brandList[brand.selectedIndex].name;
        const brand_id = brandList[brand.selectedIndex].id;

        let authgroupVal = "";

        // authGroupInfo.map((item, idx) => {

        //     const AG_auth_read_ = (document.getElementById('AG_auth_read_'+idx) as HTMLInputElement);
        //     const AG_auth_write_ = (document.getElementById('AG_auth_write_'+idx) as HTMLInputElement);

        //     const AG_auth_read = AG_auth_read_ ? AG_auth_read_.checked : false;
        //     const AG_auth_write = AG_auth_write_ ? AG_auth_write_.checked : false;
            
        //     if(AG_auth_write) {
        //         authgroupVal = authgroupVal + authGroupInfo[idx] + "1,";
        //     } else {
        //         if(AG_auth_read) {
        //             authgroupVal = authgroupVal + authGroupInfo[idx] + ",";
        //         }    
        //     }
        // });

        menu.map((item, idx) => {

            const AG_auth_read_ = (document.getElementById('AG_auth_read_'+idx) as HTMLInputElement);
            const AG_auth_read = AG_auth_read_ ? AG_auth_read_.checked : false;

            if(AG_auth_read) {
                authgroupVal = authgroupVal + item.auth + ",";
            }    
        });

        const brand_code = brandList[brand.selectedIndex].code;        
        const len = authGroupList.filter((item) => item.brand_id === brand_id).length;
        const code = brand_code + (len < 10 ? "0"+len : len);
    
        if(name === "") {
            props.showModal("", "권한 그룹명을 입력해 주세요.");
            (document.getElementById('AG_name') as HTMLInputElement).focus();
            return;
        } else if(code === "") {
            props.showModal("", "코드를 입력해 주세요.");
            (document.getElementById('AG_code') as HTMLInputElement).focus();
            return;
        }

        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const _selectedItem = selectedItem as IAuthGroup;

        const authgroup: IAuthGroup = {
            id: _selectedItem ? _selectedItem.id : undefined,
            code: _selectedItem ? _selectedItem.code : code,
            name: name,
            is_active: is_active,
            creator: _selectedItem ? undefined : uid,
            creator_id: _selectedItem ? undefined : _id,
            creator_name: _selectedItem ? undefined : uname,
            updater: _selectedItem ? uid : undefined,
            updater_id: _selectedItem ? _id : undefined,
            updater_name: _selectedItem ? uname : undefined,
            auth: authgroupVal,
            brand_id: brand_id,
            brand_name: brand_name,
        }

        logger.log("onSave", authgroup);

        props.showLoading(true);
        
        if(_selectedItem) {
            await util.updateAuthGroup(authgroup);
        } else {
            await util.addAuthGroup(authgroup);
        }
        setIsEditing(false);
        getAuthGroupList();

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    function onChangeCB(e: ChangeEvent<HTMLInputElement>, item: IMenuInfo) {

        if(item.menu === "0:3") {
            const menu_0_4 = menu.find((_menu) => _menu.menu === "0:4");
            const menu_0_5 = menu.find((_menu) => _menu.menu === "0:5");
            const menu_0_6 = menu.find((_menu) => _menu.menu === "0:6");
            if(menu_0_4) {
                const AG_auth_read_0_4 = document.getElementById('AG_auth_read_'+menu_0_4.id) as HTMLInputElement;
                if(AG_auth_read_0_4) {
                    AG_auth_read_0_4.checked = e.target.checked;
                }
            }
            if(menu_0_5) {
                const AG_auth_read_0_5 = document.getElementById('AG_auth_read_'+menu_0_5.id) as HTMLInputElement;
                if(AG_auth_read_0_5) {
                    AG_auth_read_0_5.checked = e.target.checked;
                }
            }
            if(menu_0_6) {
                const AG_auth_read_0_6 = document.getElementById('AG_auth_read_'+menu_0_6.id) as HTMLInputElement;
                if(AG_auth_read_0_6) {
                    AG_auth_read_0_6.checked = e.target.checked;
                }
            }
        } else if(item.menu === "0:4" || item.menu === "0:5" || item.menu === "0:6") {

            let bChecked = false;

            const menu_0_4 = menu.find((_menu) => _menu.menu === "0:4");
            const menu_0_5 = menu.find((_menu) => _menu.menu === "0:5");
            const menu_0_6 = menu.find((_menu) => _menu.menu === "0:6");
            if(menu_0_4) {
                const AG_auth_read_0_4 = document.getElementById('AG_auth_read_'+menu_0_4.id) as HTMLInputElement;
                if(AG_auth_read_0_4 && AG_auth_read_0_4.checked) {
                    bChecked = true;
                }
            }
            if(menu_0_5) {
                const AG_auth_read_0_5 = document.getElementById('AG_auth_read_'+menu_0_5.id) as HTMLInputElement;
                if(AG_auth_read_0_5 && AG_auth_read_0_5.checked) {
                    bChecked = true;
                }
            }
            if(menu_0_6) {
                const AG_auth_read_0_6 = document.getElementById('AG_auth_read_'+menu_0_6.id) as HTMLInputElement;
                if(AG_auth_read_0_6 && AG_auth_read_0_6.checked) {
                    bChecked = true;
                }
            }

            const menu_0_3 = menu.find((_menu) => _menu.menu === "0:3");
            if(menu_0_3) {
                const AG_auth_read_0_3 = document.getElementById('AG_auth_read_'+menu_0_3.id) as HTMLInputElement;
                if(AG_auth_read_0_3) {
                    AG_auth_read_0_3.checked = bChecked;
                }    
            }
        } else if(item.menu === "8:2" || item.menu === "8:3") {

            let bChecked = false;

            const menu_8_2 = menu.find((_menu) => _menu.menu === "8:2");
            const menu_8_3 = menu.find((_menu) => _menu.menu === "8:3");

            if(menu_8_2) {
                const AG_auth_read_8_2 = document.getElementById('AG_auth_read_'+menu_8_2.id) as HTMLInputElement;
                if(AG_auth_read_8_2 && AG_auth_read_8_2.checked) {
                    bChecked = true;
                }
            }
            if(menu_8_3) {
                const AG_auth_read_8_3 = document.getElementById('AG_auth_read_'+menu_8_3.id) as HTMLInputElement;
                if(AG_auth_read_8_3 && AG_auth_read_8_3.checked) {
                    bChecked = true;
                }
            }

            const menu_8_1 = menu.find((_menu) => _menu.menu === "8:1");
            if(menu_8_1) {
                const AG_auth_read_8_1 = document.getElementById('AG_auth_read_'+menu_8_1.id) as HTMLInputElement;
                if(AG_auth_read_8_1) {
                    AG_auth_read_8_1.checked = bChecked;
                }    
            }
        } else if(item.menu === "8:1") {
            const menu_8_2 = menu.find((_menu) => _menu.menu === "8:2");
            const menu_8_3 = menu.find((_menu) => _menu.menu === "8:3");
            if(menu_8_2) {
                const AG_auth_read_8_2 = document.getElementById('AG_auth_read_'+menu_8_2.id) as HTMLInputElement;
                if(AG_auth_read_8_2) {
                    AG_auth_read_8_2.checked = e.target.checked;
                }
            }
            if(menu_8_3) {
                const AG_auth_read_8_3 = document.getElementById('AG_auth_read_'+menu_8_3.id) as HTMLInputElement;
                if(AG_auth_read_8_3) {
                    AG_auth_read_8_3.checked = e.target.checked;
                }
            }
        }

        //getChildren
        const children = menu.filter((_menu) => _menu.parent === item.name);
        // logger.log("onChangeCB", e.target.checked, item, children);

        if(children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                const AG_auth_read_ = document.getElementById('AG_auth_read_'+child.id) as HTMLInputElement;
                if(AG_auth_read_) {
                    AG_auth_read_.checked = e.target.checked;
                }
            }        
        } else {
            let bChecked = false;
            const siblings = menu.filter((_menu) => _menu.parent === item.parent);
            for (let i = 0; i < siblings.length; i++) {
                const bns = siblings[i];
                const AG_auth_read_ = document.getElementById('AG_auth_read_'+bns.id) as HTMLInputElement;
                if(AG_auth_read_) {
                    if(AG_auth_read_.checked) {
                        bChecked = true;
                        break;
                    }
                }
            }   

            const arr = item.menu.split(":");
            const parent = menu.find((_menu) => _menu.menu === arr[0]+":");

            if(parent) {
                const AG_auth_read_ = document.getElementById('AG_auth_read_'+parent.id) as HTMLInputElement;
                if(AG_auth_read_) {
                    AG_auth_read_.checked = bChecked;
                }    
            }
        }
        
        if(e.target.checked) {
        } else {
        }
    }

    return (

    <>
    {/* <!-- 검색영역 --> */}
    <div className="px-8 py-8 mb-8 border border-gray-300 rounded-xl" style={{display:isEditing ? "none" : ""}}>
        <div className="text-right">
            <fieldset>
                <label htmlFor="">사용 설정</label>
                <select id="AG_Active">
                    <option value="">전체</option>
                    <option value="1">사용</option>
                    <option value="0">미사용</option>
                </select>
                <label htmlFor="AG_Key" className="ml-3">검색 선택</label>
                <select id="AG_Key">
                    <option value="group">그룹명</option>
                    <option value="creator">등록자</option>
                </select>
                <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        onSearch();
                    }
                }} />
                <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                    <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                </button>
            </fieldset>
        </div>
    </div>
    {/* <!-- .//검색영역 --> */}        

    <table style={{display:isEditing ? "none" : ""}}>
        <caption className="sr-only">권한 그룹 관리</caption>
        <thead>
            <tr>
                <th scope="col" className="pl-4 pr-3" style={{width:"80px"}}>번호</th>
                <th scope="col" className="px-3">그룹명</th>
                <th scope="col" className="px-3">코드</th>
                <th scope="col" className="px-3">브랜드</th>
                <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                <th scope="col" className="px-3" style={{width:"100px"}}>등록자​</th>
                <th scope="col" className="px-3" style={{width:"100px"}}>등록일​</th>
                <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
            </tr>
        </thead>
        
        <tbody>
            {viewList && searchedBrandList &&
            <tr style={{display: (searchedBrandList as IAuthGroup[]).length === 0 ? "" : "none"}}>
                <td colSpan={8}>등록된 메타 정보가 없습니다.</td>
            </tr>}

            {viewList && searchedBrandList && (searchedBrandList as IAuthGroup[]).map((item, idx) => {
            const _brand = brandList.find((brand) => brand.id === item.brand_id);
            return (
                <tr key={idx}>
                    <td>{searchedBrandList.length-idx}</td>
                    <td>{item.name}</td>
                    <td>{item.code}</td>
                    <td>{_brand?.name}</td>
                    {item.is_active && <td>사용</td>}
                    {!item.is_active && <td><span className="text-red-600">미사용</span></td>}
                    <td className="leading-4">{item.creator}</td>
                    <td className="leading-4">{item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>
                    <td>
                        <button type="button" className="btn-white-s" onClick={() => onEdit(item)}>보기</button>
                    </td>
                </tr>
                );
            })}
        </tbody>

    </table>

    <div className="relative mt-5" style={{display:isEditing ? "none" : ""}}>
        <div className="flex">
            <div className="text-right" style={{width: "100%"}}>
                <button type="button" className="btn-sky" onClick={() => onEdit()}>등록</button>
            </div>
        </div>
    </div>

    <table style={{display:isEditing ? "" : "none"}}>
        <caption className="sr-only">권한 그룹 관리</caption>
        <tbody>
            <tr>
                <th scope="row" className="w-56"><label htmlFor="inp-meta">그룹명 <em className="text-sky-500 align-top">*</em></label></th>
                <td className="text-left">
                    <input type="text" id="AG_name" className="w-1/2" onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <th scope="row" className="w-56"><label htmlFor="AG_desc">브랜드 선택 <em className="text-sky-500 align-top">*</em></label></th>
                <td className="text-left">
                    <select id="AG_brand">
                        {brandList && brandList.map((item, idx) => {
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            );
                        })}
                    </select>
                    <input type="text" id="AG_desc" onChange={() => {}} style={{display:"none"}}/>
                </td>
            </tr>

            <tr>
                <td colSpan={2} style={{padding:0}}>
                    <table>
                        <thead>
                            <tr>
                                <th scope="row" className="w-full" colSpan={3}><label htmlFor="inp-meta">메뉴 선택 <em className="text-sky-500 align-top">*</em></label></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{paddingLeft:0, paddingRight:0}}>
                                    <table>
                                        <thead>
                                            <tr>    
                                                <th scope="row" className="w-1/2" style={{backgroundColor:"rgb(229 231 235 / var(--tw-bg-opacity)"}}><label htmlFor="inp-meta">메뉴명</label></th>
                                                <th scope="row" className="w-56"><label htmlFor="inp-meta">읽기 권한</label></th>
                                                <th scope="row" className="w-56"><label htmlFor="inp-meta">쓰기 권한</label></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {menu && menu.map((item, idx) => {
                                                const depth = item.depth;
                                                let className = depth === 1 ? "text-left" : "text-left pl-5";
                                                const name = item.name;
                                                if(name === "어휘 관리" || name === "문장 관리" || name === "지문 관리" || name === "자유대화 주제" || name === "작문 주제") {
                                                    className = "text-left pl-10";
                                                }

                                                let view = true;
                                                const arrMenu = item.menu.split(":");
                                                if(arrMenu[1] !== "") {
                                                    view = isVisibleMenu(Number(arrMenu[0]), Number(arrMenu[1]));
                                                }
                                        
                                                return (
                                                    <tr key={idx} style={{display: view ? "" : "none"}}>
                                                        <td className={className}>{name}</td>
                                                        <td>
                                                            <input id={"AG_auth_read_"+idx} type="checkbox" onChange={(e) => onChangeCB(e, item)} />
                                                        </td>
                                                        <td>
                                                            <input id={"AG_auth_write_"+idx} type="checkbox" />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>


            <tr>
                <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                <td className="text-left">
                    <input type="text" id="AG_code" onChange={() => {}} />
                </td>
            </tr>
            <tr>
                <th scope="row" className="w-56">등록자</th>
                {selectedItem &&
                <td className="text-left">{selectedItem.creator_name} ({selectedItem.creator})</td>}
                {!selectedItem &&
                <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
            </tr>
            <tr>
                <th scope="row" className="w-56">등록일</th>
                {selectedItem &&
                <td className="text-left">{selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
                {!selectedItem &&
                <td className="text-left">{util.getDate()}</td>}
            </tr>
            <tr>
                <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                <td className="text-left">
                    <div className="radio-wrap">
                        <span className="inp-radio text-sky-500">
                            <input id="AG_is_active" name="rd-use" type="radio" />
                            <label htmlFor="rd-on">사용</label>
                        </span>
                        <span className="inp-radio text-red-600">
                            <input id="AG_non_active" name="rd-use" type="radio" />
                            <label htmlFor="rd-off">미사용</label>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
            
    <div className="relative mt-5" style={{display:isEditing ? "" : "none"}}>
        <div className="flex justify-between">
            <button type="button" className="btn-white" onClick={() => {
                setIsEditing(false);
            }}>목록</button>
            <button type="button" className="btn-sky" onClick={onSave}>{selectedItem ? "수정" : "등록"}</button>
        </div>
    </div>
    </>);
}
export default AuthGroup;
