import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as util from '../util';
import '../common.scss';
import { IMeta, ITextBookIndex, IUser } from "../common";
import { logger } from "../logger";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { NodeData } from "./TextBookIndexEdit";

interface IPopupAddCurriUnit {
    selectedNode: NodeModel;
    curriBook: IMeta;
    treeData: NodeModel[];
    onClose: () => void;
    onSave: (data1: any, data2: any, data3: any, chk_all_children: boolean) => void;
    showModal: (title: string, description: string) => void;    
    showLoading: (view: boolean) => void;    
}

function PopupAddCurriUnit(props: IPopupAddCurriUnit) {

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);

    const [curriUnitList, setCurriUnitList] = useState([] as any);
    const [curriUnitMetaList, setCurriUnitMetaList] = useState([] as any);
    const [studyMapList, setStudyMapList] = useState([] as any);
    const [studyMapMetaList, setStudyMapMetaList] = useState([] as any);
    const [studyMapValList, setStudyMapValList] = useState([] as any);

    const curriInfo = util.getCurrentCurriInfo();
    const selectedNode = props.selectedNode;

    const [studyMapsList, setStudyMapsList] = useState<IMeta[]>([]);
    const [studyMap0List, setStudyMap0List] = useState<IMeta[]>([]);
    const [studyMap1List, setStudyMap1List] = useState<IMeta[]>([]);
    const [studyMap2List, setStudyMap2List] = useState<IMeta[]>([]);
    const [studyMap3List, setStudyMap3List] = useState<IMeta[]>([]);
    const [studyMap4List, setStudyMap4List] = useState<IMeta[]>([]);
    const [studyMap5List, setStudyMap5List] = useState<IMeta[]>([]);

    const SM_studyMap1 = document.getElementById("SM_studyMap1") as HTMLSelectElement;
    const SM_studyMap2 = document.getElementById("SM_studyMap2") as HTMLSelectElement;
    const SM_studyMap3 = document.getElementById("SM_studyMap3") as HTMLSelectElement;
    const SM_studyMap4 = document.getElementById("SM_studyMap4") as HTMLSelectElement;
    const SM_studyMap5 = document.getElementById("SM_studyMap5") as HTMLSelectElement;
    
    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            logger.log("props", props);

            if(selectedNode) {

                const data = selectedNode.data as NodeData;
                if(data && data.curriUnitList) {
                    const curriUnitList = JSON.parse(data.curriUnitList) as string[];
                    if(curriUnitList && curriUnitList.length > 0) {
                        setCurriUnitList(curriUnitList);
                    }
                }
                if(data && data.studyMapList) {
                    const studyMapList = JSON.parse(data.studyMapList) as string[];
                    if(studyMapList && studyMapList.length > 0) {
                        setStudyMapList(studyMapList);
                    }
                }
                if(data && data.studyMapValList) {
                    const studyMapValList = JSON.parse(data.studyMapValList) as string[];
                    if(studyMapValList && studyMapValList.length > 0) {
                        setStudyMapValList(studyMapValList);
                    }
                }

                const children = props.treeData.filter((node) => node.parent === selectedNode.id);
                if(children.length === 0) {
                    const all_children = document.getElementById("all_children") as HTMLElement;        
                    all_children.style.display = "none";
                }

            } else {
                onClose();
            }

            if(props.curriBook) {

                getDescendantsList(props.curriBook.code);
    
                const CU_html_curriUnit1 = document.getElementById("CU_curriUnit1") as HTMLSelectElement;
                const CU_html_curriUnit2 = document.getElementById("CU_curriUnit2") as HTMLSelectElement;
                const CU_html_curriUnit3 = document.getElementById("CU_curriUnit3") as HTMLSelectElement;
                const CU_html_curriUnit4 = document.getElementById("CU_curriUnit4") as HTMLSelectElement;
                const CU_html_curriUnit5 = document.getElementById("CU_curriUnit5") as HTMLSelectElement;
            
                CU_html_curriUnit1.selectedIndex = 0;
                CU_html_curriUnit2.selectedIndex = 0;
                CU_html_curriUnit3.selectedIndex = 0;
                CU_html_curriUnit4.selectedIndex = 0;
                if(CU_html_curriUnit5) {
                    CU_html_curriUnit5.selectedIndex = 0;
                }
        
                setCurriUnit2([]);
                setCurriUnit3([]);
                setCurriUnit4([]);
                setCurriUnit5([]);

                getStudyMapList();

            } else {
                onClose();
            }
        }
        bDone = true;
    }, [])

    function onClose() {
        props.onClose();
    }

    async function onAdd() {

        if((curriUnitList as []).length > 4) {
            props.showModal("", "최대 5개까지만 선택 가능합니다.");
            return;
        }

        let val = "";
        const CU_curriUnit1 = document.getElementById("CU_curriUnit1") as HTMLSelectElement;
        if(CU_curriUnit1.selectedIndex > 0) {

            const _curriUnitMetaList = JSON.parse(JSON.stringify(curriUnitMetaList)) as IMeta[];

            const CU_curriUnit1_val = curriUnit1[CU_curriUnit1.selectedIndex-1]
            val = "" + CU_curriUnit1_val.id;
            _curriUnitMetaList.push(CU_curriUnit1_val);
            const CU_curriUnit2 = document.getElementById("CU_curriUnit2") as HTMLSelectElement;
            if(CU_curriUnit2.selectedIndex > 0) {
                const CU_curriUnit2_val = curriUnit2[CU_curriUnit2.selectedIndex-1]
                val = val + "," + CU_curriUnit2_val.id;
                _curriUnitMetaList.push(CU_curriUnit2_val);
                const CU_curriUnit3 = document.getElementById("CU_curriUnit3") as HTMLSelectElement;
                if(CU_curriUnit3.selectedIndex > 0) {
                    const CU_curriUnit3_val = curriUnit3[CU_curriUnit3.selectedIndex-1]
                    val = val + "," + CU_curriUnit3_val.id;
                    _curriUnitMetaList.push(CU_curriUnit3_val);
                    const CU_curriUnit4 = document.getElementById("CU_curriUnit4") as HTMLSelectElement;
                    if(CU_curriUnit4.selectedIndex > 0) {
                        const CU_curriUnit4_val = curriUnit4[CU_curriUnit4.selectedIndex-1]
                        val = val + "," + CU_curriUnit4_val.id;
                        _curriUnitMetaList.push(CU_curriUnit4_val);
                        const CU_curriUnit5 = document.getElementById("CU_curriUnit5") as HTMLSelectElement;                            
                        if(CU_curriUnit5.selectedIndex > 0) {
                            const CU_curriUnit5_val = curriUnit5[CU_curriUnit5.selectedIndex-1]
                            val = val + "," + CU_curriUnit5_val.id;
                            _curriUnitMetaList.push(CU_curriUnit5_val);
                        }                    
                    }                    
                }        
            }    

            setCurriUnitMetaList(_curriUnitMetaList);

            const _curriUnitList = JSON.parse(JSON.stringify(curriUnitList)) as string[];
            const found = _curriUnitList.find((item) => item === val);
            if(!found) {
                _curriUnitList.push(val);
                setCurriUnitList(_curriUnitList);    
                logger.log("onAdd", _curriUnitList);    
            }    
        }
    }

    async function onAddStudyMap() {

        let val = "";
        let name_val = "";

        const SM_studyMap0 = document.getElementById("SM_studyMap0") as HTMLSelectElement;
        if(SM_studyMap0.selectedIndex > 0) {

            const _studyMapMetaList = JSON.parse(JSON.stringify(studyMapMetaList)) as IMeta[];

            const SM_studyMap0_val = studyMap0List[SM_studyMap0.selectedIndex-1]
            val = "" + SM_studyMap0_val.id;
            name_val = "" + SM_studyMap0_val.val;
            _studyMapMetaList.push(SM_studyMap0_val);

            const SM_studyMap1 = document.getElementById("SM_studyMap1") as HTMLSelectElement;
            if(SM_studyMap1.selectedIndex > 0) {
                const SM_studyMap1_val = studyMap1List[SM_studyMap1.selectedIndex-1]
                val = val + "," + SM_studyMap1_val.id;
                name_val = name_val + "," + SM_studyMap1_val.val;
                _studyMapMetaList.push(SM_studyMap1_val);
                const SM_studyMap2 = document.getElementById("SM_studyMap2") as HTMLSelectElement;
                if(SM_studyMap2.selectedIndex > 0) {
                    const SM_studyMap2_val = studyMap2List[SM_studyMap2.selectedIndex-1]
                    val = val + "," + SM_studyMap2_val.id;
                    name_val = name_val + "," + SM_studyMap2_val.val;
                    _studyMapMetaList.push(SM_studyMap2_val);
                    const SM_studyMap3 = document.getElementById("SM_studyMap3") as HTMLSelectElement;
                    if(SM_studyMap3.selectedIndex > 0) {
                        const SM_studyMap3_val = studyMap3List[SM_studyMap3.selectedIndex-1]
                        val = val + "," + SM_studyMap3_val.id;
                        name_val = name_val + "," + SM_studyMap3_val.val;
                        _studyMapMetaList.push(SM_studyMap3_val);
                        const SM_studyMap4 = document.getElementById("SM_studyMap4") as HTMLSelectElement;
                        if(SM_studyMap4.selectedIndex > 0) {
                            const SM_studyMap4_val = studyMap4List[SM_studyMap4.selectedIndex-1]
                            val = val + "," + SM_studyMap4_val.id;
                            name_val = name_val + "," + SM_studyMap4_val.val;
                            _studyMapMetaList.push(SM_studyMap4_val);
                            const SM_studyMap5 = document.getElementById("SM_studyMap5") as HTMLSelectElement;                            
                            if(SM_studyMap5.selectedIndex > 0) {
                                const SM_studyMap5_val = studyMap5List[SM_studyMap5.selectedIndex-1]
                                val = val + "," + SM_studyMap5_val.id;
                                name_val = name_val + "," + SM_studyMap5_val.val;
                                _studyMapMetaList.push(SM_studyMap5_val);
                            }                    
                        }                    
                    }        
                }              
            }

            setStudyMapMetaList(_studyMapMetaList);

            const _studyMapList = JSON.parse(JSON.stringify(studyMapList)) as string[];
            const found = _studyMapList.find((item) => item === val);
            if(!found) {
                _studyMapList.push(val);
                setStudyMapList(_studyMapList);    
                logger.log("onAddStudyMap", _studyMapList);    
            }    
            const _studyMapValList = JSON.parse(JSON.stringify(studyMapValList)) as string[];
            const foundVal = _studyMapValList.find((item) => item === name_val);
            if(!foundVal) {
                _studyMapValList.push(name_val);
                setStudyMapValList(_studyMapValList);    
                logger.log("onAddStudyMap", _studyMapValList);    
            }    
        }
    }

    async function onSave() {
        const chk_all_children = document.getElementById("chk_all_children") as HTMLInputElement;        
        props.onSave(curriUnitList, studyMapList, studyMapValList, chk_all_children.checked);
    }

    async function getDescendantsList(code: string) {

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {

            props.showLoading(true);

            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);

            props.showLoading(false);

            return list;
        }
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);
        logger.log("onChangeUnit1", e.target.value, result);

        const CU_html_curriUnit2 = document.getElementById("CU_curriUnit2") as HTMLSelectElement;
        const CU_html_curriUnit3 = document.getElementById("CU_curriUnit3") as HTMLSelectElement;
        const CU_html_curriUnit4 = document.getElementById("CU_curriUnit4") as HTMLSelectElement;
        const CU_html_curriUnit5 = document.getElementById("CU_curriUnit5") as HTMLSelectElement;
    
        CU_html_curriUnit2.selectedIndex = 0;
        CU_html_curriUnit3.selectedIndex = 0;
        CU_html_curriUnit4.selectedIndex = 0;
        if(CU_html_curriUnit5) {
            CU_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        const CU_html_curriUnit3 = document.getElementById("CU_curriUnit3") as HTMLSelectElement;
        const CU_html_curriUnit4 = document.getElementById("CU_curriUnit4") as HTMLSelectElement;
        const CU_html_curriUnit5 = document.getElementById("CU_curriUnit5") as HTMLSelectElement;
    
        CU_html_curriUnit3.selectedIndex = 0;
        CU_html_curriUnit4.selectedIndex = 0;
        if(CU_html_curriUnit5) {
            CU_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        const CU_html_curriUnit4 = document.getElementById("CU_curriUnit4") as HTMLSelectElement;
        const CU_html_curriUnit5 = document.getElementById("CU_curriUnit5") as HTMLSelectElement;
    
        CU_html_curriUnit4.selectedIndex = 0;
        if(CU_html_curriUnit5) {
            CU_html_curriUnit5.selectedIndex = 0;
        }

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        const CU_html_curriUnit5 = document.getElementById("CU_curriUnit5") as HTMLSelectElement;    
        if(CU_html_curriUnit5) {
            CU_html_curriUnit5.selectedIndex = 0;
        }
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    function onDelUnit(val: string) {
        const _curriUnitList = JSON.parse(JSON.stringify(curriUnitList)) as string[];
        const newList = _curriUnitList.filter((item) => item !== val);
        setCurriUnitList(newList);    
        // logger.log("onDelUnit", _curriUnitList, val, newList);    
    }

    function onDelStudyMap(idx: number) {
        const _studyMapList = JSON.parse(JSON.stringify(studyMapList)) as string[];        
        const _studyMap = _studyMapList.at(idx);
        const newList = _studyMapList.filter((item) => item !== _studyMap);
        setStudyMapList(newList);    

        const _studyMapValList = JSON.parse(JSON.stringify(studyMapValList)) as string[];        
        const _studyValMap = _studyMapValList.at(idx);
        const newValList = _studyMapValList.filter((item) => item !== _studyValMap);
        setStudyMapValList(newValList);    

        logger.log("onDelStudyMap", newList, newValList);    
    }

    async function getStudyMapList() {

        props.showLoading(true);

        const brand = util.getCurrentBrand();

        const metaMap: {"name": string, "id": number}[] = [];

        const data = {
            "brand_id": brand?.id,
            "depth" : "1",
            "name":"studyMap",
            "metaMap": metaMap,
            "is_active": true,
        }

        metaMap.push({"name":"curriBook", "id":Number(props.curriBook.id)});

        data.metaMap = metaMap;
      
        const list = await util.searchMeta(data);    
        // const list = await util.getMetaList("1", "studyMap");
        if(list && (list as IMeta[]).length > 0) {
            const _list = (list as IMeta[]).sort((a, b) => a.id! < b.id! ? 1 : -1);	
            logger.log("getStudyMapList", _list);
            setStudyMap0List(_list);
        }
        
        props.showLoading(false);
    }

    async function getStudyMapDescendantsList(code: string) {

        props.showLoading(true);

        const list = await util.getDescendantsList(code);

        const _studyMap = list.find((item) => item.code === code);
        if(!_studyMap) {
            return;
        }
        const _list = list.filter((item) => item.is_active === true);
        setStudyMapsList(_list);

        const _filtered_1 = _list.filter((item) => item.name === "studyMap1");
        setStudyMap1List(_filtered_1);

        logger.log("getStudyMapDescendantsList", list, _list);

        props.showLoading(false);
    }

    async function onChangeStudyMap(e: ChangeEvent<HTMLSelectElement>) {
        if(e.target.selectedIndex > 0) {
            const _studyMap = studyMap0List[e.target.selectedIndex-1];
            logger.log("onChangeStudyMap", _studyMap);
    
            getStudyMapDescendantsList(_studyMap.code);    
        } else {
            setStudyMap1List([]);
        }

        SM_studyMap1.selectedIndex = 0;

        setStudyMap2List([]);
        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
    }

    async function onChangeStudyMap1(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_2 = (studyMapsList as IMeta[]).filter((item) => item.depth === 3);
        logger.log("onChangeStudyMap1", _filtered_2);

        const result = _filtered_2.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap2List(result);
        logger.log("onChangeStudyMap1", e.target.value, result);

        SM_studyMap2.selectedIndex = 0;

        setStudyMap3List([]);
        setStudyMap4List([]);
        setStudyMap5List([]);
    }

    async function onChangeStudyMap2(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_3 = (studyMapsList as IMeta[]).filter((item) => item.depth === 4);
        logger.log("onChangeStudyMap2", _filtered_3);

        const result = _filtered_3.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap3List(result);
        logger.log("onChangeStudyMap2", e.target.value, result);

        SM_studyMap3.selectedIndex = 0;

        setStudyMap4List([]);
        setStudyMap5List([]);
    }

    async function onChangeStudyMap3(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_4 = (studyMapsList as IMeta[]).filter((item) => item.depth === 5);
        logger.log("onChangeStudyMap3", _filtered_4);

        const result = _filtered_4.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap4List(result);
        logger.log("onChangeStudyMap3", e.target.value, result);

        SM_studyMap4.selectedIndex = 0;

        setStudyMap5List([]);
    }

    async function onChangeStudyMap4(e: ChangeEvent<HTMLSelectElement>) {
        const _filtered_5 = (studyMapsList as IMeta[]).filter((item) => item.depth === 6);
        logger.log("onChangeStudyMap4", _filtered_5);

        const result = _filtered_5.filter((item) => item.parent_id === Number(e.target.value))
        setStudyMap5List(result);
        logger.log("onChangeStudyMap4", e.target.value, result);

        SM_studyMap5.selectedIndex = 0;
    }

    return (
        
        <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="dim"></div>
            {selectedNode &&
            <div className="popup-contents">
                <div className="popup-contents-inner">
                    <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                    <div className="popup-box min-w-[768px]">
                        {/* <!-- 팝업 내용 --> */}
                        <div className="inner">
                            <button type="button" className="btn-close" onClick={() => {
                                props.onClose();
                            }}>
                                <span className="sr-only">Close</span>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 className="pop-title">교과과정 연결​</h2>
                            <div>
                                <div className="px-5">
                                    <div className="py-5 text-center">
                                        <table style={{borderTopWidth:0}}>
                                            <tbody>
                                                <tr>
                                                    <td className="text-right" colSpan={3} style={{paddingTop:"0.25rem", paddingBottom:"0.25rem"}}>
                                                        <span>* 교과서 목차 - 교과과정 연결은 AI추천학습을 위한 용도로만 활용됩니다.</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56"><label htmlFor="inp-meta">교과과정</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        {props.curriBook?.val}
                                                    </td>
                                                </tr>
                                                <tr className="items-center">
                                                    <th scope="row" className="w-56"><label htmlFor="inp-disc">선택 목차​</label></th>
                                                    <td className="text-left">
                                                        <span>{selectedNode.text}</span>
                                                    </td>
                                                    <td id="all_children" className="text-right" style={{borderLeftWidth:0}}>
                                                        <input id="chk_all_children" type="checkbox" />
                                                        <span className="ml-2">단원 하위에도 동일한 매핑 적용</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56"><label htmlFor="inp-code">교과과정 선택</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        <select className="mr-2" id="CU_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriUnit1 && (curriUnit1 as IMeta[]).length > 0 && curriDepth > 0 ? "" : "none"}}>
                                                            <option value="0">1Depth</option>              
                                                            {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="CU_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriUnit2 && (curriUnit2 as IMeta[]).length > 0 && curriDepth > 1 ? "" : "none"}}>
                                                            <option value="0">2Depth</option>              
                                                            {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="CU_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriUnit3 && (curriUnit3 as IMeta[]).length > 0 && curriDepth > 2 ? "" : "none"}}>
                                                            <option value="0">3Depth</option>              
                                                            {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="CU_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriUnit4 && (curriUnit4 as IMeta[]).length > 0 && curriDepth > 3 ? "" : "none"}}>
                                                            <option value="0">4Depth</option>              
                                                            {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="CU_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriUnit5 && (curriUnit5 as IMeta[]).length > 0 && curriDepth > 4 ? "" : "none"}}>
                                                            <option value="0">5Depth</option>              
                                                            {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>    

                                                        <button type="button" className="btn-sky-s mr-3" onClick={onAdd}>추가</button>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56">선택된​<br/>교과과정​</th>
                                                    <td className="text-left" colSpan={2}>
                                                        {curriUnitList && (curriUnitList as string[]).map((item, idx) => {

                                                            let val = "";
                                                            const _bookDescendantsList = bookDescendantsList as IMeta[];
                                                            if(_bookDescendantsList) {

                                                                const arr = item.split(",");
                                                                arr.map((_val) => {
                                                                    const unit = _bookDescendantsList.find((_item) => _item.id === Number(_val));
                                                                    if(unit) {
                                                                        if(val === "") {
                                                                            val = unit.val;
                                                                        } else {
                                                                            val = val + " > " + unit.val;
                                                                        }    
                                                                    }
                                                                });                                                                
                                                            }

                                                            return(
                                                                <div key={idx} className="flex py-1">
                                                                    <button type="button" className="btn-sky-s mr-3" onClick={() => onDelUnit(item)}>삭제</button>
                                                                    <span>{val}</span>
                                                                </div>              
                                                        )})}                                                        
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row" className="w-56"><label htmlFor="inp-code">학습맵</label></th>
                                                    <td className="text-left" colSpan={2}>
                                                        <select className="mr-2" id="SM_studyMap0" name="SM_studyMap0" onChange={(e) => onChangeStudyMap(e)} style={{display:studyMap0List && (studyMap0List as IMeta[]).length > 0 ? "" : "none"}}>
                                                            <option value="0">학습맵</option>              
                                                            {studyMap0List && (studyMap0List as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="SM_studyMap1" name="SM_studyMap1" onChange={(e) => onChangeStudyMap1(e)} style={{display:studyMap1List && (studyMap1List as IMeta[]).length > 0 ? "" : "none"}}>
                                                            <option value="0">1Depth</option>              
                                                            {studyMap1List && (studyMap1List as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="SM_studyMap2" name="SM_studyMap2" onChange={(e) => onChangeStudyMap2(e)} style={{display:studyMap2List && (studyMap2List as IMeta[]).length > 0 ? "" : "none"}}>
                                                            <option value="0">2Depth</option>              
                                                            {studyMap2List && (studyMap2List as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="SM_studyMap3" name="SM_studyMap3" onChange={(e) => onChangeStudyMap3(e)} style={{display:studyMap3List && (studyMap3List as IMeta[]).length > 0 ? "" : "none"}}>
                                                            <option value="0">3Depth</option>              
                                                            {studyMap3List && (studyMap3List as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="SM_studyMap4" name="SM_studyMap4" onChange={(e) => onChangeStudyMap4(e)} style={{display:studyMap4List && (studyMap4List as IMeta[]).length > 0 ? "" : "none"}}>
                                                            <option value="0">4Depth</option>              
                                                            {studyMap4List && (studyMap4List as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>
                                                        <select className="mr-2" id="SM_studyMap5" name="SM_studyMap5" style={{display:studyMap5List && (studyMap5List as IMeta[]).length > 0 ? "" : "none"}}>
                                                            <option value="0">5Depth</option>              
                                                            {studyMap5List && (studyMap5List as IMeta[]).map((item, idx) => {
                                                            return(
                                                            <option key={idx} value={item.id}>{item.val}</option>              
                                                            )})}
                                                        </select>    

                                                        <button type="button" className="btn-sky-s mr-3" onClick={onAddStudyMap}>추가</button>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="w-56">선택된​<br/>학습맵</th>
                                                    <td className="text-left" colSpan={2}>
                                                        {studyMapValList && (studyMapValList as string[]).map((item, idx) => {

                                                            let val = "";
                                                            // const _studyMapsList = studyMapsList as IMeta[];
                                                            // if(_studyMapsList) {

                                                            //     const arr = item.split(",");
                                                            //     arr.map((_val) => {
                                                            //         const unit = _studyMapsList.find((_item) => _item.id === Number(_val));
                                                            //         if(unit) {
                                                            //             if(val === "") {
                                                            //                 val = unit.val;
                                                            //             } else {
                                                            //                 val = val + " > " + unit.val;
                                                            //             }    
                                                            //         }
                                                            //     });                                                                
                                                            // }

                                                            const arr = item.split(",");
                                                            arr.map((_val) => {
                                                                if(val === "") {
                                                                    val = _val;
                                                                } else {
                                                                    val = val + " > " + _val;
                                                                }    
                                                        });                                                                

                                                            return(
                                                                <div key={idx} className="flex py-1">
                                                                    <button type="button" className="btn-sky-s mr-3" onClick={() => onDelStudyMap(idx)}>삭제</button>
                                                                    <span>{val}</span>
                                                                </div>              
                                                        )})}                                                        
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="my-5 text-center">
                                    <button type="button" className="btn-sky mr-3" onClick={onSave}>저장</button>
                                    <button type="button" className="btn-white" onClick={onClose}>닫기</button>
                                </div>
                            </div>
                            
                        </div>
                        {/* <!-- .//팝업 내용 --> */}
                    </div>                    
                </div>
            </div>}
        </div>        
    );
}
  
export default PopupAddCurriUnit;
