import { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IBrand, IMenu } from "../common";
import { logger } from '../logger';

interface _IBrand {
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

function Brand(props: _IBrand) {

    const [brandList, setBrandList] = useState([] as IBrand[]);
    const [searchedBrandList, setSearchedBrandList] = useState([] as IBrand[]);
    const [viewList, setViewList] = useState(false);

    const [searchData, setSearchData] = useState(null as any);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);

    const currMenu = util.getCurrentMenu();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {
            setIsEditing(false);
            getBrandList();
        }
        bDone = true;
    }, [])

    useEffect(() => {

        if(isEditing) {

            // const editAuth = util.getEditAuth();
            // if(!editAuth[currMenu.menu1]) {
            //     props.showModal("", "권한이 없습니다.");
            //     setIsEditing(false);
            //     return;
            // }    
    
            const B_name = (document.getElementById('B_name') as HTMLInputElement);
            if(B_name) {
                B_name.focus();
            }                    
            const B_is_active = (document.getElementById('B_is_active') as HTMLInputElement);
            if(B_is_active) {
                B_is_active.checked = true;
            }
            (document.getElementById('B_code') as HTMLInputElement).readOnly = false;

            const _selectedItem = selectedItem as IBrand;
            if(_selectedItem) {
                (document.getElementById('B_name') as HTMLInputElement).value = _selectedItem.name;
                let description = (document.getElementById('B_desc') as HTMLInputElement);
                description.value = _selectedItem.description ? _selectedItem.description : "";
                (document.getElementById('B_code') as HTMLInputElement).value = _selectedItem.code;
                (document.getElementById('B_code') as HTMLInputElement).readOnly = true;
                (document.getElementById('B_is_active') as HTMLInputElement).checked = _selectedItem.is_active;    
                (document.getElementById('B_non_active') as HTMLInputElement).checked = !_selectedItem.is_active;    
            }
        } else {
            (document.getElementById('B_name') as HTMLInputElement).value = "";
            let description = (document.getElementById('B_desc') as HTMLInputElement);
            description.value = "";
            (document.getElementById('B_code') as HTMLInputElement).value = "";
            (document.getElementById('B_is_active') as HTMLInputElement).checked = true;
        }

    }, [isEditing])

    useEffect(() => {
        if(props.menu) {
            if(isEditing) {
                setIsEditing(false);
            }
        }
    }, [props.menu])

    async function getBrandList() {

        props.showLoading(true);

        const brandList = await util.getBrandList();
        if(!brandList) {
            alert("브랜드 가져오기 실패!!!");
            return;
        }
        const _brandList = brandList.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        setBrandList(_brandList);
        setSearchedBrandList(_brandList);
        logger.log("brandList", _brandList);
        setViewList(true);

        props.showLoading(false);
    }

    function onEdit(item: IBrand | null = null): void {
        setIsEditing(true);
        setSelectedItem(item);
    }

    function onSearch(): void {

        const B_Active = document.getElementById("B_Active") as HTMLSelectElement;
        const searchKeyword = (document.getElementById('searchKeyword') as HTMLInputElement).value;
        const B_Key = document.getElementById("B_Key") as HTMLSelectElement;

        const searchData = {
            "active":B_Active.value,
            "key":B_Key.value,
            "value":searchKeyword,
        }

        const result : IBrand[] = [];
        let filterdBrand : IBrand[] = [];
        if(searchData.active === "1") {
            filterdBrand = brandList.filter((item) => item.is_active === true);
        } else if(searchData.active === "0") {
            filterdBrand = brandList.filter((item) => item.is_active === false);
        } else {
            filterdBrand = brandList;
        }
        if(searchData.value !== "") {
            if(searchData.key === "brand") {
                filterdBrand.map((item) => {
                    if(item.name.indexOf(searchData.value) > -1) {
                        result.push(item);
                    }
                });
            } else if(searchData.key === "creator") {
                filterdBrand.map((item) => {
                    if(item.creator && item.creator.indexOf(searchData.value) > -1) {
                        result.push(item);
                    }
                });
            }    
        } else {
            filterdBrand.map((item) => {
                result.push(item);
            });
        }

        setSearchedBrandList(result);
        logger.log("onSearch", searchData, result);
    }

    async function onSave() {

        const name = (document.getElementById('B_name') as HTMLInputElement).value
        const description = (document.getElementById('B_desc') as HTMLInputElement).value
        const code = (document.getElementById('B_code') as HTMLInputElement).value
        const is_active = (document.getElementById('B_is_active') as HTMLInputElement).checked

        if(name === "") {
            props.showModal("", "브랜드 명을 입력해 주세요.");
            (document.getElementById('B_name') as HTMLInputElement).focus();
            return;
        } else if(code === "") {
            props.showModal("", "코드를 입력해 주세요.");
            (document.getElementById('B_code') as HTMLInputElement).focus();
            return;
        }

        if(!selectedItem) {
            let regMatch = /^[a-zA-Z]*$/.test(code.substring(0, 4));
            if(regMatch) {
                regMatch = /^[0-9\b]+$/.test(code.substring(4, 6));
            }
            if(code.length !== 6 || !regMatch) {
                props.showModal("", "알파벳 4자 + 숫자 2개 조합으로 입력해 주세요");
                (document.getElementById('B_code') as HTMLInputElement).focus();
                return;
            }    
        }
        
        const _id = util.getUserInfo("id");
        const uid = util.getUserInfo("uid");
        const uname = util.getUserInfo("name");

        const _selectedItem = selectedItem as IBrand;
        const brand: IBrand = {
            id: _selectedItem ? _selectedItem.id : undefined,
            code: _selectedItem ? _selectedItem.code : code,
            name: name,
            is_active: is_active,
            creator: _selectedItem ? undefined : uid,
            creator_id: _selectedItem ? undefined : _id,
            creator_name: _selectedItem ? undefined : uname,
            updater: _selectedItem ? uid : undefined,
            updater_id: _selectedItem ? _id : undefined,
            updater_name: _selectedItem ? uname : undefined,
            description: description,
        }

        props.showLoading(true);

        if(_selectedItem) {
            await util.updateBrand(brand);
        } else {
            await util.addBrand(brand);
        }
        logger.log("onSave", brand);
        setIsEditing(false);
        getBrandList();

        props.showLoading(false);
        props.showModal("", "저장이 완료 되었습니다.");
    }

    return (

    <>
    {/* <!-- 검색영역 --> */}
    <div className="px-8 py-8 mb-8 border border-gray-300 rounded-xl" style={{display:isEditing ? "none" : ""}}>
        <div className="text-right">
            <fieldset>
                <label htmlFor="">사용 설정</label>
                <select id="B_Active">
                    <option value="">전체</option>
                    <option value="1">사용</option>
                    <option value="0">미사용</option>
                </select>
                <label htmlFor="B_Key" className="ml-3">검색 선택</label>
                <select id="B_Key">
                    <option value="brand">브랜드</option>
                    <option value="creator">등록자</option>
                </select>
                <input type="text" name="searchKeyword" id="searchKeyword" className="w-[300px] ml-3 mr-2" onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        onSearch();
                    }
                }} />
                <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                    <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                </button>
            </fieldset>
        </div>
    </div>
    {/* <!-- .//검색영역 --> */}        

    <table style={{display:isEditing ? "none" : ""}}>
        <caption className="sr-only">브랜드 관리</caption>
        <thead>
            <tr>
                <th scope="col" className="pl-4 pr-3" style={{width:"80px"}}>번호</th>
                <th scope="col" className="px-3">Key</th>
                <th scope="col" className="px-3">브랜드</th>
                <th scope="col" className="px-3">코드</th>
                <th scope="col" className="px-3">설명</th>
                <th scope="col" className="px-3" style={{width:"80px"}}>사용</th>
                <th scope="col" className="px-3" style={{width:"100px"}}>등록자​</th>
                <th scope="col" className="px-3" style={{width:"100px"}}>등록일​</th>
                <th scope="col" className="pl-3 pr-4" style={{width:"100px"}}>상세보기</th>
            </tr>
        </thead>
        
        <tbody>
            {viewList && searchedBrandList &&
            <tr style={{display: (searchedBrandList as IBrand[]).length === 0 ? "" : "none"}}>
                <td colSpan={9}>등록된 메타 정보가 없습니다.</td>
            </tr>}

            {viewList && searchedBrandList && (searchedBrandList as IBrand[]).map((item, idx) => {

            return (
                <tr key={idx}>
                    <td>{searchedBrandList.length-idx}</td>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.code}</td>
                    <td className="text-left"><p className="w-52 xl:w-80 truncate">{item.description}</p></td>
                    {item.is_active && <td>사용</td>}
                    {!item.is_active && <td><span className="text-red-600">미사용</span></td>}
                    <td className="leading-4">{item.creator}</td>
                    <td className="leading-4">{item.regdate?.substring(0, 10).replaceAll("-", ".")}</td>
                    <td>
                        <button type="button" className="btn-white-s" onClick={() => onEdit(item)}>보기</button>
                    </td>
                </tr>
                );
            })}
        </tbody>

    </table>

    <div className="relative mt-5" style={{display:isEditing ? "none" : ""}}>
        <div className="flex">
            <div className="text-right" style={{width: "100%"}}>
                <button type="button" className="btn-sky" onClick={() => onEdit()}>등록</button>
            </div>
        </div>
    </div>

    <table style={{display:isEditing ? "" : "none"}}>
        <caption className="sr-only">브랜드 관리</caption>
        <tbody>
            <tr>
                <th scope="row" className="w-56"><label htmlFor="inp-meta">브랜드 명 <em className="text-sky-500 align-top">*</em></label></th>
                <td className="text-left">
                    <input type="text" id="B_name" onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <th scope="row" className="w-56"><label htmlFor="B_desc">설명</label></th>
                <td className="text-left">
                    <input type="text" id="B_desc" onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <th scope="row" className="w-56"><label htmlFor="inp-code">코드 <em className="text-sky-500 align-top">*</em></label></th>
                <td className="text-left">
                    <input type="text" id="B_code" onChange={() => {}} /><span className="ml-2 text-red-600" style={{paddingLeft:"1rem"}}>* 알파벳 4자 + 숫자 2개 조합으로 입력해 주세요.</span>
                </td>
            </tr>
            <tr>
                <th scope="row" className="w-56">등록자</th>
                {selectedItem &&
                <td className="text-left">{selectedItem.creator_name} ({selectedItem.creator})</td>}
                {!selectedItem &&
                <td className="text-left">{util.getUserInfo("name")} ({util.getUserInfo("uid")})</td>}
            </tr>
            <tr>
                <th scope="row" className="w-56">등록일</th>
                {selectedItem &&
                <td className="text-left">{selectedItem.regdate.substring(0, 10).replaceAll("-", ".")}</td>}
                {!selectedItem &&
                <td className="text-left">{util.getDate()}</td>}
            </tr>
            <tr>
                <th scope="row" className="w-56">사용 설정<em className="text-sky-500 align-top">*</em></th>
                <td className="text-left">
                    <div className="radio-wrap">
                        <span className="inp-radio text-sky-500">
                            <input id="B_is_active" name="rd-use" type="radio" />
                            <label htmlFor="rd-on">사용</label>
                        </span>
                        <span className="inp-radio text-red-600">
                            <input id="B_non_active" name="rd-use" type="radio" />
                            <label htmlFor="rd-off">미사용</label>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
            
    <div className="relative mt-5" style={{display:isEditing ? "" : "none"}}>
        <div className="flex justify-between">
            <button type="button" className="btn-white" onClick={() => {
                setIsEditing(false);
            }}>목록</button>
            <button type="button" className="btn-sky" onClick={onSave}>{selectedItem ? "수정" : "등록"}</button>
        </div>
    </div>
    </>);
}
export default Brand;
